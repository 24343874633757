export const EXPORT_TYPES = ['csv', 'txt'];
export const EXPORT_FIELDS_MAP = Object.freeze({
  code: 'Code',
  language: 'Language',
  level: 'Level',
  date: 'Date',
  status: 'Job Status',
  message: 'Message',
});

export const WARNING_MESSAGE = 'Only records for the past two months will be included in the exported file. Please do not close the page until the export is finished.';
export const NO_DATA = 'There is no data to export!';
export const MAIN_ERROR = 'Sorry we couldn\'t export data. Please, try later.';
export const REQUEST_LIMIT = 2000;

export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} h:mm:ss a`;

export const FILE_NAME_DATE_FORMAT = 'MM-dd-yyyy';
export const FILE_NAME_DATE_TIME_FORMAT = `${FILE_NAME_DATE_FORMAT} h_mm_ss a`;

export const MAX_DOWNLOAD_RETRIES = 3;
export const DOWNLOAD_ABORT_EXCEPTION = new DOMException('Aborted', 'AbortError');
export const MAX_DOWNLOAD_FETCH_TIMEOUT = 10000;
