import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectResource } from 'ra-redux/selectors';

import {
  Id,
  Locale,
  Segment,
} from './Breadcrumb';

const Show = () => {
  const params = useParams();
  const { locale, resource, id } = params;
  const { availableLocales } = useSelector(selectResource(resource, id)) || { availableLocales: [] };

  return (
    <Segment>
      <Id link={!!locale} />
      {availableLocales?.length ? <Locale /> : null }
    </Segment>
  );
};

export default Show;
