/* eslint-disable react/no-unused-prop-types */
import React, { useState, useCallback } from 'react';
import ImageFadeIn from 'react-image-fade-in';
import PropTypes from 'prop-types';
import { makeStyles, Paper, Fade } from '@material-ui/core';
import { BrokenImage } from '@material-ui/icons';

import { getImgSRC, getImgDimensions } from 'utils/images';

const IMG_SIZE_CAP = 320;

const useStyles = makeStyles(theme => ({
  root: {},
  img: dimensions => ({
    ...dimensions,
  }),
  brokenImageBox: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[500],
    width: 320,
    height: 185,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));

const ImageField = props => {
  const { seleniumId } = props;
  const [srcError, setSrcError] = useState(false);
  const imgSource = getImgSRC(props);
  const onError = useCallback(() => { setSrcError(true); }, [imgSource]);

  const dimensions = getImgDimensions(props, IMG_SIZE_CAP);
  const classes = useStyles(dimensions);
  return (
    <div className={classes.root}>
      {
        (imgSource && !srcError)
          ? (
            <ImageFadeIn
              src={imgSource}
              className={classes.img}
              onError={onError}
              data-seleniumid={seleniumId}
            />
          )
          : (
            <Fade in>
              <Paper
                data-seleniumid={seleniumId}
                elevation={0}
                className={classes.brokenImageBox}
              >
                <BrokenImage />
              </Paper>
            </Fade>
          )
      }
    </div>
  );
};

ImageField.propTypes = {
  addLabel: PropTypes.bool,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  record: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  seleniumId: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  source: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

ImageField.defaultProps = {
  addLabel: true,
  record: {},
  seleniumId: 'image-field',
  source: 'src',
  width: IMG_SIZE_CAP,
};

export default ImageField;
