import { createSelector } from 'reselect';

export const IMPORTS_UPLOADS = 'IMPORTS_UPLOADS';
export const IMPORTS_UPLOADS_START = `${IMPORTS_UPLOADS}_START`;
export const IMPORTS_UPLOADS_PROGRESS = `${IMPORTS_UPLOADS}_PROGRESS`;
export const IMPORTS_UPLOADS_COMPLETE = `${IMPORTS_UPLOADS}_COMPLETE`;
export const IMPORTS_UPLOADS_CLEAR = `${IMPORTS_UPLOADS}_CLEAR`;

export default (state = [], { type, payload }) => {
  switch (type) {
    case IMPORTS_UPLOADS_START:
      return state.concat(payload);
    case IMPORTS_UPLOADS_PROGRESS:
      return state.map(el => (el.id === payload.id ? payload : el));
    case IMPORTS_UPLOADS_COMPLETE: {
      return state.map(el => (el.id === payload ? { ...el, progress: 100 } : el));
    }
    case IMPORTS_UPLOADS_CLEAR: {
      return state.filter(el => el.id !== payload);
    }
    default:
      return state;
  }
};

export const fetchImportUploads = payload => ({ type: IMPORTS_UPLOADS, payload });
export const startImportUpload = payload => ({ type: IMPORTS_UPLOADS_START, payload });
export const importUploadProgress = payload => ({ type: IMPORTS_UPLOADS_PROGRESS, payload });
export const importUploadComplete = id => ({ type: IMPORTS_UPLOADS_COMPLETE, payload: id });
export const importUploadClear = id => ({ type: IMPORTS_UPLOADS_CLEAR, payload: id });

export const getUploadsList = state => state.importsUpload || [];
export const isImportProcess = entityId => createSelector(
  getUploadsList,
  imports => imports.some(el => el.entityId === entityId),
);

export * from './selectors';
