import { matches } from 'lodash';
import {
  GET_LIST,
  GET_MANY,
} from 'react-admin';

const rd = r => require(`../../../../../mock/initial-data/predefined/${r}`);

export const resources = [
  'content-names',
  'editorial-statuses',
  'import-statuses',
  'import-types',
  'publication-types',
  'learning-object-states',
  'media-types',
  'publishers',
];

const getListItems = (resource, params) => {
  const {
    filter: { q: quickSearch, ...restFilter } = {},
    pagination: {
      page = 1,
      perPage = 25,
    } = {},
  } = params;
  const rawData = rd(resource);
  const restFilterMatcher = matches(restFilter);

  const filteredData = rawData
    .filter(i => !quickSearch || i.name.toLowerCase().includes(quickSearch.toLowerCase()))
    .filter(i => restFilterMatcher(i));
  const start = (page - 1) * perPage;
  const end = start + perPage;
  return { data: filteredData.slice(start, end), total: filteredData.length };
};

const getManyItems = (resource, params) => {
  const rawData = rd(resource);
  const data = rawData.filter(({ id }) => params.ids.includes(id));
  return { data };
};

const TYPE_HANDLERS = {
  [GET_MANY]: getManyItems,
  [GET_LIST]: getListItems,
};

const ALLOWED_TYPES = Object.keys(TYPE_HANDLERS);

const DataTransformer = (wrappedDataProvider) => async (type, resource, params) => {
  if (!resources.includes(resource)) {
    return wrappedDataProvider(type, resource, params);
  }

  if (!ALLOWED_TYPES.includes(type)) {
    throw new Error(`${type} is not allowed for ${resource}.`);
  }

  return TYPE_HANDLERS[type](resource, params);
};

export default DataTransformer;
