import React from 'react';
import FalseIcon from '@material-ui/icons/Clear';
import TrueIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';

const HasValueField = ({ record, source }) => {
  if (!record) return <></>;
  const { locale, [source]: value } = record;
  return value
    ? <span title={value}><TrueIcon data-seleniumid={`statusLabel-${source}-true-${locale}`} /></span>
    : <FalseIcon data-seleniumid={`statusLabel-${source}-false-${locale}`} />;
};

HasValueField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default HasValueField;
