import { useState, useEffect } from 'react';
import getDataProvider from 'api/backend-data-server/BackendServerDataProvider';
import { RESOURCE_PREFIX } from 'api/constants';
import { GET_LIST } from 'react-admin';

const useCount = ({ counter, isExpanded, filter, resource }) => {
  const [labelCount, setLabel] = useState(null);

  useEffect(
    () => {
      if (!isExpanded || !counter) return;

      const listParams = { pagination: { page: 1, perPage: 0 } };
      const payload = { filter, ...listParams };

      const requestDataProvider = getDataProvider(RESOURCE_PREFIX);
      const fetchData = async () => {
        const { total } = await requestDataProvider(GET_LIST, resource, payload) || {};
        if (total && labelCount !== total) setLabel(total);
      };

      fetchData();
      const requestInterval = setInterval(fetchData, 6e4);
      return () => { if (requestInterval) clearInterval(requestInterval); };
    },
    [isExpanded],
  );

  return labelCount;
};

export default useCount;
