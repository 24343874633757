import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import { filter } from 'lodash';
import { PUBLICATION_STATUSES } from '_domains/Jobs/Publications/constants';
import { makeStyles, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { sortLocales } from 'utils/locales';

export const NOTIFICATION_DURATION = 20000;
const SUCCESS_LABEL = 'Successful';
const FAILED_LABEL = 'Failed';

const useNotificationStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.snackbar,
    backgroundColor: '#313131',
    color: theme.palette.common.white,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    borderRadius: '4px',
    display: 'flex',
    minWidth: '30rem',
    maxWidth: '40rem',
    ...theme.typography.body2,
  },
  button: {
    color: theme.palette.common.white,
  },
  splitter: {
    height: '1px',
    backgroundColor: theme.palette.common.white,
    marginTop: '.4rem',
  },
}));

const useNotificationRowStyles = makeStyles({
  subtitle: ({ isSuccess }) => ({
    flexBasis: isSuccess ? '5rem' : '3rem',
  }),
  text: {
    flex: 1,
  },
});

const getMessage = (data) => (data.length ? sortLocales(data.map(({ locale }) => locale))
  .map(locale => (<Box display="inline-flex" key={locale}>{locale}</Box>)).reduce((prev, curr) => [prev, ', ', curr]) : null);

const NotificationRow = ({ code, success = [], failed = [] }) => {
  const classes = useNotificationRowStyles({ isSuccess: !!success.length });
  const seleniumId = 'publications-progress-notification';
  return (
    <Box key={code} display="flex" flexDirection="column" data-seleniumid={`${seleniumId}-${code}`}>
      <Typography variant="subtitle1">{code}</Typography>
      {[
        { title: SUCCESS_LABEL, msg: getMessage(success) },
        { title: FAILED_LABEL, msg: getMessage(failed) },
      ].filter(({ msg }) => !!msg).map(({ title, msg }) => (
        <Box key={title} display="flex" data-seleniumid={`${seleniumId}-${title.toLowerCase()}`}>
          <span className={classes.subtitle}>{title}: </span><span className={classes.text}>{msg}</span>
        </Box>
      ))}
    </Box>
  );
};

NotificationRow.propTypes = {
  code: PropTypes.string,
  failed: PropTypes.arrayOf(PropTypes.shape({
    locale: PropTypes.string,
    status: 'FAILED',
  })),
  success: PropTypes.arrayOf(PropTypes.shape({
    locale: PropTypes.string,
    status: 'SUCCESSFUL',
  })),
};

const Notification = ({ data, onClose }) => {
  const classes = useNotificationStyles();
  return (
    <div className={classes.root} data-seleniumid="publications-progress-notification">
      <Box flex={1}>
        <Typography variant="subtitle1">Publication(s)</Typography>
        {data.map(({ code, locales }) => (
          <NotificationRow
            key={code}
            code={code}
            success={filter(locales, ['status', PUBLICATION_STATUSES.SUCCESSFUL])}
            failed={filter(locales, ['status', PUBLICATION_STATUSES.FAILED])}
          />
        )).reduce((acc, component, i) => [...acc, ...(i === 0 ? [component] : [<div key={i} className={classes.splitter} />, component])], [])}
      </Box>
      <IconButton
        className={classes.button}
        title="Hide"
        size="small"
        onClick={onClose}
        data-seleniumid="close-notification-btn"
      >
        <Close />
      </IconButton>
    </div>
  );
};

Notification.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    locales: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      id: PropTypes.string,
      locale: PropTypes.string,
      status: PropTypes.string,
    })),
  })),
  onClose: PropTypes.func,
};

export default Notification;
