import get from 'lodash/get';
import { sortLocales } from 'utils/locales';
import { LR_LOCALE_BEING_PUBLISHED_STATE } from 'constants/localeStates';

const isFilteredInList = (value, filterList) => {
  if (!filterList?.length) return true;
  return filterList.includes(value);
};
const isLocaleEntityFiltered = (entity, { availableLocales, states, editorialStatuses }) => {
  const { locale, state, editorialStatus } = entity;
  return isFilteredInList(locale, availableLocales)
    && isFilteredInList(state, states)
    && isFilteredInList(editorialStatus, editorialStatuses);
};
const parseFilters = (filters) => ({
  availableLocales: get(filters, 'availableLocales', []),
  states: get(filters, 'state', []),
  editorialStatuses: get(filters, 'editorialStatus', []),
});

// generate map like "LR id": ["locale1", "locale2"]
export const getLocalesMap = (locales, filters) => {
  const parsedFilters = parseFilters(filters);
  const localeMap = {};
  // eslint-disable-next-line no-unused-vars
  Object.entries(locales).forEach(([id, entity]) => {
    const { parentId, locale, lastPublished, state } = entity || {};
    const isFiltered = isLocaleEntityFiltered(entity, parsedFilters);

    if (lastPublished && parentId && locale && isFiltered && state !== LR_LOCALE_BEING_PUBLISHED_STATE) {
      const locales = localeMap[parentId] || [];
      localeMap[parentId] = [...locales, locale];
    }
  });

  // sort locales for LR id.
  Object.entries(localeMap).forEach(([lrId, locales = []]) => {
    localeMap[lrId] = sortLocales(locales);
  });
  return localeMap;
};
