import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE,
  UPDATE_MANY,
  CREATE,
  DELETE,
  DELETE_MANY,
} from 'react-admin';

export const GET_LIST_TYPE = 'getList';
export const GET_ONE_TYPE = 'getOne';
export const GET_MANY_TYPE = 'getMany';
export const GET_MANY_REFERENCE_TYPE = 'getManyReference';
export const UPDATE_TYPE = 'update';
export const UPDATE_MANY_TYPE = 'updateMany';
export const CREATE_TYPE = 'create';
export const DELETE_TYPE = 'delete';
export const DELETE_MANY_TYPE = 'deleteMany';

export default (handle) => ({
  [GET_LIST_TYPE]: (resource, params) => handle(GET_LIST, resource, params),
  [GET_ONE_TYPE]: (resource, params) => handle(GET_ONE, resource, params),
  [GET_MANY_TYPE]: (resource, params) => handle(GET_MANY, resource, params),
  [GET_MANY_REFERENCE_TYPE]: (resource, params) => handle(GET_MANY_REFERENCE, resource, params),
  [UPDATE_TYPE]: (resource, params) => handle(UPDATE, resource, params),
  [UPDATE_MANY_TYPE]: (resource, params) => handle(UPDATE_MANY, resource, params),
  [CREATE_TYPE]: (resource, params) => handle(CREATE, resource, params),
  [DELETE_TYPE]: (resource, params) => handle(DELETE, resource, params),
  [DELETE_MANY_TYPE]: (resource, params) => handle(DELETE_MANY, resource, params),
});
