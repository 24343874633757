import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import { generateSearchParams } from 'utils';

import MenuItemLink from './MenuItemLink';
import CustomIcon from '../CustomIcon';
import useCount from './useCount';

const SubMenuItem = ({
  checkMenuItemSelected,
  classes,
  config: {
    key,
    counter,
    filter,
    label,
    IconComponent,
    iconColor,
    iconName,
    seleniumId,
  },
  defaultParams,
  name,
  subItemClick,
  isExpanded,
}) => {
  const countLabel = useCount({ counter, isExpanded, filter, resource: name });

  const leftIcon = IconComponent
    ? <Box component={(props) => <IconComponent {...props} />} m={1} marginRight={3} />
    : <Box color={iconColor} component={(props) => <CustomIcon name={iconName} {...props} />} m={1} marginRight={3} />;

  const primaryText = useMemo(() => (
    <>
      <ListItemText>{label}</ListItemText>
      {
        countLabel && (
          <Chip
            label={countLabel}
            data-seleniumid={`${seleniumId}-counter`}
          />
        )
      }
    </>
  ), [label, countLabel]);
  return (
    <MenuItemLink
      className={classes.selectedSubLink}
      key={`${name}${key}`}
      selected={checkMenuItemSelected(`/${name}`, filter)}
      to={`${generateSearchParams(`/${name}`, { ...defaultParams, filter })}`}
      primaryText={primaryText}
      label={label}
      leftIcon={leftIcon}
      onClick={subItemClick({ ...defaultParams, filter })}
      data-seleniumid={`menu-${seleniumId}-button`}
      sidebarIsOpen={open}
    />
  );
};

SubMenuItem.propTypes = {
  checkMenuItemSelected: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    menuItem: PropTypes.string.isRequired,
    selectedLink: PropTypes.string.isRequired,
    selectedSubLink: PropTypes.string.isRequired,
  }),
  config: PropTypes.shape({
    counter: PropTypes.bool,
    filter: PropTypes.object.isRequired,
    iconColor: PropTypes.string,
    IconComponent: PropTypes.func,
    iconName: PropTypes.string,
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    seleniumId: PropTypes.string.isRequired,
  }),
  defaultParams: PropTypes.shape({
    order: PropTypes.string,
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
  }),
  isExpanded: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  subItemClick: PropTypes.func.isRequired,
};

export default SubMenuItem;
