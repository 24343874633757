import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectIsLoading } from 'ra-redux/selectors';
import { TextField as RaTextField } from 'react-admin';

import { LinearProgress } from '@material-ui/core';

const TextField = (props) => {
  const { showProgress, seleniumId, ...fieldProps } = props;
  const isLoading = useSelector(selectIsLoading);

  return (
    showProgress && isLoading
      ? <LinearProgress />
      : <RaTextField data-seleniumid={seleniumId || ''} {...fieldProps} />
  );
};

TextField.propTypes = {
  addLabel: PropTypes.bool,
  seleniumId: PropTypes.string,
  showProgress: PropTypes.bool,
};

TextField.defaultProps = {
  showProgress: false,
  addLabel: true,
};

export default memo(TextField);
