import { MEDIA_TYPE_VIDEO, MEDIA_TYPE_READING, MEDIA_TYPE_INTERACTIVE } from 'constants/mediaTypes';
import { APP_URL } from 'api/constants';
import { sortLocales } from 'utils/locales';
import { SUB_FILE_EXT, VIDEO_FILE_EXT } from '../constants';

export const SUBTITLE_LOCALE_MAP = {
  DEFAULT: ['ar-SA', 'id-ID', 'nb-NO', 'th-TH', 'vi-VN'],
  CUSTOM: { 'ja-JP': 'JP', 'zh-CHS': 'CH', 'ms-MY': 'MY' },
};
export const transformLocaleSubtitleCode = (locale) => {
  switch (true) {
    case SUBTITLE_LOCALE_MAP.DEFAULT.includes(locale):
      return locale;
    case locale in SUBTITLE_LOCALE_MAP.CUSTOM:
      return SUBTITLE_LOCALE_MAP.CUSTOM[locale];
    default:
      return locale.split('-')[0].toUpperCase();
  }
};

export const getStaticPaths = ({ bucket, code, locale, mediaType, isLegacyPath }) => {
  if (!bucket) return null;
  const bucketPath = `${APP_URL}${bucket}`;
  const subsLangShort = transformLocaleSubtitleCode(locale);

  switch (mediaType) {
    case MEDIA_TYPE_VIDEO: {
      const videoPath = isLegacyPath ? `${bucketPath}/${code}` : `${bucketPath}`;
      const subtitlePath = `${videoPath}/${code}_${isLegacyPath ? subsLangShort : locale}.vtt`;
      return {
        video: `${videoPath}/${code}.mp4`,
        videoSubtitles: [{
          src: subtitlePath,
          label: subsLangShort,
        }],
      };
    }
    case MEDIA_TYPE_READING: {
      const path = isLegacyPath ? '/media/' : '/';
      return `${bucketPath}${path}${code}_${locale}.pdf`;
    }
    case MEDIA_TYPE_INTERACTIVE:
      return `${bucketPath}/index.html?tracking=standalone`;
    default:
      return null;
  }
};

/**
 * Prepare the list of locales for video player locale list
 * @constructor
 * @param {Object[]} entities - Parsed collection from content.json
 * @param {String[]} allAvailableLocales - List of all locales which available in LR
 * @param {String} bucket - Content S3 Bucket name
 * @param {String} mainLabel - Dropdown label for main video
 * @returns {Object[]}
 */
export const getAvailableLocalesFromContentFile = (entities, allAvailableLocales, bucket, mainLabel) => {
  const mainVideo = entities.filter(({ language, metadata: { fileName, embeddedSubtitles } }) =>
    !language && fileName.split('.').pop() === VIDEO_FILE_EXT && !embeddedSubtitles,
  )[0];

  const defaultLocalEntity = {
    videoSrc: mainVideo ? `${bucket}/${mainVideo.metadata.fileName}` : '',
    subSrc: null,
    label: null,
    showSubOption: false,
  };

  const contentAvailableLanguages = entities
    .filter(({ language }) => !!language)
    .reduce((result, entity) => {
      const lang = result.find(({ label }) => label === entity.language);
      const fileExtension = entity?.metadata?.fileName.split('.').pop() || '';

      if (fileExtension === VIDEO_FILE_EXT) {
        lang.videoSrc = `${bucket}/${entity.metadata.fileName}`;
      }

      if (fileExtension === SUB_FILE_EXT) {
        lang.subSrc = `${bucket}/${entity.metadata.fileName}`;
      }

      if (
        fileExtension === SUB_FILE_EXT
        || (fileExtension === VIDEO_FILE_EXT && entity.metadata.embeddedSubtitles)
        || (fileExtension === VIDEO_FILE_EXT && entity.metadata.fileName.includes(entity.language))
      ) {
        lang.showSubOption = true;
      }
      return result;
    }, sortLocales(allAvailableLocales || []).map((locale) => ({ ...defaultLocalEntity, label: locale })))
    .filter(({ videoSrc }) => !!videoSrc);

  // If LR doesn't have any locales show just main video
  if (!contentAvailableLanguages.length && mainVideo) {
    contentAvailableLanguages.push(({ ...defaultLocalEntity, label: mainLabel }));
  }

  return contentAvailableLanguages;
};
