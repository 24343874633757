import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  changeListParams,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import {
  ArrowRight as ArrowRightIcon,
} from '@material-ui/icons';
import { getIsExpandedItem } from 'ra-redux/menu';
import { generateSearchParams } from 'utils';
import { useIsFirstRender } from 'utils/hooks';
import SubMenuHolder from './SubMenuHolder';
import SubMenuItem from './SubMenuItem';

const SubMenuSection = (props) => {
  const {
    name,
    icon,
    isDesktop,
    toggleSidebar,
    open,
    checkMenuItemSelected,
    classes,
    config,
  } = props;
  const { defaultParams, subItemsConfig, label, seleniumId } = config;
  const dispatch = useDispatch();

  const isExpanded = useSelector(getIsExpandedItem(name));
  const isFirstRender = useIsFirstRender();
  const subItemClick = useCallback((params) => () => {
    dispatch(changeListParams(name, params));

    if (!isFirstRender && !isDesktop) {
      toggleSidebar();
    }
  }, [name, isDesktop, toggleSidebar]);

  return (
    <SubMenuHolder
      className={`${classes.menuItem} ${checkMenuItemSelected(`/${name}`) ? classes.selectedLink : ''}`}
      key={name}
      selected={checkMenuItemSelected(`/${name}`)}
      sidebarIsOpen={open}
      name={name}
      primaryText={label}
      leftIcon={<Box component={icon || ArrowRightIcon} m={1} marginRight={3} />}
      to={`${generateSearchParams(`/${name}`, defaultParams)}`}
      seleniumId={`menu-${seleniumId}`}
      onClick={subItemClick(defaultParams)}
    >
      {
        subItemsConfig.map((subItemConfig) => (
          <SubMenuItem
            key={`${subItemConfig.key}-${name}`}
            checkMenuItemSelected={checkMenuItemSelected}
            classes={classes}
            config={subItemConfig}
            defaultParams={defaultParams}
            name={name}
            subItemClick={subItemClick}
            isExpanded={isExpanded}
          />
        ))
      }
    </SubMenuHolder>
  );
};

SubMenuSection.propTypes = {
  checkMenuItemSelected: SubMenuItem.propTypes.checkMenuItemSelected,
  classes: SubMenuItem.propTypes.classes,
  config: PropTypes.shape({
    defaultParams: SubMenuItem.propTypes.defaultProps,
    label: PropTypes.string.isRequired,
    seleniumId: PropTypes.string.isRequired,
    subItemsConfig: PropTypes.arrayOf(PropTypes.shape(SubMenuItem.propTypes.config)),
  }),
  icon: PropTypes.object,
  isDesktop: PropTypes.bool,
  name: SubMenuItem.propTypes.name,
  open: PropTypes.bool,
  toggleSidebar: PropTypes.func.isRequired,
};

SubMenuSection.defaultProps = {
  isDesktop: false,
  open: true,
};

export default memo(SubMenuSection);
