/* eslint-disable react/prop-types */

import React, { useState, useRef, useEffect, useMemo, useLayoutEffect } from 'react';
import { AutocompleteArrayInput as RaAutocompleteArrayInput } from 'react-admin';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core';
import { clearRefineSearch, addRefineSearch } from './RefineSearch';

const useStyles = makeStyles((theme) => ({
  container: (isFilter) => ({
    marginBottom: isFilter ? theme.spacing(-0.5) : 0,
    ...(isFilter ? {} : { minHeight: '68px' }),
  }),
  input: {
    minWidth: 300,
  },
}));

const AutocompleteArrayInput = ({
  seleniumId,
  helperText,
  minSearchableLength,
  maxValuesArrayLength,
  choices,
  sort,
  input,
  label,
  searchLabel,
  name,
  ...props
}) => {
  const { source, isRefineSearch, perPage } = props;
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  const suggestionsRef = useRef(null);
  const hasValue =    containerRef.current
    && !!containerRef.current.querySelector('[role="button"]');
  const [shrink, setShrink] = useState(hasValue);
  const [isFilter, setIsFilter] = useState(false);
  const classes = useStyles(isFilter);

  useEffect(() => {
    setShrink(hasValue);
  }, [hasValue]);

  useEffect(
    () => {
      if (containerRef.current) {
        const formId = containerRef.current.closest('form').getAttribute('data-seleniumid') || '';
        setIsFilter(formId.includes('filter'));
      }
    },
    [containerRef.current],
  );

  const filteredChoices = choices.filter(e => !props.exclude.includes(e.id));

  const sortedChoices = useMemo(() => {
    if (sort) {
      const { field, order = 'ASC' } = sort;
      return _.orderBy(filteredChoices, field, order.toLowerCase());
    }

    return filteredChoices;
  }, [filteredChoices, sort]);

  useLayoutEffect(() => {
    if (isRefineSearch && isOpen && suggestionsRef?.current) {
      const { current } = suggestionsRef;
      clearRefineSearch(current);
      if (sortedChoices.length < perPage) return;
      addRefineSearch(current);
    }
  }, [isOpen, sortedChoices]);

  const componentProps = {
    ...props,
    allowEmpty: false,
    choices: sortedChoices,
    classes: { ...classes, input: undefined },
    variant: 'standard',
    options: {
      helperText,
      inputProps: {
        className: classes.input,
        name: name || seleniumId,
        'data-seleniumid': seleniumId,
        onFocus: () => {
          setShrink(true);
          if (isRefineSearch) setTimeout(() => { setIsOpen(true); });
        },
        onBlur: () => {
          if (!hasValue) {
            setShrink(false);
          }
          if (isRefineSearch) setIsOpen(false);
        },
        onClick: () => {
          if (isRefineSearch && !isOpen) setTimeout(() => { setIsOpen(true); });
        },
      },
      labelProps: { label: (isOpen && searchLabel) ? searchLabel : label },
      suggestionsContainerProps: {
        'data-seleniumid': `${seleniumId}-${source}-select-wrap`,
        placement: 'top-start',
        ref: suggestionsRef,
      },
      InputLabelProps: { shrink },
    },
    onInputValueChange: text => {
      if (text) setShrink(true);
    },
    helperText: false,
  };

  if (input) {
    componentProps.input = {
      ...input,
      onChange: v => {
        if (isRefineSearch && isOpen) setIsOpen(false);
        input.onChange(
          v?.length && maxValuesArrayLength
            ? v.slice(0, maxValuesArrayLength)
            : v,
        );
      },
    };

    if (
      maxValuesArrayLength
      && componentProps?.input?.value?.length >= maxValuesArrayLength
    ) {
      componentProps.options = {
        ...componentProps.options,
        disabled: true,
        onClick: null,
      };
    }
  }

  return (
    <span
      ref={containerRef}
      data-seleniumid={`${seleniumId}-${source}-container`}
    >
      <RaAutocompleteArrayInput {...componentProps} />
    </span>
  );
};

AutocompleteArrayInput.propTypes = {
  exclude: PropTypes.arrayOf(PropTypes.string),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isRefineSearch: PropTypes.bool,
  label: PropTypes.string,
  matchSuggestion: PropTypes.func,
  maxValuesArrayLength: PropTypes.number,
  minSearchableLength: PropTypes.number,
  name: PropTypes.string,
  perPage: PropTypes.number,
  searchLabel: PropTypes.string,
  seleniumId: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    order: PropTypes.oneOf(['ASC', 'DESC']),
  }),
};

AutocompleteArrayInput.defaultProps = {
  exclude: [],
  helperText: false,
  sort: undefined,
  matchSuggestion: () => true,
  maxValuesArrayLength: undefined,
  minSearchableLength: 0,
  name: null,
  searchLabel: '',
};

export default AutocompleteArrayInput;
