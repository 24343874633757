import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isPreviewAvailable } from 'utils';
import { PUBLICATION_PREVIEW } from 'constants/resources';
import Preview from '_ui/Layout/Preview';
import { makeStyles } from '@material-ui/styles';
import { MEDIA_TYPE_VIDEO, MEDIA_TYPE_READING } from 'constants/mediaTypes';
import { PUBLICATION_STATUSES, PUBLICATION_TYPE, ARCHIVE_TYPE } from '../constants';
import StatusField from './StatusField';
import DownloadField from './DownloadField';

const useStyles = makeStyles({
  root: {
    '& button': {
      marginLeft: 0,
    },
  },
});

/**
 * Preview on Publications screen:
 * For MEDIA_TYPE_VIDEO we don't use content-list endpoint, we use legacy video implementation (old hook) but with another files paths if directlyUploadedVideo = true.
 * For MEDIA_TYPE_READING the isLegacyPath is true when !directlyUploadedPdf
 */
const isLegacyPath = (mediaType, directlyUploadedVideo, directlyUploadedPdf) => {
  if (mediaType === MEDIA_TYPE_VIDEO && directlyUploadedVideo) return false;
  if (mediaType === MEDIA_TYPE_READING && directlyUploadedPdf) return false;
  return true;
};

const ActionsField = ({ record, className, basePath, isShow = false, downloadable = false }) => {
  const { id, status, learningObjectCode, language, mediaType, directlyUploadedVideo, directlyUploadedPdf } = record;
  const classes = useStyles();

  const isPreview = id && status === PUBLICATION_STATUSES.SUCCESSFUL && isPreviewAvailable(mediaType);
  return (
    <div className={cn(className, classes.root)}>
      {isShow ? <StatusField record={record} basePath={basePath} mode="action" /> : null }
      {isPreview
        ? (
          <Preview
            key={id}
            id={id}
            code={learningObjectCode}
            locale={language}
            mediaType={mediaType}
            label="PREVIEW"
            resource={PUBLICATION_PREVIEW}
            isShow={isShow}
            isLegacyVideo
            isLegacyPath={isLegacyPath(mediaType, directlyUploadedVideo, directlyUploadedPdf)}
          />
        )
        : null }
      {downloadable ? <DownloadField record={record} /> : null }
    </div>
  );
};

ActionsField.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  downloadable: PropTypes.bool,
  isShow: PropTypes.bool,
  record: PropTypes.shape({
    code: PropTypes.string,
    directlyUploadedPdf: PropTypes.bool,
    directlyUploadedVideo: PropTypes.bool,
    id: PropTypes.string,
    language: PropTypes.string,
    learningObjectCode: PropTypes.string,
    mediaType: PropTypes.string,
    publicationType: PropTypes.oneOf([PUBLICATION_TYPE, ARCHIVE_TYPE, null]),
    status: PropTypes.string,
  }),
};

ActionsField.defaultProps = {
  className: undefined,
  record: {},
};

export default ActionsField;
