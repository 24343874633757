import { LOCATION_CHANGE } from 'connected-react-router';
import { setLocales } from 'ra-redux/locales';
import { findLocaleParam } from 'utils/locales';

const handleLocales = (next, payload) => {
  const { location } = payload || {};
  const locale = findLocaleParam(location || { pathname: '' });
  if (locale) next(setLocales(locale));
};

export default () => (next) => (action) => {
  next(action);

  const { type, payload } = action;
  if (type === LOCATION_CHANGE) handleLocales(next, payload);
};
