/* eslint-disable no-alert */
import { TAXONOMY_EXPORT_TYPES } from 'constants/exports';
import { getCapLabel } from './strings';

export const EXPORT_GENERATE_ERROR  = 'Error: export isn\'t generated';
export const TAXONOMY_DOWNLOAD_MESSAGE = 'Taxonomy is sent to downloading';
export const TAXONOMY_DOWNLOAD_EVENT_TYPE = 'taxonomyDownloadStart';

export const getTaxonomyDownloadEvent = (url) => new CustomEvent(
  TAXONOMY_DOWNLOAD_EVENT_TYPE,
  { detail: { url } },
);

export const openTaxonomyDownloadWindow = (resource) => {
  const label = getCapLabel(resource);
  const taxonomy = TAXONOMY_EXPORT_TYPES[resource];
  const downloadWindow = window.open(
    `${window.location.origin}/#/${encodeURI(`exports?filter={"searchQ":"Taxonomy ${label}"}&autoDownload=${taxonomy}`)}`,
    `Download ${label} taxonomy`,
    'height=800,width=900',
  );

  return downloadWindow;
};

export const getTaxonomyReportParams = (resource) => ({
  type: 'update',
  resource: 'exports',
  payload: { type: TAXONOMY_EXPORT_TYPES[resource] },
  options: {
    onError: {
      notification: { body: EXPORT_GENERATE_ERROR, level: 'error' },
    },
  },
});

export const generateTaxonomyReport = (resource) => {
  const downloadWindow = openTaxonomyDownloadWindow(resource);
  downloadWindow.addEventListener(TAXONOMY_DOWNLOAD_EVENT_TYPE, ({ detail: { url } }) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
  }, false);

  return downloadWindow;
};
