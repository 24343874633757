import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
  },
  player: {
    height: '100%',
    width: '100%',
  },
});

const VideoPlayer = ({ config }) => {
  const classes = useStyles();
  const videoRef = useRef();

  const { selectedLanguage, availableLanguages } = config;

  const options = {
    autoplay: true,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    poster: config.videoThumbnail,
    controls: true,
    controlBar: {
      pictureInPictureToggle: false,
    },
    sources: [
      {
        src: availableLanguages.find((lang) => lang.label === selectedLanguage)?.videoSrc || '',
      },
    ],
    subtitles: availableLanguages.reduce((result, lang) => {
      if (!lang.showSubOption) return result;
      const subEntity = { label: lang.label };
      if (lang.subSrc) {
        subEntity.src = lang.subSrc;
      }
      return [...result, subEntity];
    }, []),
  };

  useEffect(() => {
    const player = videojs(videoRef.current, options);
    const tracks = player.textTracks();

    player.ready(() => {
      const currentTrack = tracks.tracks_.find((track) => track.label === selectedLanguage);
      if (currentTrack) {
        currentTrack.mode = 'showing';
      }
    });

    tracks.on('change', () => {
      const newLocale = tracks.tracks_.find((track) => track.mode === 'showing')?.label;
      const videoSrc = availableLanguages.find((lang) => lang.label === newLocale)?.videoSrc;
      if (videoSrc !== player.src()) {
        player.src(videoSrc);
        const time = player.currentTime();
        if (time) player.currentTime(time);
      }
    });
  }, []);

  return (
    <div className={classes.root}>
      {/*eslint-disable*/}
      <video ref={videoRef} className={`video-js ${classes.player}`}>
        {options.subtitles.map((sub, i) => (
          <track key={i} kind='captions' {...sub}/>
        ))}
      </video>
    </div>
  );
};

VideoPlayer.propTypes = {
  config: PropTypes.shape({
    selectedLanguage: PropTypes.string,
    availableLanguages: PropTypes.arrayOf(
      PropTypes.shape({
        videoSrc: PropTypes.string.isRequired,
        subSrc: PropTypes.string,
        label: PropTypes.string
      })
    ),
    videoThumbnail: PropTypes.string
  })
};

export default VideoPlayer;
