import { HttpError } from 'react-admin';
import { stringify } from 'query-string';
import _ from 'lodash';

export default (url, options = {}) => {
  const requestHeaders = options.headers
    || new Headers({
      Accept: 'application/json',
    });

  try {
    if (_.isObject(JSON.parse(options?.body))) {
      if (
        !requestHeaders.has('Content-type')
        && !(options?.body instanceof FormData)
      ) {
        requestHeaders.set('Content-Type', 'application/json');
      }
    }
    else {
      throw new Error();
    }
  }
  catch (error) {
    requestHeaders.set('Content-type', 'text/plain');
  }

  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token);
  }

  return fetch(url, { ...options, headers: requestHeaders })
    .then(response =>
      response.text().then(text => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      })),
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      }
      catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError(
            status >= 500
              ? 'Request cannot be processed due to system issues. Please retry at a later time and log a ServiceNow ticket if issues persist.'
              : (json?.message || body || statusText),
            status,
            { headers, ...json },
          ),
        );
      }
      return { status, headers, body, json };
    });
};

export const queryParameters = stringify;
