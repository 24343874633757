export const LR = 'learning-objects';
export const LR_PREVIEW = 'learning-object-preview';
export const LR_PREVIEW_VIDEO = 'learning-object-preview-video';
export const LR_VIDEO_CONTENT_LIST = 'lo-content-list';
export const LR_LOCALES = 'learning-objects-locales';

export const PUBLICATION_PREVIEW = 'publication-preview';
export const PUBLICATIONS = 'publications';
export const IMPORTS = 'imports';
export const AUTHORS = 'authors';
export const THEMES = 'themes';
export const CONTENT_NAMES = 'content-names';
export const EXPORTS = 'exports';
export const DISCIPLINES = 'disciplines';
export const PROGRAMS = 'programs';
export const SKILLSETS = 'skillsets';
export const TAGS = 'tags';

const getHeight = (list, height) => list.reduce((acc, key) => ({ ...acc, [key]: height }), {});

export const ROW_HEIGHT = {
  [IMPORTS]: 66,
  ...(getHeight([PUBLICATIONS, LR_LOCALES], 78)),
  ...(getHeight([AUTHORS, THEMES, TAGS, SKILLSETS, PROGRAMS, DISCIPLINES], 108)),
};
