import _ from 'lodash';

export default (wrappedDataProvider) => async (type, resource, params = {}) => {
  const { filter } = params;
  const transformed = {};
  if (filter) {
    Object.keys(filter).forEach(prop => {
      const value = filter[prop];

      if (_.isNil(value)) return;
      if (value.gte || value.lte) {
        if (value.gte) transformed[`${prop}Gte`] = value.gte;
        if (value.lte) transformed[`${prop}Lte`] = value.lte;
        return;
      }
      transformed[prop] = value;
    });
  }

  return wrappedDataProvider(type, resource, { ...params, filter: transformed });
};
