import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

import { ReactComponent as SuccessfulIcon } from '../../assets/icons/successful.svg';
import { ReactComponent as PartialIcon } from '../../assets/icons/partial.svg';
import { ReactComponent as ProgressIcon } from '../../assets/icons/progress.svg';
import { ReactComponent as FailedIcon } from '../../assets/icons/failed.svg';

const CustomIcon = props => {
  const iconsSet = {
    successful: <SuccessfulIcon />,
    partial: <PartialIcon />,
    progress: <ProgressIcon />,
    failed: <FailedIcon />,
  };

  const { name } = props;
  return (
    <SvgIcon {...props}>
      {iconsSet[name]}
    </SvgIcon>
  );
};

CustomIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CustomIcon;
