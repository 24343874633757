import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput as RaBooleanInput,
  useListContext,
} from 'react-admin';
import set from 'lodash/set';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { startCase, slugify } from 'utils';

const InputComponent = ({
  options = {},
  seleniumId,
  ...rest
}) => {
  set(options, { inputProps: { 'data-seleniumid': `${seleniumId}-input` } });

  const props = {
    'data-seleniumid': seleniumId,
    options,
    ...rest,
  };

  return <RaBooleanInput {...props} />;
};

InputComponent.propTypes = {
  ...RaBooleanInput.propTypes,
  label: PropTypes.string,
  onClick: PropTypes.func,
  seleniumId: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

InputComponent.defaultProps = {
  ...RaBooleanInput.defaultProps,
  options: {},
};

const useStyles = makeStyles({
  root: ({ isActiveFilter }) => ({
    '& label': {
      cursor: isActiveFilter ? 'default' : 'pointer',
    },
  }),
  switch: ({ isActiveFilter }) => ({
    display: isActiveFilter ? 'none' : 'block',
  }),
});

const BooleanInput = (props) => {
  const {
    source,
    label = startCase(source),
    inputLabel,
    seleniumId = slugify(label),
  } = props;

  const { displayedFilters = {} } = useListContext() || {};
  const isActiveFilter = source in displayedFilters;

  const classes = useStyles({ isActiveFilter });
  const inputProps = {
    ...props,
    label: inputLabel || label,
    seleniumId,
    classes: { root: classes.root },
    options: { classes: { root: classes.switch } },
  };

  {
    const onClickDummy = useCallback((e) => e.preventDefault());
    if (isActiveFilter) {
      return (
        <Box marginLeft={1} marginBottom={1}>
          <InputComponent
            onClick={onClickDummy}
            {...inputProps}
          />
        </Box>
      );
    }
  }

  return <InputComponent {...inputProps} />;
};

BooleanInput.propTypes = InputComponent.propTypes;
BooleanInput.defaultProps = InputComponent.defaultProps;

export default BooleanInput;
