export const PUBLICATION_STATUSES = {
  SUCCESSFUL: 'SUCCESSFUL',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  PARTIAL: 'PARTIAL', // used for imports
};

export const ARCHIVE_TYPE = 'ARCHIVE';
export const PUBLICATION_TYPE = 'PUBLICATION';
export const ARCHIVE_PUBLICATION_TYPE_LABELS = {
  [ARCHIVE_TYPE]: 'Archival',
  [PUBLICATION_TYPE]: 'Publication',
};

export const DATE_SORT_FIELDS = ['publicationDate', 'initiatedDate'];
export const LANGUAGE_AGNOSTIC_SORT_FIELDS = [
  'learningObjectCode',
  'language',
  'publishedBy',
  'status',
];

export const STATUS_OPTIONS = [
  { id: PUBLICATION_STATUSES.IN_PROGRESS, name: 'In Progress' },
  { id: PUBLICATION_STATUSES.FAILED, name: 'Failed' },
  { id: PUBLICATION_STATUSES.SUCCESSFUL, name: 'Successful' },
  { id: PUBLICATION_STATUSES.PARTIAL, name: 'Partial Processed' },
];

export const MISSING_GMO = 'is published with missing';

export const EXPORT_FIELDS_MAP = {
  code: 'Code',
  language: 'Language',
  level: 'Level',
  date: 'Date',
  initiatedBy: 'Initiated By',
  status: 'Job Status',
  message: 'Message',
  gmoType: 'Missing',
  gmoCode: 'GMO Code',
};
