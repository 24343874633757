import XRegExp from 'xregexp';
import {
  REGEX_LR_CODE,
  REGEX_GMO_CODE,
  REGEX_GENERIC_CODE,
  REGEX_LOCALE,
  REGEX_FILE_EXTENSION_TEMPLATE,
} from 'constants/regex';

export const validateXregexp = ({ regExp, message = 'Incorrect value' }) => value => (XRegExp(regExp).test(value) ? null : message);
export const parseResourceCode = (source = [], { type, locale } = {}) => {
  const fileExtRegexp = new RegExp(`${REGEX_FILE_EXTENSION_TEMPLATE``.source}$`);
  let codeBaseRegexpSrc = 'p?(?<code>';
  switch (true) {
    case type && ['LR', 'LO', 'LEARNING_OBJECT'].some(t => type.includes(t)):
      codeBaseRegexpSrc += `${REGEX_LR_CODE.source})`;
      break;
    case type:
      codeBaseRegexpSrc += `${REGEX_GMO_CODE.source.replace('p?', '')})`;
      break;
    default:
      codeBaseRegexpSrc += `${REGEX_GENERIC_CODE.source.replace('p?', '')})`;
      break;
  }

  for (const entry of (Array.isArray(source) ? source : [source])) {
    const isMessage = entry?.includes(' ');
    const isFileName = fileExtRegexp.test(entry);
    const conditions = [];
    if (locale) {
      conditions.push(`_${locale}${isFileName ? fileExtRegexp.source : ''}`);
    }
    else {
      conditions.push(`_${REGEX_LOCALE.source}${isFileName ? fileExtRegexp.source : ''}`);
      conditions.push(isFileName ? fileExtRegexp.source : '');
    }

    for (const conditionSrc of conditions) {
      const match = entry?.match(`${isMessage ? '[ ,",\']' : ''}${codeBaseRegexpSrc}${conditionSrc}`)?.groups?.code;
      if (match) return match;
    }
  }
};

export const getWithNBSpace = (str = '') => str.replace(/ /g, '\u00a0');

export const parseFileExtension = (source = [], extensions) => {
  for (const entry of (Array.isArray(source) ? source : [source])) {
    const match = entry?.match(REGEX_FILE_EXTENSION_TEMPLATE`${extensions}`)?.groups?.extension;
    if (match) return match?.length === 1 ? match.pop() : match;
  }
};
