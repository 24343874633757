export const REGEX_LR_UUID = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
export const REGEX_LOCALE = /[a-z]{2,}-[A-Z]{2,3}/;
export const REGEX_LR_CODE = /[A-Z]{4}[0-9]{3}/;
export const REGEX_LR_CODE_WITH_LOCALE = new RegExp(`${REGEX_LR_CODE.source}_${REGEX_LOCALE.source}`);
export const REGEX_GMO_CODE = /p?[A-Z0-9]+(?:[_-][A-Z0-9]+)+/;
export const REGEX_GENERIC_CODE = new RegExp(`${REGEX_LR_CODE.source}|${REGEX_GMO_CODE.source}`);

export const REGEX_FILE_EXTENSION_TEMPLATE = (
  strings = [],
  extensions = [],
  quantifier = '{2,}',
) => {
  const charClass = `[${(Array.isArray(extensions) && extensions.length) ? extensions.join() : 'a-z'}]${quantifier}`;
  return new RegExp(`\\.(?<extension>${strings?.join('')}${charClass})`);
};
