import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useQueryWithStore, FunctionField } from 'react-admin';
import { Box, LinearProgress, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { LocaleReferenceField, TextAreaShowField } from '_ui';
import { AvatarField } from '_domains/Author/Fields';

const useProgressStyles = makeStyles({
  root: {
    margin: '0.5rem',
    width: '20rem',
  },
});

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.grey['700'],
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const EMPTY_AUTHOR = 'Author is empty';

export const EmptyAuthor = () => (
  <Box padding={1} data-seleniumid="lo-empty-author">{EMPTY_AUTHOR}</Box>
);

const AuthorCard = ({ usePriorityLocale, record, ...props }) => {
  const { authorId } = record;

  const data = useSelector((state) => _.get(state, `admin.resources.authors.data.${authorId}`));
  const progressClasses = useProgressStyles();
  const classes = useStyles();

  const { loading, error, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: 'authors',
    payload: { id: authorId },
  });

  if (!authorId) return <EmptyAuthor {...props} />;
  if (error || (loaded && !data)) return null;

  return (
    <>
      {loading ? <LinearProgress classes={progressClasses} /> : (
        <Box padding={[3, 1, 1]}>
          <Box display="flex" flexDirection="row" marginBottom={2}>
            <AvatarField
              upload
              record={data}
              size={80}
              label="Author Thumbnail"
              source="thumbnailUrl"
              sortable={undefined}
              seleniumId="lo-author-thumbnail"
            />
            <Link
              className={classes.link}
              to={`/authors/${authorId}/show`}
              data-seleniumid="lo-author-link"
            >
              <LocaleReferenceField
                {...props}
                reference="authors-locales"
                record={data}
                source="id"
                link={false}
                usePriorityLocale={usePriorityLocale}
              >
                <FunctionField
                  data-seleniumid="lo-author-name"
                  render={({ firstName, lastName }) => `${firstName} ${lastName}`}
                />
              </LocaleReferenceField>
            </Link>
          </Box>
          <Box position="relative">
            <LocaleReferenceField
              {...props}
              record={data}
              source="id"
              reference="authors-locales"
              link={false}
              usePriorityLocale={usePriorityLocale}
            >
              <TextAreaShowField
                allowHTML
                source="biography"
                seleniumId="lo-author-biography"
              />
            </LocaleReferenceField>
          </Box>
        </Box>
      )}
    </>
  );
};

AuthorCard.propTypes = {
  error: PropTypes.bool,
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
  record: PropTypes.object,
  usePriorityLocale: PropTypes.bool,
};

AuthorCard.defaultProps = {
  usePriorityLocale: true,
};

export default AuthorCard;
