/* eslint-disable no-unused-vars,react/prop-types */
import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReferenceArrayInput, useNotify, useMutation } from 'react-admin';
import { withCustomWidthTableStyles } from 'utils/styleUtils';
import { BASE_SEARCH_SOURCE } from 'constants';
import {
  List,
  Datagrid,
  LocaleReferenceField,
  LocaleListField,
  TextField,
  Filter,
  TextInput,
  AutocompleteArrayInput,
  TaxonomyButton,
} from '_ui';
import {
  TAXONOMY_DOWNLOAD_EVENT_TYPE,
  TAXONOMY_DOWNLOAD_MESSAGE,
  generateTaxonomyReport as generateTaxonomyReportUtil,
  getTaxonomyReportParams,
} from 'utils/exports';

const DisciplinesFilter = (props) => (
  <Filter {...props} seleniumId="disciplines-filter">
    <TextInput
      alwaysOn
      allowEmpty
      label="Search"
      source={BASE_SEARCH_SOURCE}
      seleniumId="disciplines-filter-search"
      isFilter
    />
    <ReferenceArrayInput
      allowEmpty
      alwaysOn
      label="Language"
      perPage={50}
      reference="languages"
      sort={{ field: 'name', order: 'ASC' }}
      source="availableLocales"
    >
      <AutocompleteArrayInput
        maxValuesArrayLength={1}
        seleniumId="disciplines-filter-languages"
        optionText="code"
        allowDuplicates={false}
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <TextInput
      source="code"
      isFilter
      seleniumId="disciplines-filter-code"
    />
  </Filter>
);

export const columnWidth = {
  code: 30,
  title: 45,
  availableLocales: 25,
};

const useStyle = makeStyles(withCustomWidthTableStyles(columnWidth));

const DisciplineList = (props) => {
  const classes = useStyle();
  const history = useHistory();
  const notify = useNotify();

  const [generate] = useMutation(
    getTaxonomyReportParams('disciplines'),
  );

  const generateTaxonomyReport = useCallback(() => {
    generate();
    const downloadWindow = generateTaxonomyReportUtil('disciplines');
    downloadWindow.addEventListener(TAXONOMY_DOWNLOAD_EVENT_TYPE, () => {
      notify(`Disciplines ${TAXONOMY_DOWNLOAD_MESSAGE}`);
    });
  }, [generate, notify]);

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<DisciplinesFilter />}
      seleniumId="disciplines"
      sort={{ field: 'code', order: 'ASC' }}
      actions={(
        <TaxonomyButton
          onClick={generateTaxonomyReport}
          data-seleniumid="generate-disciplines-taxonomy-report"
        />
      )}
    >
      <Datagrid localeRowClick classes={classes}>
        <TextField source="code" data-seleniumid="discipline-code" />
        <LocaleReferenceField
          source="id"
          label="Title"
          reference="disciplines-locales"
          link={false}
          sortable={undefined}
          allowEmpty
        >
          <TextField source="title" data-seleniumid="discipline-title" />
        </LocaleReferenceField>
        <LocaleListField
          sortable={undefined}
          source="availableLocales"
          label="Available in"
          hideLabel
          targetResource="disciplines"
          onClick={(locale, recordId) => {
            const { basePath } = props;
            history.push(`${basePath}/${recordId}/show/${locale}`);
          }}
        />
      </Datagrid>
    </List>
  );
};

DisciplineList.propTypes = {
  basePath: PropTypes.string,
  hasCreate: PropTypes.bool,
};

export default DisciplineList;
