import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
  },
});

export const ErrorPage = ({ status, children: message }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h1" color="primary">{status}</Typography>
      <Box marginBottom={1} />
      <Typography variant="h5">{message}</Typography>
      <Box marginBottom={3} />
      <IconButton color="primary" href="#/"><ArrowBackIcon /></IconButton>
    </div>
  );
};

ErrorPage.propTypes = {
  children: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export const NotFound = () => <ErrorPage status="404">Not found</ErrorPage>;
export const NotPermitted = () => <ErrorPage status="403">Forbidden</ErrorPage>;
