import { CREATE } from 'react-admin';
import axios from 'axios';
import { RESOURCE_PREFIX } from 'api/constants';

export const BULK_IMPORT_RESOURCE = 'imports';

export default (requestHandler) => async (type, resource, params = {}) => {
  if (type === CREATE && resource === BULK_IMPORT_RESOURCE) {
    const { data: { file, subType, type }, onProgress } = params;
    const formData = new FormData();

    formData.append('file', file.rawFile);
    if (subType) {
      formData.append('subType', subType);
    }
    formData.append('type', type);

    const { data } = await axios({
      method: 'post',
      url: `${window.ENDPOINT_BASE_URL}${RESOURCE_PREFIX}/${resource}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onProgress,
    });
    return { data };
  }
  return requestHandler(type, resource, params);
};
