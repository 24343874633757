import React from 'react';
import { Switch, Route } from 'react-router';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';

import { ROUTE_PATTERNS } from 'constants/routes';

import { Id } from './Breadcrumb';
import List from './List';
import Edit from './Edit';
import Show from './Show';
import Create, { getLabel } from './Create';

export const pubCodeBcSeleniumId = 'publication-code-breadcrumb';
export const pubLangBcSeleniumId = 'publication-lang-breadcrumb';
const ShowPublication = () => (
  <>
    <Id field="learningObjectCode" seleniumId={pubCodeBcSeleniumId} />
    <Id field="language" seleniumId={pubLangBcSeleniumId} />
  </>
);
const ShowImports = () => {
  let importTypes = useSelector(({ admin: { resources } }) => resources['import-types']?.data);
  if (importTypes) {
    importTypes = Object.values(importTypes).reduce((acc, { id, name }) => ({
      ...acc,
      [id]: name,
    }), {});
  }

  return <Id field="type" labelMap={importTypes} />;
};

const Breadcrumbs = () => (
  <Box
    display="flex"
    flexDirection="row"
    alignItems="center"
    data-seleniumid="breadcrumbs"
  >
    <Route path="/:resource" component={List} />
    <Switch>
      <Route
        exact
        path="/imports/bulk/create"
        component={() => <Create label="Upload files" />}
      />
      <Route
        exact
        path="/imports/:id/show"
        component={ShowImports}
      />
      <Route
        exact
        path="/publications/:id/show"
        component={ShowPublication}
      />
      <Route
        exact
        path={ROUTE_PATTERNS.edit}
        component={Edit}
      />
      <Route
        exact
        path={ROUTE_PATTERNS.show}
        component={Show}
      />
      <Route
        path={ROUTE_PATTERNS.create}
        component={(props) => <Create label={getLabel(props?.location)} />}
      />
    </Switch>
  </Box>
);

export default Breadcrumbs;
