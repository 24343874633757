import React, { useCallback } from 'react';
import { generatePath } from 'react-router';
import {
  useDelete,
  useRefresh,
  useRedirect,
  useNotify,
} from 'react-admin';

import Box from '@material-ui/core/Box';

import { useGetResourceSelector } from 'ra-redux/selectors';
import { usePathMatch } from 'utils/router';
import { sortLocales } from 'utils/locales';
import { getCapLabel } from 'utils/strings';
import { DeleteButton } from '_ui';

// TODO convert to linked list decorator
const getNextLocale = (locale, availableLocales = []) => {
  if (availableLocales.length > 1) {
    const [first] = availableLocales;
    const nextIndex = availableLocales.indexOf(locale) + 1;
    return availableLocales[nextIndex] || first;
  }
};

const DeleteAction = (buttonProps) => {
  const { path, params } = usePathMatch();
  const { resource, id, locale } = params;
  const {
    availableLocales,
    code,
    contentName: contentNameProp,
  } = useGetResourceSelector(resource, ['data', id]);
  const contentName = getCapLabel(contentNameProp);

  const [deleteOne, { loading }] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSubmit = useCallback((cb) => (event) => {
    event.preventDefault();
    cb();
    deleteOne(
      {
        resource,
        payload: { id, locale },
      },
      {
        onSuccess: () => {
          refresh();
          const nextLocale = getNextLocale(locale, sortLocales(availableLocales));
          const redirectPathname = generatePath(path, { ...params, locale: nextLocale });

          redirect(redirectPathname);
          notify(`Deleted: ${contentName} ${code}_${locale}`);
        },
        onError: (error) => {
          console.warn(error); // eslint-disable-line
          notify(
            `Error: delete action failed for ${contentName} ${code}_${locale}`,
            'error',
          );
        },
      },
    );
  });

  return (
    <Box marginLeft={5}>
      <DeleteButton
        loading={loading}
        label={`Delete ${locale}`}
        modalProps={{
          label: `${contentName} ${code}_${locale}`,
          /* eslint-disable react/no-this-in-sfc */
          get message() {
            return `
            You are about to delete ${this.label} from CKCS.
            This action cannot be undone.
            Continue?
            `;
          },
          /* eslint-enable */
          onSubmit,
        }}
        progressProps={{ size: 30 }}
        {...buttonProps}
      />
    </Box>
  );
};

DeleteAction.propTypes = DeleteButton.propTypes;
DeleteAction.defaultProps = DeleteButton.defultProps;

export default DeleteAction;
