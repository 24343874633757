import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDataProvider, getReferenceResource, CRUD_GET_LIST } from 'react-admin';
import List from './List';
import { getRequestParams } from './utils';

const INTERVAL = 10000;

const RealtimeList = ({ interval, extraHandler, ...props }) => {
  const dataProvider = useDataProvider();
  const { resource } = props;
  const { list: { params } } = useSelector((state) => getReferenceResource(state, { reference: resource }));

  const parsedParams = useMemo(() => getRequestParams(params), [params]);
  useEffect(
    () => {
      const requestInterval = setInterval(async () => {
        const list = await dataProvider.getList(resource, parsedParams, { action: CRUD_GET_LIST });
        if (extraHandler && list?.data) extraHandler(list.data);
      }, interval);
      return () => { clearInterval(requestInterval); };
    },
    [parsedParams],
  );

  return (
    <List {...props} />
  );
};

RealtimeList.propTypes = {
  extraHandler: PropTypes.func,
  interval: PropTypes.number,
  resource: PropTypes.string.isRequired,
};

RealtimeList.defaultProps = {
  interval: INTERVAL,
  extraHandler: null,
};

export default RealtimeList;
