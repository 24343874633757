import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import {
  getPublicationsState,
  getProgressPublicationsIDs,
  getProcessedPublications,
  finishPublications,
} from 'ra-redux/publications';
import { isEmpty } from 'lodash';
import usePublicationProgress from './usePublicationProgress';
import Notification, { NOTIFICATION_DURATION } from './Notification';
import PublicationsProgressView from './PublicationsProgress';

const PublicationsProgress = () => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const publications = useSelector(getPublicationsState);
  const progressIDs = useSelector(getProgressPublicationsIDs);
  const processed = useSelector(getProcessedPublications);

  usePublicationProgress({ ids: progressIDs });
  useEffect(() => {
    if (isEmpty(processed)) return;
    dispatch(finishPublications(processed));
    notify('', 'info', {
      component: (props) => <Notification data={processed} {...props} />,
      ignoreClickAway: true,
    }, false, NOTIFICATION_DURATION);
  }, [JSON.stringify(processed)]);

  if (isEmpty(publications)) return null;
  return (
    <PublicationsProgressView data={publications} />
  );
};

export default PublicationsProgress;
