import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '_ui';
import { LRsIcons } from '_ui/Layout/Menu/subMenusConfig';

const useStyles = makeStyles({
  wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '0.5rem',
  },
});

const StateField = (props) => {
  const { record = {} } = props || {};
  const { id } = record;
  const classes = useStyles();

  if (!id) return null;

  const Icon = LRsIcons[id];
  return (
    <div className={classes.wrap}>
      {Icon ? <Icon className={classes.icon} /> : null}
      <TextField {...props} />
    </div>
  );
};

StateField.defaultProps = {
  addLabel: true,
  label: 'State',
};

export default StateField;
