/* eslint-disable no-case-declarations */

import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router';
import { cloneDeep, get, set } from 'lodash';

import { ROUTE_PATTERNS } from 'constants/routes';

export const LOCATION_CHANGE_MENU = 'toggleFromMenu';

export const savePreviousPath = (store, payload) => {
  const prevPathname = get(store, 'location.pathname', null);
  const prevSearch = get(store, 'location.search', null);

  if (!prevPathname) return payload;

  const res = cloneDeep(payload);
  set(res, 'location.state', { ...get(res, 'location.state', {}), prevPathname, prevSearch });

  return res;
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const fromPathname = state?.location?.pathname;
      const matchShowFrom = matchPath(fromPathname, { path: ROUTE_PATTERNS.show });
      const matchListFrom = matchPath(fromPathname, { path: ROUTE_PATTERNS.list });
      const fromResource = matchShowFrom?.params?.resource || matchListFrom?.params?.resource;

      const toPathname = action.payload.location.pathname;
      const matchShowTo = matchPath(toPathname, { path: ROUTE_PATTERNS.show });
      const toResource = matchShowTo?.params?.resource;

      if (
        (fromResource === 'learning-objects' && toResource === 'authors')
        || (fromResource === 'imports' && matchShowTo)
      ) {
        action.payload.location.state = {
          from: fromPathname,
        };
      }

      return savePreviousPath(state, action.payload);
    default:
      return state;
  }
}

export const getLocation = (state) => state.router.location;
