import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'inherit !important',
    },
  },
});

const VideoInfoField = ({ record }) => {
  const { id, hasImportantInfo } = record || {};
  const classes = useStyles();
  if (!id || !hasImportantInfo) return null;

  return (
    <IconButton
      classes={classes}
      color="primary"
      title="Not applied video"
      size="medium"
      data-seleniumid={`${id}-info-button`}
    >
      <InfoIcon />
    </IconButton>
  );
};

VideoInfoField.propTypes = {
  record: PropTypes.shape({
    hasImportantInfo: PropTypes.bool,
    id: PropTypes.string.isRequired,
  }),
};

export default VideoInfoField;
