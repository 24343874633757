/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { LR_LOCALE_PUBLISHED_STATE } from 'constants/localeStates';
import { useQueryWithStore } from 'react-admin';
import Box from '@material-ui/core/Box';

import { WarningBox } from '_ui/Layout';
import { getManyLocalesPayloadRequest } from 'utils/locales';

const MESSAGE = 'The following languages will be automatically published after the Metadata update:';

const CurrentlyEditingWarning = ({ id, availableLocales }) => {
  const { data, loading } = useQueryWithStore(getManyLocalesPayloadRequest(id, availableLocales));
  if (!data?.length || loading) return null;

  const locales = data.filter(el => el.state === LR_LOCALE_PUBLISHED_STATE).map(({ locale }) => locale);
  if (!locales.length) return null;

  return (
    <Box marginTop={4}>
      <WarningBox seleniumId="currently-editing-warning">
        <>
          {MESSAGE} <strong>{locales.join(', ')}</strong>
        </>
      </WarningBox>
    </Box>
  );
};

CurrentlyEditingWarning.propTypes = {
  availableLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
};

export default (props) => {
  const { record } = props;
  const { id, availableLocales } = record || {};
  if (!availableLocales.length) return null;

  return <CurrentlyEditingWarning id={id} availableLocales={availableLocales} />;
};
