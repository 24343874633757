/* eslint-disable react/prop-types */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  CreateButton,
  ReferenceArrayInput,
  ReferenceField,
  useMutation,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import {
  Datagrid,
  List,
  LocaleReferenceField,
  AutocompleteArrayInput,
  Filter,
  TextInput,
  LocaleListField,
  TextField,
  TaxonomyButton,
} from '_ui';
import { BASE_SEARCH_SOURCE } from 'constants';
import {
  TAXONOMY_DOWNLOAD_EVENT_TYPE,
  TAXONOMY_DOWNLOAD_MESSAGE,
  generateTaxonomyReport as generateTaxonomyReportUtil,
  getTaxonomyReportParams,
} from 'utils/exports';
import { withCustomWidthTableStyles } from 'utils/styleUtils';

const ProgramFilter = (props) => (
  <Filter {...props} seleniumId="programs-filter">
    <TextInput
      label="Search"
      source={BASE_SEARCH_SOURCE}
      alwaysOn
      allowEmpty
      seleniumId="programs-filter-q"
      isFilter
    />
    <ReferenceArrayInput
      allowEmpty
      alwaysOn
      label="Language"
      perPage={50}
      reference="languages"
      sort={{ field: 'name', order: 'ASC' }}
      source="availableLocales"
    >
      <AutocompleteArrayInput
        maxValuesArrayLength={1}
        seleniumId="programs-filter-languages"
        optionText="code"
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <TextInput
      label="Code"
      source="code"
      allowEmpty
      seleniumId="programs-filter-code"
      isFilter
    />
    <ReferenceArrayInput
      label="Parent Code"
      source="parentId"
      reference="programs"
      allowEmpty
      sort={{ field: 'code', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        minSearchableLength={1}
        seleniumId="programs-filter-parent"
        optionText="code"
        allowEmpty={false}
      />
    </ReferenceArrayInput>
  </Filter>
);

const columnWidth = {
  code: 25,
  title: 25,
  program: 25,
  availableLocales: 25,
};

const useStyle = makeStyles(withCustomWidthTableStyles(columnWidth));

const ProgramsList = props => {
  const { hasCreate } = props;
  const history = useHistory();
  const notify = useNotify();
  const classes = useStyle();

  const [generate] = useMutation(
    getTaxonomyReportParams('program'),
  );

  const generateTaxonomyReport = useCallback(() => {
    generate();
    const downloadWindow = generateTaxonomyReportUtil('program');
    downloadWindow.addEventListener(TAXONOMY_DOWNLOAD_EVENT_TYPE, () => {
      notify(`Program ${TAXONOMY_DOWNLOAD_MESSAGE}`);
    });
  }, [generate, notify]);

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<ProgramFilter />}
      actions={(
        <>
          {
            hasCreate
              ? <CreateButton basePath="../programs" data-seleniumid="create-programs-btn" />
              : null
          }
          <TaxonomyButton
            onClick={generateTaxonomyReport}
            data-seleniumid="generate-program-taxonomy-report"
          />
        </>
      )}
      seleniumId="programs"
      sort={{ field: 'code', order: 'ASC' }}
    >
      <Datagrid localeRowClick classes={classes}>
        <TextField source="code" />
        <LocaleReferenceField
          source="id"
          label="Title"
          reference="programs-locales"
          link={false}
          sortable={undefined}
        >
          <TextField source="title" />
        </LocaleReferenceField>

        <ReferenceField
          reference="programs"
          source="parentId"
          label="Parent Program"
          link={false}
        >
          <TextField source="code" seleniumId="programs-parent-code" />
        </ReferenceField>

        <LocaleListField
          sortable={undefined}
          source="availableLocales"
          label="Available in"
          hideLabel
          targetResource="programs"
          onClick={(locale, recordId) => {
            const { basePath } = props;
            history.push(`${basePath}/${recordId}/show/${locale}`);
          }}
        />
      </Datagrid>
    </List>
  );
};

ProgramsList.propTypes = {
  basePath: PropTypes.string,
};

export default ProgramsList;
