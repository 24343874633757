import React, { useState, useCallback } from 'react';
import {
  TextField,
  ReferenceArrayInput,
  List as RaList,
} from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';

import {
  AutocompleteArrayInput,
  BooleanInput,
  CommentField,
  DateField,
  Datagrid,
  TruncatedTextField,
  TextInput,
  List,
  Filter,
  UserField,
  ReferenceField,
  WarningBox,
} from '_ui';

import DateRangeInput, { useDateQuickFilter } from '_ui/Input/DateRangeInput';

import { withCustomWidthTableStyles } from 'utils/styleUtils';
import { WARNING_MESSAGE } from 'utils/export/constants';
import { BASE_SEARCH_SOURCE } from 'constants';

import { PublicationLRLocaleLinkField } from '_ui/Field';
import PublisherInput from '../../common/UserInput';

import { STATUS_OPTIONS } from '../constants';
import { ActionsField, StatusField, WarningField } from '../Fields';

import PublicationsListActions from '../PublicationsListActions';
import PublicationLogsField from '../PublicationsLogField';

const PublicationsFilter = props => {
  const { lastTwoMonths } = useDateQuickFilter();
  const dateRangeProps = {
    inputProps: lastTwoMonths,
    initialValue: lastTwoMonths.max,
  };

  return (
    <Filter {...props} seleniumId="publications-filters">
      <TextInput
        label="Search (50 LRs at max)"
        source={BASE_SEARCH_SOURCE}
        seleniumId="publications-filter-search"
        alwaysOn
        isFilter
        inputProps={{ maxLength: 450 }}
      />
      <ReferenceArrayInput
        alwaysOn
        label="Language"
        perPage={50}
        reference="languages"
        source="availableLocales"
      >
        <AutocompleteArrayInput
          seleniumId="publications-filter-languages"
          optionText="code"
          allowDuplicates={false}
          isFilter
          ignoreInputValueMatching
        />
      </ReferenceArrayInput>
      <AutocompleteArrayInput
        source="status"
        alwaysOn
        isFilter
        allowEmpty={false}
        ignoreInputValueMatching
        choices={STATUS_OPTIONS}
        seleniumId="publications-filter-status"
      />
      <ReferenceArrayInput
        label="Publication Type"
        source="publicationType"
        reference="publication-types"
        allowEmpty
        data-seleniumid="publications-filter-type"
      >
        <AutocompleteArrayInput
          optionText="name"
          seleniumId="publications-type-input"
          allowEmpty={false}
        />
      </ReferenceArrayInput>
      <PublisherInput
        label="Initiated By"
        source="publishedBy"
        reference="publication-creators"
        seleniumId="publications-filter-publisher"
      />
      <DateRangeInput
        label="Initiated Date"
        source="created"
        seleniumId="publications-filter-initiated"
        {...dateRangeProps}
      />
      <DateRangeInput
        label="Completed Date"
        source="published"
        seleniumId="publications-filter-completed"
        {...dateRangeProps}
      />
      <BooleanInput
        label="Warnings"
        inputLabel="Active warnings"
        source="successfulWithWarnings"
        defaultValue={true}
        seleniumId="publications-filter-warning"
      />
    </Filter>
  );
};

const columnWidth = {
  expand: 4,
  warning: 3,
  type: 7,
  code: 8,
  locale: 6,
  title: 10,
  comment: 10,
  publishedBy: 10,
  initiated: 10,
  completed: 10,
  status: 10,
  actions: 12,
};

const useStyle = makeStyles(withCustomWidthTableStyles(columnWidth));

const PublicationsList = props => {
  const [showWarning, setShowWarning] = useState(false);
  const onExportMenuToggle = useCallback((anchor) => {
    setShowWarning(!!anchor);
  }, []);

  const classes = useStyle();

  return (
    <>
      <List
        {...props}
        actions={<PublicationsListActions onExportMenuToggle={onExportMenuToggle} />}
        filters={<PublicationsFilter />}
        bulkActionButtons={false}
        seleniumId="publications"
      >
        <Datagrid
          classes={classes}
          rowClick="show"
          data-seleniumid="publications-table"
          warning={showWarning ? <WarningBox seleniumId="import-list-export-warning">{WARNING_MESSAGE}</WarningBox> : null}
          expand={<PublicationLogsField />}
        >
          <WarningField />
          <ReferenceField
            source="publicationType"
            reference="publication-types"
            link={false}
          >
            <TextField source="name" data-seleniumid="publication-type" />
          </ReferenceField>
          <TextField label="LR Code" source="learningObjectCode" data-seleniumid="publication-code" />
          <PublicationLRLocaleLinkField
            seleniumId="publication-locale"
          />
          <TruncatedTextField
            source="title"
            label="Title"
            data-seleniumid="publication-title"
            sortable={undefined}
          />
          <CommentField
            sortable={false}
            label="Comment"
          />
          <UserField
            showLabeled={false}
            seleniumId="publication-creator"
            label="Initiated by"
            source="publishedBy"
          />
          <DateField
            source="initiatedDate"
            label="Initiated Date/Time"

            data-seleniumid="publication-initiated"
          />
          <DateField
            source="publicationDate"
            label="Completed Date/Time"

            data-seleniumid="publication-created"
          />
          <StatusField
            label="Status"
            sortBy="status"
            mode="list"
            seleniumId="publication-status"
          />
          <ActionsField label="Actions" />
        </Datagrid>
      </List>
    </>
  );
};

PublicationsList.propTypes = RaList.propTypes;
PublicationsList.defaultProps = RaList.defaultProps;

export default PublicationsList;
