import {
  call,
  all,
  fork,
  take,
  put,
  select,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { channel } from 'redux-saga';
import uuid from 'uuid/v1';
import { showNotification } from 'react-admin';
import get from 'lodash/get';

import { generateSearchParams } from 'utils';
import { parseResourceLocale } from 'utils/locales';
import { CREATE_TYPE, GET_ONE_TYPE } from 'api/backend-data-server/dataProviderAdapter';
import dataProvider from 'api/DataProvider';
import { BULK_IMPORT_RESOURCE } from 'api/DataProvider/handlers/createBulkImports';

import { IMPORT_HIDE_DURATION } from '_domains/Jobs/Imports/constants';
import { JOB_STATUSES } from '_domains/Jobs/constants';
import {
  IMPORTS_UPLOADS,
  startImportUpload,
  importUploadProgress,
  importUploadComplete,
  importUploadClear,
} from 'ra-redux/importsUpload';
import { getOnCompleteMessage, getStartedJobProcessMessage } from './utils/messages';

const RETRY_DELAY = 2000;
const RETRY_COUNT = Math.round((60 * 1000) / RETRY_DELAY);

/**
 * Display error message when the number of attempts to take the import status (!= in_progress) has ended
 */
function* displayNotCompletedNotification(jobId) {
  yield put(
    showNotification(`Import: Can not get job (${jobId}) status.
          Retry limit reached.
          Check import listing for status update.`,
    'error',
    { autoHideDuration: IMPORT_HIDE_DURATION },
    ),
  );
}

/**
 * Display error message when the import job creation failed
 */
function* displayFailedImportNotification() {
  yield put(
    showNotification('Import: The import process cannot be started. Please, try later.',
      'error',
      { autoHideDuration: IMPORT_HIDE_DURATION },
    ),
  );
}

function* getImportJob(id) {
  const { data } = yield call(
    dataProvider[GET_ONE_TYPE],
    'imports',
    { id },
  );

  return data;
}

/**
 * Handle direct upload from LR Locale and Author view pages.
 * @param entityId
 * @param entityResource
 */
function* handleSuccessDirectUpload({ entityId, entityResource }) {
  if (!entityId || !entityResource) return;
  const requestPayload = { id: entityId };
  // get entity with new data (e.g. lr locale with update thumbnailUrl)
  const payload = yield call(dataProvider[GET_ONE_TYPE], entityResource, requestPayload);
  // update entity row in store
  yield put({
    type: 'RA/CRUD_GET_ONE_SUCCESS',
    payload,
    requestPayload,
    meta: {
      resource: entityResource,
      fetchResponse: 'GET_ONE',
      fetchStatus: 'RA/FETCH_END',
    },
  });
}

/**
 * Generation notification of completion of import processing based on logs of import job
 */
function* finishImportProcessing(importJob) {
  const { id } = importJob;
  const { data: importDetails } = yield call(
    dataProvider.getMany,
    'import-logs',
    { ids: [id] },
  );

  const [message, messageType] = getOnCompleteMessage(importJob, importDetails);
  yield put(showNotification(
    message,
    messageType,
    { autoHideDuration: IMPORT_HIDE_DURATION },
  ));
}

function* watchOnComplete({ jobId, importId, uploadChannel, ...rest }) {
  let retry = RETRY_COUNT;
  while (true) {
    if (!retry) {
      yield displayNotCompletedNotification(jobId);
      break;
    }
    yield delay(2000);
    try {
      const importJob = yield getImportJob(jobId);
      const { status } = importJob;

      if (status === JOB_STATUSES.SUCCESSFUL) {
        yield handleSuccessDirectUpload(rest);
      }

      if (status && status !== JOB_STATUSES.IN_PROGRESS) {
        yield finishImportProcessing(importJob);
        break;
      }
    }
    catch (error) {
      console.warn(error); // eslint-disable-line no-console
    }

    retry--;
  }
  uploadChannel.close();
  yield put(importUploadClear(importId));
}

/**
 * Callback for uploading progress fetching
 */
const handleBulkImportProgress = (channel, event) => {
  const progress = Math.round((event.loaded * 100) / event.total);
  channel.put(progress);
};

/**
 * For each channel action updates import progress
 */
function* watchOnProgress(chan, uploadInfo) {
  while (true) {
    const progress = yield take(chan);
    yield put(importUploadProgress({ ...uploadInfo, progress }));
  }
}

/**
 * Call to create import job with callback for progress event
 */
function* initBulkImportJob(payload, file, uploadChannel) {
  return yield call(
    dataProvider[CREATE_TYPE],
    BULK_IMPORT_RESOURCE,
    { data: { ...payload, file }, onProgress: event => handleBulkImportProgress(uploadChannel, event) },
  );
}

/**
 *  Display notification after successfully uploading the file and creating the import job
 */
function* displayStartMessage(locale, payload, fileName) {
  yield put(
    showNotification(
      getStartedJobProcessMessage({ locale, payload, fileName }),
      'info',
      { autoHideDuration: IMPORT_HIDE_DURATION },
    ),
  );
}

function* uploadFile(data, file) {
  const { entityId = null, entityResource = null, ...payload } = data;
  const fileName = file?.rawFile?.name;
  const importId = `${uuid()}_${fileName}`;
  const uploadInfo = { id: importId, title: fileName, entityId };
  const locale = parseResourceLocale(fileName);

  yield put(startImportUpload({ ...uploadInfo, progress: 0 }));

  // create channel and watch progress (wait action from created channel)
  const uploadChannel = yield call(channel);
  yield fork(watchOnProgress, uploadChannel, uploadInfo);

  try {
    // create import job with channel
    const { data: { id: jobId } } = yield initBulkImportJob(payload, file, uploadChannel);
    yield displayStartMessage(locale, payload, fileName);

    // start a non-blocking saga to check the status of import job with a limit of checks
    yield fork(watchOnComplete, { jobId, importId, entityId, entityResource, uploadChannel });

    // when import jobs created (file download is over), we move the import data in the store to the status completed (progress 100)
    yield put(importUploadComplete(importId));
  }
  catch (e) {
    yield displayFailedImportNotification();
    // eslint-disable-next-line no-console
    console.error(e);
    uploadChannel.close();
    yield put(importUploadClear(importId));
  }
}

function* uploadSource({ payload = {} }) {
  const { source } = payload;

  const files = Array.isArray(source.file) ? source.file : [source.file];

  try {
    const { pathname: initPathname } = yield select(({ router }) => router.location);
    if (/create$/.test(initPathname)) {
      const listSearchParams = yield select((state) => get(state, 'admin.resources.imports.list.params', {}));
      yield put(push(generateSearchParams('/imports', listSearchParams)));
    }
    yield all(files.map(file => call(uploadFile, payload, file)));
  }
  catch (err) {
    yield put(
      showNotification(
        `Can't upload: ${err.message || err.response?.data || err.request?.data}`,
        'error',
        { autoHideDuration: IMPORT_HIDE_DURATION },
      ),
    );
  }
}

export default function* importsUpload() {
  yield takeLatest(IMPORTS_UPLOADS, uploadSource);
}
