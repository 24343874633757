export const LOADING_RESOURCE = 'LOADING_RESOURCE';

export default (state = {}, { type, payload }) => {
  switch (type) {
    case LOADING_RESOURCE: {
      const { resource, flag } = payload;
      return {
        ...state,
        [resource]: flag,
      };
    }
    default:
      return state;
  }
};

export const loadingResource = (payload) => ({ type: LOADING_RESOURCE, payload });

export const getResourceLoading = (resource) => (state) => (state.loading || {})[resource] || false;
