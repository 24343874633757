import React, { memo, useState, useRef, useLayoutEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'react-admin';
import { Collapse, IconButton, makeStyles } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { sanitizeProps } from '_ui/Field/utils';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    wordWrap: 'break-word',
  },
  button: {
    float: 'left',
    marginRight: theme.spacing(),
    padding: theme.spacing(),
  },
  icon: ({ isOpen }) => ({
    transition: theme.transitions.create('transform'),
    transform: `rotate(${isOpen ? '0' : '-90deg'})`,
  }),
}));

const CollapsedTextField = ({
  seleniumId,
  numLines,
  Component = TextField,
  ...props
}) => {
  const { record, source } = props;

  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [collapseState, setCollapseState] = useState({
    isCollapsable: false,
    collapsedHeight: 0,
    lineHeight: 20,
  });

  const toggleOpen = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsOpen(!isOpen);
    },
    [isOpen],
  );

  useLayoutEffect(
    () => {
      if (containerRef.current) {
        const containerEl = containerRef.current.querySelector(`[data-seleniumid="${seleniumId}"]`);
        const lineHeight = parseInt(window.getComputedStyle(containerEl)['line-height'], 10);
        const totalNumLines = Math.ceil(containerEl.offsetHeight / lineHeight);

        setCollapseState({
          isCollapsable: totalNumLines > numLines,
          collapsedHeight: lineHeight * (totalNumLines > numLines ? numLines : totalNumLines),
        });
      }
    },
    [containerRef.current, record.locale],
  );

  const { isCollapsable, collapsedHeight, lineHeight } = collapseState;
  const classes = useStyles({ isOpen, lineHeight });

  if (!record || !record[source]) return null;

  return (
    <div ref={containerRef} className={classes.container}>
      {
        isCollapsable
          && (
            <IconButton
              onClick={toggleOpen}
              data-seleniumid={`${seleniumId}-expand-button`}
              classes={{ root: classes.button }}
            >
              <ExpandMoreIcon classes={{ root: classes.icon }} />
            </IconButton>
          )
      }
      <Collapse in={isOpen} collapsedHeight={collapsedHeight}>
        <Component
          {...sanitizeProps(props)}
          data-seleniumid={seleniumId}
        />
      </Collapse>
    </div>
  );
};

CollapsedTextField.propTypes = {
  ...TextField.propTypes,
  numLines: PropTypes.number,
  seleniumId: PropTypes.string.isRequired,
};

CollapsedTextField.defaultProps = {
  ...TextField.defaultProps,
  numLines: 2,
};

export default memo(CollapsedTextField);
