export const LEVEL_OPTIONS = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
];

const ContentNamesLinksAllowed = {
  ACTION_TIPS: true,
  ACTION_TIPS_THE_TEAM: true,
  ESSENTIAL: true,
  ESSENTIAL_THE_TEAM: true,
  QUICK_WIN: true,
  SESSION: true,
  SESSION_THE_TEAM: true,
  VIDEO_LEADERS: true,
  VIDEO_FACULTY: true,
};

export const LR_LINKS_CONFIG = {
  ACTION_TIPS: {
    ...ContentNamesLinksAllowed,
    ACTION_TIPS: false,
    ACTION_TIPS_THE_TEAM: false,
    ESSENTIAL_THE_TEAM: false,
    ESSENTIAL: false,
  },
  ACTION_TIPS_THE_TEAM: {
    ...ContentNamesLinksAllowed,
    ACTION_TIPS: false,
    ACTION_TIPS_THE_TEAM: false,
    ESSENTIAL: false,
    ESSENTIAL_THE_TEAM: false,
  },
  ESSENTIAL: {
    ...ContentNamesLinksAllowed,
    ACTION_TIPS: false,
    ACTION_TIPS_THE_TEAM: false,
    ESSENTIAL: false,
    ESSENTIAL_THE_TEAM: false,
  },
  ESSENTIAL_THE_TEAM: {
    ...ContentNamesLinksAllowed,
    ACTION_TIPS: false,
    ACTION_TIPS_THE_TEAM: false,
    ESSENTIAL: false,
    ESSENTIAL_THE_TEAM: false,
  },
  QUICK_WIN: {
    ...ContentNamesLinksAllowed,
    ACTION_TIPS_THE_TEAM: false,
    ESSENTIAL: false,
    ESSENTIAL_THE_TEAM: false,
  },
  SESSION: {
    ...ContentNamesLinksAllowed,
    ACTION_TIPS_THE_TEAM: false,
    ESSENTIAL_THE_TEAM: false,
  },
  SESSION_THE_TEAM: {
    ...ContentNamesLinksAllowed,
    ACTION_TIPS: false,
    ESSENTIAL: false,
  },
  VIDEO_LEADERS: {
    ...ContentNamesLinksAllowed,
    ACTION_TIPS_THE_TEAM: false,
    ESSENTIAL: false,
    ESSENTIAL_THE_TEAM: false,
  },
  VIDEO_FACULTY: {
    ...ContentNamesLinksAllowed,
    ACTION_TIPS_THE_TEAM: false,
    ESSENTIAL: false,
    ESSENTIAL_THE_TEAM: false,
  },
};

export const LR_MAX_VALUES_CONFIG = {
  ACTION_TIPS: {
    VIDEO_FACULTY: 5,
    VIDEO_LEADERS: 5,
  },
};

export const REPUBLISH_TITLE =  'The following languages will be automatically republished';
export const NOTIFY = 'Auto-publishing has been started:';
