import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { isEmpty } from 'lodash';
import { useInput, useListContext } from 'react-admin';
import { makeStyles, TextField } from '@material-ui/core';
import { useIsFirstRender } from 'utils/hooks';
import {
  addDays,
  subDays,
  subMonths,
  isAfter,
  isBefore,
} from 'date-fns';

import { TODAY_LOCAL } from 'constants/dateTime';
import { formatToUpperCase, parseValue, formatValue } from 'utils';

export const useDateQuickFilter = () => ({
  get lastTwoMonths() {
    return { min: formatValue(subMonths(TODAY_LOCAL, 2)), max: formatValue(TODAY_LOCAL) };
  },
});

const useStyles = makeStyles({
  root: {
    margin: 0,
    '&:first-of-type': {
      marginRight: '1rem',
    },
  },
});

const sanitizeDateInputProps = ({ allowEmpty, defaultValue, ...props }) => props;
const DateInput = (props) => {
  const {
    resource,
    source,
    initialValue,
    onChange,
    ...restProps
  } = sanitizeDateInputProps(props);

  const {
    id,
    input,
    meta: { error, submitError, touched },
  } = useInput({
    initialValue,
    resource,
    source,
    format: formatValue,
    parse: parseValue,
  });

  const classes = useStyles();

  return (
    <TextField
      id={id}
      variant="standard"
      type="date"
      error={!!(touched && (error || submitError))}
      helperText={false}
      classes={classes}
      InputLabelProps={{ shrink: true }}
      {...input}
      {...restProps}
      onChange={(event) => {
        onChange(event);
        input.onChange(event);
      }}
    />
  );
};

DateInput.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  resource: PropTypes.string,
  source: PropTypes.string.isRequired,
};

export const OPERATOR_TO_EXPRESSION = { gte: 'from', lte: 'to' };

const sanitizeDateRangeInputProps = ({ record, ...props }) => props;

const DateRangeInput = (props) => {
  const {
    source,
    seleniumId,
    label,
    inputProps,
    initialValue,
    ...restProps
  } = sanitizeDateRangeInputProps(props);
  const form = useForm();
  const isFirstRender = useIsFirstRender();
  const { filterValues } = useListContext();

  useEffect(() => {
    if (isFirstRender && !isEmpty(filterValues[source])) return;

    if (initialValue) {
      const [startDate, endDate] = !Array.isArray(initialValue)
        ? [initialValue, initialValue]
        : initialValue;
      setTimeout(() => {
        form.batch(() => {
          form.change(`${source}.gte`, parseValue(startDate, `${source}.gte`));
          form.change(`${source}.lte`, parseValue(endDate, `${source}.lte`));
        });
      }, 0);
    }
  }, [source, initialValue?.toString()]);

  const { values } = form.getState();

  const onGteChange = useCallback((event) => {
    if (values[source]?.lte) {
      const gteDate = new Date(event.target.value);
      const lteDate = new Date(values[source]?.lte);
      const maxDate = inputProps?.max ? new Date(inputProps?.max) : null;
      const nextDate = addDays(gteDate, 1);

      if (isAfter(gteDate, lteDate)) {
        form.change(
          `${source}.lte`,
          parseValue(
            maxDate && isAfter(nextDate, maxDate) ? maxDate : nextDate,
            `${source}.lte`,
          ),
        );
      }
    }
  }, [source, values, inputProps?.max]);

  const onLteChange = useCallback((event) => {
    if (values[source]?.gte) {
      const lteDate = new Date(event.target.value);
      const gteDate = new Date(values[source]?.gte);
      const minDate = inputProps?.min ? new Date(inputProps?.min) : null;
      const nextDate = subDays(lteDate, 1);

      if (isBefore(lteDate, gteDate)) {
        form.change(
          `${source}.gte`,
          parseValue(
            minDate && isBefore(nextDate, minDate) ? minDate : nextDate,
            `${source}.gte`,
          ),
        );
      }
    }
  }, [source, values, inputProps?.min]);

  return ['gte', 'lte'].map((operator) => (
    <DateInput
      key={`date-input-${operator}`}
      source={`${source}.${operator}`}
      label={`${label || formatToUpperCase(source)} ${OPERATOR_TO_EXPRESSION[operator]}`}
      inputProps={{
        ...inputProps,
        'data-seleniumid': `${seleniumId}-date-${OPERATOR_TO_EXPRESSION[operator]}`,
      }}
      onChange={operator === 'gte' ? onGteChange : onLteChange}
      {...restProps}
    />
  ));
};

DateRangeInput.propTypes = {
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  inputProps: PropTypes.shape({
    max: PropTypes.string,
    min: PropTypes.string,
  }),
  label: PropTypes.string,
  resource: PropTypes.string,
  seleniumId: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

DateRangeInput.defaultProps = {
  inputProps: {},
};

export default DateRangeInput;
