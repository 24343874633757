/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  makeStyles,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  SaveButton as RASaveButton,
  Toolbar as RAToolBar,
  Button,
  useQueryWithStore,
} from 'react-admin';
import usePristineForm from 'hoc/usePristineForm';
import { ToolBar } from '_ui/Layout';
import SaveIcon from '@material-ui/icons/Save';
import { LR_LOCALE_PUBLISHED_STATE } from 'constants/localeStates';

const MOVED_TO_CURRENTLY_EDITING_HEADER = 'has already been published';
const MOVED_TO_CURRENTLY_EDITING = 'Saving these changes will move this localization to Currently Editing state. Continue?';

const useToolBarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const SaveButton = ({ resource, disabled, onClick }) => (
  <>
    <Button
      disabled={disabled}
      label="Save"
      size="medium"
      variant="contained"
      onClick={onClick}
      data-seleniumid={`${resource}-save-button`}
    >
      <SaveIcon />
    </Button>
  </>
);

SaveButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  resource: PropTypes.string,
};

const LearningResourceLocaleEditToolBar = (props) => {
  const [open, setOpen] = useState(false);
  const classes = useToolBarStyles();
  const { resource, saving, record, record: { locale, parentId } } = props;
  const pristine = usePristineForm(record);

  const { data } = useQueryWithStore({
    type: 'getOne',
    resource: 'learning-objects',
    payload: { id: parentId },
  });

  const isDisabled = !record?.id || saving || pristine;

  return (
    <RAToolBar {...props} classes={classes}>
      <SaveButton
        resource={resource}
        onClick={() => setOpen(true)}
        disabled={isDisabled}
      />
      <>
        <Dialog
          open={open}
          onClose={() => !saving && setOpen(false)}
          aria-labelledby="re-publish-title"
        >
          <DialogTitle data-seleniumid="moved-to-ce-modal-title" id="moved-to-ce-modal-title">
            {data?.code}
            <Box
              data-seleniumid={`locale-for-republish-${locale}`}
              key={`locale-for-republish-${locale}`}
              margin={1}
              component={Chip}
              label={locale}
              variant="outlined"
            />
            {MOVED_TO_CURRENTLY_EDITING_HEADER}
          </DialogTitle>
          <Divider />
          <Box
            data-seleniumid="moved-to-ce-modal-text"
            component={DialogContent} marginTop={2}
          >
            {MOVED_TO_CURRENTLY_EDITING}
          </Box>
          <RAToolBar {...props} classes={classes}>
            <>
              <Button
                color="default"
                label="Cancel"
                data-seleniumid="move-to-currently-editing-cancel"
                onClick={() => setOpen(false)}
                size="medium"
                variant="contained"
              />
              <Box display="flex" flexGrow="1" />
            </>
            <RASaveButton
              label="Confirm"
              data-seleniumid="move-to-currently-editing-confirm"
            />
          </RAToolBar>
        </Dialog>
      </>
    </RAToolBar>
  );
};

LearningResourceLocaleEditToolBar.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  saving: PropTypes.bool,
};

export default (props) => {
  const { record } = props;

  if (!record?.state === LR_LOCALE_PUBLISHED_STATE) return <ToolBar {...props} />;

  return <LearningResourceLocaleEditToolBar {...props} />;
};
