import { compose } from 'redux';
import hash from 'object-hash';
import jsonServerProvider, { IGNORE_ABORTING_KEY } from '../backend-data-server/BackendServerDataProvider';
import dataProviderAdapter from '../backend-data-server/dataProviderAdapter';
import { RESOURCE_PREFIX } from '../constants';
import fetchJson from '../fetchJson';
import handlers from './handlers';

const EMPTY_AUTOCOMPLETE_RESPONSE = { json: { items: [], totalAmount: 0 } };

const lastAbortController = {};
const checkIsRequestAborted = (url, options) => {
  if (!url.includes('/search') || url.includes('learning-objects-locales')) return false;

  const { [IGNORE_ABORTING_KEY]: ignoreAborting } = options;
  if (ignoreAborting) {
    delete options[IGNORE_ABORTING_KEY];
    return false;
  }
  return true;
};

const httpClient = (url, options = {}) => {
  const requestHash = hash(options?.body || {});
  if (url in lastAbortController && lastAbortController[url].requestHash === requestHash) {
    lastAbortController[url].abort();
  }

  const isRequestAborted = checkIsRequestAborted(url, options);
  if (isRequestAborted) {
    const currentAbortController = new AbortController();
    currentAbortController.requestHash = requestHash;
    lastAbortController[url] = currentAbortController;

    const { signal } = currentAbortController;
    signal.addEventListener('abort', () => {
      delete lastAbortController[url];
    });

    options.signal = signal;
  }

  const fullUrl = `${window.ENDPOINT_BASE_URL}${url}`;
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  return fetchJson(fullUrl, options)
    .catch((error) => {
      if (error.name === 'AbortError' && isRequestAborted) return EMPTY_AUTOCOMPLETE_RESPONSE;
      return Promise.reject(error);
    });
};

const jsonServerDataProvider = jsonServerProvider(RESOURCE_PREFIX, httpClient);

export default dataProviderAdapter(compose(...handlers)(jsonServerDataProvider));
