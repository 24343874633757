/* eslint-disable react/prop-types */

import React from 'react';
import { ShowView as RaShowView } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    '& > div': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const ShowView = ({ resource, ...props }) => {
  const classes = useStyles();

  return <RaShowView classes={classes} resource={resource} {...props} />;
};

export default ShowView;
