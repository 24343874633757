import React from 'react';
import PropTypes from 'prop-types';

import { FunctionField } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  root: ({ isInactive }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: isInactive ? theme.palette.grey[400] : theme.palette.warning.main,
  }),
}));

const WarningField = (props) => {
  const { record: { fixedWarnings, nextSuccessfulPubId } } = props;
  const isInactive = !!(fixedWarnings || nextSuccessfulPubId);
  const classes = useStyles({ isInactive });
  return (
    <FunctionField
      {...props}
      render={({ hasWarnings }) => (
        hasWarnings && (
          <WarningIcon
            data-seleniumid="publication-warning"
            classes={classes}
          />
        )
      )}
    />
  );
};

WarningField.propTypes = {
  record: PropTypes.shape({
    fixedWarnings: PropTypes.string,
    nextSuccessfulPubId: PropTypes.string,
  }),
};
export default WarningField;
