import React from 'react';
import { TextField } from 'react-admin';
import { useIsLanguageAgnosticView } from 'utils/hooks';

import {
  LocaleReferenceField,
  TabbedLanguagesView,
  SimpleShowLayout,
} from '_ui';

const DisciplineView = props => {
  const isLanguageAgnosticView = useIsLanguageAgnosticView();

  return (
    <TabbedLanguagesView
      {...props}
      hasEdit={!isLanguageAgnosticView}
      resource="disciplines"
      languageAgnosticFields={(props) => (
        <SimpleShowLayout {...props}>
          <TextField source="code" data-seleniumid="disciplines-code" />
        </SimpleShowLayout>
      )}
      languageSpecificFields={(props) => (
        <SimpleShowLayout {...props}>
          <LocaleReferenceField
            source="id"
            label="Title"
            reference="disciplines-locales"
            link={false}
            usePriorityLocale={false}
          >
            <TextField source="title" data-seleniumid="disciplines-locale-title" />
          </LocaleReferenceField>
        </SimpleShowLayout>
      )}
    />
  );
};
export default DisciplineView;
