import { cloneElement } from 'react';
import { useFormState } from 'react-final-form';

const SkillSetWrap = ({ children, ignore, choices, ...props }) => {
  const { values } = useFormState();
  const ignoredIDs = values[ignore] || [];
  return cloneElement(children, {
    ...props,
    choices: (choices || []).filter(el => !(Array.isArray(ignoredIDs) ? ignoredIDs : [ignoredIDs]).includes(el.id)),
  });
};

export default SkillSetWrap;
