import format from 'date-fns/format';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

import { DATE_TIME_FORMAT } from './constants';

export const getEmptyFieldsMap = (fieldsMap) => Object.keys(fieldsMap || {})
  .reduce((acc, key) => ({ ...acc, [key]: '' }), {});

export const formatExportDate = date => format(
  new Date(date),
  DATE_TIME_FORMAT,
);

export const getExportDateRange = (exportEntriesOverride, format) => {
  const exportEntries = [...exportEntriesOverride];
  const sortCb = ({ date }) => Date.parse(date);

  return new Proxy({
    lte: maxBy(exportEntries, sortCb),
    gte: minBy(exportEntries, sortCb),
  }, {
    get(target, prop) {
      if (!prop) return target;

      if (target?.[prop]?.date && format) return format(target[prop]?.date);
      return target?.[prop]?.date;
    },
  });
};
