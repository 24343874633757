import React, { useState, useCallback, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDelete, useRefresh, useNotify, useDataProvider, GET_ONE } from 'react-admin';
import set from 'lodash/set';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import DialogContent from '@material-ui/core/DialogContent';

import { getFilteredLocales } from 'ra-redux/locales';
import { useGetResourceSelector } from 'ra-redux/selectors';
import { fileImportType } from '_domains/Jobs/Imports/constants';
import { validateLOThumbnail } from '_domains/Jobs/Imports/utils';
import { ImportInput } from '_domains/Jobs/Imports/Input';
import { ImageField } from '_ui/Field';
import { ConfirmationModal } from '_ui/Layout';
import { LR_LOCALE_PUBLISHED_STATE } from 'constants/localeStates';
import { LR_LOCALES, LR } from 'constants/resources';
import { REGEX_LR_CODE } from 'constants/regex';
import { usePathMatch } from 'utils/router';
import { parseResourceLocale } from 'utils/locales';
import { REPUBLISH_TITLE } from '../constants';

const META_VALIDATION_TEMPLATE = (
  [field],
  isLocalized,
  name,
  value,
) => `The ${
  !isLocalized
    ? 'non-'
    : ''
}localized file name ${name} does not match the ${field} "${value}" of the selected Learning Resource`;

export const useCreateValidator = ({
  resource,
  record: localeRecord,
}) => {
  const {
    parentId,
    locale: recordLocale,
  } = localeRecord;

  const { code } = useGetResourceSelector(resource, ['data', parentId]);

  return useCallback((file) => {
    if (!code) return { code: 'no-code', message: 'LR has no associated code' };
    if (!REGEX_LR_CODE.test(code)) {
      return {
        code: 'invalid-code',
        message: `LR has invalid code pattern expected: "${REGEX_LR_CODE.source}"`,
      };
    }

    const fileName = file?.name;
    if (!fileName) return { code: 'no-name', message: 'Empty file name not allowed' };

    const fileNameLocale = parseResourceLocale(fileName);
    const isLocalized = !!fileNameLocale;
    const name = fileName.split('.').shift();
    if (!validateLOThumbnail(name)) {
      const message = isLocalized
        ? `for localised LR thumbnail, file name should be "p[LR code]_[language code].jpg" (e.g. p${code}_${recordLocale}.jpg)`
        : `for non-localised LR thumbnail, file name should be "p[LRcode].jpg" (e.g. p${code}.jpg)`;
      return { code: 'invalid-name', message: `Incorrect file name "${name}" ${message}` };
    }

    if (!new RegExp(`^p${code}`).test(fileName)) {
      return {
        code: 'invalid-code',
        message: META_VALIDATION_TEMPLATE`code ${isLocalized}${name}${code}`,
      };
    }
    if (isLocalized && recordLocale !== fileNameLocale) {
      return {
        code: 'invalid-locale',
        message: META_VALIDATION_TEMPLATE`locale ${isLocalized}${name}${recordLocale}`,
      };
    }

    return null;
  }, [code, recordLocale]);
};

const INITIAL_STATE = {
  modal: {
    remove: false,
    republish: false,
  },
};

const ACTION_TYPES = {
  TOGGLE_MODAL: 'TOGGLE_MODAL',
};
const toggleModalAC = (...payload) => ({ type: ACTION_TYPES.TOGGLE_MODAL, payload });
const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.TOGGLE_MODAL: {
      const [key, value] = payload;
      if (!key) {
        return set(
          { ...state },
          'modal',
          Object.keys(state.modal).reduce((acc, key) => {
            if (state.modal[key]) acc[key] = false;
            return acc;
          }, {}),
        );
      }

      const isOpen = state.modal[key];
      if (typeof isOpen === 'boolean') {
        return set({ ...state }, `modal.${key}`, value || !isOpen);
      }

      throw new TypeError(`
      Invalid key selector "${key}" for modal state control,
      allowed: ${Object.keys(state.modal)}
      `);
    }
    default:
      throw new Error(`Action type ${type} not allowed`);
  }
};

const ThumbInput = (props) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const uploadImportCb = useRef(null);
  const { params: { resource } } = usePathMatch();
  const [state, dispatch] = useReducer(reducer, { ...INITIAL_STATE });
  const dataProvider = useDataProvider();
  const toggleModal = (key, value) => {
    dispatch(toggleModalAC(key, value));
  };

  const [deleteOne] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();

  const { record: { parentId, id: localeId = '', localizedThumbnailExists } } = props;
  const [id, locale] = localeId.split('_');
  const { code, commonThumbnailExists } = useGetResourceSelector(resource, ['data', id]);

  const onRemove = toggleModal.bind(null, 'remove', true);
  const onRemoveCancel = useCallback(() => {
    toggleModal('remove', false);
  }, []);
  const onImportCancel = useCallback(() => {
    toggleModal('republish', false);
  }, []);

  const localesForRepublcation = useSelector(getFilteredLocales(resource, {
    parentId,
    localizedThumbnailExists: false,
    state: LR_LOCALE_PUBLISHED_STATE,
  }));

  const onDrop = useCallback((uploadCb) => {
    if (localesForRepublcation.length) {
      toggleModal('republish', true);
      uploadImportCb.current = uploadCb;
    }
    else {
      uploadCb();
    }
  }, [localesForRepublcation]);

  const validator = useCreateValidator({ ...props, resource });
  const options = {
    loading: isRemoving,
    get onRemove() {
      return commonThumbnailExists && localizedThumbnailExists && onRemove;
    },
    onDrop,
  };

  const onConfirmUpload = useCallback(() => {
    toggleModal('republish', false);
    uploadImportCb.current();
  }, []);
  const onConfirmRemove = useCallback(event => {
    event.preventDefault();
    toggleModal('remove', false);
    setIsRemoving(true);
    deleteOne({ resource: LR, payload: { id, locale, target: 'thumbnail' } },
      {
        onSuccess: () => {
          dataProvider.getOne(LR_LOCALES, { id: localeId }, { action: GET_ONE })
            .then(() => {
              notify(`Deleted: localized thumbnail "${code}_${locale}"`);
              refresh();
            }).finally(() => {
              setIsRemoving(false);
            });
        },
        onError: (error) => {
          console.warn(error); // eslint-disable-line
          notify(
            `Error: localized thumbnail "${code}_${locale}" delete action failed`,
            'error',
          );
          setIsRemoving(false);
        },
      },
    );
  }, []);

  const { modal: modalState } = state;
  return (
    <>
      <ImportInput
        {...props}
        addLabel={false}
        importType={fileImportType.LO_THUMBNAIL}
        isRequired={false}
        seleniumId="lo-locale-thumbnail"
        source="thumbnailUrl"
        validator={validator}
        options={options}
      >
        <ImageField width="100%" />
      </ImportInput>
      <ConfirmationModal
        open={modalState.remove}
        onCancel={onRemoveCancel}
        onSubmit={onConfirmRemove}
        label="Delete localized thumbnail"
        message="You are about to replace a localised thumbnail with a non-localised thumbnail. This action cannot be undone. Continue?"
      />
      <ConfirmationModal
        open={modalState.republish}
        onCancel={onImportCancel}
        onSubmit={onConfirmUpload}
        label={REPUBLISH_TITLE}
        message={null}
      >
        <Box component={DialogContent} marginTop={2} marginBottom={2} display="block !important">
          {localesForRepublcation.map(({ locale }) => (
            <Box
              data-seleniumid={`locale-for-republish-${locale}`}
              key={`locale-for-republish-${locale}`}
              margin={1}
              component={Chip}
              label={locale}
              variant="outlined"
            />
          ))}
        </Box>
      </ConfirmationModal>
    </>
  );
};

ThumbInput.propTypes = {
  record: PropTypes.shape({
    availableLocales: PropTypes.arrayOf(PropTypes.string),
    code: PropTypes.string,
    id: PropTypes.string,
    localizedThumbnailExists: PropTypes.bool,
    parentId: PropTypes.string,
  }),
};

ThumbInput.defaultProps = {
  record: {},
};

export default ThumbInput;
