import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectLR, selectLocalesByLR } from 'ra-redux/selectors';
import Actions from './Actions';
import { isWithoutVideos, isWithoutSubtitles, isWithoutPDF } from './utils';

const ActionsPanel = (props) => {
  const { editHref, learningResourceId, learningResourceLocale, directlyUploadedVideo, directlyUploadedPdf } = props;
  const localeId = `${learningResourceId}_${learningResourceLocale}`;

  const record = useSelector(selectLR(learningResourceId));
  const localesRecords = useSelector(selectLocalesByLR(learningResourceId));
  const localeRecord = localesRecords.find(el => el.id === localeId) || {};

  const withoutVideos = isWithoutVideos({ directlyUploadedVideo, ...localeRecord });
  const withoutSubtitles = isWithoutSubtitles({ directlyUploadedVideo, ...localeRecord });
  const withoutPdf = isWithoutPDF({ directlyUploadedPdf, ...localeRecord });

  const noLocales = record?.availableLocales?.length === 0;
  const isLoading = !record || !localeRecord;

  return (
    <Actions
      record={record}
      localeRecord={localeRecord}
      localesRecords={localesRecords}
      isLoading={isLoading}
      editHref={editHref}
      hiddenPublishButton={noLocales || withoutVideos || withoutSubtitles || withoutPdf}
      hiddenArchiveButton={noLocales || withoutVideos || withoutSubtitles || withoutPdf}
    />
  );
};

ActionsPanel.propTypes = {
  directlyUploadedPdf: PropTypes.bool,
  directlyUploadedVideo: PropTypes.bool,
  editHref: PropTypes.string.isRequired,
  learningResourceId: PropTypes.string.isRequired,
  learningResourceLocale: PropTypes.string.isRequired,
};

export default memo(ActionsPanel);
