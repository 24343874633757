import React from 'react';
import PropTypes from 'prop-types';

import {
  ReferenceInput,
  SimpleForm,
} from 'react-admin';
import { getEntityModifyRedirectHref } from 'utils';
import {
  Create,
  Edit,
  TextInput,
  ToolBar,
  AutocompleteInput,
} from '_ui';

const sanitizeProps = ({ isCreate, ...props }) => props;

const ProgramsEditForm = props => {
  const { record: { id }, isCreate } = props;
  const excludeList = id ? [id] : [];

  return (
    <SimpleForm
      {...sanitizeProps(props)}
      redirect={getEntityModifyRedirectHref}
      toolbar={<ToolBar />}
    >
      <TextInput disabled={!isCreate} source="code" seleniumId="program-modify-code" />
      <ReferenceInput
        allowEmpty
        label="Parent Program"
        reference="programs"
        source="parentId"
        exclude={excludeList}
        sort={{ field: 'code', order: 'ASC' }}
      >
        <AutocompleteInput
          minSearchableLength={1}
          emptyText="None"
          source="parentId"
          defaultLabel="Parent Program"
          optionText="code"
          seleniumId="program-modify"
        />
      </ReferenceInput>
    </SimpleForm>
  );
};

ProgramsEditForm.propTypes = {
  isCreate: PropTypes.bool,
  record: PropTypes.object,
};

export const ProgramsCreate = props => (
  <Create {...props}>
    <ProgramsEditForm isCreate={true} />
  </Create>
);

export const ProgramsEdit = props => (
  <Edit undoable={false} {...props}>
    <ProgramsEditForm />
  </Edit>
);
