/* eslint-disable no-case-declarations */

import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import { generatePath } from 'react-router';

import { PUBLICATION_PREVIEW, LR_PREVIEW, LR_PREVIEW_VIDEO, LR_VIDEO_CONTENT_LIST } from 'constants/resources';
import { ROUTE_PATTERNS } from 'constants/routes';
import { parseLocaleId } from '../../utils/locales';
import difference from '../../utils/objDiff';
import { BASE_SEARCH_SOURCE } from '../../constants';
import {
  processExportsHTTPResponse,
  processGetManyReferenceResponse,
} from './processHTTPResponse';

const LOCALES_KEY = 'locales';
const LOCALES_SUFFIX = `-${LOCALES_KEY}`;
export const IGNORE_ABORTING_KEY = 'ignoreAborting';

const isLocaleResource = resource => resource.endsWith(LOCALES_SUFFIX);
const getLocaleResource = resource => resource.split(LOCALES_SUFFIX)[0];

export const getLocaleFromID = id => parseLocaleId(id).locale;
export const getLocaleParentFromID = id => id.split(`_${getLocaleFromID(id)}`)[0];
const getLocaleSingleEntityURL = (apiUrl, resource, id) => {
  const parentResource = getLocaleResource(resource);
  const locale = getLocaleFromID(id);
  const parentId = getLocaleParentFromID(id);

  return `${apiUrl}/${parentResource}/${parentId}/locale/${locale}`;
};

const convertDataToPaginationParams = (params) => {
  const { page, perPage = 10 } = params.pagination;
  const { field, order, ...sortParams } = params.sort || {};

  if (field === 'id') { // fixme workaround to make api calls works, it doesn't support sorting by id
    return {
      offset: (page - 1) * perPage,
      size: perPage,
    };
  }

  return {
    sortField: field,
    sortDir: order,
    offset: (page - 1) * perPage,
    size: perPage,
    ...sortParams,
  };
};

const parseAdditionalOptions = params => {
  const options = {};
  const { [IGNORE_ABORTING_KEY]: ignoreParam } = params || {};
  if (ignoreParam) options[IGNORE_ABORTING_KEY] = ignoreParam;
  return options;
};

// request GET_MANY
const processDefaultGetMany = ({ apiUrl, params, resource }) => ({
  handledURL: `${apiUrl}/${resource}/getByIds`,
  handledOptions: { method: 'POST', body: JSON.stringify(params.ids) },
});

const processLocalesGetMany = ({ apiUrl, params: { ids = [] }, resource }) => ({
  handledURL: `${apiUrl}/${getLocaleResource(resource)}/${LOCALES_KEY}/getByQuery`,
  handledOptions: {
    method: 'POST',
    body: JSON.stringify({
      locales: ids.map(id => ({
        id: getLocaleParentFromID(id),
        locale: getLocaleFromID(id),
      })),
    }),
  },
});

// request GET_MANY_REFERENCE
const processDefaultGetManyReference = ({ apiUrl, params, resource }) => {
  const query = {
    ...fetchUtils.flattenObject(params.filter),
    [params.target]: params.id,
    ...convertDataToPaginationParams(params),
  };

  return {
    handledURL: `${apiUrl}/${resource}/search`,
    handledOptions: {
      method: 'POST',
      body: JSON.stringify(query),
    },
  };
};

const processLocalesGetManyReference = ({ apiUrl, params, resource }) => {
  const { id, filter: { availableLocales = [], ...filter } } = params;

  return {
    handledURL: `${apiUrl}/${getLocaleResource(resource)}/${LOCALES_KEY}/getByQuery`,
    handledOptions: {
      method: 'POST',
      body: JSON.stringify({
        ...filter,
        locales: availableLocales.map(locale => ({ id, locale })),
      }),
    },
  };
};

// request CREATE
const processDefaultCreate = ({ apiUrl, params: { data }, resource }) => ({
  handledURL: `${apiUrl}/${resource}`,
  handledOptions: {
    method: 'POST',
    body: JSON.stringify(data),
  },
});

const processLocaleCreate = ({ apiUrl, params: { data }, resource }) => {
  const { parentId, locale } = data;
  const parentResource = getLocaleResource(resource);
  return {
    handledURL: `${apiUrl}/${parentResource}/${data.parentId}/locale/${data.locale}`,
    handledOptions: {
      method: 'POST',
      body: JSON.stringify({
        ...data,
        id: `${parentId}_${locale}`,
      }),
    },
  };
};

const setLocaleID = localeMeta => ({
  ...localeMeta,
  id: `${localeMeta.parentId}_${localeMeta.locale}`,
});

const sanitizeUpdatedFields = ({ availableLocales, canBeLocalizedIn, ...rest }) => rest;

const processLocalesHTTPResponse = (type, source, params) => {
  switch (type) {
    case GET_ONE:
    case CREATE:
      return setLocaleID(source);
    case UPDATE:
      return setLocaleID({ ...params.data, ...source });
    case GET_LIST:
    case GET_MANY:
    case GET_MANY_REFERENCE: {
      const items = source.items || source;
      return {
        ...source,
        items: items && items.map(setLocaleID),
      };
    }
    default:
      return source;
  }
};

const transformFilters = (filters = {}) => {
  const { [BASE_SEARCH_SOURCE]: searchQ, ...filter } = filters;
  return {
    ...filter,
    ...(searchQ ? { q: searchQ } : {}),
  };
};

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * GET_LIST     => GET http://my.api.url/posts?_sort=title&_order=ASC&_offset=0&_size=25
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PATCH http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = '';
    let options = {};
    const isLocaleProcess = isLocaleResource(resource);
    const processProps = { apiUrl, params, resource };

    switch (type) {
      case GET_LIST: {
        switch (resource) {
          case 'import-creators':
            url = `${apiUrl}/imports/creators`;
            break;
          case 'publication-creators':
            url = `${apiUrl}/publications/publishers`;
            break;
          default:
            const body = {
              ...fetchUtils.flattenObject(transformFilters(params.filter)),
              ...convertDataToPaginationParams(params),
            };

            url = `${apiUrl}/${resource}/search`;
            options = {
              method: 'POST',
              body: JSON.stringify(body),
              ...parseAdditionalOptions(params),
            };
            break;
        }
        break;
      }
      case GET_ONE: {
        switch (resource) {
          case PUBLICATION_PREVIEW:
            url = `${apiUrl}/publications/${params.id}/preview`;
            break;
          case LR_PREVIEW:
            url = `${apiUrl}/learning-objects/${params.id}/locale/${params.locale}/preview`;
            break;
          case LR_PREVIEW_VIDEO:
            url = `${apiUrl}/learning-objects/${params.id}/video-preview`;
            break;
          case LR_VIDEO_CONTENT_LIST:
            url = `${apiUrl}/learning-objects/${params.id}/content-list`;
            break;
          default: {
            url = isLocaleProcess ? getLocaleSingleEntityURL(apiUrl, resource, params.id) : `${apiUrl}/${resource}/${params.id}`;
          }
        }
        break;
      }
      case GET_MANY_REFERENCE: {
        switch (resource) {
          case 'publication-logs':
            url = `${apiUrl}/publications/${params.id}/details`;
            options = { method: 'GET' };
            break;
          case 'import-logs':
            url = `${apiUrl}/imports/${params.id}/details`;
            options = { method: 'GET' };
            break;
          default: {
            const { handledURL, handledOptions } = isLocaleProcess ? processLocalesGetManyReference(processProps) : processDefaultGetManyReference(processProps);
            url = handledURL;
            options = handledOptions || {};
          }
        }
        break;
      }
      case UPDATE: {
        switch (resource) {
          case 'exports':
            url = `${apiUrl}/${resource}/${params.type}/generate`;
            options = { method: 'POST' };
            break;
          case 'learning-objects-locales':
            if (params.action) {
              url = `${getLocaleSingleEntityURL(apiUrl, resource, params.id)}/${params.action}`;
              options = { method: 'POST', body: params.comment };
            }
            else {
              const prepared = sanitizeUpdatedFields(difference(params.data, params.previousData, ['_version']));
              url = getLocaleSingleEntityURL(apiUrl, resource, params.id);
              options = { method: 'PATCH', body: JSON.stringify(prepared) };
            }
            break;
          default: {
            options.method = 'PATCH';
            url = isLocaleProcess ? getLocaleSingleEntityURL(apiUrl, resource, params.id) : `${apiUrl}/${resource}/${params.id}`;
            if (typeof params.data === 'string') {
              options.headers = new Headers({ 'Content-type': 'text/plain' });
              options.body = params.data;
            }
            else {
              const prepared = sanitizeUpdatedFields(difference(params.data, params.previousData, ['_version']));
              options.body = JSON.stringify(prepared);
            }
          }
        }
        break;
      }
      case CREATE: {
        const { handledURL, handledOptions } = isLocaleProcess ? processLocaleCreate(processProps) : processDefaultCreate(processProps);
        url = handledURL;
        options = handledOptions;
        break;
      }
      case GET_MANY: {
        switch (resource) {
          case 'publication-logs':
            url = `${apiUrl}/publications/details/getByIds`;
            options = {
              method: 'POST', body: JSON.stringify(params.ids) };
            break;
          case 'import-logs':
            url = `${apiUrl}/imports/details/getByIds`;
            options = {
              method: 'POST', body: JSON.stringify(params.ids) };
            break;
          case 'import-creators':
          case 'publication-creators':
            url = `${apiUrl}/users/getByIds`;
            options = {
              method: 'POST', body: JSON.stringify(params.ids) };
            break;
          default:
            const {
              handledURL,
              handledOptions,
            } = isLocaleProcess
              ? processLocalesGetMany(processProps)
              : processDefaultGetMany(processProps);
            url = handledURL;
            options = handledOptions || {};
            break;
        }
        break;
      }
      case DELETE: {
        url = `${apiUrl}${generatePath(ROUTE_PATTERNS.deleteLocale, { resource, ...params })}`;
        options = { method: 'DELETE' };
        break;
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }

    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { json } = response;
    let source = json;

    const isLocaleProcess = isLocaleResource(resource);
    if (isLocaleProcess) {
      source = processLocalesHTTPResponse(type, source, params);
    }

    if ([PUBLICATION_PREVIEW, LR_PREVIEW, LR_PREVIEW_VIDEO].includes(resource)) {
      const { body } = response;
      const { id } = params;
      source = { id, bucket: body };
    }

    if (type === GET_MANY_REFERENCE) {
      source = processGetManyReferenceResponse(resource, source, params);
    }

    if (resource === 'exports') {
      source = processExportsHTTPResponse(type, source, params);
    }

    if (resource === LR_VIDEO_CONTENT_LIST) {
      const { id } = params;
      source = { id, entities: source.entities };
    }

    const items = source?.items || source;

    switch (type) {
      case GET_MANY:
      case GET_LIST:
      case GET_MANY_REFERENCE:
        return {
          data: items,
          total: source.totalAmount || items.length,
        };
      case CREATE:
        return { data: { ...params.data, id: source.id } };
      case UPDATE:
        return { data: { ...source, ...params.data, id: source.id } };
      default:
        return { data: source };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    if ([UPDATE_MANY, DELETE_MANY].includes(type)) {
      throw new Error(`${type} is not supported currently`);
    }

    const { url, options } = convertDataRequestToHTTP(
      type,
      resource,
      params,
    );

    return httpClient(url, options).then(response =>
      convertHTTPResponse(response, type, resource, params),
    );
  };
};
