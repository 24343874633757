import React, { useState } from 'react';
import {
  useMutation,
  TopToolbar,
  TextField,
  FunctionField,
  useNotify,
} from 'react-admin';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Button,
  Collapse,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { TAXONOMY_EXPORT_TYPES } from 'constants/exports';
import { EXPORTS } from 'constants/resources';
import { withCustomWidthTableStyles } from 'utils/styleUtils';
import { getCapLabel } from 'utils/strings';
import {
  getTaxonomyDownloadEvent,
  TAXONOMY_DOWNLOAD_MESSAGE,
} from 'utils/exports';

import {
  AutocompleteArrayInput,
  Datagrid,
  DateField,
  Filter,
  RealtimeList,
  TextInput,
} from '_ui';

import { BASE_SEARCH_SOURCE } from 'constants';
import { STATUS_OPTIONS, IN_PROGRESS_STATUS, SUCCESSFUL_STATUS } from './constants';

const ExportFilter = (props) => (
  <Filter {...props} seleniumId="exports-filter">
    <TextInput
      alwaysOn
      allowEmpty
      label="Search"
      source={BASE_SEARCH_SOURCE}
      seleniumId="exports-filter-search"
      isFilter
    />
    <AutocompleteArrayInput
      alwaysOn
      allowDuplicates={false}
      label="Status"
      source="status"
      seleniumId="exports-filter-statuses"
      optionText="name"
      choices={STATUS_OPTIONS}
    />
  </Filter>
);

const actionsStyle = makeStyles((theme) => ({
  root: {
    justifyContent: 'flex-start',
    padding: '8px 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      '& .MuiButtonBase-root:nth-child(2)': {
        marginTop: '1rem',
      },
    },
  },
}));

const Actions = ({
  id,
  type,
  title,
  status,
}) => {
  const [generate, { loading }] = useMutation({
    type: 'update',
    resource: EXPORTS,
    payload: { id, type, title },
    options: {
      onError: {
        notification: { body: 'Error: export isn\'t generated', level: 'error' },
      },
    },
  });

  const canGenerate = !loading && status !== IN_PROGRESS_STATUS.id;
  const canDownload = status === SUCCESSFUL_STATUS.id;
  const classes = actionsStyle();
  return (
    <TopToolbar className={classes.root}>
      <Button
        color="primary"
        disabled={!canDownload}
        href={`/api/v1/exports/${type}`}
        data-seleniumid={`export-${id}-download`}
      >
        Download
      </Button>
      <Button
        color="primary"
        disabled={!canGenerate}
        onClick={generate}
        data-seleniumid={`export-${id}-generate`}
      >
        Generate
      </Button>
    </TopToolbar>
  );
};

Actions.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.oneOf([...STATUS_OPTIONS, null]).isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  icon: ({ isOpen }) => ({
    transition: theme.transitions.create('transform'),
    transform: `rotate(${isOpen ? '0' : '-90deg'})`,
  }),
}));

const ErrorWithExpand = ({ statusLabel, error }) => {
  const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles({ isOpen: expanded });

  return (
    <>
      {statusLabel}
      <IconButton
        onClick={() => setExpanded(!expanded)}
        aria-expanded={expanded}
        data-seleniumid="expand-error"
        aria-label="show more"
      >
        <ExpandMoreIcon classes={{ root: classes.icon }} />
      </IconButton>
      <Collapse in={expanded} unmountOnExit>{error}</Collapse>
    </>
  );
};

ErrorWithExpand.propTypes = {
  error: PropTypes.string.isRequired,
  statusLabel: PropTypes.string.isRequired,
};

const progressStatus = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2),
  },
}));

const LabeledLinearProgress = ({ statusLabel, progress = 0 }) => {
  const classes = progressStatus();
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      data-seleniumid="export-progress"
    >
      {statusLabel}
      <LinearProgress
        classes={classes}
        variant={`${!progress ? 'in' : ''}determinate`}
        value={progress}
        data-seleniumid="export-progress-bar"
      />
      <span data-seleniumid="export-progress-value">{`${progress}%`}</span>
    </Box>
  );
};

LabeledLinearProgress.propTypes = {
  progress: PropTypes.number,
  statusLabel: PropTypes.string.isRequired,
};

const DownloadDialog = ({ searchParams }) => {
  const [downloadDialogOpen, toggleDownloadDialog] = useState(!!searchParams?.autoDownload);

  if (searchParams?.autoDownload) {
    const [resource] = getCapLabel(searchParams.autoDownload).split('_');

    return (
      <Dialog
        open={downloadDialogOpen}
        onClose={toggleDownloadDialog}
        aria-labelledby="taxonomy-download-dialog-title"
      >
        <DialogTitle
          data-seleniumid="taxonomy-download-dialog-title"
          id="taxonomy-download-dialog-title"
        >
          <Box display="flex" alignItems="center">
            <Box marginRight={1} component={ErrorOutlineIcon} />{resource} Taxonomy download
          </Box>
        </DialogTitle>
        <Divider />
        <Box component={DialogContent} marginY={2}>
          <Typography>
            This tab will be automatically closed upon taxonomy download completion. Please do not close it as the generation is still in progress.
          </Typography>
        </Box>
      </Dialog>
    );
  }

  return null;
};

DownloadDialog.propTypes = {
  searchParams: PropTypes.shape({
    autoDownload: PropTypes.oneOf(Object.values(TAXONOMY_EXPORT_TYPES)),
    filter: PropTypes.string,
  }),
};

DownloadDialog.defaultProps = {
  searchParams: null,
};

const columnWidth = {
  title: 35,
  status: 25,
  finishDate: 20,
  Actions: 20,
};

const listStyles = makeStyles(withCustomWidthTableStyles(columnWidth));

const ExportsList = (props) => {
  const { hasEdit } = props;
  const notify = useNotify();

  let searchParams;
  if (props?.location?.search) searchParams = parse(props?.location?.search);

  const classes = listStyles();

  return (
    <>
      <RealtimeList
        {...props}
        bulkActionButtons={false}
        extraHandler={(data) => {
          const processingId = searchParams?.autoDownload;
          if (!processingId || !data.length) return;

          const entity = data.find(el => el.id === processingId) || {};
          if (entity.status !== 'SUCCESSFUL') return;

          const [resource] = processingId.toLowerCase().split('_');
          const label = getCapLabel(resource);
          notify(`${label} ${TAXONOMY_DOWNLOAD_MESSAGE}`);

          const downloadUrl = document.querySelector(`[data-seleniumid="export-${processingId}-download"]`).href;
          window.dispatchEvent(getTaxonomyDownloadEvent(downloadUrl));
          if (window.opener != null || window.history.length === 1) window.close();
        }}
        filters={<ExportFilter />}
        seleniumId="exports"
      >
        <Datagrid classes={classes}>
          <TextField source="title" label="Type" data-seleniumid="export-title" />
          <FunctionField
            label="Status" source="status"
            data-seleniumid="export-status"
            render={({ status: recordStatus, progress, error }) => {
              const [status] = STATUS_OPTIONS.filter(status => status.id === recordStatus);
              const statusLabel = status ? status.name : '';

              if (error) return <ErrorWithExpand statusLabel={statusLabel} error={error} />;

              const inProgress = !error && status?.id === IN_PROGRESS_STATUS.id;
              if (inProgress) return <LabeledLinearProgress progress={progress} statusLabel={statusLabel} />;

              return statusLabel;
            }}
          />
          <FunctionField
            label="Finish Date" source="finishDate"
            data-seleniumid="export-finish-date"
            render={(record) =>
              (record.finishDate
                ? <DateField record={record} source="finishDate" label="Finish Date" />
                : null)}
          />
          {
            hasEdit && (
              <FunctionField
                label="Actions"
                render={Actions}
              />
            )
          }
        </Datagrid>
      </RealtimeList>
      <DownloadDialog searchParams={searchParams} />
    </>
  );
};

ExportsList.propTypes = {
  hasEdit: PropTypes.bool.isRequired,
};

export default ExportsList;
