import React from 'react';
import PropTypes from 'prop-types';
import { getResourceFromPathname } from 'utils/router';
import { capString } from 'utils/strings';
import { Text, Segment, Divider } from './Breadcrumb';

export const seleniumId = 'resource-create-breadcrumb';

const DEFAULT_LABEL = 'Create new';
const createResource = ['authors', 'themes', 'tags', 'disciplines', 'skillsets', 'programs'].reduce((acc, resource) => {
  acc[resource] = `${DEFAULT_LABEL} ${resource === 'skillsets' ? 'Skill set' : capString(resource.slice(0, -1))}`;
  acc[`${resource}-locales`] = `${acc[resource]} locale`;
  return acc;
}, {});

export const getLabel = location => {
  const { pathname = '' } = location || {};
  if (!pathname) return DEFAULT_LABEL;

  return createResource[getResourceFromPathname(pathname)] || DEFAULT_LABEL;
};

const Create = ({ label }) => (
  <Segment>
    <Divider />
    <Text seleniumId={seleniumId}>{label}</Text>
  </Segment>
);

Create.propTypes = {
  label: PropTypes.string,
};

Create.defaultProps = {
  label: DEFAULT_LABEL,
};

export default Create;
