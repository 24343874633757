import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { startOfDay, endOfDay, format } from 'date-fns';

import { IS_TEST_ENV } from 'constants';
import { DATE_FORMAT } from 'constants/dateTime';

export const getCurrentTimezone = () => (
  Intl.DateTimeFormat('default', IS_TEST_ENV ? { timeZone: 'UTC' } : {}).resolvedOptions().timeZone
);

export const parseValue = (value, name) => {
  if (value) {
    const date = (value instanceof Date) ? value : new Date(value);
    const utcDateString = zonedTimeToUtc(
      /.lte$/.test(name)
        ? endOfDay(date)
        : startOfDay(date),
      getCurrentTimezone(),
    ).toISOString();

    return utcDateString;
  }

  return value;
};

export const formatValue = value => {
  if (value) {
    const date = value instanceof Date ? value : new Date(value);
    return format(date, DATE_FORMAT);
  }

  return value;
};
