/* eslint-disable react/prop-types */
import React from 'react';
import { Show as RaShow } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    '& > div': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const Show = ({ resource, ...props }) => {
  const classes = useStyles();

  return <RaShow classes={classes} resource={resource} {...props} />;
};

export default Show;
