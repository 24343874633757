import React from 'react';
import { PUBLICATION_STATUSES } from '_domains/Jobs/Publications/constants';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,

  makeStyles,
  Chip,
} from '@material-ui/core';
import { customIconColors } from '_ui/Layout/Menu/subMenusConfig';

const useStyles = makeStyles((theme) => ({
  locale: {
    height: 25,
    margin: theme.spacing(0.5),
    cursor: 'pointer',
  },
}));

const getRenderConfig = locales => {
  const success = filter(locales, ['status', PUBLICATION_STATUSES.SUCCESSFUL]);
  const failed = filter(locales, ['status', PUBLICATION_STATUSES.FAILED]);
  const progress = filter(locales, ['status', PUBLICATION_STATUSES.IN_PROGRESS]);

  return {
    success: { key: 'success', entities: success, color: customIconColors.success },
    failed: { key: 'failed', entities: failed, color: customIconColors.failed },
    progress: { key: 'progress', entities: progress, color: customIconColors.progress },
  };
};

const ProgressRow = ({ seleniumId, code, locales }) => {
  const classes = useStyles();
  const { success, failed, progress } = getRenderConfig(locales);
  return (
    <TableRow
      key={code}
      data-seleniumId={`${seleniumId}-row-${code}`}
    >
      <TableCell
        data-seleniumId={`${seleniumId}-cell-${code}`}
        style={{ verticalAlign: 'top', paddingTop: '14px', wordBreak: 'break-all', minWidth: '6rem' }}
      >
        {code}
      </TableCell>
      {[progress, failed, success].map(({ key, entities, color }) => (
        <TableCell
          key={key}
          style={{ verticalAlign: 'top', padding: '8px' }}
          data-seleniumId={`${seleniumId}-cell-${key}-${code}`}
        >
          {entities.map(({ id, locale }) => (
            <Chip
              className={classes.locale}
              key={id}
              variant="outlined"
              label={`${locale}`}
              style={{ borderColor: color }}
            />
          ))}
        </TableCell>
      ))}
    </TableRow>
  );
};

ProgressRow.propTypes = {
  code: PropTypes.bool,
  locales: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    id: PropTypes.string,
    locale: PropTypes.string,
    status: PropTypes.oneOf(['IN_PROGRESS', 'FAILED', 'SUCCESSFUL']),
  })),
  seleniumId: PropTypes.string,
};

export default ProgressRow;
