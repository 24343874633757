import { useLayoutEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { get } from 'lodash';
import { getLocation, LOCATION_CHANGE_MENU } from 'ra-redux/router';
import { ROUTE_PATTERNS } from 'constants/routes';

export const SCROLL_LOCALE_PARAM = 'viewLocale';

const getViewMatchParams = path => (matchPath(path, { path: ROUTE_PATTERNS.show }) || matchPath(path, { path: ROUTE_PATTERNS.show.replace(/:locale.+/, '') })) || { params: {} };
const getListMatchParams = path => (matchPath(path, { path: ROUTE_PATTERNS.list })) || { params: {} };
const isOpenFromViewToList = (prevPath, newPath) => {
  const { params: prevParams } = getViewMatchParams(prevPath);
  const { params: newParams } = getListMatchParams(newPath);

  return prevParams?.resource === newParams?.resource;
};

const getEntityIds = (state, newPathname) => {
  const { prevPathname, [SCROLL_LOCALE_PARAM]: viewLocale } = state || {};

  if (!prevPathname || !newPathname) return [];
  if (!isOpenFromViewToList(prevPathname, newPathname)) return [];

  const { params } = getViewMatchParams(prevPathname);
  const { id, locale } = params;
  return id ? [`${id}_${viewLocale || locale}`, id] : [];
};

const getElement = ids => {
  const [element] = ids.map(id => document.querySelector(`[data-seleniumid='table-row-${id}']`)).filter(el => !!el);
  return element;
};

export const useScrollIntoView = ({ isLoading, enableScrollIntoView }) => {
  const [scrolled, setScrolled] = useState(false);
  const location = useSelector(getLocation);

  const state = get(location, 'state', null);
  const pathname = get(location, 'pathname', null);
  const isToggleFromMenu = get(location, `state.${LOCATION_CHANGE_MENU}`, false);

  const entityIds = useMemo(() => (!enableScrollIntoView || isToggleFromMenu ? [] : getEntityIds(state, pathname)),
    [state, pathname, isToggleFromMenu],
  );
  const element = entityIds.length ? getElement(entityIds) : null;
  useLayoutEffect(() => {
    if (isLoading || !element || scrolled) return;
    element.style.backgroundColor = '#f5f5f5';
    element.onmouseleave = () => {
      element.style.backgroundColor = '';
    };
    element.scrollIntoView({ block: 'center' });
    setScrolled(true);
  }, [isLoading, element, scrolled]);
};
