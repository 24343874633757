import { forIn, isEqual, isArray, cloneDeep } from 'lodash';

export const determineValuesToUpdate = (initial = {}, changed = {}) => {
  const valuesToSubmit = cloneDeep(changed);

  forIn(valuesToSubmit, (val, key) => {
    if (
      isEqual(
        isArray(initial[key]) ? [...initial[key]].sort() : initial[key],
        isArray(changed[key]) ? [...changed[key]].sort() : changed[key],
      )
    ) {
      delete valuesToSubmit[key];
    }
  });

  return valuesToSubmit;
};

/**
 * @param {Object} changed modified object
 * @param {Object} current original object
 * @param {Array} passed list of passed fields
 * @returns {Object} object with updated fields
 */
export default (changed, current, passed = []) => {
  const diffs = determineValuesToUpdate(current, changed);

  if (current) {
    passed.forEach((f) => {
      if (current[f]) {
        diffs[f] = current[f];
      }
    });
  }
  return diffs;
};
