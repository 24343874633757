import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSidebarVisibility } from 'react-admin';
import clsx from 'clsx';
import ls from 'api/ls';

import {
  makeStyles,
  useMediaQuery,
  Box,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
} from '@material-ui/icons';

import Menu from './Menu';

export const DRAWER_WIDTH = 280;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
}));

export const SidebarToggle = () => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const dispatch = useDispatch();
  const toggleSidebar = () => {
    dispatch(setSidebarVisibility(!open));
    ls.set('sidebarOpen', !open);
  };

  return (
    <IconButton color="inherit" onClick={toggleSidebar} data-seleniumid="sidebar-button">
      {
        open
          ? <MenuOpenIcon />
          : <MenuIcon />
      }
    </IconButton>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const dispatch = useDispatch();
  const toggleSidebar = (isOpen = !open) => {
    dispatch(setSidebarVisibility(isOpen));
  };

  const classes = useStyles();

  // keep sidebar open on desktop
  useEffect(
    () => {
      const lsSidebarOpen = ls.get('sidebarOpen');
      toggleSidebar(lsSidebarOpen === null && isDesktop ? true : lsSidebarOpen === 'true');
    },
    [isDesktop], // eslint-disable-line
  );

  return (
    <MuiDrawer
      variant={isDesktop ? 'permanent' : 'temporary'}
      open={open}
      onClose={() => toggleSidebar()}
      className={
        isDesktop
          ? (
            clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })
          )
          : classes.drawer
      }
      classes={{
        paper: isDesktop
          ? (
            clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })
          )
          : classes.drawer,
      }}
    >
      {
        isDesktop
          ? <Toolbar />
          : (
            <>
              <Box component={Toolbar} disableGutters paddingX={1.5}>
                <SidebarToggle />
              </Box>
              <Divider light />
            </>
          )
      }
      <Menu />
    </MuiDrawer>
  );
};

export default Sidebar;
