export const TOGGLE_MENU_ITEM = 'TOGGLE_MENU_ITEM';

export default (state = null, { type, payload: name }) => {
  switch (type) {
    case TOGGLE_MENU_ITEM:
      return {
        ...(state || {}),
        [name]: !(state || {})[name],
      };
    default:
      return state;
  }
};

export const toggleMenuItem = (payload) => ({ type: TOGGLE_MENU_ITEM, payload });
export const getIsExpandedItem = (name) => state => (state?.menu || {})[name];
