import React from 'react';

import {
  Archive as ArchiveIcon,
  Publish as PublishIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import { PUBLICATION_STATUSES } from '_domains/Jobs/Publications/constants';
import { LO_SORT_PARAMS, IMPORT_SORT_PARAMS } from 'constants/sort';
import { DEFAULT_PAGINATION } from 'constants/pagination';
import {
  LR_LOCALE_CURRENTLY_EDITING_STATE,
  LR_LOCALE_PUBLISHED_STATE,
  LR_LOCALE_ARCHIVED_STATE,
  LR_LOCALE_BEING_PUBLISHED_STATE,
  LR_LOCALE_BEING_ARCHIVED_STATE,
} from 'constants/localeStates';

export const customIconColors = {
  progress: '#00A9FF',
  failed: '#FA5555',
  partial: '#FF9D00',
  success: '#00C853',
  gray: '#bdbdbd',
};

export const LRsIcons = {
  [LR_LOCALE_CURRENTLY_EDITING_STATE]: props => <EditIcon style={{ color: customIconColors.progress }} {...props} />,
  [LR_LOCALE_PUBLISHED_STATE]: props => <PublishIcon style={{ color: customIconColors.success }} {...props} />,
  [LR_LOCALE_ARCHIVED_STATE]: props => <ArchiveIcon style={{ color: customIconColors.partial }} {...props} />,
  [LR_LOCALE_BEING_PUBLISHED_STATE]: props => <PublishIcon style={{ color: customIconColors.gray }} {...props} />,
  [LR_LOCALE_BEING_ARCHIVED_STATE]: props => <ArchiveIcon style={{ color: customIconColors.gray }} {...props} />,
};

const LOSubItemsConfig = [
  {
    key: LR_LOCALE_CURRENTLY_EDITING_STATE,
    filter: { state: [LR_LOCALE_CURRENTLY_EDITING_STATE] },
    label: 'Currently Editing',
    IconComponent: LRsIcons[LR_LOCALE_CURRENTLY_EDITING_STATE],
    seleniumId: 'lr-curedit',
  },
  {
    key: LR_LOCALE_PUBLISHED_STATE,
    filter: { state: [LR_LOCALE_PUBLISHED_STATE] },
    label: 'Published',
    IconComponent: LRsIcons[LR_LOCALE_PUBLISHED_STATE],
    seleniumId: 'lr-published',
  },
  {
    key: LR_LOCALE_ARCHIVED_STATE,
    filter: { state: [LR_LOCALE_ARCHIVED_STATE] },
    label: 'Archived',
    IconComponent: LRsIcons[LR_LOCALE_ARCHIVED_STATE],
    seleniumId: 'lr-archived',
  },
];

const ImportsSubItemsConfig = [
  {
    key: 'IN_PROGRESS',
    filter: { status: ['IN_PROGRESS'] },
    label: 'In Progress',
    iconColor: customIconColors.progress,
    iconName: 'progress',
    seleniumId: 'progress',
  },
  {
    key: 'FAILED',
    filter: { status: ['FAILED'] },
    label: 'Failed',
    iconColor: customIconColors.failed,
    iconName: 'failed',
    seleniumId: 'failed',
  },
  {
    key: 'PARTIAL',
    filter: { status: ['PARTIAL'] },
    label: 'Partial',
    iconColor: customIconColors.partial,
    iconName: 'partial',
    seleniumId: 'partial',
  },
  {
    key: 'SUCCESSFUL',
    filter: { status: ['SUCCESSFUL'] },
    label: 'Successful',
    iconColor: customIconColors.success,
    iconName: 'successful',
    seleniumId: 'success',
  },
];

const PublicationsSubItemsConfig = [
  {
    key: PUBLICATION_STATUSES.IN_PROGRESS,
    filter: { status: [PUBLICATION_STATUSES.IN_PROGRESS] },
    label: 'In Progress',
    iconColor: customIconColors.progress,
    iconName: 'progress',
    seleniumId: 'publications-progress',
  },
  {
    key: PUBLICATION_STATUSES.FAILED,
    filter: { status: [PUBLICATION_STATUSES.FAILED] },
    label: 'Failed',
    iconColor: customIconColors.failed,
    iconName: 'failed',
    seleniumId: 'publications-failed',
    counter: true,
  },
  {
    key: PUBLICATION_STATUSES.SUCCESSFUL,
    filter: { status: [PUBLICATION_STATUSES.SUCCESSFUL] },
    label: 'Successful',
    iconColor: customIconColors.success,
    iconName: 'successful',
    seleniumId: 'publications-success',
  },
];

export default {
  'learning-objects': {
    defaultParams: { ...LO_SORT_PARAMS, ...DEFAULT_PAGINATION },
    seleniumId: 'learning-resources-parent',
    subItemsConfig: LOSubItemsConfig,
  },
  imports: {
    defaultParams: { ...IMPORT_SORT_PARAMS, ...DEFAULT_PAGINATION },
    seleniumId: 'imports-parent',
    subItemsConfig: ImportsSubItemsConfig,
  },
  publications: {
    defaultParams: { ...DEFAULT_PAGINATION },
    seleniumId: 'publications-parent',
    subItemsConfig: PublicationsSubItemsConfig,
  },
};
