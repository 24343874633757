import { determineValuesToUpdate } from 'utils/objDiff';
import _ from 'lodash';

const IGNORE_FIELDS = ['_version', 'canBeLocalizedIn'];

export const isPristine = (record, values) => {
  const diff = determineValuesToUpdate(record, values);
  const clearedFields = _.difference(Object.keys(record), Object.keys(values));

  return Object.keys(diff).filter(k => !IGNORE_FIELDS.includes(k)).length === 0 && clearedFields.length === 0;
};
