import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { update, getStateByID } from 'ra-redux/exportList';
import { Checkbox } from '_ui/Field';
import { LR_LOCALE_BEING_PUBLISHED_STATE } from 'constants/localeStates';

const HINT = 'Select a SCORM';

const ExpandDownloadField = ({ record }) => {
  const { id, lastPublished, state } = record;
  const dispatch = useDispatch();
  const isSelected = useSelector(getStateByID(id));

  const onClick = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(update({
      ids: id,
      flag: !isSelected,
    }));
  }, [dispatch, isSelected]);

  if (!lastPublished || state === LR_LOCALE_BEING_PUBLISHED_STATE) return null;

  return (
    <div title={HINT}>
      <Checkbox
        defaultChecked={false}
        checked={!!isSelected}
        onClick={onClick}
        data-seleniumid={`export-scorm-${id}`}
        color="primary"
      />
    </div>
  );
};

ExpandDownloadField.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    lastPublished: PropTypes.string,
    state: PropTypes.string,
  }),
};

ExpandDownloadField.defaultProps = {
  record: {},
};

export default ExpandDownloadField;
