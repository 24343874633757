import _ from 'lodash';
import { sortLocales } from 'utils/locales';
import { processExportsHTTPResponse } from './processExports';

export { processExportsHTTPResponse };

const handleManyLogs = (source, params) => {
  const { id } = params;
  return (source || []).map((el, i) => ({ ...el, id: `${id}_${i}` }));
};

export const processGetManyReferenceResponse = (resource, source, params) => {
  switch (resource) {
    case 'learning-objects-locales': {
      const { items = [] } = source;
      const locales = _.map(items, 'locale');
      if (locales.length) {
        const sortedLocales = sortLocales(locales);
        return _.compact(sortedLocales.map(locale => _.find(items, { locale })));
      }
      return source;
    }
    case 'publication-logs':
      return handleManyLogs(source, params);
    case 'import-logs':
      return handleManyLogs(source, params);
    default:
      return source;
  }
};
