import React, { useEffect, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDataProvider } from 'react-admin';
import { DrillDownLinkField } from '_ui';
import {
  CN_SESSIONS_FILTER as sessions,
  CN_VIDEOCASTS_FILTER as videocasts,
  CN_ACTIONTIPS_FILTER,
  CN_ESSENTIALS_FILTER,
  CN_FILTER_FIELDS,
} from 'constants/contentNames';
import { IGNORE_ABORTING_KEY } from 'api/backend-data-server/BackendServerDataProvider';

const FILTERS = { sessions, videocasts };
const PAGINATION = { page: 1, perPage: 25 };
const SORT = { field: 'id', order: 'DESC' };

const getFilterFieldName = (contentName) => {
  if (CN_ACTIONTIPS_FILTER.includes(contentName)) return CN_FILTER_FIELDS.actiontips;
  if (CN_ESSENTIALS_FILTER.includes(contentName)) return CN_FILTER_FIELDS.essentials;
  return null;
};

export const useAssociatedContent = (
  props,
  associationType = null,
) => {
  const { resource, record: { id = null } = {} } = props;
  const dataProvider = useDataProvider();
  const { data, list } = useSelector(state => state.admin.resources[resource], shallowEqual);
  const resourceData = id
    ? [data[id]]
    : list.ids.map(entryId => data[entryId]);

  const filterContentNames = associationType
    ? FILTERS[associationType]
    : _.flatten(Object.values(FILTERS));

  const filters = resourceData?.length
    ? (resourceData.reduce((acc, resourceDataElem) => {
      if (!resourceDataElem?.id || !resourceDataElem?.contentName) {
        return acc;
      }
      const { id, contentName } = resourceDataElem;
      const fieldName = getFilterFieldName(contentName);
      if (fieldName) {
        return {
          ...acc,
          [fieldName]: [
            ...(acc[fieldName] || []),
            id,
          ],
        };
      }
      return acc;
    }, {}))
    : {};

  const hasFilter = !_.isEmpty(filters);

  useEffect(() => {
    if (hasFilter) {
      const payload = {
        sort: SORT,
        pagination: { page: 1, perPage: null },
        [IGNORE_ABORTING_KEY]: true,
      };

      Object.entries(filters).forEach(([filterField, ids]) => {
        dataProvider.getList(
          resource,
          {
            ...payload,
            filter: {
              [filterField]: ids,
              contentName: filterContentNames,
            },
          },
        );
      });
    }
  }, [hasFilter, list.ids]);
};

const LearningResourceACField = ({
  seleniumId,
  associationType,
  resource,
  record,
}) => {
  if (!record || !record.contentName) return null;
  const { id, contentName } = record;
  const filterField = getFilterFieldName(contentName);

  return (
    function AssociationList() {
      const { data: stateData } = useSelector(state => state.admin.resources[resource], shallowEqual);
      const data = useMemo(
        () => {
          const filteredData = Object.values(stateData).filter((entry) => (
            FILTERS[associationType].includes(entry.contentName)
            && (
              Array.isArray(entry[filterField])
                ? entry[filterField].includes(id)
                : entry[filterField] === id
            )
          ));

          return filteredData.slice(0, PAGINATION.perPage);
        },
        [stateData, associationType],
      );

      return !!data.length && data.map((record) => (
        <DrillDownLinkField
          key={record.code}
          record={record}
          targetResource={resource}
          source="code"
          seleniumId={seleniumId}
        />
      ));
    }()
  );
};

LearningResourceACField.propTypes = {
  addLabel: PropTypes.bool,
  associationType: PropTypes.string.isRequired,
  label: PropTypes.string,
  record: PropTypes.shape({
    contentName: PropTypes.string,
    id: PropTypes.string.isRequired,
  }),
  resource: PropTypes.string,
  seleniumId: PropTypes.string.isRequired,
};

LearningResourceACField.defaultProps = {
  addLabel: true,
  label: 'Session',
};

export default LearningResourceACField;
