import React from 'react';
import {
  ReferenceArrayInput,
  ReferenceInput,
  ReferenceField,
  SimpleForm,
  useQueryWithStore,
} from 'react-admin';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  TextField,
  AutocompleteInput,
  AutocompleteArrayInput,
  Edit,
} from '_ui';
import {
  CN_ACTIONTIPS_FILTER,
  CN_ESSENTIALS_FILTER,
} from 'constants/contentNames';
import { getEntityModifyRedirectHref, getContentNameFilter } from 'utils';
import { getManyLocalesPayloadRequest } from 'utils/locales';
import { LR_LOCALE_PUBLISHED_STATE } from 'constants/localeStates';

import { LR_LINKS_CONFIG, LR_MAX_VALUES_CONFIG } from '../constants';
import { validateLR, markComponentRequiredForPublish } from '../LearningResourceView/Actions/utils';
import RequiredAnnotation from '../LearningResourceView/Actions/RequiredAnnotation';
import CurrentlyEditingWarning from '../CurrentlyEditingWarning';

import LearningResourceEditToolBar from './ToolBar';
import SkillSetWrap from './components/SkillSetWrap';

const LearningResourceEdit = props => {
  const record = useSelector(state => state.admin.resources['learning-objects']?.data[props?.id]);
  const contentName = record?.contentName || '';

  const { data, loading } = useQueryWithStore(getManyLocalesPayloadRequest(props?.id, record?.availableLocales));
  const locales = (data || []).filter(el => el.state === LR_LOCALE_PUBLISHED_STATE).map(({ locale }) => locale);
  const additionalValidation = loading || locales.length;

  const validateGlobalMetadataIfLanguagesPublished = (values) => {
    const errors = {};
    if (!additionalValidation) return errors;

    const errorFields = [...validateLR(values)];

    if (errorFields?.length) {
      errorFields.forEach(({ field, name }) => {
        errors[field] = [`The field ${name} is required`];
      });
    }

    return errors;
  };

  const inputComponents = [
    <ReferenceInput
      allowEmpty
      key="authorId"
      source="authorId"
      reference="authors"
      perPage={50}
      sort={{ field: 'code', order: 'ASC' }}
    >
      <AutocompleteInput
        defaultLabel="Author"
        searchLabel="Search Authors"
        isRefineSearch
        emptyText="None"
        optionValue="id"
        optionText="code"
        seleniumId="lo-edit"
        allowEmpty={false}
      />
    </ReferenceInput>,
    <ReferenceArrayInput
      key="themeIds"
      source="themeIds"
      label="Themes"
      reference="themes"
      perPage={50}
      filter={{ childrenOnly: true }}
      sort={{ field: 'code', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        searchLabel="Search Themes"
        isRefineSearch
        perPage={50}
        minSearchableLength={1}
        seleniumId="lo-edit"
        optionText="code"
        optionValue="id"
      />
    </ReferenceArrayInput>,
    <ReferenceArrayInput
      key="tagIds"
      source="tagIds"
      label="Tags"
      reference="tags"
      perPage={50}
      sort={{ field: 'code', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        searchLabel="Search Tags"
        isRefineSearch
        perPage={50}
        minSearchableLength={1}
        seleniumId="lo-edit"
        optionText="code"
        optionValue="id"
      />
    </ReferenceArrayInput>,
    <ReferenceInput
      allowEmpty
      key="mainSkillsetId"
      source="mainSkillsetId"
      reference="skillsets"
      label="Major Skill Set"
      perPage={50}
      filter={{ childrenOnly: true }}
      sort={{ field: 'code', order: 'ASC' }}
    >
      <SkillSetWrap ignore="skillsetIds">
        <AutocompleteInput
          defaultLabel="Major Skill Set"
          searchLabel="Search Major Skill Set"
          isRefineSearch
          emptyText="None"
          optionValue="id"
          optionText="code"
          seleniumId="lo-edit"
          allowEmpty={false}
        />
      </SkillSetWrap>
    </ReferenceInput>,
    <ReferenceArrayInput
      key="skillsetIds"
      source="skillsetIds"
      label="Skill Sets"
      reference="skillsets"
      perPage={50}
      sort={{ field: 'code', order: 'ASC' }}
    >
      <SkillSetWrap ignore="mainSkillsetId">
        <AutocompleteArrayInput
          searchLabel="Search Skill Sets"
          isRefineSearch
          perPage={50}
          minSearchableLength={1}
          maxValuesArrayLength={2}
          seleniumId="lo-edit"
          optionText="code"
          optionValue="id"
        />
      </SkillSetWrap>
    </ReferenceArrayInput>,
    <ReferenceInput
      allowEmpty
      key="programId"
      source="programId"
      reference="programs"
      perPage={50}
      filter={{ childrenOnly: true }}
      sort={{ field: 'code', order: 'ASC' }}
    >
      <AutocompleteInput
        defaultLabel="Program"
        searchLabel="Search Programs"
        isRefineSearch
        emptyText="None"
        optionValue="id"
        optionText="code"
        seleniumId="lo-edit"
        allowEmpty={false}
      />
    </ReferenceInput>,
    <ReferenceInput
      allowEmpty
      key="disciplineId"
      source="disciplineId"
      reference="disciplines"
      perPage={50}
      sort={{ field: 'code', order: 'ASC' }}
    >
      <AutocompleteInput
        defaultLabel="Discipline"
        searchLabel="Search Disciplines"
        isRefineSearch
        emptyText="None"
        optionValue="id"
        optionText="code"
        seleniumId="lo-edit"
        allowEmpty={true}
      />
    </ReferenceInput>,
    <ReferenceInput
      key="publisher"
      source="publisher"
      label="Publisher"
      reference="publishers"
    >
      <AutocompleteInput
        optionText="name"
        optionValue="id"
        seleniumId="lo-edit"
        sort={{ field: 'name' }}
      />
    </ReferenceInput>,
  ];

  if (
    !LR_LINKS_CONFIG[contentName]
    || LR_LINKS_CONFIG[contentName].ESSENTIAL
    || LR_LINKS_CONFIG[contentName].ESSENTIAL_THE_TEAM
  ) {
    inputComponents.push(
      <ReferenceInput
        key="learning-objects"
        label="Essential"
        reference="learning-objects"
        source="essentialId"
        filter={{ contentName: getContentNameFilter(contentName, CN_ESSENTIALS_FILTER) }}
        allowEmpty
      >
        <AutocompleteInput
          emptyText="None"
          optionText="code"
          seleniumId="lo-edit"
          sort={{ field: 'code' }}
        />
      </ReferenceInput>,
    );
  }

  if (
    !LR_LINKS_CONFIG[contentName]
    || LR_LINKS_CONFIG[contentName].ACTION_TIPS
    || LR_LINKS_CONFIG[contentName].ACTION_TIPS_THE_TEAM
  ) {
    const maxValuesArrayLength = LR_MAX_VALUES_CONFIG.ACTION_TIPS[contentName];
    inputComponents.push(
      <ReferenceArrayInput
        key="actionTips"
        label="Action Tips"
        reference="learning-objects"
        source="actionTips"
        perPage={50}
        filter={{ contentName: getContentNameFilter(contentName, CN_ACTIONTIPS_FILTER) }}
        sort={{ field: 'id', order: 'DESC' }}
        fullWidth={true}
      >
        <AutocompleteArrayInput
          searchLabel="Search Action Tips"
          isRefineSearch
          perPage={50}
          maxValuesArrayLength={maxValuesArrayLength}
          minSearchableLength={1}
          optionText="code"
          seleniumId="lo-edit"
        />
      </ReferenceArrayInput>,
    );
  }

  return (
    <Edit undoable={false} {...props} hasShow={false}>
      <SimpleForm
        redirect={getEntityModifyRedirectHref}
        toolbar={<LearningResourceEditToolBar />}
        validate={validateGlobalMetadataIfLanguagesPublished}
      >
        <RequiredAnnotation />
        <TextField
          source="code"
          label="Code"
          seleniumId="lo-code"
        />
        <ReferenceField
          source="contentName"
          label="Content Type"
          reference="content-names"
          link={false}
        >
          <TextField source="name" data-seleniumid="lo-content-name" />
        </ReferenceField>
        { inputComponents.map(markComponentRequiredForPublish) }
        <CurrentlyEditingWarning />
      </SimpleForm>
    </Edit>
  );
};
LearningResourceEdit.propTypes = {
  id: PropTypes.string.isRequired,
};

export default LearningResourceEdit;
