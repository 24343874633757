import React from 'react';
import FalseIcon from '@material-ui/icons/Clear';
import TrueIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';

const HasLocaleValueField = ({ record = {}, id, learningResource }) => {
  const iconLabel = `statusLabel-${learningResource.code}-`;
  if (!id || !learningResource?.availableLocales?.length) return <FalseIcon data-seleniumid={`${iconLabel}false`} />;

  const locale = learningResource.availableLocales.find(el => el === record.locale);
  return locale
    ? <TrueIcon data-seleniumid={`${iconLabel}true-${locale}`} />
    : <FalseIcon data-seleniumid={`${iconLabel}false-${locale}`} />;
};

HasLocaleValueField.propTypes = {
  id: PropTypes.string.isRequired,
  learningResource: PropTypes.object,
  record: PropTypes.object,
};

export default HasLocaleValueField;
