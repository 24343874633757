import { createSelector } from 'reselect';
import {
  selectLocalesLRs,
  selectDisplayedLRs,
  selectFilterLRs,
} from '../selectors';
import { getLocalesMap } from './utils';

export const UPDATE_ENTITY = 'UPDATE_EXPORT_ENTITY';
export const CLEAR_EXPORT = 'CLEAR_EXPORT';
export const CLEAR_EXPORT_PAGE = 'CLEAR_EXPORT_PAGE';

export const initialState = {};

const getUpdatePart = (ids, flag) => (Array.isArray(ids) ? ids : [ids]).reduce((acc, id) => ({ ...acc, [id]: flag }), {});

export const update = ({ ids, flag }) => ({ type: UPDATE_ENTITY, payload: { ids, flag } });
export const clear = () => ({ type: CLEAR_EXPORT });
export const clearPage = (payload) => ({ type: CLEAR_EXPORT_PAGE, payload });

export const getStateByID = localeId => state => state.exportList[localeId];

const select = state => state.exportList;

// return map of the form: "LR id": ["locale1", "locale2"] (all published and filtered locales for displayed LRs)
export const selectPublishedLocales = createSelector(
  selectLocalesLRs,
  selectFilterLRs,
  selectDisplayedLRs,
  (locales, lrFilters, displayedLRs) => {
    const displayedLocalesEntity = Object.values(locales).filter(({ parentId }) => displayedLRs.includes(parentId));
    const displayedLocales = displayedLocalesEntity.reduce((acc, localeEntity) => {
      const { id } = localeEntity;
      if (id) {
        acc[id] = localeEntity;
      }
      return acc;
    }, {});
    return getLocalesMap(displayedLocales, lrFilters);
  },
);

export const getSelected = createSelector(select, info => Object.entries(info || {})
  // eslint-disable-next-line no-unused-vars
  .filter(([_, flag]) => !!flag)
  .map(([id]) => id) || []);

export const getSelectedPage = createSelector(
  getSelected,
  selectDisplayedLRs,
  (selectedLocaleIds, displayedLRs) => selectedLocaleIds.filter(localeId => displayedLRs.some(lrId => localeId.includes(lrId))),
);

export const getSelectableLocaleIds = createSelector(
  selectPublishedLocales,
  selectDisplayedLRs,
  (publishedLocales, displayedLRs) => {
    const res = [];
    displayedLRs.forEach(lrId => {
      const locales = publishedLocales[lrId] || [];
      locales.forEach(locale => res.push(`${lrId}_${locale}`));
    });

    return res;
  },
);

export const getPublishedLocalesForLR = (lrId) => createSelector(
  selectPublishedLocales,
  publishedLocales => (publishedLocales || {})[lrId] || [],
);

export const getSelectedByLRId = lrId => createSelector(
  getSelected,
  selected => (selected || []).filter(el => el.startsWith(lrId)),
);

// reducer
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_ENTITY: {
      const { flag } = payload;
      let { ids } = payload;
      if (flag) {
        const selected = getSelected({ exportList: state });
        ids = (Array.isArray(ids) ? ids : [ids]).filter(id => !selected.includes(id));
      }

      return {
        ...state,
        ...getUpdatePart(ids, flag),
      };
    }
    case CLEAR_EXPORT:
      return initialState;
    case CLEAR_EXPORT_PAGE: {
      const { lrs = [] } = payload;

      return Object.entries(state).reduce((acc, [id, flag]) => {
        acc[id] = (flag && lrs.some(lrId => id.includes(lrId))) ? false : flag;
        return acc;
      }, {});
    }
    default:
      return state;
  }
};
