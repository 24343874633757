import { UPDATE } from 'react-admin';
import axios from 'axios';
import { API_URL } from 'api/constants';
import { LR_LOCALES, LR, PUBLICATIONS } from 'constants/resources';

const searchPublications = async (ids) => {
  const { data } = await axios({
    method: 'post',
    url: `${API_URL()}/${PUBLICATIONS}/search`,
    data: {
      sortDir: 'DESC',
      offset: 0,
      size: ids.length,
      ids,
    },
  });

  const { items } = data || {};
  return items;
};

const getProcessedPublications = async (ids) => {
  const publications = await searchPublications(ids);
  return publications.map(({ id, language, status, learningObjectCode: code }) => ({ id, locale: language, status, code }));
};

export default (requestHandler) => async (type, resource, params = {}) => {
  if (type === UPDATE && resource === LR_LOCALES && params?.action === 'publish') {
    const { comment, locale, lrId } = params;
    const { data: ids } = await axios({
      headers: { 'Content-Type': 'text/plain' },
      method: 'post',
      url: `${API_URL()}/${LR}/${lrId}/locale/${locale}/publish`,
      data: comment,
    });

    const data = await getProcessedPublications(ids);
    return { data };
  }

  if (type === UPDATE && resource === LR && params?.action === 'publishAllLocales') {
    const { locales, comment, id } = params;
    const { data: ids } = await axios({
      method: 'post',
      url: `${API_URL()}/${resource}/${id}/publish`,
      data: { locales, comment },
    });

    const data = await getProcessedPublications(ids);
    return { data, locales, id };
  }

  if (resource === PUBLICATIONS && params?.action === 'publishProgress') {
    const { ids } = params;
    const { data } = await axios({
      method: 'post',
      url: `${API_URL()}/${PUBLICATIONS}/search`,
      data: {
        sortDir: 'DESC',
        offset: 0,
        size: ids.length,
        ids,
      },
    });

    const { items } = data || {};
    return { data: (items || [])
      .map(({ id, status, learningObjectCode, language }) => ({ id, status, learningObjectCode, language })) };
  }

  return requestHandler(type, resource, params);
};
