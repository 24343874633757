import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updatePublications } from 'ra-redux/publications';
import { PUBLICATION_STATUSES } from '_domains/Jobs/Publications/constants';
import { useMutation } from 'react-admin';
import { filter, isEmpty } from 'lodash';
import { PUBLICATIONS } from 'constants/resources';
import { isTestFlow } from 'utils';

const INTERVAL = isTestFlow() ? 1000 : 5000;

const usePublicationProgress = ({ ids }) => {
  const [getStatuses] = useMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!ids?.length) return;
    const requestInterval = setInterval(async () => {
      getStatuses({
        type: 'update',
        resource: PUBLICATIONS,
        payload: {
          action: 'publishProgress',
          ids,
        },
      },
      {
        onSuccess: ({ data }) => {
          const processed = [
            ...filter(data, ['status', PUBLICATION_STATUSES.SUCCESSFUL]),
            ...filter(data, ['status', PUBLICATION_STATUSES.FAILED]),
          ].map(({ id, language, learningObjectCode, status }) => ({
            id, code: learningObjectCode, locale: language, status,
          }));

          if (!isEmpty(processed)) dispatch(updatePublications(processed));
        },
      });
    }, INTERVAL);

    return () => { clearInterval(requestInterval); };
  }, [ids.toString()]);

  return null;
};

export default usePublicationProgress;
