/* eslint-disable react/prop-types */

import React, { useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Datagrid as RaDatagrid,
  DatagridBody as RaDatagridBody,
  DatagridRow as RaDatagridRow,
  useListContext,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

import { getLocales, setLocales } from 'ra-redux/locales';
import { ROW_HEIGHT } from 'constants/resources';
import { DEFAULT_LOCALE, getRelevantLocale } from '../../utils/locales';

const useStyles = makeStyles({
  root: ({ height }) => (height ? { height } : {}),
});

export const DatagridRow = (props) => {
  const { id, className, resource } = props;
  const classes = useStyles({ height: ROW_HEIGHT[resource] });
  return (
    <RaDatagridRow
      {...props}
      className={cn(className, classes.root)}
      data-seleniumid={`table-row-${id}`}
    />
  );
};

DatagridRow.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const DatagridWrapper = ({ localeRowClick, ...props }) => {
  const selectedLocales = useSelector(getLocales);

  return (
    <RaDatagrid
      {...props}
      body={<RaDatagridBody row={<DatagridRow />} />}
      {...(localeRowClick
        ? { rowClick: (id, basePath, { availableLocales }) => {
          const locale = getRelevantLocale(selectedLocales, availableLocales);
          return `${basePath}/${id}/show/${locale || DEFAULT_LOCALE}`;
        } }
        : {})
      }
    />
  );
};

const Datagrid = ({
  filterValues: { availableLocales = null } = {},
  warning = null,
  ...props
}) => {
  const { loading } = useListContext();
  const dispatch = useDispatch();
  useEffect(
    () => {
      if (availableLocales) {
        dispatch(setLocales(availableLocales));
      }
    },
    [JSON.stringify(availableLocales || '')],
  );

  return !loading || (process.env.NODE_ENV === 'test')
    ? (
      <>
        {warning}
        <DatagridWrapper {...props} />
      </>
    )
    : (
      <Box margin={2}>
        <LinearProgress data-seleniumid="datagrid-progress-bar" />
      </Box>
    );
};

export default Datagrid;
