import { SRC_PREFIX } from 'api/constants';

export const getImgSRC = ({ record, source }) => {
  const imgSource = typeof record === 'string'
    ? record
    : record?.[source];

  if (!imgSource) return;
  return /^blob/.test(imgSource)
    ? imgSource
    : `${SRC_PREFIX}?url=${encodeURIComponent(imgSource)}`;
};

export const getImgDimensions = (
  props,
  MAX = 0,
) => {
  let { size, width, height } = props;
  size = parseInt(size, 10);

  if (size) {
    size = Math.min(size, MAX || size);
    width = size; height = size;
  }

  const dimesnsions = { width, height };
  if (MAX) dimesnsions.maxWidth = MAX;
  return dimesnsions;
};
