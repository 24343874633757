import React, { useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DownloadIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';

const defaultProps = {
  color: 'primary',
  title: 'Download package(s)',
};

const getDateWithTZ = (date) => `${moment(date || new Date()).format('M-DD-YYYY__h.mm.ss_A')}`;

export const getSeleniumId = recordId => `lrexport-${recordId}-button`;
const getLink = path => `${window.location.origin}${path}`;

const DownloadField = ({ recordId, packagePath, created, fileName }) => {
  const prefix = fileName ? fileName.replace(' ', '_') : 'LRExport';
  const name = useMemo(() => `${prefix}__${getDateWithTZ(created)}.zip`, [created, prefix]);
  if (!packagePath) return '';
  return (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
      }}
      href={getLink(packagePath)}
      data-seleniumid={getSeleniumId(recordId)}
      {...defaultProps}
      download={name}
    >
      <DownloadIcon />
    </IconButton>
  );
};

DownloadField.propTypes = {
  created: PropTypes.string,
  fileName: PropTypes.string,
  packagePath: PropTypes.string,
  recordId: PropTypes.string.isRequired,
};

export default DownloadField;
