import React, { useState, useCallback, useMemo } from 'react';
import { useDataProvider, TextField } from 'react-admin';
import PropTypes from 'prop-types';
import { isBefore, addHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import { ARCHIVE_PUBLICATION_TYPE_LABELS } from '_domains/Jobs/Publications/constants';
import { getCurrentTimezone } from 'utils';
import { CommentModal } from '../Layout/Modals';

const useStyles = makeStyles({
  link: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 20,
    textDecoration: ({ isCommentAllowed }) => (
      isCommentAllowed
        ? 'underline'
        : 'none'
    ),
    '&:hover': {
      cursor: ({ isCommentAllowed }) => (
        isCommentAllowed
          ? 'pointer'
          : 'default'
      ),
    },
  },
});

const CommentField = (props) => {
  const onContainerClick = (event) => {
    event.stopPropagation();
  };

  const [open, setOpen] = useState(false);
  const toggleModal = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const { record } = props;
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const onSubmit = useCallback((comment) => {
    setLoading(true);
    const { id } = record;
    dataProvider
      .update(
        'publications',
        {
          id: `${id}/comment`,
          data: comment,
        },
      )
      .then(() => {
        toggleModal();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [record, toggleModal]);

  const isCommentAllowed = useMemo(() => {
    if (record.publicationDate) {
      const currentTz = getCurrentTimezone();
      const publicationDateInCurrentTz = utcToZonedTime(record.publicationDate, currentTz);
      const allowedTimeframe = addHours(publicationDateInCurrentTz, 24);
      const currentDate = new Date();

      return isBefore(currentDate, allowedTimeframe);
    }

    return true;
  }, [record]);
  const title = isCommentAllowed
    ? `${record.comment ? 'Edit' : 'Add'} comment`
    : 'Comment editing only allowed within 24-hour timeframe';
  const Icon = isCommentAllowed ? AddIcon : CloseIcon;

  const { id: seleniumId } = record;
  const classes = useStyles({ isCommentAllowed });

  return (
    <div
      onClick={onContainerClick}
      data-seleniumid={`comment-field-container-${seleniumId}`}
      title={title}
    >
      {
        record.comment
          ? (
            <a
              onClick={isCommentAllowed ? toggleModal : null}
              className={classes.link}
              data-seleniumid={`comment-field-link-${seleniumId}`}
            >
              <TextField
                source="comment"
                {...props}
              />
            </a>
          )
          : (
            <IconButton
              disabled={!isCommentAllowed}
              size="small"
              onClick={toggleModal}
            >
              <Icon fontSize="small" />
            </IconButton>
          )
      }
      {
        open && (
          <CommentModal
            open
            onCancel={toggleModal}
            onSubmit={onSubmit}
            submitBtnProps={{
              disabled: loading,
              loading,
            }}
            record={{
              ...record,
              code: record.learningObjectCode,
            }}
          />
        )
      }
    </div>
  );
};

CommentField.propTypes = {
  record: PropTypes.shape({
    comment: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(null),
    ]),
    id: PropTypes.string.isRequired,
    learningObjectCode: PropTypes.string.isRequired,
    publicationDate: PropTypes.string,
    publicationType: PropTypes.oneOf(
      Object.keys(ARCHIVE_PUBLICATION_TYPE_LABELS),
    ).isRequired,
  }).isRequired,
};

export default CommentField;
