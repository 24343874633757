import React from 'react';
import PropTypes from 'prop-types';
import { CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import cn from 'classnames';
import { SimpleShowLayout } from 'react-admin';
import { sanitizeProps } from '_ui/Field/utils';

const useStyles = makeStyles({
  cardContent: {
    padding: '0 !important',
  },
  baseCardContentRow: {
    overflowX: 'auto',
  },
  cardContentRow: {
    padding: '0 !important',
    alignItems: 'stretch',
    alignContent: 'stretch',
    display: 'grid',
    'grid-template-columns': 'repeat(5, 20%)',
    '& div': {
      textAlign: 'center',
      maxWidth: '100px',
    },
    '& label': {
      textAlign: 'center',
      alignSelf: 'center',
      transform: 'none',
      transformOrigin: 'none',
      fontSize: '0.75rem',
    },
  },
  title: {
    background: '#eee !important',
    paddingTop: '0.5rem',
    paddingLeft: '1rem',
    paddingBottom: '0.5rem',
    marginBottom: '0.5rem',
    fontWeight: 'bold',
    borderBottom: '1px solid #eee',
  },
});

const SimpleShowCardContent = ({ classes, children, headerTitle, direction = 'col', ...props }) => {
  const contentClasses = useStyles({ classes });
  return (
    <CardContent className={contentClasses.cardContent}>
      {headerTitle && <Typography className={contentClasses.title}>{headerTitle}</Typography>}
      <SimpleShowLayout className={cn(direction === 'row' && contentClasses.cardContentRow, contentClasses.baseCardContentRow)} {...sanitizeProps(props)}>
        {children}
      </SimpleShowLayout>
    </CardContent>
  );
};

SimpleShowCardContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  classes: PropTypes.object,
  direction: PropTypes.string,
  headerTitle: PropTypes.string,
};

export default SimpleShowCardContent;
