import React from 'react';
import {
  SimpleForm, TextField,
} from 'react-admin';
import { getLocaleModifyRedirectHref } from 'utils';
import {
  TextInput,
  ToolBar,
  Edit,
  CreateLocale, ReferenceField,
} from '_ui';

export const DisciplineLocaleEdit = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm
      redirect={getLocaleModifyRedirectHref}
      toolbar={<ToolBar />}
      autoComplete="off"
    >
      <ReferenceField
        label="Discipline Code"
        source="parentId"
        link={false}
        reference="disciplines"
        data-seleniumid="discipline-locale-modify-code"
      >
        <TextField source="code" />
      </ReferenceField>
      <TextField source="locale" label="Language" data-seleniumid="discipline-locale-modify-locale" />
      <TextInput source="title" seleniumId="discipline-locale-modify-title" />
    </SimpleForm>
  </Edit>
);

export const DisciplineLocaleCreate = props => (
  <CreateLocale {...props}>
    <SimpleForm
      id="create-locale-form"
      redirect={getLocaleModifyRedirectHref}
      toolbar={<ToolBar />}
      autoComplete="off"
    >
      <ReferenceField
        label="Discipline Code"
        source="parentId"
        link={false}
        reference="disciplines"
        data-seleniumid="discipline-locale-modify-code"
      >
        <TextField source="code" />
      </ReferenceField>
      <ReferenceField
        data-seleniumid="discipline-locale-modify-locale"
        label="Language Code"
        link={false}
        perPage={50}
        reference="languages"
        source="locale"
      >
        <TextField source="code" />
      </ReferenceField>
      <TextInput source="title" seleniumId="discipline-locale-modify-title" />
    </SimpleForm>
  </CreateLocale>
);
