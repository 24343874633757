import jsonExport from 'jsonexport/dist';

import { download } from './download';

export default ({
  exportType,
  exportEntries,
  fileName,
  rowDelimiter = exportType === 'csv' ? ',' : '\t',
  ...options
}) => jsonExport(exportEntries, {
  rowDelimiter,
  undefinedString: '-',
  ...options,
}, (error, str) => {
  if (error) throw new Error(error);
  download(
    [
      exportType === 'csv'
        ? `sep=${rowDelimiter}\r\n${str}`
        : str.substring(str.indexOf('\n') + 1),
    ],
    exportType,
    fileName,
  );
});
