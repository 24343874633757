import _ from 'lodash';

const getTableStyles = theme => ({
  table: {
    tableLayout: 'fixed',
    minWidth: `${theme.breakpoints.values.md}px`,
  },
  tbody: {
    '& td': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      whiteSpace: 'normal',
      overflow: 'hidden',
      position: 'relative',
    },
    '& td > span': {
      wordBreak: 'break-word',
    },
  },
  expandIcon: {
    marginLeft: `-${theme.spacing(1)}px`,
  },
});

const getWidthStyles = columnWidth => {
  const values = Object.values(columnWidth);
  // eslint-disable-next-line no-console
  if (_.sum(values) > 100) console.error('Wrong columns width: ', _.sum(values));
  return {
    headerRow: values.reduce((acc, width, index) => {
      acc[`& th:nth-of-type(${index + 1})`] = { width: `${width}%` };
      return acc;
    }, {}),
  };
};

export const withCustomWidthTableStyles = (columnWidth, styles) => theme => {
  const defaultStyles = {
    ...getTableStyles(theme),
    ...getWidthStyles(columnWidth),
  };
  return (_.isEmpty(styles) && !_.isFunction(styles)) ? defaultStyles : _.merge(defaultStyles, _.isFunction(styles) ? styles(theme) : styles);
};
