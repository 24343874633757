import React from 'react';

import MuiGrid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  gridContainer: {
    display: 'flex',
    alignItems: 'stretch',
    alignContent: 'stretch',
  },
});

const Grid = props => {
  const classes = useStyles();

  return (
    <MuiGrid
      className={classes.gridContainer}
      {...props}
      container
    />
  );
};

export default Grid;
