import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ShowView } from 'react-admin';
import SimpleShowCardContent from '_ui/Layout/SimpleShowCardContent';

const flexProps = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
};
export const useStyles = makeStyles(theme => ({
  showView: {
    padding: `${theme.spacing(1)}px !important`,
    ...flexProps,
    '&>div': {
      margin: '0 !important',
      ...flexProps,
    },
  },
}));

const sanitizeProps = ({ translate, tabsLabel, isCreateLocaleAvailable, languageSpecificActions, languageSpecificFields, languageAgnosticFields, ...props }) => props;

const ViewCard = ({ children, headerTitle, seleniumId, ...props }) => {
  const contentClasses = useStyles();
  const { record: { id } = {} } = props || {};
  return (
    <ShowView
      key={`ShowView-${id}`}
      {...props}
      className={contentClasses.showView}
      actions={null}
    >
      <SimpleShowCardContent headerTitle={headerTitle} data-seleniumid={`${seleniumId}-card`} {...sanitizeProps(props)}>
        {children}
      </SimpleShowCardContent>
    </ShowView>
  );
};

ViewCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  headerTitle: PropTypes.string,
  seleniumId: PropTypes.string,
};

export default ViewCard;
