import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useNotify, useRefresh, useDataProvider } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import SimpleBar from 'simplebar-react';
import { LR_LOCALES, LR } from 'constants/resources';
import {
  LR_LOCALE_CURRENTLY_EDITING_STATE,
} from 'constants/localeStates';
import { CommentModal, ProgressButton } from '_ui';
import { PUBLICATION_TYPE } from '_domains/Jobs/Publications/constants';
import { addPublications } from 'ra-redux/publications';
import { getContentName } from 'ra-redux/selectors';
import { sortLocales } from 'utils/locales';
import { validateRequiredForPublish } from './utils';
import { NOT_PUBLISH } from './constants';

const sortEditingLocales = localesRecord => {
  const source = _.keyBy(localesRecord, 'locale');
  const sorted = sortLocales(Object.keys(source));
  return sorted.map(el => source[el]);
};

const PublishAction = ({ disabled, record, locales }) => {
  const { id: lrId, contentName } = record;
  const [showModal, setShowModal] = useState(false);
  const [notValidLocales, setNotValidLocales] = useState([]);
  const [validLocales, setValidLocales] = useState([]);
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const refresh = useRefresh();
  const viewContentName = useSelector(getContentName(contentName));

  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const notify = useNotify();
  const [publish, { loading }] = useMutation();
  const [getLocalesData, { loading: loadingActualLocalesData }] = useMutation();

  const onClick = () => {
    getLocalesData({
      type: 'getMany',
      resource: LR_LOCALES,
      payload: { ids: locales.map(({ id }) => id) },
    }, {
      onSuccess: ({ data }) => {
        const editingLocales = sortEditingLocales((data || []).filter(el => el.state === LR_LOCALE_CURRENTLY_EDITING_STATE));
        const { valid, notValid } = _.reduce(editingLocales, (result, locale) => {
          const err = validateRequiredForPublish(record, locale, viewContentName);

          err
            ? result.notValid.push({ locale: locale.locale, reason: err })
            : result.valid.push({ locale: locale.locale });
          return result;
        }, { valid: [], notValid: [] });

        if (valid.length || notValid.length) {
          setNotValidLocales(notValid);
          setValidLocales(valid);
          toggleModal();
        }
        else {
          notify(NOT_PUBLISH);
          refresh();
        }
      },
      onError: (err) => notify('Error: Can\'t fetch actual locales data.', err ?? 'error'),
    });
  };

  const onSubmit = (comment) => {
    toggleModal();
    publish({
      type: 'update',
      resource: LR,
      payload: {
        id: lrId,
        action: 'publishAllLocales',
        locales: validLocales.map(({ locale }) => locale),
        comment,
      },
    },
    {
      onSuccess: ({ data, locales, id }) => {
        dataProvider.getMany(LR_LOCALES, { ids: locales.map(locale => `${id}_${locale}`) });
        dispatch(addPublications(data));
        notify('Publication has been started');
      },
    });
  };

  return (
    <>
      <ProgressButton
        size="small"
        color="primary"
        disabled={disabled}
        loading={loading || loadingActualLocalesData}
        seleniumId={`publish-${lrId}-all`}
        onClick={onClick}
        label="Publish All"
      />
      <CommentModal
        open={showModal}
        record={{ ...record, publicationType: PUBLICATION_TYPE }}
        onSubmit={onSubmit}
        onCancel={toggleModal}
        submitBtnProps={{ disabled: validLocales.length < 1 }}
      >
        <>
          {validLocales.length
            ? (
              <div style={{ flexDirection: 'column' }}>
                <span style={{ width: '100%' }}>The following languages will be published:</span>
                <b>{validLocales.map(({ locale }) => locale).join(', ').replace(/,\s([^,]+)$/, ' and $1')}</b>
              </div>
            ) : null}
          {notValidLocales.length
            ? (
              <div style={{ flexDirection: 'column' }}>
                <span style={{ width: '100%' }}>These languages will not be published:</span>
                <SimpleBar style={{ maxHeight: '200px', width: '100%' }}>
                  <ul style={{ paddingLeft: '24px' }}>
                    { notValidLocales.map((l, k) => <li key={k}><b>{l.locale}</b>, {l.reason}</li>) }
                  </ul>
                </SimpleBar>
              </div>
            ) : null}
        </>
      </CommentModal>
    </>
  );
};

PublishAction.propTypes = {
  disabled: PropTypes.bool.isRequired,
  locales: PropTypes.arrayOf(PropTypes.string).isRequired,
  record: PropTypes.shape({
    contentName: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default PublishAction;
