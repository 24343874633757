import React from 'react';
import { SimpleForm } from 'react-admin';
import {
  ToolBar,
  TextInput,
  CreateLocale,
  TextField,
  ReferenceField,
} from '_ui';
import { getLocaleModifyRedirectHref } from 'utils';

const ProgramsCreateLocale = props => (
  <CreateLocale {...props}>
    <SimpleForm
      id="create-locale-form"
      redirect={getLocaleModifyRedirectHref}
      toolbar={<ToolBar />}
      autoComplete="off"
    >
      <ReferenceField
        link={false}
        source="parentId"
        reference="programs"
        label="Program Code"
      >
        <TextField source="code" seleniumId="program-locale-modify-code" />
      </ReferenceField>

      <ReferenceField
        label="Language"
        link={false}
        perPage={50}
        reference="languages"
        source="locale"
      >
        <TextField source="code" seleniumId="program-locale-modify-locale" />
      </ReferenceField>
      <TextInput source="title" seleniumId="program-locale-modify-title" />
      <TextInput
        label="Abstract"
        source="summary"
        seleniumId="program-locale-modify-abstract"
      />
    </SimpleForm>
  </CreateLocale>
);

export default ProgramsCreateLocale;
