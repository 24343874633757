import React from 'react';

import {
  Id,
  Locale,
  Text,
  Segment,
  Divider,
} from './Breadcrumb';

export const seleniumId = 'resource-edit-breadcrumb';

const Edit = () => (
  <Segment>
    <Id link />
    <Locale link />
    <Divider />
    <Text seleniumId={seleniumId}>Edit</Text>
  </Segment>
);

export default Edit;
