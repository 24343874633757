import { REGEX_LR_UUID, REGEX_LOCALE } from './regex';

export const LOCALE_PATTERN = REGEX_LOCALE.source;
const ID_PATTERN = `${REGEX_LR_UUID.source}|${LOCALE_PATTERN}`;

export const ROUTE_PATTERNS = {
  list: '/:resource',
  show: `/:resource/:id(${ID_PATTERN})/show/:locale(${LOCALE_PATTERN})?`,
  edit: `/:resource/:id(${ID_PATTERN}):locale(_${LOCALE_PATTERN})?`,
  create: `/:resource/create/:id(${ID_PATTERN})?/:locale(${LOCALE_PATTERN})?`,
  deleteLocale: `/:resource/:id(${ID_PATTERN})/locale/:locale(${LOCALE_PATTERN})/:target?`,
  bulk: '/:resource/bulk/create',
};
