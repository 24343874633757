import React from 'react';
import ReactDOM from 'react-dom';

import Root from './App';

import { APP_CONF_URL } from './api/constants';

// Dynatrace session env property
window.DT_ENV = window.location.hostname;

fetch(APP_CONF_URL)
  .then(res => {
    if (res.status < 200 || res.status >= 300) {
      throw new Error(res.statusText);
    }
    return res.json();
  })
  .then(config => {
    window.ENDPOINT_BASE_URL = `//${config.endpointBaseUrl}`;
  })
  .catch(() => {
    window.ENDPOINT_BASE_URL = window.location.origin;
  })
  .finally(() => {
    ReactDOM.render(<Root />, document.getElementById('root'));
  });
