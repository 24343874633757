/* eslint-disable react/prop-types */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ReferenceArrayInput,
  CreateButton,
  ReferenceField,
  useMutation,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { withCustomWidthTableStyles } from 'utils/styleUtils';
import { useHistory } from 'react-router-dom';

import {
  TAXONOMY_DOWNLOAD_EVENT_TYPE,
  TAXONOMY_DOWNLOAD_MESSAGE,
  generateTaxonomyReport as generateTaxonomyReportUtil,
  getTaxonomyReportParams,
} from 'utils/exports';
import { BASE_SEARCH_SOURCE } from 'constants';

import {
  Datagrid,
  Filter,
  List,
  LocaleListField,
  LocaleReferenceField,
  AutocompleteArrayInput,
  TextInput,
  TextField,
  TaxonomyButton,
} from '_ui';

const ThemesFilter = (props) => (
  <Filter {...props} seleniumId="themes-filter">
    <TextInput
      alwaysOn
      allowEmpty
      label="Search"
      source={BASE_SEARCH_SOURCE}
      seleniumId="themes-filter-search"
      isFilter
    />

    <ReferenceArrayInput
      allowEmpty
      alwaysOn
      label="Language"
      perPage={50}
      reference="languages"
      sort={{ field: 'name', order: 'ASC' }}
      source="availableLocales"
    >
      <AutocompleteArrayInput
        maxValuesArrayLength={1}
        seleniumId="themes-filter-languages"
        optionText="code"
        allowDuplicates={false}
        allowEmpty={false}
      />
    </ReferenceArrayInput>

    <TextInput
      source="code"
      isFilter
      seleniumId="themes-filter-code"
    />
  </Filter>
);

const columnWidth = {
  code: 25,
  title: 25,
  parent: 25,
  availableLocales: 25,
};

const useStyle = makeStyles(withCustomWidthTableStyles(columnWidth));

const ThemesList = (props) => {
  const { hasCreate } = props;
  const history = useHistory();
  const notify = useNotify();
  const classes = useStyle();

  const [generate] = useMutation(
    getTaxonomyReportParams('theme'),
  );

  const generateTaxonomyReport = useCallback(() => {
    generate();
    const downloadWindow = generateTaxonomyReportUtil('theme');
    downloadWindow.addEventListener(TAXONOMY_DOWNLOAD_EVENT_TYPE, () => {
      notify(`Themes ${TAXONOMY_DOWNLOAD_MESSAGE}`);
    });
  }, [generate, notify]);

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<ThemesFilter />}
      actions={(
        <>
          {
            hasCreate
              ? <CreateButton basePath="../themes" data-seleniumid="create-themes-btn" />
              : null
          }
          <TaxonomyButton
            onClick={generateTaxonomyReport}
            data-seleniumid="generate-theme-taxonomy-report"
          />
        </>
      )}
      seleniumId="themes"
      sort={{ field: 'code', order: 'ASC' }}
    >
      <Datagrid localeRowClick classes={classes}>
        <TextField source="code" seleniumId="themes-code" />

        <LocaleReferenceField
          sortable={undefined}
          link={false}
          source="id"
          label="Title"
          reference="themes-locales"
        >
          <TextField source="title" seleniumId="themes-locale-title" />
        </LocaleReferenceField>

        <ReferenceField
          reference="themes"
          source="parentId"
          label="Parent Theme"
          link={false}
        >
          <TextField source="code" seleniumId="themes-parent-code" />
        </ReferenceField>

        <LocaleListField
          sortable={undefined}
          source="availableLocales"
          label="Available in"
          hideLabel
          targetResource="themes"
          onClick={(locale, recordId) => {
            const { basePath } = props;
            history.push(`${basePath}/${recordId}/show/${locale}`);
          }}
        />
      </Datagrid>
    </List>
  );
};

ThemesList.propTypes = {
  basePath: PropTypes.string,
};

export default ThemesList;
