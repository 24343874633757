import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, ButtonBase, Button } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import { slugify } from '../../utils';

const CircularProgress = withStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    animation: 'none !important',
  },
})(MuiCircularProgress);

const ProgressButton = ({
  component: Component,
  children,
  disabled,
  label,
  loading,
  progressProps,
  tooltip,
  seleniumId = slugify(label),
  ...buttonProps
}) => {
  if (tooltip) {
    return (
      <Tooltip
        title={tooltip}
      >
        <Component
          {...buttonProps}
          disabled={disabled || loading}
          data-seleniumid={seleniumId}
          startIcon={tooltip ? <InfoIcon style={{ fill: 'red' }} /> : null}
        >
          {label}
          {loading && <CircularProgress {...progressProps} />}
          {children}
        </Component>
      </Tooltip>
    );
  }
  return (
    <Component
      {...buttonProps}
      disabled={disabled || loading}
      data-seleniumid={seleniumId}
    >
      {label}
      {loading && <CircularProgress {...progressProps} />}
      {children}
    </Component>
  );
};

ProgressButton.propTypes = {
  ...ButtonBase.propTypes,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  progressProps: PropTypes.shape(MuiCircularProgress.propTypes),
  seleniumId: PropTypes.string,
};

ProgressButton.defaultProps = {
  ...ButtonBase.defaultProps,
  component: Button,
  loading: false,
  progressProps: {
    ...MuiCircularProgress.defaultProps,
    size: 20,
  },
};

export default ProgressButton;
