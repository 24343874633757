import { GET_LIST } from 'react-admin';
import isEqual from 'lodash/isEqual';

import { DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER } from 'constants/sort';
import { PUBLICATION_STATUSES, DATE_SORT_FIELDS, LANGUAGE_AGNOSTIC_SORT_FIELDS } from '_domains/Jobs/Publications/constants';

export const RA_DEFAULT_LIST_SORT = { field: DEFAULT_SORT_FIELD, order: DEFAULT_SORT_ORDER };
export {
  PUBLICATION_STATUSES,
  DATE_SORT_FIELDS,
  LANGUAGE_AGNOSTIC_SORT_FIELDS,
};

export const REQUEST_ARGS = { type: GET_LIST, resource: 'publications' };
export const checkPublicationsListRequest = (type, resource) => isEqual({ type, resource }, REQUEST_ARGS);
export const checkSortedByDefault = (sort) => !sort || isEqual(sort, RA_DEFAULT_LIST_SORT);
export const checkSortedByDate = (sort = {}) => DATE_SORT_FIELDS.includes(
  Array.isArray(sort.field)
    ? sort.field?.[0]
    : sort.field,
);
export const checkSortedByLanguageAgnosticField = (sort = {}) => LANGUAGE_AGNOSTIC_SORT_FIELDS.includes(sort.field);

export const transformDefaultSortParams = (params) => {
  const { sort = RA_DEFAULT_LIST_SORT } = params;

  sort.order = 'DESC';
  sort.field = 'publicationDate';
  return sort;
};

export const transformDateSortParams = (params) => {
  const { sort = RA_DEFAULT_LIST_SORT } = params;
  const dateFieldValues = [...DATE_SORT_FIELDS];
  if (sort.field === 'initiatedDate') dateFieldValues.reverse();

  sort.field = dateFieldValues;
  return sort;
};

export const transformOrderSortParams = (params) => {
  const { sort = RA_DEFAULT_LIST_SORT } = params;
  const isSortedByDate = checkSortedByDate(sort);

  switch (sort.order) {
    case 'DESC':
      sort.nullsFirst = sort.nullsFirst ?? isSortedByDate;
      break;
    case 'ASC':
      sort.nullsFirst = sort.nullsFirst ?? (
        checkSortedByLanguageAgnosticField(sort) || !isSortedByDate
      );
      break;
    default: break;
  }

  return sort;
};

export default (wrappedDataProvider) => async (
  type,
  resource,
  params = {},
) => {
  const isPublicationsListRequest = checkPublicationsListRequest(type, resource);
  const isSortedByDefault = checkSortedByDefault(params?.sort);

  if (isPublicationsListRequest) {
    if (isSortedByDefault) params.sort = transformDefaultSortParams(params);

    const isSortedByDate = checkSortedByDate(params?.sort);
    if (isSortedByDate) params.sort = transformDateSortParams(params);

    params.sort = transformOrderSortParams(params);
  }

  const result = await wrappedDataProvider(type, resource, params);

  return result;
};
