import React, { useState, useLayoutEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import PropTypes, { oneOf } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { AutocompleteInput as RaAutocompleteInput } from 'react-admin';
import { clearRefineSearch, addRefineSearch } from './RefineSearch';

const useStyles = makeStyles(() => ({
  input: {
    minWidth: 200,
  },
}));

// TODO check before prod https://github.com/marmelab/react-admin/issues/4435 https://jira.wiley.com/browse/CPP2-1207
const AutocompleteInput = ({
  seleniumId,
  minSearchableLength,
  name,
  label,
  defaultLabel = label,
  searchLabel,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const suggestionsRef = useRef(null);
  const classes = useStyles();

  const { choices, source, sort, isRefineSearch, pagination, input } = props;
  const filteredChoices = choices.filter(e => !props.exclude.includes(e.id));
  const uniqChoices = _.uniqBy(filteredChoices, 'id');
  const sortedChoices = useMemo(() => {
    if (sort) {
      const { field, order = 'ASC' } = sort;
      return _.orderBy(uniqChoices, field, order.toLowerCase());
    }

    return choices;
  }, [uniqChoices, sort]);

  useLayoutEffect(() => {
    if (isRefineSearch && isOpen && suggestionsRef?.current) {
      const { current } = suggestionsRef;
      const { perPage } = pagination;

      clearRefineSearch(current);
      if (sortedChoices.length < perPage) return;
      addRefineSearch(current);
    }
  }, [isOpen, sortedChoices]);

  const additionalInputProps = isRefineSearch ? {
    ...(input || {}),
    onChange: v => {
      if (isRefineSearch && isOpen) setIsOpen(false);
      if (input) input.onChange(v);
    },
  } : {};

  const uniqueSeleniumId = `${seleniumId}-${source}-input`;
  return (
    <RaAutocompleteInput
      {...props}
      {...additionalInputProps}
      choices={sortedChoices}
      variant="standard"
      options={{
        suggestionsContainerProps: { 'data-seleniumid': `${seleniumId}-${source}-wrap`, placement: 'top-start', ref: suggestionsRef },
        inputProps: {
          className: classes.input,
          name: name || uniqueSeleniumId,
          'data-seleniumid': uniqueSeleniumId,
          onFocus: () => isRefineSearch && setTimeout(() => { setIsOpen(true); }),
          onBlur: () => isRefineSearch && setIsOpen(false),
        },
        labelProps: { label: (isOpen && searchLabel) ? searchLabel : defaultLabel },
      }}
      matchSuggestion={() => true}
      shouldRenderSuggestions={value => (value || '').trim().length > minSearchableLength - 1}
    />
  );
};

AutocompleteInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  defaultLabel: PropTypes.string,
  exclude: PropTypes.arrayOf(PropTypes.string),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  isRefineSearch: PropTypes.bool,
  label: PropTypes.string,
  minSearchableLength: PropTypes.number,
  name: PropTypes.string,
  pagination: PropTypes.shape({
    perPage: PropTypes.number,
  }),
  searchLabel: PropTypes.string,
  seleniumId: PropTypes.string.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    order: oneOf(['ASC', 'DESC']),
  }),
  source: PropTypes.string,
};

AutocompleteInput.defaultProps = {
  exclude: [],
  helperText: false,
  sort: undefined,
  label: undefined,
  minSearchableLength: 0,
  name: null,
  isRefineSearch: false,
  searchLabel: '',
};

export default AutocompleteInput;
