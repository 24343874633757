import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput as RASelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: isFilter => (isFilter ? { margin: 0 } : {}),
});

const SelectInput = ({
  isFilter,
  allowEmpty,
  seleniumId,
  options: optionsOverrides,
  ...props
}) => {
  const classes = useStyles(isFilter);
  const options = {
    'data-seleniumid': seleniumId,
    inputProps: { id: `${seleniumId}-input`, 'data-seleniumid': `${seleniumId}-input` },
    ...optionsOverrides.inputProps,
    ...optionsOverrides,
  };

  return (
    <RASelectInput
      {...props}
      allowEmpty={false}
      className={classes.root}
      variant="standard"
      options={options}
    />
  );
};

SelectInput.propTypes = {
  allowEmpty: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isFilter: PropTypes.bool,
  options: PropTypes.object,
  seleniumId: PropTypes.string.isRequired,
};

SelectInput.defaultProps = {
  allowEmpty: false,
  helperText: false,
  isFilter: false,
  options: {},
};

export default SelectInput;
