import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { ReferenceField as RaReferenceField } from 'react-admin';

import { resources } from 'api/DataProvider/handlers/hardcoded-dictionaries';
import { sanitizeProps } from '_ui/Field/utils';

const ReferenceField = props => {
  const { children, reference } = props;

  if (process.env.NODE_ENV === 'test' && reference in resources) {
    return cloneElement(children, { ...props });
  }

  return (
    <RaReferenceField {...sanitizeProps(props)}>
      {children}
    </RaReferenceField>
  );
};

ReferenceField.propTypes = {
  addLabel: PropTypes.bool,
  children: PropTypes.element,
  ...RaReferenceField.propTypes,
};

ReferenceField.defaultProps = {
  ...ReferenceField.defaultProps,
  addLabel: true,
};

export default ReferenceField;
