import React from 'react';
import {
  SimpleShowLayout as RASimpleShowLayout,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    padding: '0 !important',
    '&first-child': {
      padding: '0 !important',
    },
    '&last-child': {
      padding: '0 !important',
    },
    textAlign: 'justify',
    wordWrap: 'break-word',
  },
});

const SimpleShowLayout = props => {
  const classes = useStyles();
  return (
    <RASimpleShowLayout {...props} className={classes.root} />
  );
};

export default SimpleShowLayout;
