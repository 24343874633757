import { loadingResource } from 'ra-redux/loading';

export default () => (next) => (action) => {
  next(action);

  const { type, meta } = action;
  const { resource } = meta || {};
  if (type === 'RA/CRUD_GET_LIST_LOADING') {
    next(loadingResource({ resource, flag: true }));
  }
  if (type === 'RA/CRUD_GET_LIST_SUCCESS' || type === 'RA/CRUD_GET_LIST_FAILURE') {
    next(loadingResource({ resource, flag: false }));
  }
};
