import React from 'react';
import PropTypes from 'prop-types';
import { SelectArrayInput as RaSelectArrayInput } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: { minWidth: 200 },
}, { name: 'SelectArrayInput' });

const SelectArrayInput = ({
  seleniumId,
  options: optionsOverrides,
  ...props
}) => {
  const classes = useStyles();
  const options = {
    ...optionsOverrides,
    inputProps: { 'data-seleniumid': seleniumId, classes, ...optionsOverrides.InputProps },
  };

  return <RaSelectArrayInput options={options} {...props} />;
};

SelectArrayInput.propTypes = {
  ...RaSelectArrayInput.proptTypes,
  seleniumId: PropTypes.string.isRequired,
};

SelectArrayInput.defaultProps = RaSelectArrayInput.defaultProps;

export default SelectArrayInput;
