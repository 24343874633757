import {
  showNotification,
  FETCH_ERROR,
} from 'react-admin';

const allowNotificationsRegex = [
  /login$/,
  /learning-objects\/[^/]+\/show\/[^/]+$/,
];

export default (store) => (next) => (action) => {
  const { type } = action;

  if (type === FETCH_ERROR) {
    const { router: { location: { pathname } } } = store.getState();
    if (!allowNotificationsRegex.some(regex => regex.test(pathname))) {
      return next({ type: FETCH_ERROR, error: {} });
    }

    const { error: { status, message: messageOverride, body } = {} } = action;
    if (!messageOverride) return next(action);

    const transactionId = body?.headers.get('transaction-id');

    const prefix = status || transactionId
      ? `(
        ${status || ''}
        ${transactionId || ''}
      )`
      : '';
    next({
      type: FETCH_ERROR,
      error: {},
    });

    const message = `${prefix}${messageOverride.slice(0, 100)}}`;
    return next(showNotification(message, 'warning'));
  }

  return next(action);
};
