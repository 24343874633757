import React from 'react';
import PropTypes from 'prop-types';
import { FunctionField } from 'react-admin';

const TruncatedTextField = ({ maxLength, ...fieldProps }) => {
  const { source } = fieldProps;

  return (
    <FunctionField
      {...fieldProps}
      render={(record) => {
        if (!record || !record[source]) return '';
        return (
          record[source].length > maxLength
            ? `${[...record[source]].slice(0, maxLength).join('')}...`
            : record[source]
        );
      }}
    />
  );
};

TruncatedTextField.propTypes = {
  addLabel: PropTypes.bool,
  maxLength: PropTypes.number,
};

TruncatedTextField.defaultProps = {
  maxLength: 100,
  addLabel: true,
};

export default TruncatedTextField;
