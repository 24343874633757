/* eslint-disable react/prop-types */

import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, lighten } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignSelf: 'center',
  },
  filter: {
    '& > button': {
      minWidth: '9rem',
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${lighten(theme.palette.primary.main, 0.5)}`,
      '&:hover': {
        backgroundColor: `${lighten(theme.palette.primary.light, 0.95)} !important`,
      },
    },
  },
}));

const ListActions = ({
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {
        filters && cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
          className: classes.filter,
        })
      }
      {children}
    </div>
  );
};

ListActions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.element,
    ),
  ]),
};

ListActions.defaultProps = {
  children: null,
};

export default ListActions;
