import React from 'react';
import {
  ReferenceField,
  TextField,
  FunctionField,
  ShowController,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

import {
  DateField,
  Grid,
  GridTile,
  UserField,
  ViewPaper,
  ViewCard,
} from '_ui';

import {
  SourceField,
  TargetField,
  ImportLogs,
  ActionField,
} from './Fields';

const GeneralCard = (props) => (
  <ViewCard
    {...props}
    headerTitle="General"
    data-seleniumid="import-general"
  >
    <ReferenceField source="type" reference="import-types" link={false}>
      <TextField source="name" />
    </ReferenceField>
    <FunctionField label="Source" render={SourceField} />
    <TargetField source="targets" />
    <ReferenceField source="status" reference="import-statuses" link={false}>
      <TextField source="name" data-seleniumid="import-status" />
    </ReferenceField>
  </ViewCard>
);

const CreatorCard = (props) => (
  <ViewCard
    {...props}
    headerTitle="Creator"
    data-seleniumid="import-creator"
  >
    <UserField
      label="Creator"
      seleniumId="import-creator"
      source="creator"
    />
    <DateField source="created" />
  </ViewCard>
);

const LogsCard = (props) => (
  <ViewCard
    {...props}
    headerTitle="Import Report"
    data-seleniumid="import-report"
  >
    <ImportLogs addLabel={false} />
  </ViewCard>
);

const ImportView = props => (
  <ViewPaper>
    <Grid spacing={1} direction="column">
      <ShowController {...props}>
        {(viewProps) => (
          <>
            <Box textAlign="end" padding="1rem 0 0">
              <ActionField {...viewProps} />
            </Box>
            <Grid spacing={1}>
              <GridTile xs={12} md={6}>
                <GeneralCard {...props} {...viewProps} />
              </GridTile>

              <GridTile xs={12} md={6}>
                <CreatorCard {...props} {...viewProps} />
              </GridTile>

              <GridTile xs={12} md={12}>
                <LogsCard {...props} {...viewProps} />
              </GridTile>
            </Grid>
          </>
        )}
      </ShowController>
    </Grid>
  </ViewPaper>
);

ImportView.propTypes = {
  resource: PropTypes.string,
};

export default ImportView;
