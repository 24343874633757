export const sanitizeProps = ({
  languageSpecificFields,
  languageAgnosticFields,
  hasCreate,
  hasShow,
  hasEdit,
  hasList,
  perPage,
  addLabel,
  ...props
}) => props;
