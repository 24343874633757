import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useGetMany } from 'react-admin';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeletedIcon from '@material-ui/icons/Delete';
import { sortLocales } from 'utils/locales';
import { setLocales } from 'ra-redux/locales';
import { getCapLabel } from 'utils/strings';
import { WarningField } from '_ui';

export const MAX_TARGETS = 5;

const Target = ({ addLink, target }) => {
  const dispatch = useDispatch();
  const { type, id, locale } = target;
  let { code } = target;
  const seleniumId = `target-${id}`;
  const resource = `${type.replace('_', '-').toLowerCase()}s`;
  const { data: [entry] = [], error, loaded } = useGetMany(resource, [id]);
  const { code: entryCode, availableLocales = [] } = entry || {};

  if (!code && entryCode) code = entryCode;
  if (!code) return null;

  const label = [code, locale].filter(Boolean).join('/');

  const onClickLink = (event) => {
    if (!locale) dispatch(setLocales(sortLocales(availableLocales).slice(0, 1)));
    event.stopPropagation();
  };

  switch (true) {
    case !loaded: return (
      <LinearProgress data-seleniumid={`${seleniumId}-loading`} />
    );
    case loaded && !error: {
      const isDeleted = !entry || (locale && !availableLocales?.includes(locale));
      if (isDeleted) {
        const title = `${type && `${getCapLabel(type.replace('_', ' '))} `}${label} has been deleted`;
        return (
          <WarningField
            title={title}
            label={label}
            seleniumId={seleniumId}
            iconProps={{
              component: DeletedIcon,
              color: 'disabled',
            }}
          />
        );
      }

      if (addLink) {
        const to = `/${[
          `${type.replace('_', '-').toLowerCase()}s`,
          id,
          'show',
          locale,
        ].filter(Boolean).join('/')}`;
        return (
          <span title={label} onClick={onClickLink}>
            <Link to={to} data-seleniumid={`${seleniumId}-link`}>{label}</Link>
          </span>
        );
      }

      return <span data-seleniumId={`${seleniumId}-label`} title={label}>{label}</span>;
    }
    default: return null;
  }
};

Target.propTypes = {
  addLink: PropTypes.bool.isRequired,
  target: PropTypes.shape({
    code: PropTypes.string,
    id: PropTypes.string.isRequired,
    locale: PropTypes.string,
    type: PropTypes.string.isRequired,
  }),
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    textAlign: 'center',
  },
  button: {
    width: '3rem',
  },
  collapse: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '& .MuiCollapse-wrapperInner': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  icon: ({ isOpen }) => ({
    transition: theme.transitions.create('transform'),
    transform: `rotate(${isOpen ? '-180deg' : '0deg'})`,
  }),
}));

const TargetField = ({ addLink, record, source }) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const classes = useStyles({ isOpen: expanded });

  const targets = useMemo(() => (
    record?.[source] || []
  ).reduce((acc, target, i) => {
    const { id } = target;
    const Element = (
      <Target
        key={`${id}-target`}
        addLink={addLink}
        target={target}
      />
    );

    const key = i < MAX_TARGETS ? 'visible' : 'collapsed';
    if (!acc[key]) acc[key] = [];
    acc[key].push(Element);

    return acc;
  }, {}), [addLink, record?.[source]]);

  if (!targets.visible) return null;

  return (
    <Box
      displa="flex"
      flexWrap="wrap"
      flexDirection="column"
      margin={theme.spacing(1, 0)}
    >
      {targets.visible}
      {
        targets.collapsed && (
          <>
            <Collapse
              className={classes.collapse}
              in={expanded}
              data-seleniumid={`target-${record.id}-collapse`}
            >
              {targets.collapsed}
            </Collapse>
            <div className={classes.buttonContainer}>
              <IconButton
                className={classes.button}
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded(!expanded);
                }}
                aria-expanded={expanded}
                data-seleniumid={`expand-target-${record?.id}`}
                aria-label="show more"
              >
                <ExpandMoreIcon classes={{ root: classes.icon }} />
              </IconButton>
            </div>
          </>
        )
      }
    </Box>
  );
};

TargetField.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  addLabel: PropTypes.bool,
  addLink: PropTypes.bool,
  /* eslint-enable */
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

TargetField.defaultProps = {
  addLabel: true,
  addLink: true,
  record: undefined,
};

export default TargetField;
