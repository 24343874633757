/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ReferenceField } from 'react-admin';

import { getLocales } from 'ra-redux/locales';
import { getRelevantLocale, getAvailablePriorityLocale } from 'utils/locales';
import { sanitizeProps } from '_ui/Field/utils';

const LocaleReferenceField = ({ translateChoice, usePriorityLocale, ...props }) => {
  const { record } = props;
  const selectedLocales = useSelector(getLocales);

  if (!record) return null;

  const { id, availableLocales } = record;
  const locale = usePriorityLocale ? getAvailablePriorityLocale(availableLocales) : getRelevantLocale(
    selectedLocales.length ? [selectedLocales[0]] : [], availableLocales);

  if (!locale) return null;

  return (
    <ReferenceField
      key={`${id}_${locale}-ReferenceField`}
      {...sanitizeProps(props)}
      record={{
        ...record,
        id: `${id}_${locale}`,
      }}
    />
  );
};

LocaleReferenceField.propTypes = {
  addLabel: PropTypes.bool,
  usePriorityLocale: PropTypes.bool,
};

LocaleReferenceField.defaultProps = {
  usePriorityLocale: true,
  addLabel: true,
};

LocaleReferenceField.displayName = 'LocaleReferenceField';

export default LocaleReferenceField;
