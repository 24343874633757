import { getReferenceResource } from 'react-admin';

import get from 'lodash/get';
import filter from 'lodash/filter';
import matches from 'lodash/matches';

import ls from 'api/ls';
import { DEFAULT_LOCALE, sortLocales } from 'utils/locales';

const SET_LOCALES = 'SET_LOCALE';
const savedLocales = ls.getJSON('locales');

const initialState = {
  locales: savedLocales || [DEFAULT_LOCALE],
  isDefault: !savedLocales,
};

/**
 * Store contains locale based on which we can choose the relevant language for language dependent fields
 */
export default (state = initialState, { type, payload }) => {
  let locales;
  let isDefault;

  switch (type) {
    case SET_LOCALES:
      isDefault = !(payload?.length);
      if (!isDefault) {
        locales = Array.isArray(payload)
          ? payload
          : [payload];
      }
      else {
        locales = [DEFAULT_LOCALE];
      }

      locales = locales.length > 1 ? sortLocales(locales) : locales;
      ls.setJSON('locales', locales);

      return {
        locales,
        isDefault,
      };
    default:
      return state;
  }
};

export const setLocales = (payload) => ({ type: SET_LOCALES, payload });
export const getLocales = (state) => state.locales.locales;
export const getIsDefaultLocale = (state) => state.locales.isDefault;

export const getLocaleRecords = (resource) => (state) => get(getReferenceResource(state, { reference: `${resource}-locales` }), 'data');
export const getLocaleRecord = (resource, localeId) => (state) => (
  getLocaleRecords(resource)(state)?.[localeId] || {}
);

export const getFilteredLocales = (resource, filterValues) => (state) => (
  filter(getLocaleRecords(resource)(state), matches(filterValues))
);
