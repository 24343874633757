import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles, lighten } from '@material-ui/core/styles';

import ProgressButton from '_ui/Button/ProgressButton';
import { useGetResourceSelector } from 'ra-redux/selectors';
import { EXPORT_TYPES, useExport } from 'utils/export';

import { EXPORT_FIELDS_MAP } from '../constants';
import { prepareLogsForExport } from '../utils';

const useGetCreator = (id) => {
  const usersData = useGetResourceSelector('users');
  const systemsData = useGetResourceSelector('systems');
  const entry = usersData[id] || systemsData[id];
  return entry?.name || entry?.email;
};

const useStyles = makeStyles(theme => ({
  lightBtn: {
    backgroundColor: lighten(theme.palette.primary.main, 0.15),
  },
}));

const Field = ({ record }) => {
  const { id, creator: creatorId } = record;
  const [stateExportType, setStateExportType] = useState(null);

  const finishExport = useCallback(() => {
    setStateExportType(null);
  }, []);

  const creator = useGetCreator(creatorId);
  const getExportEntities = useCallback(({ detailsEntities, ...options }) => prepareLogsForExport(
    [record],
    detailsEntities.map(entry => ({ ...entry, creator })),
    options,
  ), [record, creator]);

  const { inProgress } = useExport({
    resource: 'imports',
    detailResource: 'import-logs',

    exportType: stateExportType,
    exportFields: EXPORT_FIELDS_MAP,
    onFulfill: finishExport,
    getMainEntityFilter: () => ({ id }),
    entitiesIds: [id],

    getExportEntities,
  });

  const triggerExport = useCallback((exportType) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    setStateExportType(exportType);
  }, []);

  const classes = useStyles();

  return (
    <>
      <ButtonGroup variant="text" color="primary" data-seleniumid={`${id}-export-group`}>
        {
          EXPORT_TYPES.map((exportType, i) => {
            const seleniumId = `${id}-${exportType}-export-button`;
            const btnProps = {
              seleniumId,
              label: exportType,
              loading: exportType === stateExportType && inProgress,
              onClick: triggerExport(exportType),
              variant: 'text',
            };

            if (!(i % 2)) btnProps.className = classes.lightBtn;

            return <ProgressButton key={seleniumId} {...btnProps} />;
          })
        }
      </ButtonGroup>
    </>
  );
};

Field.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
};

Field.defaultProps = {
  record: {},
};

const ExportField = ({ record }) => {
  const { status } = record;
  const { id: IN_PROGRESS } = useSelector((state) => get(state, 'admin.resources.import-statuses.data.IN_PROGRESS', {}));

  return (status !== IN_PROGRESS) ? <Field record={record} /> : null;
};

ExportField.propTypes = Field.propTypes;

ExportField.defaultProps = Field.defaultProps;

export default ExportField;
