import React from 'react';
import { RichTextField } from 'react-admin';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import {
  Grid,
  GridTile,
  ViewCard,
  LocaleReferenceField,
  TextField,
  BooleanField,
  EmptyProgressReferenceField,
  DateField,
  UserField,
} from '_ui';

import { ThumbInput } from '../../Input';
import { StateField } from '../../Fields';
import { markComponentRequiredForPublish } from '../Actions/utils';
import RequiredAnnotation from '../Actions/RequiredAnnotation';
import AuthorCard, { EmptyAuthor } from './AuthorCard';
import PublicationsCard from './PublicationsCard';

const renderBaseLocaleReferencesFields = list => list.map(([
  label,
  source,
  seleniumId,
  Component = TextField,
]) => (
  markComponentRequiredForPublish(
    <LocaleReferenceField
      key={`lo-locale-field-${source}`}
      addLabel
      link={false}
      source="id"
      label={label}
      reference="learning-objects-locales"
      usePriorityLocale={false}
    >
      <Component source={source} data-seleniumid={seleniumId} />
    </LocaleReferenceField>,
  )
));

const PublicationDataCard = props => (
  <ViewCard {...props} headerTitle="Publication data" data-seleniumid="lo-locale-publication-data-card">
    {renderBaseLocaleReferencesFields([
      ['Title', 'title', 'lo-locale-title'],
      ['In brief', 'brief', 'lo-locale-brief', RichTextField],
      ['What you will learn', 'briefSummary', 'lo-locale-briefSummary', RichTextField],
      ['Included in this learning resource', 'includedSummary', 'lo-locale-includedSummary', RichTextField],
      ['Target audience', 'audience', 'lo-locale-audience', RichTextField],
    ])}
  </ViewCard>
);

const useStyles = makeStyles({
  thumbnail: {
    margin: 0,
    padding: 0,
  },
});

const PublicationInfoCard = props => {
  const classes = useStyles();
  const fieldComponents = [
    <LocaleReferenceField
      key="state"
      usePriorityLocale={false}
      label="State"
      source="id"
      reference="learning-objects-locales"
      link={false}
    >
      <EmptyProgressReferenceField source="state" reference="learning-object-states" link={false}>
        <StateField source="name" data-seleniumid="lo-locale-state" />
      </EmptyProgressReferenceField>
    </LocaleReferenceField>,
    <LocaleReferenceField
      key="thumbnailUrl"
      usePriorityLocale={false}
      source="id"
      label="Thumbnail"
      reference="learning-objects-locales"
      link={false}
      className={classes.thumbnail}
    >
      <ThumbInput />
    </LocaleReferenceField>,
    <LocaleReferenceField
      key="editorialStatus"
      usePriorityLocale={false}
      source="id"
      label="Editorial Status"
      reference="learning-objects-locales"
      link={false}
    >
      <EmptyProgressReferenceField
        reference="editorial-statuses"
        source="editorialStatus"
        sort={{ field: 'name', order: 'DESC' }}
        allowEmpty
        link={false}
      >
        <TextField source="name" data-seleniumid="lo-locale-editorialStatus" />
      </EmptyProgressReferenceField>
    </LocaleReferenceField>,
    <LocaleReferenceField
      key="firstPublished"
      usePriorityLocale={false}
      source="id"
      label="First Published"
      reference="learning-objects-locales"
      link={false}
    >
      <DateField source="firstPublished" data-seleniumid="lo-locale-first-publish" />
    </LocaleReferenceField>,
    <LocaleReferenceField
      key="lastPublished"
      usePriorityLocale={false}
      source="id"
      label="Last Published"
      reference="learning-objects-locales"
      link={false}
    >
      <DateField source="lastPublished" data-seleniumid="lo-locale-last-publish" />
    </LocaleReferenceField>,
    <LocaleReferenceField
      key="publicationVersion"
      usePriorityLocale={false}
      label="Version"
      source="id"
      reference="learning-objects-locales"
      link={false}
    >
      <TextField source="publicationVersion" data-seleniumid="lo-locale-version" />
    </LocaleReferenceField>,
  ];

  return (
    <ViewCard {...props} headerTitle="Publication info" data-seleniumid="lo-locale-publication-info-card">
      {fieldComponents.map(markComponentRequiredForPublish)}
    </ViewCard>
  );
};

const PlayerCard = props => (
  <ViewCard {...props} direction="row" headerTitle="CK Player" data-seleniumid="lo-locale-player-card">
    {renderBaseLocaleReferencesFields([
      ['Has video', 'hasVideo', 'lo-locale-has-video', BooleanField],
      ['Has audio', 'hasAudio', 'lo-locale-has-audio', BooleanField],
      ['Duration', 'duration', 'lo-locale-duration', TextField],
      ['Interactivity', 'interactivity', 'lo-locale-interactivity', TextField],
      ['Level', 'level', 'lo-locale-level', TextField],
    ])}
  </ViewCard>
);

const ModificationCard = props => (
  <ViewCard {...props} headerTitle="Modification Data" data-seleniumid="lo-locale-modifications-card">
    <LocaleReferenceField
      usePriorityLocale={false}
      source="id"
      reference="learning-objects-locales"
      link={false}
      addLabel={false}
    >
      <UserField
        label="Creator"
        seleniumId="lo-locale-publisher"
        source="creatorLocale"
      />
    </LocaleReferenceField>

    <LocaleReferenceField
      usePriorityLocale={false}
      source="id"
      label="Created"
      reference="learning-objects-locales"
      link={false}
    >
      <DateField source="createdLocale" data-seleniumid="lo-locale-published" />
    </LocaleReferenceField>

    <LocaleReferenceField
      usePriorityLocale={false}
      source="id"
      reference="learning-objects-locales"
      link={false}
      addLabel={false}
    >
      <UserField
        label="Modifier"
        seleniumId="lo-locale-modifier"
        source="modifierLocale"
      />
    </LocaleReferenceField>

    <LocaleReferenceField
      usePriorityLocale={false}
      source="id"
      label="Modified"
      reference="learning-objects-locales"
      link={false}
    >
      <DateField source="modifiedLocale" data-seleniumid="lo-locale-modified" />
    </LocaleReferenceField>
  </ViewCard>
);

const LocalesView = (props) => (
  <>
    <Grid spacing={1} direction="column">
      <Grid xs={12}>
        <Box marginTop={2} marginLeft={2}>
          <RequiredAnnotation />
        </Box>
      </Grid>
      <Grid spacing={1}>
        <GridTile xs={12} md={4}>
          <PublicationDataCard {...props} />
        </GridTile>
        <GridTile xs={12} md={4}>
          <PublicationInfoCard {...props} />
        </GridTile>
        <GridTile xs={12} md={4}>
          <PlayerCard {...props} />
          <ModificationCard {...props} />
        </GridTile>
        <GridTile xs={12}>
          <div data-seleniumid="lo-author-card">
            <ViewCard {...props} direction="column" headerTitle="Author">
              {props?.record?.authorId
                ? (<AuthorCard {...props} usePriorityLocale={false} />)
                : <EmptyAuthor {...props} /> }
            </ViewCard>
          </div>
        </GridTile>
        <GridTile xs={12}>
          <PublicationsCard {...props} />
        </GridTile>
      </Grid>
    </Grid>
  </>
);

export default LocalesView;
