import React from 'react';
import {
  SimpleForm,
} from 'react-admin';
import { getLocaleModifyRedirectHref } from 'utils';
import {
  Edit,
  TextField,
  TextInput,
  ToolBar,
  ReferenceField,
} from '_ui';

const ThemesEditLocale = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm toolbar={<ToolBar />} redirect={getLocaleModifyRedirectHref}>
      <ReferenceField
        label="Parent Code"
        source="parentId"
        link={false}
        reference="themes"
        seleniumId="themes-locale-modify-parent-code"
      >
        <TextField source="code" />
      </ReferenceField>
      <TextField source="locale" label="Language" seleniumId="themes-locale-modify-locale" />
      <TextInput
        source="title"
        seleniumId="themes-locale-modify-title"
        inputProps={{ autoComplete: 'off' }}
      />
    </SimpleForm>
  </Edit>
);

export default ThemesEditLocale;
