import { useState, useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { isPristine } from './utils';

const usePristineForm = (record) => {
  const { values } = useFormState();
  const [pristine, setPristine] = useState(true);

  useEffect(() => {
    setPristine(isPristine(record, values));
  }, [values]);

  return pristine;
};

export default usePristineForm;
