import React, { useState } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';

import ConfirmationModal from '../Layout/Modals/ConfirmationModal';
import ProgressButton from './ProgressButton';

const DeleteProgressButton = withStyles(theme => ({
  root: {
    '&:hover $label': {
      color: theme.palette.error.main,
    },
  },
  label: {
    color: 'inherit',
  },
}))(ProgressButton);

const DeleteButton = ({
  iconProps,
  modalProps: { onSubmit: onSubmitOverride, ...modalProps },
  label,
  ...buttonProps
}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  return (
    <>
      <DeleteProgressButton title={label} {...buttonProps} onClick={toggleModal}>
        <DeleteIcon {...iconProps} />
      </DeleteProgressButton>
      <ConfirmationModal
        open={showModal}
        onCancel={toggleModal}
        onSubmit={onSubmitOverride(toggleModal)}
        {...modalProps}
      />
    </>
  );
};

DeleteButton.propTypes = {
  ...omit(ProgressButton.propTypes, ['onClick']),
  iconProps: PropTypes.shape(DeleteIcon.propTypes),
  modalProps: PropTypes.shape(omit(ConfirmationModal.propTypes, ['open'])),
};

DeleteButton.defaultProps = {
  ...ProgressButton.defaultProps,
  iconProps: DeleteIcon.defaultProps,
  modalProps: ConfirmationModal.defaultProps,
  component: IconButton,
  variant: 'outlined',
  label: 'Delete',
};

export default DeleteButton;
