/* eslint-disable prefer-const */
import { LOCATION_CHANGE } from 'connected-react-router';
import { LR } from 'constants/resources';
import { CLEAR_EXPORT } from 'ra-redux/exportList';
import { parseLocations, isSearchUpdated } from './utils';

const ignoreScormPaths = ['/', '/login'];

const lrResource = `/${LR}`;

const isIgnoredPaths = (pathname, oldPathname) => !oldPathname
  || ignoreScormPaths.includes(pathname)
  || ignoreScormPaths.includes(oldPathname);
const isOpenLrFromMenu = (toggleFromMenu, pathname) => toggleFromMenu && pathname === lrResource;
const isOnLrList = (oldPathname, pathname) => [oldPathname, pathname].every(el => el === lrResource);

const handleScormExport = (state, next, payload) => {
  const { pathname, search, toggleFromMenu, oldPathname, oldSearch } = parseLocations(state, payload);
  if (isIgnoredPaths(pathname, oldPathname)) return;
  if (isOpenLrFromMenu(toggleFromMenu, pathname)) return next({ type: CLEAR_EXPORT });
  if (!isOnLrList(oldPathname, pathname)) return;

  if (isSearchUpdated({ search, oldSearch })) {
    return next({ type: CLEAR_EXPORT });
  }
};

export default (store) => (next) => (action) => {
  const { type, payload } = action;

  if (type === LOCATION_CHANGE) {
    const state = store.getState();
    handleScormExport(state, next, payload);
  }

  next(action);
};
