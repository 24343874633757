import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceArrayField, SingleFieldList } from 'react-admin';
import { DrillDownLinkField } from '_ui';

const LearningResourceLinkedField = ({ seleniumId, ...props }) =>
  (
    <ReferenceArrayField {...props} reference="learning-objects">
      <SingleFieldList linkType={false}>
        <DrillDownLinkField targetResource="learning-objects" source="code" seleniumId={seleniumId} />
      </SingleFieldList>
    </ReferenceArrayField>
  );

LearningResourceLinkedField.propTypes = {
  addLabel: PropTypes.bool,
  label: PropTypes.string,
  seleniumId: PropTypes.string,
};

LearningResourceLinkedField.defaultProps = {
  addLabel: true,
  label: 'Action Tips',
};

export default LearningResourceLinkedField;
