import { REGEX_LR_CODE_WITH_LOCALE } from 'constants/regex';
import { EXPORT_FIELDS_MAP as DEFAULT_EXPORT_FIELDS_MAP } from 'utils/export';

export const IMPORT_HIDE_DURATION = 20e3;

export const IMPORT_STATUSES = {
  SUCCESSFUL: 'SUCCESSFUL',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  PARTIAL: 'PARTIAL',
};

const GMs = [
  { id: 'author', name: 'Authors' },
  { id: 'theme', name: 'Themes' },
  { id: 'tag', name: 'Tags' },
  { id: 'program', name: 'Programs' },
  { id: 'discipline', name: 'Disciplines' },
  { id: 'skillset', name: 'Skill Sets' },
];

export const METADATA_OPTIONS = (type) => {
  switch (type) {
    case 'GlobalMetadata':
      return GMs;
    case 'LOAssociations':
      return [
        ...GMs,
        { id: 'essentialsAndActionTips', name: 'Essentials and Action Tips' },
      ];
    default:
      return [];
  }
};

export const fileImportType = {
  LCMS: 'lcms',
  MOHIVE: 'mohive',
  LO_INGESTION: 'LOIngestion',
  Ingestion: 'Ingestion',
  LO_PUBLISH: 'LOPublish',
  LO_METADATA: 'LOMetadata',
  LO_THUMBNAIL: 'LOThumbnail',
  LO_ASSOCIATIONS: 'LOAssociations',
  GLOBAL_METADATA: 'GlobalMetadata',
  LO_EXPORT: 'LOExport',
  VideoUpload: 'VideoUpload',
  SubtitleUpload: 'SubtitleUpload',
  PdfUpload: 'PdfUpload',
};
export const EXCLUDE_TYPES = [fileImportType.LCMS, fileImportType.MOHIVE, fileImportType.Ingestion];

export const META_FILE_TYPES = ['.xlsx', '.xls', '.csv'];
const META_TYPES_LIST_STR = META_FILE_TYPES.join();
export const CONTENT_TO_FILE_TYPE_MAP = {
  [fileImportType.LO_THUMBNAIL]: 'image/jpeg',
  [fileImportType.LO_ASSOCIATIONS]: '.csv',
  [fileImportType.LO_EXPORT]: '.csv',
  [fileImportType.LO_INGESTION]: '.zip',
  [fileImportType.LO_METADATA]: META_TYPES_LIST_STR,
  [fileImportType.LO_PUBLISH]: META_TYPES_LIST_STR,
  [fileImportType.GLOBAL_METADATA]: META_TYPES_LIST_STR,
  [fileImportType.VideoUpload]: '.mp4',
  [fileImportType.SubtitleUpload]: ['.vtt', '.srt'],
  [fileImportType.PdfUpload]: '.pdf',
};

export const REGEX_FILENAME_IMPORT = new RegExp(`^p?${REGEX_LR_CODE_WITH_LOCALE.source}$`);
export const EXPORT_FIELDS_MAP = { ...DEFAULT_EXPORT_FIELDS_MAP, creator: 'Creator' };
