/* eslint-disable react/jsx-key,no-unused-vars */

import React, { useState, useMemo } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Route } from 'react-router';
import { Admin, Resource, registerResource } from 'react-admin';
import { createHashHistory } from 'history';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import AuthorsIcon from '@material-ui/icons/School';
import LanguageIcon from '@material-ui/icons/Language';
import LearningResourceIcon from '@material-ui/icons/VideoLibrary';
import ThemesIcon from '@material-ui/icons/Apps';
import TagsIcon from '@material-ui/icons/LocalOffer';
import SkillsIcon from '@material-ui/icons/PermDataSetting';
import ProgramsIcon from '@material-ui/icons/Book';
import UploadIcon from '@material-ui/icons/Unarchive';
import LayersIcon from '@material-ui/icons/Layers';
import TemplatesIcon from '@material-ui/icons/FileCopy';
import CategoryIcon from '@material-ui/icons/Category';

import { customIconColors } from '_ui/Layout/Menu/subMenusConfig';
import { ROUTE_PATTERNS } from 'constants/routes';
import {
  LR_PREVIEW,
  LR_PREVIEW_VIDEO,
  LR_VIDEO_CONTENT_LIST,
  PUBLICATION_PREVIEW,
  EXPORTS,
  AUTHORS,
  THEMES,
  TAGS,
  SKILLSETS,
  PROGRAMS,
  DISCIPLINES,
} from 'constants/resources';
import dataProvider from 'api/DataProvider';
import authProvider from 'api/auth/authProvider';
import { NotFound, NotPermitted } from '_domains/ErrorPages';
import { UserLogin } from '_domains/User';
import { Layout } from '_ui';
import * as customReducers from 'ra-redux';

import { importsUploadSaga } from 'ra-sagas';

// TODO 1.0+ CPP2-1089
import { ROLE_ADMIN, ROLE_MANAGER } from 'constants/userRoles';

import {
  AuthorList,
  AuthorView,
  AuthorEdit,
  AuthorCreate,
  AuthorEditLocale,
  AuthorCreateLocale,
} from '_domains/Author';

import {
  LanguagesList,
  LanguagesView,
  LanguagesEdit,
  LanguagesCreate,
} from '_domains/Languages';

import {
  LearningResourceList,
  LearningResourceView,
  LearningResourceEdit,
  LearningResourceEditLocale,
} from '_domains/LearningResource';

import {
  TagList,
  TagView,
  TagEdit,
  TagCreate,
  TagEditLocale,
  TagCreateLocale,
} from '_domains/Tags';

import {
  ThemesList,
  ThemesView,
  ThemesEdit,
  ThemesCreate,
  ThemesEditLocale,
  ThemesCreateLocale,
} from '_domains/Themes';

import {
  DisciplinesList,
  DisciplineView,
  DisciplineLocaleEdit,
  DisciplineLocaleCreate,
} from '_domains/Disciplines';

import {
  SkillsList,
  SkillView,
  SkillLocaleEdit,
  SkillLocaleCreate,
  SkillEdit,
  SkillCreate,
} from '_domains/Skillsets';

import {
  ProgramsList,
  ProgramsView,
  ProgramsEdit,
  ProgramsCreate,
  ProgramsCreateLocale,
  ProgramsEditLocale,
} from './_domains/Programs';

import {
  ExportsList,
  ImportList,
  ImportView,
  ImportsCreate,
  PublicationsList,
  PublicationsView,
} from './_domains/Jobs';
import createAdminStore from './createAdminStore';

const history = createHashHistory();
const StubComponent = () => null;

const defaultTheme = createMuiTheme();
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1500,
    xl: 1920,
  },
};
export const theme = createMuiTheme({
  breakpoints,
  zIndex: {
    appBar: defaultTheme.zIndex.drawer + 1,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 25,
        minWidth: 95,
        padding: '0.25rem 1rem !important',
        marginLeft: '0.5rem',
        '&$disabled': {
          backgroundColor: '#eee',
          border: '1px solid #aeaeae',
          color: '#000 !important',
        },
        '&.MuiButtonGroup-grouped': {
          marginLeft: 0,
        },
        [`@media (max-width: ${breakpoints.values.lg}px)`]: {
          fontSize: '0.75rem',
        },
      },
      textPrimary: {
        color: `${defaultTheme.palette.common.white} !important`,
        backgroundColor: defaultTheme.palette.primary.main,
        border: `1px solid ${defaultTheme.palette.primary.main}`,
        '&:hover': {
          backgroundColor: `${defaultTheme.palette.primary.dark}`,
          borderColor: `${defaultTheme.palette.primary.dark}`,
        },
      },
      textSecondary: {
        backgroundColor: defaultTheme.palette.common.white,
        'box-shadow': `inset 0 0 0 1px ${defaultTheme.palette.primary.main}`,
        color: defaultTheme.palette.primary.main,
        '&:hover': {
          backgroundColor: defaultTheme.palette.common.white,
          'box-shadow': `inset 0 0 0 2px ${defaultTheme.palette.primary.main}`,
        },
      },
      label: {
        'word-break': 'normal',
      },
    },
    MuiCollapse: {
      wrapperInner: {
        display: 'table',
        tableLayout: 'fixed',
      },
    },
    MuiTextField: {
      root: {
        width: '100% !important',
        '& .MuiInputBase-root:not(.MuiInput-multiline):not(.MuiInputBase-adornedStart)': {
          width: '30%',
          minWidth: 300,
        },
      },
    },
    MuiToolbar: {
      root: {
        '& .filter-field': {
          lineHeight: '3.25rem',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '8px 16px',
      },
    },
  },
  palette: {
    state: {
      warning: amber[700],
    },
    icons: customIconColors,
  },
});

const storeProps = {
  dataProvider,
  authProvider,
  customReducers,
  customSagas: [importsUploadSaga],
  history,
};

const messages = { en: englishMessages };
const i18nProvider = polyglotI18nProvider(
  locale => messages[locale],
  'en',
  { allowMissing: true },
);

export const App = () => {
  const dispatch = useDispatch();
  const { resources } = useSelector((state) => state.admin);
  const [emptyRoutes, setEmptyRoutes] = useState({
    403: [],
    404: [],
  });

  const customRoutes = useMemo(
    () => Object.entries(emptyRoutes)
      .reduce((acc, [status, path]) => [
        ...acc,
        <Route
          exact
          path={path}
          component={status === '404' ? NotFound : NotPermitted}
        />,
      ], []),
    [emptyRoutes],
  );

  return (
    <>
      <CssBaseline />
      <Admin
        loginPage={UserLogin}
        layout={Layout}
        theme={theme}
        catchAll={NotFound}
        customRoutes={customRoutes}
        i18nProvider={i18nProvider}
        {...storeProps}
      >
        {
          (permissions) => {
            // TODO 1.0+ CPP2-1089
            const editAllowed = true; // !!_.intersection([ROLE_ADMIN, ROLE_MANAGER], permissions).length;
            const {
              registerResources,
              routeResources,
              routes,
            } = [
              {
                name: 'learning-objects',
                icon: LearningResourceIcon,
                options: { label: 'Learning Resources' },
                list: LearningResourceList,
                show: LearningResourceView,
                edit: editAllowed && LearningResourceEdit,
              },
              {
                name: 'imports/bulk',
                create: editAllowed && ImportsCreate,
              },
              { name: 'import-types' },
              { name: 'import-statuses' },
              { name: 'import-creators' },
              { name: 'import-logs' },
              {
                name: 'imports',
                icon: UploadIcon,
                options: { label: 'Imports' },
                list: ImportList,
                show: ImportView,
                create: editAllowed && ImportsCreate,
              },
              {
                name: 'publications',
                icon: LayersIcon,
                list: PublicationsList,
                show: PublicationsView,
              },
              { name: 'publication-logs' },
              { name: 'publication-types' },
              { name: 'users' },
              {
                name: 'learning-objects-locales',
                options: { label: 'Learning Resource Locale' },
                edit: editAllowed && LearningResourceEditLocale,
              },
              {
                name: AUTHORS,
                icon: AuthorsIcon,
                options: { label: 'Authors' },
                list: AuthorList,
                show: AuthorView,
                edit: editAllowed && AuthorEdit,
                create: editAllowed && AuthorCreate,
              },
              {
                name: 'authors-locales',
                options: { label: 'Author Locale' },
                edit: editAllowed && AuthorEditLocale,
                create: editAllowed && AuthorCreateLocale,
              },
              {
                name: THEMES,
                icon: ThemesIcon,
                options: { label: 'Themes' },
                list: ThemesList,
                show: ThemesView,
                edit: editAllowed && ThemesEdit,
                create: editAllowed && ThemesCreate,
              },
              {
                name: 'themes-locales',
                options: { label: 'Theme Locale' },
                edit: editAllowed && ThemesEditLocale,
                create: editAllowed && ThemesCreateLocale,
              },
              {
                name: TAGS,
                icon: TagsIcon,
                options: { label: 'Tags' },
                list: TagList,
                show: TagView,
                edit: editAllowed && TagEdit,
                create: editAllowed && TagCreate,
              },
              {
                name: 'tags-locales',
                options: { label: 'Tag Locale' },
                edit: editAllowed && TagEditLocale,
                create: editAllowed && TagCreateLocale,
              },

              {
                name: SKILLSETS,
                icon: SkillsIcon,
                options: { label: 'Skill Sets' },
                list: SkillsList,
                show: SkillView,
                edit: editAllowed && SkillEdit,
                create: editAllowed && SkillCreate,
              },
              {
                name: 'skillsets-locales',
                options: { label: 'Skill Locale' },
                edit: editAllowed && SkillLocaleEdit,
                create: editAllowed && SkillLocaleCreate,
              },

              {
                name: PROGRAMS,
                icon: ProgramsIcon,
                options: { label: 'Programs' },
                list: ProgramsList,
                show: ProgramsView,
                edit: editAllowed && ProgramsEdit,
                create: editAllowed && ProgramsCreate,
              },
              {
                name: 'programs-locales',
                options: { label: 'Program Locale' },
                edit: editAllowed && ProgramsEditLocale,
                create: editAllowed && ProgramsCreateLocale,
              },
              {
                name: DISCIPLINES,
                icon: CategoryIcon,
                list: DisciplinesList,
                show: DisciplineView,
              },
              {
                name: 'disciplines-locales',
                options: { label: 'Discipline Locale' },
                edit: editAllowed && DisciplineLocaleEdit,
                create: editAllowed && DisciplineLocaleCreate,
              },
              {
                name: 'languages',
                icon: LanguageIcon,
                options: { label: 'Languages' },
                list: LanguagesList,
                show: LanguagesView,
                edit: editAllowed && LanguagesEdit,
              },
              {
                name: EXPORTS,
                icon: TemplatesIcon,
                options: { label: 'Reports' },
                list: ExportsList,
                edit: editAllowed && StubComponent,
              },
              { name: 'editorial-statuses' },
              { name: 'learning-object-states' },
              { name: 'media-types' },
              { name: 'systems' },
              {
                name: 'disciplines-locales',
                options: { label: 'Discipline Locale' },
              },
              { name: 'publishers' },
              { name: 'publication-creators' },
              { name: PUBLICATION_PREVIEW },
              { name: LR_PREVIEW },
              { name: LR_PREVIEW_VIDEO },
              { name: 'content-names' },
              { name: LR_VIDEO_CONTENT_LIST },
              // TODO 1.0+ CPP2-1089
              // (permissions || []).includes(ROLE_ADMIN) && { name: 'content-names' },
            ].reduce((acc, resource) => {
              const routeList = ['list', 'show', 'edit', 'create'];
              const hasRoutes = routeList.some((route) => (
                route in resource
              ));

              if (hasRoutes) {
                acc.routeResources.push(resource);
                routeList.forEach((route) => {
                  const isEmpty = !resource[route];
                  if (isEmpty) {
                    const status = !(route in resource)
                      ? 404
                      : 403;

                    acc.routes[status].push(
                      ROUTE_PATTERNS[route].replace(':resource', resource.name),
                    );
                  }
                });
              }
              else {
                acc.registerResources.push(resource);
              }

              return acc;
            }, {
              registerResources: [],
              routeResources: [],
              routes: emptyRoutes,
            });

            if (!customRoutes.length && routes) {
              setEmptyRoutes(routes);
            }

            if (registerResources.length) {
              registerResources.forEach((resource) => {
                if (!(resource.name in resources)) {
                  dispatch(registerResource(resource));
                }
              });
            }

            return routeResources.map((props) => <Resource {...props} />);
          }
        }
      </Admin>
    </>
  );
};

const store = createAdminStore(storeProps);
const persistor = persistStore(store);

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

export default Root;
