import React from 'react';
import {
  ArrayInput,
  TextField,
  SimpleForm,
  SimpleFormIterator,
} from 'react-admin';
import { getLocaleModifyRedirectHref } from 'utils';
import {
  ToolBar,
  TextInput,
  ReferenceField,
  Edit,
} from '_ui';
import { formatSynonyms } from './utils';

const TagEditLocale = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm redirect={getLocaleModifyRedirectHref} toolbar={<ToolBar />}>
      <ReferenceField
        label="Tag Code"
        source="parentId"
        link={false}
        reference="tags"
        data-seleniumid="tag-locale-modify-code"
      >
        <TextField source="code" />
      </ReferenceField>
      <TextField source="locale" label="Language" data-seleniumid="tag-locale-modify-locale" />
      <TextInput source="title" seleniumId="tag-locale-modify-title" />
      <ArrayInput source="synonym" label="Synonyms">
        <SimpleFormIterator>
          <TextInput label={false} format={formatSynonyms} seleniumId="tag-locale-modify-synonym" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export default TagEditLocale;
