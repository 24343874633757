import React, { useState, useRef } from 'react';
import {
  SimpleForm,
  FormDataConsumer,
  ReferenceInput,
  required,
  useNotify,
  FileInput,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { Create, AutocompleteInput } from '_ui';
import { makeStyles } from '@material-ui/core';
import {
  isDisplaySubTypes,
  mapContentToFileType,
  isMultipleFiles,
  validateFilenames,
} from '../utils';
import { METADATA_OPTIONS, EXCLUDE_TYPES, IMPORT_HIDE_DURATION } from '../constants';
import FileField from './FileField';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  thumbnailStyle: {
    display: 'block',
    maxWidth: '50%',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

const ImportCreate = (props) => {
  const classes = useStyles();
  const [invalidFileNames, setInvalidFileNames] = useState([]);

  const inputRef = useRef(null);
  const notify = useNotify();

  const handleFileSelection = importType => files => {
    try {
      const invalidFiles = validateFilenames(importType, files);
      setInvalidFileNames(invalidFiles);
    }
    catch ({ message }) {
      notify(message, 'warning', {}, false, IMPORT_HIDE_DURATION);
    }
  };

  return (
    <Create {...props}>
      <SimpleForm toolbar={null}>
        <FormDataConsumer>
          {({ formData }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const form = useForm();
            const isFileInputVisible = !(isDisplaySubTypes(formData) && (!formData.subType)) && formData.type;
            const isUploadAllowed = Array.isArray(formData.source?.file)
              ? formData.source.file.length
              : formData.source?.file;

            return (
              <>
                <input ref={inputRef} style={{ position: 'absolute', zIndex: -1 }} />
                <ReferenceInput
                  label="Type" source="type" reference="import-types"
                  onChange={() => {
                    form.batch(() => {
                      form.change('source.file', null);
                      form.change('subType', null);
                    });
                    inputRef.current.focus();
                  }}
                  validate={required()}
                >
                  <AutocompleteInput
                    sort={{ field: 'name' }}
                    seleniumId="import-type"
                    optionText="name"
                    exclude={EXCLUDE_TYPES}
                  />
                </ReferenceInput>
                {
                  isDisplaySubTypes(formData) && (
                    <AutocompleteInput
                      seleniumId="metadata-type"
                      source="subType"
                      validate={required()}
                      label="Metadata type"
                      sort={{ field: 'name' }}
                      choices={METADATA_OPTIONS(formData?.type)}
                      onChange={() => inputRef.current.focus()}
                    />
                  )
                }
                {
                  isFileInputVisible && (
                    <FileInput
                      onChange={handleFileSelection(formData.type)}
                      validate={required()}
                      source="source.file"
                      accept={mapContentToFileType(formData.type)}
                      label={isMultipleFiles(formData) ? 'Files' : 'File'}
                      multiple={isMultipleFiles(formData)}
                      placeholder={<p>Drop file{isMultipleFiles(formData) && 's'} here or click to upload</p>}
                      helperText={false}
                    >
                      <FileField
                        thumbnailStyle={classes.thumbnailStyle}
                        uploadType={formData.type}
                        source="source.fileName"
                        title="title"
                        invalidFileNames={invalidFileNames}
                        classes={classes.fileField}
                      />
                    </FileInput>
                  )
                }
                <Toolbar
                  disabled={!isUploadAllowed || !!invalidFileNames.length}
                  data={formData}
                />
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default ImportCreate;
