import React from 'react';
import PropTypes from 'prop-types';
import { TextInput as RaTextInput, required } from 'react-admin';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: isFilter => (isFilter ? { margin: 0 } : {}),
});

const TextInput = ({
  helperText,
  options,
  isFilter,
  isRequired,
  seleniumId,
  inputProps,
  name,
  ...props
}) => {
  const classes = useStyles(isFilter);

  return (
    <RaTextInput
      validate={isRequired ? required() : null}
      {...props}
      options={{
        ...options,
        helperText,
      }}
      inputProps={{
        'data-seleniumid': seleniumId,
        name: name || seleniumId,
        ...inputProps,
      }}
      variant="standard"
      className={classes.root}
    />
  );
};

TextInput.propTypes = {
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  inputProps: PropTypes.object,
  isFilter: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.object,
  seleniumId: PropTypes.string.isRequired,
};

TextInput.defaultProps = {
  helperText: false,
  inputProps: {},
  isFilter: false,
  isRequired: false,
  name: null,
  options: {},
};

export default TextInput;
