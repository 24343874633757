import React from 'react';
import PropTypes from 'prop-types';
import { BooleanField as RABooleanField } from 'react-admin';
import { sanitizeProps } from '_ui/Field/utils';

const getRecordWithBoolSource = (record, source) => (Object.prototype.hasOwnProperty.call(record || {}, source) ? record : { ...record, [source]: false });

const BooleanField = ({ seleniumId, record: rawRecord, ...props }) => {
  const { source } = props;
  const record = getRecordWithBoolSource(rawRecord, source);

  return (
    <RABooleanField
      data-seleniumid={seleniumId || `boolean-field-${source}`}
      {...sanitizeProps(props)}
      record={record}
    />
  );
};
BooleanField.propTypes = {
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  seleniumId: PropTypes.string,
  source: PropTypes.string,
};
BooleanField.defaultProps = {
  addLabel: true,
};

export default BooleanField;
