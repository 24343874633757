import addAvailableLocalesDataEntry from './addAvailableLocalesDataEntry';
import createBulkImports from './createBulkImports';
import sortLanguageList from './sortLanguageList';
import sortPublicationsList from './sortPublicationsList';
import sortImportsList from './sortImportsList';
import sortExportsList from './sortExportsList';
import transformFilterParam from './transformFilterParam';
import hardcodedDictionaries from './hardcoded-dictionaries';
import fetchAssociatedContent from './fetchAssociatedContent';
import addLanguageFields from './addLanguageFields';
import publishLocales from './publishLocales';

export default [
  addLanguageFields,
  addAvailableLocalesDataEntry,
  createBulkImports,
  sortLanguageList,
  sortPublicationsList,
  sortImportsList,
  sortExportsList,
  transformFilterParam,
  fetchAssociatedContent,
  hardcodedDictionaries,
  publishLocales,
];
