/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.icons.success,
  },
}));

const DirectField = (props) => {
  const { record: { directlyUploadedVideo, directlyUploadedPdf } } = props;
  const classes = useStyles();
  if (!directlyUploadedVideo && !directlyUploadedPdf) return null;

  return (
    <Icon
      data-seleniumid="publication-direct"
      classes={classes}
    />
  );
};

DirectField.propTypes = {
  addLabel: PropTypes.bool,
  label: PropTypes.string,
  record: PropTypes.shape({
    directlyUploadedPdf: PropTypes.bool,
    directlyUploadedVideo: PropTypes.bool,
  }),
};

DirectField.defaultProps = {
  addLabel: true,
  label: 'Direct',
  record: {},
};

export default DirectField;
