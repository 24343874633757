/* eslint-disable react/prop-types */

import React from 'react';
import { Edit as RaEdit } from 'react-admin';

import { makeStyles } from '@material-ui/core';

import { BackButton } from '_ui/Button';

const useStyles = makeStyles(theme => ({
  root: {
    '& > div': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const Edit = ({ resource, ...props }) => {
  const classes = useStyles();

  return (
    <>
      <BackButton label="Cancel" />
      <RaEdit classes={classes} resource={resource} {...props} hasShow={false} />
    </>
  );
};

export default Edit;
