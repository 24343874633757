import { useEffect, useMemo, useRef, useState } from 'react';
import { CRUD_GET_LIST, getReferenceResource, useDataProvider } from 'react-admin';
import { matchPath, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { ROUTE_PATTERNS } from 'constants/routes';
import { getRequestParams } from '_ui/List/utils';

export const useDelayed = (delay = 0) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(
    () => {
      const timer = setTimeout(() => setIsLoading(false), delay);
      return () => clearTimeout(timer);
    },
    [],
  );

  return isLoading;
};

export const useIsFirstRender = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);

  return isMountRef.current;
};

export const useRefreshList = ({ resource, condition }) => {
  const dataProvider = useDataProvider();
  const isFirstRender = useIsFirstRender();
  const { list: { params } } = useSelector((state) => getReferenceResource(state, { reference: resource }));
  const parsedParams = useMemo(() => getRequestParams(params), [params]);

  useEffect(() => {
    if (!isFirstRender && condition) {
      dataProvider.getList(resource, parsedParams, { action: CRUD_GET_LIST });
    }
  }, [condition]);
};

export const useIsLanguageAgnosticView = () => {
  const { pathname } = useLocation();
  const languageAgnosticPath = ROUTE_PATTERNS.show.replace(/:locale.+/, '');
  return !!matchPath(pathname, {
    path: languageAgnosticPath,
    exact: true,
  });
};
