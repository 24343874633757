export const CLOSABLE = [
  /^Retrieving data for export/,
  /^Required Fields are missing/,
  /^Export:/,
  /^Deleted:/,
  /^Import:/,
  /^Invalid file/,
  /.*load preview/,
];

export const DEFAULT_HIDE_DURATION = 4e3;

export const SELENIUM = {
  root: 'notification',
  closeBtn: 'notification-close-btn',
};
