import { getToken, removeToken, getPtpId } from './utils';
import { URL_AUTHENTICATE } from '../constants';
import ls from '../ls';

export default {
  login: ({ email, password }) => {
    const request = new Request(`${window.ENDPOINT_BASE_URL}${URL_AUTHENTICATE}`, {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'include',
    });

    return fetch(request)
      .then(response => {
        const { status, statusText } = response;
        if (response.status < 200 || response.status >= 300) {
          return Promise.reject({ status, message: statusText });
        }
        return response.json();
      })
      .then((user) => {
        const token = getToken();
        const ptpid = getPtpId(token);
        ls.setJSON('user', { ...user, ptpid });
      });
  },
  logout: () => {
    ls.remove('user');
    removeToken();
    return Promise.resolve();
  },
  checkError: (error) => {
    if (error.status === 401 || error.status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const user = ls.getJSON('user');
    if (user && getToken()) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  getPermissions: () => {
    const user = ls.getJSON('user');
    return (user && user.roles) ? Promise.resolve(user.roles) : Promise.reject();
  },
};
