import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import { getIsExpandedItem } from 'ra-redux/menu';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
  },
  paper: {
    margin: theme.spacing(1),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
}));

const MenuTransition = ({ name, children }) => {
  const classes = useStyles();
  const isExpanded = useSelector(getIsExpandedItem(name));

  return (
    <div className={classes.root}>
      <Collapse in={isExpanded}>
        {children}
      </Collapse>
    </div>
  );
};

MenuTransition.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export default MenuTransition;
