/* eslint-disable */
import React, { useCallback } from 'react';
import { Checkbox as MUCheckbox, Box } from '@material-ui/core';

const Checkbox = ({ disabled, ...props }) => {
  const onWrapClick = useCallback((e) => {
    if (disabled) e.stopPropagation();
  }, [disabled]);
  
  return (
    <Box onClick={onWrapClick} minWidth='42px'>
      <MUCheckbox {...props} disabled={disabled} />
    </Box>
  );
};

export default Checkbox;
