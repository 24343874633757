import React, { useCallback } from 'react';
import {
  Button,
} from 'react-admin';
import PropTypes from 'prop-types';
import { fetchImportUploads } from 'ra-redux/importsUpload';
import { useDispatch } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    marginTop: '1rem',
  },
});

const Toolbar = ({ disabled, data }) => {
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    dispatch(fetchImportUploads(data));
  }, [data]);
  const classes = useStyles();

  return (
    <Button
      disabled={disabled}
      label="Import"
      variant="contained"
      data-seleniumid="save-import-button"
      size="medium"
      className={classes.button}
      onClick={onClick}
    >
      <SaveIcon />
    </Button>
  );
};

Toolbar.propTypes = {
  data: PropTypes.object,
  disabled: PropTypes.bool,
};

export default Toolbar;
