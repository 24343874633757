import React from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  viewPaper: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
}));

export const sanitizeProps = ({
  languageSpecificFields,
  languageAgnosticFields,
  hasCreate,
  hasShow,
  hasEdit,
  hasList,
  perPage,
  addLabel,
  basePath,
  ...props
}) => props;

const Grid = (props) => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.viewPaper}
      {...sanitizeProps(props)}
    />
  );
};

export default Grid;
