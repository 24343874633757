import { stringify } from 'query-string';
import moment from 'moment';

const SCORM_ENDPOINT = 'api/v1/learning-objects/scorm';

const getDate = (rawDate) => {
  const date = rawDate || new Date();
  return `${moment(date).format('M-DD-YYYY h-mm A')} GMT${moment(date).format('ZZ')}`;
};
export const getFileName = () => `Scorm export.${getDate()}`;
export const getLink = (ids = []) => `${SCORM_ENDPOINT}?${stringify({ id: ids })}`;

export const download = (ids = []) => {
  const href = `${SCORM_ENDPOINT}?${stringify({ id: ids })}`;
  const linkElement = document.createElement('a');
  linkElement.style.display = 'none';
  document.body.appendChild(linkElement);
  linkElement.setAttribute('href', href);
  linkElement.setAttribute('download', `${getFileName()}.zip`);
  linkElement.click();
  document.body.removeChild(linkElement);
};
