import React from 'react';
import {
  ReferenceField,
  ReferenceArrayInput,
  FunctionField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

import {
  Datagrid,
  List,
  LocaleReferenceField,
  Filter,
  TextInput,
  AutocompleteArrayInput,
  TextField,
  CollapsedTextField,
  EmptyProgressReferenceField,
  DrillDownLinkField,
  DateRangeInput,
} from '_ui';
import { BASE_SEARCH_SOURCE } from 'constants';
import { LO_LIST_SORT_PARAMS } from 'constants/sort';
import { withCustomWidthTableStyles } from 'utils/styleUtils';
import { useAvailableLocales } from 'utils/locales';

import { DownloadField, LinkedField } from './Fields';
import LearningResourceACField, { useAssociatedContent } from './Fields/ACField';
// todo CK1.0+ CPP0-1882
// eslint-disable-next-line no-unused-vars
import LearningResourceExpand from './LearningResourceExpand';
import { ExportPanel, ExportHeader } from './Export';

const LearningResourceFilter = props => (
  <Filter seleniumId="lo-filters" {...props}>
    <TextInput
      label="Search (50 LRs at max)"
      source={BASE_SEARCH_SOURCE}
      alwaysOn
      seleniumId="lo-search"
      isFilter
      inputProps={{ maxLength: 450 }}
    />
    <DateRangeInput
      allowEmpty
      label="Published"
      source="published"
      seleniumId="lo-filter-published"
    />
    <ReferenceArrayInput
      allowEmpty
      alwaysOn
      label="Language"
      perPage={50}
      reference="languages"
      sort={{ field: 'name', order: 'ASC' }}
      source="availableLocales"
    >
      <AutocompleteArrayInput
        seleniumId="lo-filter-languages"
        optionText="code"
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Author"
      source="authorId"
      reference="authors"
      allowEmpty
      sort={{ field: 'code', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        minSearchableLength={1}
        seleniumId="lo-filter-authors"
        optionText="code"
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Discipline"
      source="disciplineId"
      reference="disciplines"
      allowEmpty
      sort={{ field: 'code', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        minSearchableLength={1}
        seleniumId="lo-filter-discipline"
        optionText="code"
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Tag"
      source="tagIds"
      reference="tags"
      sort={{ field: 'code', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        minSearchableLength={1}
        seleniumId="lo-filter-tags"
        optionText="code"
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Theme"
      source="themeIds"
      reference="themes"
      sort={{ field: 'code', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        minSearchableLength={1}
        seleniumId="lo-filter-themes"
        optionText="code"
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Skill Set"
      source="skillsetIds"
      reference="skillsets"
      sort={{ field: 'code', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        minSearchableLength={1}
        seleniumId="lo-filter-skillsets"
        optionText="code"
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Program"
      reference="programs"
      source="programId"
      sort={{ field: 'code', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        minSearchableLength={1}
        optionText="code"
        seleniumId="lo-filter-program"
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Content Type"
      source="contentName"
      reference="content-names"
      allowEmpty
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        seleniumId="lo-filter-content-names"
        optionText="name"
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Publishing State"
      source="state"
      reference="learning-object-states"
      allowEmpty
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        seleniumId="lo-filter-states"
        optionText="name"
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Editorial Status"
      source="editorialStatus"
      reference="editorial-statuses"
      allowEmpty
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteArrayInput
        seleniumId="lo-filter-editorial-statuses"
        optionText="name"
        allowEmpty={false}
      />
    </ReferenceArrayInput>
  </Filter>
);

export const columnWidth = {
  expand: 4,
  download: 6,
  code: 8,
  title: 16,
  contentName: 10,
  program: 12,
  author: 12,
  essential: 8,
  actionTips: 8,
  sessions: 8,
  videocasts: 8,
};

const useStyle = makeStyles(
  withCustomWidthTableStyles(columnWidth, theme => ({
    table: {
      minWidth: `${theme.breakpoints.values.lg}px`,
    },
  })),
);

const LearningResourceList = props => {
  useAssociatedContent(props);
  useAvailableLocales(props);
  const classes = useStyle();

  return (
    <>
      <List
        {...props}
        filters={<LearningResourceFilter />}
        bulkActionButtons={false}
        seleniumId="learning-resources"
        sort={LO_LIST_SORT_PARAMS}
      >
        <Datagrid
          localeRowClick
          expand={<LearningResourceExpand />}
          classes={classes}
          warning={<ExportPanel />}
        >
          <DownloadField source="scorm" label={<ExportHeader />} sortable={false} />
          <TextField source="code" label="Code" seleniumId="lo-code" />
          <LocaleReferenceField
            source="id"
            label="Title"
            reference="learning-objects-locales"
            link={false}
            sortable={undefined}
          >
            <TextField source="title" seleniumId="lo-title" />
          </LocaleReferenceField>
          <ReferenceField
            source="contentName"
            label="Content Type"
            reference="content-names"
            link={false}
          >
            <TextField source="name" seleniumId="lo-content-name" />
          </ReferenceField>
          <ReferenceField
            source="programId"
            reference="programs"
            link={false}
            sortable={undefined}
          >
            <LocaleReferenceField
              source="id"
              label="Title"
              reference="programs-locales"
              link={false}
            >
              <CollapsedTextField
                numLines={2}
                source="title"
                seleniumId="lo-program"
              />
            </LocaleReferenceField>
          </ReferenceField>
          <ReferenceField
            source="authorId"
            reference="authors"
            link={false}
            sortable={undefined}
            allowEmpty
          >
            <LocaleReferenceField
              source="id"
              label="author"
              reference="authors-locales"
              link={false}
            >
              <FunctionField
                label="author"
                data-seleniumid="lo-author-name"
                render={record =>
                  `${record.firstName || ''} ${record.lastName || ''}`}
              />
            </LocaleReferenceField>
          </ReferenceField>
          <EmptyProgressReferenceField
            label="Essential"
            reference="learning-objects"
            source="essentialId"
            link={false}
          >
            <DrillDownLinkField
              targetResource="learning-objects"
              source="code"
              seleniumId="lo-essential"
            />
          </EmptyProgressReferenceField>
          <LinkedField
            source="actionTips"
            label="Action Tips"
            sortable={undefined}
            seleniumId="lo-action-tips"
          />
          <LearningResourceACField
            sortable={undefined}
            associationType="sessions"
            label="Sessions"
            seleniumId="lo-session"
          />
          <LearningResourceACField
            sortable={undefined}
            associationType="videocasts"
            label="Videocast Resources"
            seleniumId="lo-videocasts"
          />
        </Datagrid>
      </List>
    </>
  );
};

export default LearningResourceList;
