import {
  CREATE,
  GET_ONE,
  GET_LIST,
  GET_MANY_REFERENCE,
} from 'react-admin';

const LOCALIZABLE_RESOURCES = ['learning-objects-history', 'learning-objects', 'themes', 'authors', 'programs', 'tags', 'skillsets', 'disciplines'];
let cachedLanguages = null;
const getLanguages = async (wrappedDataProvider) => {
  if (cachedLanguages) {
    return cachedLanguages;
  }

  const { data } = await wrappedDataProvider(GET_LIST, 'languages', {
    pagination: { perPage: 100, page: 1 },
    sort: {},
    filter: {},
  });
  cachedLanguages = data;
  return cachedLanguages;
};

export default (wrappedDataProvider) => async (type, resource, params) => {
  if (type === CREATE && resource === 'languages') {
    cachedLanguages = null;
  }

  const result = await wrappedDataProvider(type, resource, params);

  if (type === GET_ONE && LOCALIZABLE_RESOURCES.includes(resource)) {
    const transformedData = { ...result.data };
    const { availableLocales = [] } = result.data;
    const allLanguages = await getLanguages(wrappedDataProvider);
    transformedData.canBeLocalizedIn = allLanguages.map(l => l.id).filter(id => !availableLocales.includes(id));
    transformedData.availableLocales = transformedData.availableLocales || [];
    return { ...result, data: transformedData };
  }

  if (type === GET_MANY_REFERENCE && LOCALIZABLE_RESOURCES.includes(resource)) {
    const allLanguages = await getLanguages(wrappedDataProvider);
    const transformedData = result.data.map(t => {
      t.canBeLocalizedIn = allLanguages.map(l => l.id).filter(id => !t.availableLocales.includes(id));
      t.availableLocales = t.availableLocales || [];
      return t;
    });
    return { ...result, data: transformedData };
  }

  return result;
};
