import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useNotify } from 'react-admin';

import { ARCHIVE_TYPE } from '_domains/Jobs/Publications/constants';
import { CommentModal, ProgressButton } from '_ui';

const ArchiveAction = ({
  shortLabel,
  disabled,
  isArchive,
  localeRecord,
  record,
}) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const { id, locale } = localeRecord;
  const label = isArchive ? 'Archive' : `Retrieve${shortLabel ? '' : ' from archive'}`;
  const seleniumId = `${isArchive ? 'archive' : 'unarchive'}-${id}`;

  const notify = useNotify();

  const [archive, { loading: isLoading }] = useMutation();
  const submit = (comment) => {
    if (isArchive) toggleModal();
    archive({
      type: 'update',
      resource: 'learning-objects-locales',
      payload: {
        id,
        action: isArchive
          ? 'archive'
          : 'unarchive',
        comment,
      },
    },
    {
      onError: () => notify('Error: LO is not published.', 'error'),
    });
  };

  return (
    <>
      <ProgressButton
        size="small"
        color="primary"
        disabled={disabled || isLoading}
        loading={isLoading}
        seleniumId={seleniumId}
        onClick={isArchive ? toggleModal : () => submit()}
        label={label}
      />
      <CommentModal
        open={showModal}
        record={{
          ...record,
          publicationType: ARCHIVE_TYPE,
          language: locale,
        }}
        onSubmit={submit}
        onCancel={toggleModal}
      />
    </>
  );
};

ArchiveAction.propTypes = {
  disabled: PropTypes.bool,
  isArchive: PropTypes.bool,
  localeRecord: PropTypes.object,
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
  shortLabel: PropTypes.bool,
};

ArchiveAction.defaultProps = {
  disabled: false,
  isArchive: true,
  shortLabel: false,
};

export default ArchiveAction;
