import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,

  Modal,
  Paper,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import { sortLocales } from 'utils/locales';
import { getLink } from './utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30vw',
    minHeight: '20rem',
    backgroundColor: theme.palette.background.default,
  },
  container: {
    flex: 1,
    padding: `${theme.spacing(2, 2)} !important`,
    maxHeight: '70vh',
    overflowY: 'auto',
  },
  title: {
    padding: `${theme.spacing(2, 2)} !important`,
    flexBasis: '3rem',
    backgroundColor: theme.palette.common.white,
  },
  buttonWrap: {
    padding: `${theme.spacing(2, 2)} !important`,
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
  button: {
    margin: 0,
  },
}));

const ExportTable = ({ data, seleniumId }) => (
  <TableContainer component={Paper}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell width={25}>Code</TableCell>
          <TableCell>Locale(s)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(data).map(([code, locales = []]) => (
          <TableRow
            key={code}
            data-seleniumId={`${seleniumId}-row-${code}`}
          >
            <TableCell
              component="th"
              scope="row"
              data-seleniumId={`${seleniumId}-cell-${code}`}
            >
              {code}
            </TableCell>
            <TableCell
              component="th"
              data-seleniumId={`${seleniumId}-locales-cell-${code}`}
            >
              {sortLocales(locales).join(', ')}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
ExportTable.propTypes = { data: PropTypes.arrayOf(PropTypes.object), seleniumId: PropTypes.string };

const ExportModal = ({
  onSubmit,
  onCancel,
  data: rawData,
  title,
  seleniumId,
  ...modalProps
}) => {
  const cancel = useCallback((event) => {
    event.stopPropagation();
    onCancel();
  }, [onCancel]);

  const submit = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit();
  }, [onSubmit]);

  const classes = useStyles();
  const data = useMemo(() => rawData.reduce((acc, { code, locale }) => {
    acc[code] = [...(acc[code] || []), locale];
    return acc;
  }, []), [rawData]);

  return (
    <Modal
      {...modalProps}
      className={classes.root}
    >
      <>
        <ClickAwayListener onClickAway={cancel} mouseEvent="onMouseDown">
          <Paper classes={{ root: classes.paper }} data-seleniumid={`${seleniumId}-modal`}>
            <div className={classes.title}>
              <Typography variant="h5">
                {title}
              </Typography>
            </div>
            <Divider />
            <div className={classes.container}>
              <ExportTable
                data={data}
                seleniumId={`${seleniumId}-modal-table`}
              />
            </div>
            <Divider />
            <div className={classes.buttonWrap}>
              <Button
                color="default"
                onClick={cancel}
                classes={{ root: classes.button }}
                data-seleniumid={`${seleniumId}-cancel-button`}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={submit}
                classes={{ root: classes.button }}
                data-seleniumid={`${seleniumId}-submit-button`}
                link={getLink(rawData.map(el => el.id))}
              >
                Download
              </Button>
            </div>
          </Paper>
        </ClickAwayListener>
      </>
    </Modal>
  );
};

ExportModal.propTypes = {
  ...Modal.propTypes,
  children: PropTypes.element,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  seleniumId: PropTypes.string,
  title: PropTypes.string,
};

ExportModal.defaultProps = {
  children: null,
};

export default ExportModal;
