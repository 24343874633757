/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { uniq, compact } from 'lodash';
import {
  useGetMany,
} from 'react-admin';
import PropTypes from 'prop-types';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { LR } from 'constants/resources';
import LocalesExpandRow from './LocalesExpandRow';

const useStyles = makeStyles({
  progress: {
    position: 'relative',
    left: '50%',
    transform: 'translate(-50%, 0)',
    animation: 'none !important',
  },
});

const LearningResourceExpand = (props) => {
  const classes = useStyles();
  const { linkedIDs } = props;

  const { data: rawData, loading } = useGetMany(LR, linkedIDs || []);
  const data = compact(rawData || []);
  if (loading) return <CircularProgress className={classes.progress} />;
  return <LocalesExpandRow {...props} learningResources={data?.length ? data : null} />;
};

const LearningResourceExpandWrap = ({ record, ...rest }) => {
  const { availableLocales } = record || { availableLocales: [] };
  const linkedIDs = useMemo(() => {
    const { essentialId, actionTips = [], sessions = [], videocasts = [] } = record || {};
    const rawLRIDs = actionTips && sessions && videocasts ? [...actionTips, ...sessions, ...videocasts] : [];
    return essentialId ? uniq(rawLRIDs.concat(essentialId)) : rawLRIDs;
  }, [record]);

  if (!availableLocales.length) return 'No languages available';
  return <LearningResourceExpand record={record} linkedIDs={linkedIDs} {...rest} />;
};

LearningResourceExpandWrap.propTypes = {
  record: PropTypes.shape({
    actionTips: PropTypes.arrayOf(PropTypes.string),
    availableLocales: PropTypes.arrayOf(PropTypes.string),
    essentialId: PropTypes.string,
    sessions: PropTypes.arrayOf(PropTypes.string),
    videocasts: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default LearningResourceExpandWrap;
