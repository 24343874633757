/* eslint-disable prefer-const */
import { get, isEmpty, isEqual } from 'lodash';
import { LOCATION_CHANGE_MENU } from 'ra-redux/router';
import { LO_LIST_SORT_PARAMS } from 'constants/sort';
import { ROWS_PER_PAGE } from 'constants/pagination';
import { parse } from 'query-string';

// ignore some search params, e.g. displayedFilters, page
const sanitizeProps = ({ order, perPage, sort }) => ({ order, perPage, sort });
const defaultSearchParams = { order: LO_LIST_SORT_PARAMS.order, perPage: ROWS_PER_PAGE, sort: LO_LIST_SORT_PARAMS.field };
export const parseSearchOptions = {
  parseNumbers: true,
  parseBooleans: true,
};
const prepareParams = params => (isEmpty(params) ? defaultSearchParams : sanitizeProps(params));

export const parseLocations = (state, payload) => {
  const oldLocation = state?.router?.location || {};

  return {
    pathname: get(payload, 'location.pathname', null),
    search: get(payload, 'location.search', null),
    toggleFromMenu: get(payload, `location.state.${LOCATION_CHANGE_MENU}`, null),
    oldPathname: oldLocation.pathname || null,
    oldSearch: oldLocation.search || null,
  };
};

export const isSearchUpdated = ({ search, oldSearch }) => {
  let { filter: oldFilter = '{}', ...oldRestParams } = parse(oldSearch || '', parseSearchOptions);
  let { filter = '{}', ...restParams } = parse(search || '', parseSearchOptions);

  oldRestParams = prepareParams(oldRestParams);
  restParams = prepareParams(restParams);

  return !isEqual(oldFilter, filter) || !isEqual(oldRestParams, restParams);
};
