import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const History = () => {
  const history = useHistory();
  const navigate = useCallback((direction) => () => {
    history.go(direction);
  }, [history]);

  return (
    <Box display="flex" flexDirection="row">
      <IconButton
        color="inherit"
        data-seleniumid="back-button"
        onClick={navigate(-1)}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        color="inherit"
        data-seleniumid="forward-button"
        onClick={navigate(1)}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};

export default History;
