/* eslint-disable */
import React from 'react';
import { Button } from '@material-ui/core';

const TaxonomyButton = (props) => (
  <Button
    style={{ width: '12rem' }}
    color="primary"
    size="small"
    {...props}
  >
    Generate Taxonomy
  </Button>
);

export default TaxonomyButton;
