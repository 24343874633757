/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Filter as RaFilter } from 'react-admin';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    ...theme.mixins.toolbar,
    paddingTop: 10,
  },
}));

const Filter = ({ seleniumId, ...props }) => {
  const { context } = props;
  const [id] = useState(() => _.uniqueId('filter-form-'));

  const classes = useStyles();

  return (
    context === 'form'
      ? (
        <RaFilter
          classes={classes}
          {...props}
          id={id}
          data-seleniumid={`${seleniumId}-form`}
        />
      )
      : <RaFilter {...props} id={id} data-seleniumid={`${seleniumId}-button`} />
  );
};

Filter.propTypes = {
  seleniumId: PropTypes.string.isRequired,
};

export default Filter;
