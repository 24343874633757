import { get, difference, isEmpty } from 'lodash';
import { useState, useEffect } from 'react';
import { useMutation } from 'react-admin';
import {
  ErrorOutline as WarningIcon,
  SubtitlesOutlined as EmbeddedSubtitleIcon,
} from '@material-ui/icons';
import { LR_VIDEO_CONTENT_LIST } from 'constants/resources';
import { GET_ONE_TYPE } from 'api/backend-data-server/dataProviderAdapter';
import { MEDIA_TYPE_VIDEO, MEDIA_TYPE_READING } from 'constants/mediaTypes';
import { useSelector } from 'react-redux';
import { SUB_FILE_EXT, VIDEO_FILE_EXT } from '_ui/Layout/Preview/constants';
import { selectLocalesByLR, selectLR, selectLRPreviewContent } from 'ra-redux/selectors';

export const TOOLTIP_MESSAGE = {
  VIDEO_MISSING_SUBTITLES: 'Missing subtitles',
  VIDEO_EMBEDDED_SUBTITLES: fileName => `This Video is a localised video with embedded subtitles ${fileName}`,
  [MEDIA_TYPE_READING]: 'Missing PDF',
};

export const removeVersion = fileName => `${fileName.replace(`.${VIDEO_FILE_EXT}`, '')
  .split('_')
  .slice(0, 2)
  .join('_')}.${VIDEO_FILE_EXT}`;

const getVideoLocalesSubtitlesInfo = (entities) => {
  const withSubtitles = [];
  const withEmbeddedSubtitles = {};
  entities.forEach(entity => {
    const language = get(entity, 'language');
    const fileName = get(entity, 'metadata.fileName');
    if (!language || !fileName) return;

    const fileExtension = fileName.split('.').pop() || '';
    if (fileExtension === SUB_FILE_EXT) {
      withSubtitles.push(language);
      return;
    }

    const embeddedSubtitles = get(entity, 'metadata.embeddedSubtitles');
    if (fileExtension === VIDEO_FILE_EXT && embeddedSubtitles) {
      withEmbeddedSubtitles[language] = removeVersion(fileName);
    }
  });
  return { withSubtitles, withEmbeddedSubtitles };
};
export const getVideoLocalesHighlights = (entities, lrLocales) => {
  const { withSubtitles, withEmbeddedSubtitles } = getVideoLocalesSubtitlesInfo(entities);
  const withoutSubtitles = difference(lrLocales, withSubtitles);

  return {
    ...withoutSubtitles.reduce((acc, locale) => ({
      ...acc,
      [locale]: { tooltip: TOOLTIP_MESSAGE.VIDEO_MISSING_SUBTITLES, icon: WarningIcon },
    }), {}),
    ...Object.entries(withEmbeddedSubtitles).reduce((acc, [locale, fileName]) => ({
      ...acc,
      [locale]: { tooltip: TOOLTIP_MESSAGE.VIDEO_EMBEDDED_SUBTITLES(fileName), icon: EmbeddedSubtitleIcon },
    }), {}),
  };
};

const getLocalesWithoutPDF = entities => entities.filter(el => !el.localizedPdfExists).map(el => el.locale);

export const useContentList = ({ id }) => {
  const [contentList, { loading }] = useMutation();
  const lrEntity = useSelector(selectLR(id));

  const { mediaType, directlyUploadedVideo } = lrEntity || {};
  useEffect(() => {
    if (mediaType !== MEDIA_TYPE_VIDEO || !directlyUploadedVideo) return;
    contentList({ type: GET_ONE_TYPE, resource: LR_VIDEO_CONTENT_LIST, payload: { id } });
  }, [mediaType, directlyUploadedVideo]);

  return loading;
};

export const useHighlightedLocales = ({ id, lr }) => {
  const [highlighted, setHighlighted] = useState({});

  const localesEntities = useSelector(selectLocalesByLR(id));
  const contentEntities = useSelector(selectLRPreviewContent(id));

  const { mediaType, directlyUploadedVideo, directlyUploadedPdf, availableLocales } = lr || {};
  const isContentLocales = contentEntities?.length && availableLocales?.length;

  useEffect(() => {
    if (mediaType !== MEDIA_TYPE_VIDEO || !directlyUploadedVideo || !isContentLocales) return;
    setHighlighted(getVideoLocalesHighlights(contentEntities, availableLocales));
  }, [mediaType, directlyUploadedVideo, isContentLocales]);

  const withoutPDF = getLocalesWithoutPDF(localesEntities || []);
  useEffect(() => {
    if (mediaType !== MEDIA_TYPE_READING) return;

    if (!directlyUploadedPdf || !withoutPDF?.length) {
      if (!isEmpty(highlighted)) setHighlighted({});
      return;
    }
    setHighlighted(getLocalesWithoutPDF(localesEntities).reduce((acc, locale) => ({
      ...acc,
      [locale]: {
        tooltip: TOOLTIP_MESSAGE[MEDIA_TYPE_READING],
        icon: WarningIcon,
      },
    }), {}));
  }, [mediaType, directlyUploadedPdf, JSON.stringify(withoutPDF)]);

  return highlighted;
};
