/* eslint-disable react/prop-types,jsx-a11y/alt-text */
import React, {
  useLayoutEffect,
  useCallback,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import DeleteIcon from '@material-ui/icons/Delete';

import { getThumbType, getAuthorImportInfo } from '../utils';
import { fileImportType } from '../constants';
import { REGEX_LR_CODE, REGEX_LR_CODE_WITH_LOCALE } from '../../../../constants/regex';

const Thumbnail = ({ title, url, code, locale, thumbnailStyle }) => <>{title}<span> code: <strong>{code}</strong>{locale ? <span> locale: <strong>{locale}</strong></span> : ''}</span><img className={thumbnailStyle} src={url} /></>;

const ThumbnailFileField = (props) => {
  const { record: { rawFile, title }, thumbnailStyle } = props;
  const name = title.replace(/\.\w+/, '');
  const type = getThumbType(name);
  const url = URL.createObjectURL(rawFile);

  switch (type) {
    case 'LO': {
      const [code, locale] = name.split('_');
      return (
        <Thumbnail
          data-seleniumid="imports-upload-file-ThumbnailFileField-LO"
          thumbnailStyle={thumbnailStyle}
          title="Learning Resource"
          url={url}
          code={code.substr(1)}
          locale={locale}
        />
      );
    }
    case 'AUTHOR': {
      const { code, locale } = getAuthorImportInfo(name);
      return (
        <Thumbnail
          data-seleniumid="imports-upload-file-ThumbnailFileField-AUTHOR"
          thumbnailStyle={thumbnailStyle}
          title="Author"
          url={url}
          code={code}
          locale={locale}
        />
      );
    }

    default:
      return <><span data-seleniumid="imports-upload-file-ThumbnailFileField"><strong><span style={{ color: 'red' }}>Invalid file name: {name}</span></strong></span><img src={url} className={thumbnailStyle} /></>;
  }
};

const CSVFileField = (props) => {
  const { record: { rawFile, title } } = props;
  return <a data-seleniumid="imports-upload-file-CSVFileField" href={URL.createObjectURL(rawFile)}>{title}</a>;
};

const CodeWithLocaleField = (props) => {
  const { record: { rawFile, title }, invalidFileNames, uploadType } = props;
  const fileName = title.replace(/\.[a-z]+/, '');
  const [loCode, locale] = fileName.split('_');
  return (
    <>
      <a href={URL.createObjectURL(rawFile)}>{title}</a>
      {invalidFileNames.includes(fileName)
        ? <div data-seleniumid={`imports-upload-file-error-${uploadType}`}><strong><span style={{ color: 'red' }}>Invalid file name</span></strong></div>
        : <div data-seleniumid={`imports-upload-file-${uploadType}`}>code: <strong>{loCode}</strong> locale: <strong>{locale}</strong></div>}
    </>
  );
};

export const VideocastFileField = (props) => {
  const { record: { title }, invalidFileNames } = props;
  const fileName = title.replace(/\.mp4/, '');
  let locale;
  let lrCode;
  if (REGEX_LR_CODE_WITH_LOCALE.test(fileName)) {
    [lrCode, locale] = fileName.split('_');
  }
  else if (REGEX_LR_CODE.test(fileName)) {
    lrCode = fileName;
  }

  return (
    <>
      <span>{title}</span>
      {invalidFileNames.includes(fileName)
        ? <div data-seleniumid="imports-upload-file-error-VideocastFileField"><strong><span style={{ color: 'red' }}>Invalid file name</span></strong></div>
        : <div data-seleniumid="imports-upload-file-VideocastFileField">code: <strong>{lrCode}</strong> locale: <strong>{locale}</strong></div>}
    </>
  );
};

const RemoveIcon = ({ parentClassName, ...iconProps }) => {
  const [btnElement, setBtnElement] = useState(false);

  useLayoutEffect(() => {
    const [wraperElement] = document.getElementsByClassName(parentClassName);
    const [iconElement] = wraperElement?.previousElementSibling?.getElementsByTagName('svg') || [];
    if (iconElement?.style) {
      iconElement.style.display = 'none';
    }
    setBtnElement(wraperElement?.previousElementSibling);
  }, []);

  const onClick = useCallback(() => {
    if (btnElement?.click) btnElement.click();
  }, [btnElement]);

  if (!btnElement) return null;
  return createPortal(
    <DeleteIcon {...iconProps} onClick={onClick} />,
    btnElement,
  );
};

const FileField = props => {
  const {
    uploadType,
    thumbnailStyle,
    record,
  } = props;
  let fileElement = null;
  switch (uploadType) {
    case fileImportType.LO_THUMBNAIL:
      fileElement = <ThumbnailFileField thumbnailStyle={thumbnailStyle} {...props} />;
      break;
    case fileImportType.LO_ASSOCIATIONS:
    case fileImportType.LO_EXPORT:
    case fileImportType.LO_PUBLISH:
    case fileImportType.LO_METADATA:
    case fileImportType.GLOBAL_METADATA:
      fileElement = <CSVFileField {...props} />;
      break;
    case fileImportType.VideoUpload:
      fileElement = <VideocastFileField {...props} />;
      break;
    case fileImportType.PdfUpload:
    case fileImportType.LO_INGESTION:
    case fileImportType.SubtitleUpload:
      fileElement = <CodeWithLocaleField {...props} />;
      break;
    default:
      fileElement = <span data-seleniumid="imports-upload-file-default">{uploadType}</span>;
      break;
  }

  const parentClassName = `input-file-${record?.['source.fileName'].split('/').pop()}`;
  return (
    <span className={parentClassName}>
      {fileElement}
      <RemoveIcon parentClassName={parentClassName} />
    </span>
  );
};

export default FileField;
