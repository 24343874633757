import React from 'react';
import { Layout as RALayout } from 'react-admin';

import { makeStyles } from '@material-ui/core';

import AppBar from './AppBar';
import Sidebar from './Sidebar';
import Notification from './Notification';

const useStyles = makeStyles((theme) => {
  const mediaMobile = `${theme.breakpoints.up('xs')} and (orientation: landscape)`;
  const mediaDesktop = theme.breakpoints.up('sm');

  return ({
    root: {
      display: 'flex',
      flexGrow: 1,
      paddingBottom: '0.8rem',
    },
    appFrame: {
      marginTop: theme.mixins.toolbar.minHeight,
      [mediaMobile]: {
        marginTop: theme.mixins.toolbar[mediaMobile].minHeight,
      },
      [mediaDesktop]: {
        marginTop: theme.mixins.toolbar[mediaDesktop].minHeight,
      },
    },
    content: {
      padding: theme.spacing(3),
    },
  });
});

const Layout = (props) => {
  const classes = useStyles();

  return (
    <RALayout
      {...props}
      classes={classes}
      appBar={AppBar}
      sidebar={Sidebar}
      notification={Notification}
    />
  );
};

export default Layout;
