import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import {
  hideNotification,
  getNotification,
  undo,
  complete,
  undoableEventEmitter,
  useTranslate,
} from 'ra-core';

import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { CLOSABLE, DEFAULT_HIDE_DURATION, SELENIUM } from './constants';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      flexWrap: 'nowrap',
    },
    error: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
    },
    warning: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
    },
    undo: {
      color: theme.palette.primary.light,
    },
  }),
  { name: 'RaNotification' },
);

const Notification = ({
  autoHideDuration: autoHideDurationOverride,
  classes: classesOverride,
  type: typeOverride,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const translate = useTranslate();

  const notification = useSelector(getNotification) || {};
  useEffect(() => { setOpen(!isEmpty(notification)); }, [notification]);

  const {
    message: messageOverride,
    undoable,
    messageArgs,
    type = typeOverride,
  } = notification;

  const message = (typeof messageOverride === 'string')
    ? translate(messageOverride, messageArgs)
    : messageOverride;

  const { component: Component, className: classNameOverride = '', ignoreClickAway = false } = messageArgs || {};

  const isClosable = useMemo(() => (
    (message && CLOSABLE.some((regExp) => regExp.test(message)))
    || React.isValidElement(message)
  ), [message]);

  let { autoHideDuration = autoHideDurationOverride } = notification;

  if (isClosable && autoHideDuration === DEFAULT_HIDE_DURATION) {
    autoHideDuration = null;
  }

  const handleRequestClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleExited = useCallback(() => {
    if (undoable) {
      dispatch(complete());
      undoableEventEmitter.emit('end', { isUndo: false });
    }
    dispatch(hideNotification());
  }, [dispatch, undoable]);

  const handleUndo = useCallback(() => {
    dispatch(undo());
    undoableEventEmitter.emit('end', { isUndo: true });
  }, [dispatch]);

  const styles = useStyles();
  const className = classnames(
    styles.root,
    styles[type],
    classNameOverride,
  );

  let action = null;
  if (undoable) {
    action = (
      <Button
        color="primary"
        className={styles.undo}
        size="small"
        onClick={handleUndo}
      >
        {translate('ra.action.undo')}
      </Button>
    );
  }
  else if (isClosable) {
    action = (
      <IconButton
        color="inherit"
        size="small"
        className={styles.closeBtn}
        onClick={handleRequestClose}
        data-seleniumid={SELENIUM.closeBtn}
      >
        <CloseIcon />
      </IconButton>
    );
  }

  const props = {
    action,
    'data-seleniumid': SELENIUM.root,
    open,
    message,
    autoHideDuration,
    disableWindowBlurListener: true,
    onExited: handleExited,
    onClose: handleRequestClose,
    ContentProps: useMemo(() => ({ className }), [className]),
    ClickAwayListenerProps: useMemo(() => (
      (isClosable || ignoreClickAway)
        ? { onClickAway: () => {} }
        : {}
    ), [isClosable, ignoreClickAway]),
    ...rest,
  };

  return Component ? <Snackbar {...props}><Component onClose={handleRequestClose} /></Snackbar> : <Snackbar {...props} />;
};

Notification.propTypes = {
  autoHideDuration: PropTypes.number,
  classes: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.string,
};

Notification.defaultProps = {
  type: 'info',
  autoHideDuration: DEFAULT_HIDE_DURATION,
};

export default Notification;
