import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  FunctionField,
  TextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/styles';

import {
  PUBLICATION_TYPE,
  ARCHIVE_TYPE,
  PUBLICATION_STATUSES,
  ARCHIVE_PUBLICATION_TYPE_LABELS,
} from '_domains/Jobs/Publications/constants';
import { ActionsField, WarningField, DirectField } from '_domains/Jobs/Publications/Fields';
import PublicationLogsField from '_domains/Jobs/Publications/PublicationsLogField';
import {
  DateField,
  ViewCard,
  List,
  Datagrid,
  TruncatedTextField,
  UserField,
  CommentField,
} from '_ui';
import { PUBLICATION_LIST_SORT_PARAMS } from 'constants/sort';
import { getLocales } from 'ra-redux/locales';

const useStyles = makeStyles(theme => ({
  cardContent: {
    margin: `${theme.spacing(1)}px !important`,
    padding: '0 !important',
    borderRadius: theme.shape.borderRadius,
  },
  list: {
    '& > div': {
      margin: 0,
    },
    '& .MuiPaper-root': {
      boxShadow: 'none',
      borderRadius: 0,
    },
    '& tr > td': {
      height: 45,
      maxWidth: 300,
      position: 'relative',
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
  },
}));

const PublicationsCard = (props) => {
  const { record: { code } } = props;
  const classes = useStyles();
  const [locale] = useSelector(getLocales);

  const actions = (record = {}) => <ActionsField downloadable className={classes.actions} record={record} />;

  return (
    <ViewCard
      {...props}
      headerTitle="Publications"
      data-seleniumid="lo-locale-publications-card"
      basePath="/publications"
      resource="publications"
    >
      <List
        {...props}
        displayToolbar={false}
        filter={{
          code,
          availableLocales: [locale],
          status: [PUBLICATION_STATUSES.SUCCESSFUL],
        }}
        sort={PUBLICATION_LIST_SORT_PARAMS}
        bulkActionButtons={false}
        actions={null}
        seleniumId="publications"
        className={classes.list}
        enableScrollIntoView={false}
      >
        <Datagrid
          data-seleniumid="publications-table"
          expand={<PublicationLogsField />}
        >
          <WarningField />
          <FunctionField
            label="Type"
            data-seleniumid="publication-type"
            render={({ publicationType }) => ARCHIVE_PUBLICATION_TYPE_LABELS[publicationType]}
          />
          <TruncatedTextField
            source="title"
            label="Title"
            data-seleniumid="publication-title"
            sortable={undefined}
          />
          <CommentField
            sortable={false}
            label="Comment"
          />
          <UserField
            showLabeled={false}
            seleniumId="publication-creator"
            label="Initiated by"
            source="publishedBy"
          />
          <DateField
            source="initiatedDate"
            label="Initiated Date/Time"

            data-seleniumid="publication-initiated"
          />
          <DateField
            source="publicationDate"
            label="Completed Date/Time"

            data-seleniumid="publication-created"
          />
          <TextField
            source="version"
            data-seleniumid="publication-version"
          />
          <DirectField />
          <FunctionField
            label="Actions"
            render={actions}
          />
        </Datagrid>
      </List>
    </ViewCard>
  );
};

PublicationsCard.propTypes = {
  record: PropTypes.shape({
    code: PropTypes.string.isRequired,
    publicationType: PropTypes.oneOf([PUBLICATION_TYPE, ARCHIVE_TYPE, null]),
  }).isRequired,
};

export default memo(PublicationsCard);
