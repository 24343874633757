import React from 'react';
import { ChipField } from 'react-admin';
import PropTypes from 'prop-types';
import { sanitizeProps } from '_ui/Field/utils';

const ChipLink = ({ seleniumId, source, ...props }) => {
  const { record: { id }, basePath } = props;
  const parentUrl = `#${basePath}/${id}/show`;

  return (
    <ChipField
      {...sanitizeProps(props)}
      clickable
      component="a"
      href={parentUrl}
      source={source}
      data-seleniumid={`${seleniumId}-${id}`}
    />
  );
};

ChipLink.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
  seleniumId: PropTypes.string.isRequired,
  source: PropTypes.string,
};

ChipLink.defaultProps = {
  source: 'code',
};

export default ChipLink;
