import React from 'react';
import PropTypes from 'prop-types';
import {
  ReferenceField,
} from 'react-admin';
import { sanitizeProps } from '_ui/Field/utils';

const EmptyProgressReferenceField = ({ translateChoice, showEmpty, emptyLabel, ...props }) => {
  const { source, record } = props;
  if (!record || !record[source]) return showEmpty ? <div data-seleniumid={`empty-field-${source}`}>{emptyLabel}</div> : null;

  return <ReferenceField {...sanitizeProps(props)} allowEmpty={false} />;
};

EmptyProgressReferenceField.propTypes = {
  emptyLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  record: PropTypes.object,
  showEmpty: PropTypes.bool,
  source: PropTypes.string,
  translateChoice: PropTypes.bool,
};

EmptyProgressReferenceField.defaultProps = {
  showEmpty: false,
  emptyLabel: '',
};

export default EmptyProgressReferenceField;
