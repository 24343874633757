import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';

import { REQUIRED_FOR_PUBLISH_ANNOTATION } from './constants';

const RequiredAnnotation = () => (
  <Box marginBottom={2} data-seleniumid="required-for-publish-annotation">
    <InputLabel shrink>{REQUIRED_FOR_PUBLISH_ANNOTATION}</InputLabel>
  </Box>
);

export default RequiredAnnotation;
