import _ from 'lodash';

const DataWithEmptyMessage = ({ emptyLogsMessage = 'No data available', children, ...props }) => {
  const { data, loaded } = props;
  return !_.isEmpty(data) || !loaded
    ? children(props)
    : emptyLogsMessage;
};

export default DataWithEmptyMessage;
