import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { fileImportType } from '../constants';
import SubtasksField from './SubtasksField';
import DownloadField from './DownloadField';
import VideoInfoField from './VideoInfoField';

const ActionField = (props) => {
  const { record } = props || {};
  if (!record) {
    return '';
  }
  let component = <span />;

  switch (record.type) {
    case fileImportType.LO_PUBLISH:
      component = <SubtasksField {...props} />;
      break;
    case fileImportType.VideoUpload:
      component = <VideoInfoField {...props} />;
      break;
    case fileImportType.LO_EXPORT: {
      const { packagePath, id: recordId, created, source } = record;
      const { originalFileName } = source || {};
      component = <DownloadField recordId={recordId} packagePath={packagePath} created={created} fileName={originalFileName} />;
      break;
    }
    default:
      break;
  }

  return (
    <Box marginLeft={-2}>
      {component}
    </Box>
  );
};

ActionField.propTypes = {
  record: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
};

export default ActionField;
