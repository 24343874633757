import { RECORDS_PER_PAGE } from '_ui/constants';
import {
  MEDIA_TYPE_INTERACTIVE,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_READING,
} from 'constants/mediaTypes';

export * from './strings';
export * from './contentNames';

export const showResourceHref = (resource, id) => `${resource}/${id}/show`;

export const showLocaleResourceHref = (resource, id, locale) =>
  `${resource}/${id}/show/${locale}`;

export const getEntityModifyRedirectHref = (resource, id) =>
  showResourceHref(resource, id);

export const getLocaleModifyRedirectHref = (basePath, id, data) => {
  const resource = basePath.replace(/-locales/, '');
  const { parentId, locale } = data;
  return `${resource}/${parentId}/show/${locale}`;
};

export const generateSearchParams = (path, params = {}) => {
  const defaultParams = {
    filter: {},
    page: 1,
    perPage: RECORDS_PER_PAGE,
  };

  return `${path}?${Object.entries({ ...defaultParams, ...params })
    .filter(([, value]) => !!value)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          key === 'filter' ? JSON.stringify(value) : value,
        )}`,
    )
    .join('&')}`;
};

export const isPreviewAvailable = (mediaTypes) => [MEDIA_TYPE_VIDEO, MEDIA_TYPE_READING, MEDIA_TYPE_INTERACTIVE].includes(mediaTypes);
export const parseSortParamsToListParams = ({ sort, order }) => ({ field: sort, order });

export const isTestFlow = () => !!(window.location.hostname === 'localhost' && window.Cypress);
export const sortObjByFields = obj => Object.keys(obj).sort().reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {});

export * from './dateTime';
export * from './format';
