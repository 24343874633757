import React, {
  useRef,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/styles';

import ProgressButton from '_ui/Button/ProgressButton';
import { ARCHIVE_PUBLICATION_TYPE_LABELS } from '_domains/Jobs/Publications/constants';
import { getCapLabel } from 'utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      outline: 'none',
    },
  },
  paper: {
    width: '40vw',
    minWidth: 300,
    '& > form > div': {
      display: 'flex',
      flexGrow: 1,
      padding: theme.spacing(),
    },
  },
  textareaContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2, 1)} !important`,
  },
  textarea: {
    width: '100%',
    marginTop: theme.spacing(),
    padding: theme.spacing(),
    resize: 'none',
  },
  button: {
    margin: 0,
  },
}));

const getFormCommentValue = (formElement) => {
  if (formElement) {
    const formData = new FormData(formElement);
    return formData.get('comment');
  }

  return '';
};

const CommentModal = ({
  onSubmit,
  onCancel,
  record,
  submitBtnProps,
  children,
  ...modalProps
}) => {
  const [stateComment, setStateComment] = useState(record.comment || '');
  const formRef = useRef();

  const cancel = useCallback((event) => {
    event.stopPropagation();
    const comment = getFormCommentValue(formRef.current);
    setStateComment(comment);
    onCancel();
  }, [formRef, onCancel]);

  const submit = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const comment = getFormCommentValue(formRef.current);
    setStateComment(comment);
    onSubmit(comment);
  }, [formRef, onSubmit]);

  const classes = useStyles();
  const seleniumId = (record.publicationType || '-').toLowerCase();
  const label = getCapLabel(record.publicationType || '-');

  return (
    <Modal
      {...modalProps}
      className={classes.root}
      data-seleniumid={`${seleniumId}-comment-modal`}
    >
      <>
        <ClickAwayListener onClickAway={cancel} mouseEvent="onMouseDown">
          <Paper classes={{ root: classes.paper }}>
            <form
              ref={formRef}
              onSubmit={submit}
              data-seleniumid={`${seleniumId}-comment-form`}
            >
              <div>
                <Typography variant="h5">
                  {`${label} | ${record.code} ${record.language ? `| ${record.language}` : ''}`}
                </Typography>
              </div>
              <Divider />
              { children ? <>{children}<Divider /></> : <></> }
              <div className={classes.textareaContainer}>
                <InputLabel
                  htmlFor={`${seleniumId}-comment`}
                  data-seleniumid={`${seleniumId}-comment-label`}
                >
                  {label} comment
                </InputLabel>
                <TextareaAutosize
                  autoFocus
                  id={`${seleniumId}-comment`}
                  name="comment"
                  defaultValue={stateComment}
                  rows={6}
                  rowsMax={6}
                  maxLength={250}
                  className={classes.textarea}
                  data-seleniumid={`${seleniumId}-comment-input`}
                />
                <FormHelperText data-seleniumid={`${seleniumId}-comment-helper-text`}>
                  Maximum of 250 characters allowed
                </FormHelperText>
              </div>
              <Divider />
              <div>
                <Button
                  color="default"
                  onClick={cancel}
                  classes={{ root: classes.button }}
                  data-seleniumid={`${seleniumId}-comment-cancel-button`}
                >
                  Cancel
                </Button>
                <Box display="flex" flexGrow={1} />
                <ProgressButton
                  color="primary"
                  type="submit"
                  onClick={submit}
                  classes={{ root: classes.button }}
                  seleniumId={`${seleniumId}-comment-submit-button`}
                  label="Submit"
                  {...submitBtnProps}
                />
              </div>
            </form>
          </Paper>
        </ClickAwayListener>
      </>
    </Modal>
  );
};

CommentModal.propTypes = {
  ...Modal.propTypes,
  children: PropTypes.element,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  record: PropTypes.shape({
    code: PropTypes.string.isRequired,
    comment: PropTypes.string,
    language: PropTypes.string.isRequired,
    publicationType: PropTypes.oneOf(
      Object.keys(ARCHIVE_PUBLICATION_TYPE_LABELS),
    ).isRequired,
  }).isRequired,
  submitBtnProps: PropTypes.object,
};

CommentModal.defaultProps = {
  children: null,
  submitBtnProps: {},
};

export default CommentModal;
