import React from 'react';

import { formatToBasename } from 'utils/format';

const SourceField = (props) => {
  if (!props?.source) {
    return '';
  }
  const { source: { fileName = '', originalFileName = '' } } = props;
  return fileName && (
    <span data-seleniumid="source-filename">
      {(originalFileName || formatToBasename(fileName))}
    </span>
  );
};

export default SourceField;
