import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItemLink as RaMenuItemLink } from 'react-admin';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';

import { toggleMenuItem, getIsExpandedItem } from 'ra-redux/menu';
import { LOCATION_CHANGE_MENU } from 'ra-redux/router';
import { getCapLabel } from 'utils';

const addResourceUri = {
  imports: '/imports/bulk/create',
};

const enumResourcesWithSubmenu = {
  'learning-objects': 1,
  imports: 2,
  publications: 3,
};

Object.freeze(enumResourcesWithSubmenu);

const useStyles = makeStyles(() => ({
  menuStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuItem: {
    width: '100%',
  },
  buttons: {
    position: 'absolute',
    right: 0,
  },
}));

const MenuItemLink = ({
  name,
  primaryText: primaryTextOverride,
  ...props
}) => {
  const {
    selected,
    className,
    sidebarIsOpen,
    onClick,
    to,
    ...rest
  } = props;
  const history = useHistory();

  const dispatch = useDispatch();
  const isExpanded = useSelector(getIsExpandedItem(name));
  const toggleMenu = useCallback(() => {
    dispatch(toggleMenuItem(name));
  }, [name]);

  useEffect(() => {
    if (selected && typeof isExpanded === 'undefined') toggleMenu();
  }, []);

  const primaryText = typeof primaryTextOverride === 'string'
    ? getCapLabel(primaryTextOverride)
    : primaryTextOverride;

  const classes = useStyles();
  const [pathname = null, search = null] = (to || '').split('?');

  return (
    <div className={enumResourcesWithSubmenu[name] ? classes.menuStyle : {}}>
      <RaMenuItemLink
        className={`${classes.menuItem} ${selected === true ? className : ''}`}
        {...rest}
        to={{
          pathname,
          search,
          state: { [LOCATION_CHANGE_MENU]: true },
        }}
        primaryText={primaryText}
        sidebarIsOpen={sidebarIsOpen}
        onClick={() => {
          if (typeof isExpanded === 'undefined') toggleMenu();
          if (onClick) onClick();
        }}
      />
      {
        enumResourcesWithSubmenu[name] && sidebarIsOpen && (
          <div className={classes.buttons}>
            {addResourceUri[name] && (
              <IconButton
                size="small"
                color="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(addResourceUri[name]);
                }}
              >
                <AddCircleIcon />
              </IconButton>
            )}
            <IconButton onClick={toggleMenu} data-seleniumid="expand-toggle-button">
              {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </div>
        )
      }
    </div>
  );
};

MenuItemLink.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  primaryText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
  ]).isRequired,
  selected: PropTypes.bool,
  sidebarIsOpen: PropTypes.bool,
  to: PropTypes.string,
};

export default MenuItemLink;
