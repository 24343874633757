import React, { useState, useMemo, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNotify } from 'react-admin';
import { makeStyles, Button, Fade } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { getSelected, clear } from 'ra-redux/exportList';
import { selectLRs } from 'ra-redux/selectors';
import { download } from './utils';
import ExportModal from './ExportModal';

const useStyles = makeStyles(() => ({
  wrap: {
    height: '3rem',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 1rem',
    alignItems: 'center',
  },
}));

const NOTIFICATION = 'Retrieving data for export';
const DOWNLOAD_BUTTON_LABEL = count => `Download selected ${count} SCORM(s)`;
const CANCEL_BUTTON_LABEL = 'CLEAR';
const CANCEL_BUTTON_HINT = 'Clear Selected SCORM(s)';
const DOWNLOAD_TIMEOUT = 10000;

const ExportPanel = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const dispatch = useDispatch();
  const selected = useSelector(getSelected);
  const LRs = useSelector(selectLRs);

  const exportSources = useMemo(() => {
    if (!selected.length) return [];
    return selected.map(localeId => {
      const [lrId, locale] = localeId.split('_');
      const { code = '' } = LRs[lrId];

      return {
        id: localeId,
        code,
        locale,
      };
    });
  }, [selected, LRs]);

  const click = async (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const onCancelSelection = useCallback(() => {
    dispatch(clear());
  }, [dispatch]);

  const onSubmit = useCallback(async () => {
    download(exportSources.map(el => el.id));
    setOpen(false);
    notify(NOTIFICATION, 'info', {}, false, DOWNLOAD_TIMEOUT);
    dispatch(clear());
  }, [dispatch, exportSources]);

  if (!selected.length) return <div className={classes.wrap} />;
  const title = DOWNLOAD_BUTTON_LABEL(selected.length);
  return (
    <Fade in>
      <div className={classes.wrap}>
        <Button
          size="small"
          color="primary"
          startIcon={<DownloadIcon />}
          onClick={click}
          data-seleniumId="download-all-button"
        >
          {title}
        </Button>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={onCancelSelection}
          data-seleniumId="clear-all-button"
          title={CANCEL_BUTTON_HINT}
        >
          {CANCEL_BUTTON_LABEL}
        </Button>
        <ExportModal
          data={exportSources}
          open={open}
          onCancel={() => setOpen(false)}
          onSubmit={onSubmit}
          title={title}
          seleniumId="download-lr"
        />
      </div>
    </Fade>
  );
};

export default memo(ExportPanel);
