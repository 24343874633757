import React from 'react';
import PropTypes from 'prop-types';
import { SimpleForm } from 'react-admin';

import {
  Create,
  ToolBar,
  TextInput,
  Edit,
} from '_ui';

const sanitizeProps = ({ isCreate, ...props }) => props;

const EditForm = props => {
  const { isCreate } = props;
  return (
    <SimpleForm
      {...sanitizeProps(props)}
      redirect="show"
      toolbar={<ToolBar />}
    >
      <TextInput disabled={!isCreate} source="code" seleniumId="languages-modify-code" />
      <TextInput
        source="name"
        seleniumId="languages-modify-name"
        inputProps={{ autoComplete: 'off' }}
      />
    </SimpleForm>
  );
};

EditForm.propTypes = {
  isCreate: PropTypes.bool,
};

export const LanguagesEdit = props => (
  <Edit undoable={false} {...props} hasShow={false}>
    <EditForm />
  </Edit>
);

export const LanguagesCreate = props => (
  <Create {...props}>
    <EditForm isCreate />
  </Create>
);
