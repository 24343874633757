import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { useTranslate } from 'ra-core';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: ({ isWide }) => ({
    position: 'absolute',
    zIndex: 100,
    top: '50%',
    left: '50%',
    display: 'flex',
    justifyContent: 'space-around',
    width: isWide ? 110 : 50,
    padding: theme.spacing(1),
    transform: 'translate(-50%, -50%)',
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    pointerEvents: 'none',
  }),
  item: {
    backgroundColor: theme.palette.grey[100],
    pointerEvents: 'all',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '&:hover .import-input-delete-icon': {
      color: theme.palette.error.main,
    },
    '&:hover .import-input-drop-icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const ImportInputPanel = ({ seleniumId, onRemove, onDrop }) => {
  const classes = useStyles({ isWide: !!onRemove });
  const translate = useTranslate();

  return (
    <Box className={cn(classes.root, 'import-input-panel')}>
      <IconButton
        onClick={onDrop}
        color="default"
        className={classes.item}
        seleniumId={`${seleniumId}-drop-button`}
      >
        <PhotoCameraIcon className="import-input-drop-icon" />
      </IconButton>
      { onRemove ? (
        <IconButton
          onClick={onRemove}
          aria-label={translate('ra.action.delete')}
          className={classes.item}
          seleniumId={`${seleniumId}-delete-button`}
          title={translate('ra.action.delete')}
        >
          <DeleteIcon className="import-input-delete-icon" />
        </IconButton>
      ) : null }
    </Box>
  );
};

ImportInputPanel.propTypes = {
  onDrop: PropTypes.func,
  onRemove: PropTypes.func,
  seleniumId: PropTypes.string.isRequired,
};

export default ImportInputPanel;
