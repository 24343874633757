import { createElement } from 'react';
import { generatePath } from 'react-router';
import { Link as RrLink } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';

import { formatToBasename } from 'utils/format';
import { ROUTE_PATTERNS } from 'constants/routes';

import { fileImportType } from '_domains/Jobs/Imports/constants';
import { JOB_STATUSES } from '_domains/Jobs/constants';
import {
  ACTION_TIPS_LABEL,
  ACTION_TIPS_PREFIX,
  ACTION_TIPS_TEAM_LABEL,
  ACTION_TIPS_TEAM_PREFIX,
  ESSENTIAL_LABEL,
  ESSENTIAL_PREFIX,
  ESSENTIAL_TEAM_LABEL,
  ESSENTIAL_TEAM_PREFIX,
} from 'constants/actionTips';

const Link = withStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    textDecoration: 'underline',
  },
}))(MuiLink);

export const getPdfUploadLabel = fileName => {
  switch (true) {
    case ACTION_TIPS_TEAM_PREFIX.some(prefix => fileName.startsWith(prefix)):
      return ACTION_TIPS_TEAM_LABEL;
    case ESSENTIAL_TEAM_PREFIX.some(prefix => fileName.startsWith(prefix)):
      return ESSENTIAL_TEAM_LABEL;
    case ACTION_TIPS_PREFIX.some(prefix => fileName.startsWith(prefix)):
      return ACTION_TIPS_LABEL;
    case ESSENTIAL_PREFIX.some(prefix => fileName.startsWith(prefix)):
      return ESSENTIAL_LABEL;
    default:
      return null;
  }
};

const getSuccessMessage = (type, fileName) => {
  switch (type) {
    case fileImportType.LO_EXPORT:
      return 'Import: Successfully exported scorm package(s).';
    case fileImportType.SubtitleUpload:
      return `Import: Subtitles "${fileName}" has been successfully imported.`;
    case fileImportType.PdfUpload:
      return `Import: ${getPdfUploadLabel(fileName)} "${fileName}" has been successfully imported.`;
    default:
      return null;
  }
};

export const getOnCompleteMessage = (importJob, importDetails = []) => {
  const {
    type,
    status,
    source,
    targets,
  } = importJob;

  const fileName = formatToBasename(source?.fileName);
  const withLogMessage = (logLevel, baseMessage) => {
    const [logMessage] = importDetails
      .filter(({ level }) => level.toLowerCase() === logLevel)
      .map(({ message }) => message);

    if (logMessage) return `${baseMessage}${logMessage}`;
    return baseMessage;
  };

  if (!importDetails?.length) {
    return [`Import: ${fileName} upload ${JOB_STATUSES.SUCCESSFUL === status ? 'success' : 'failed'}.`];
  }
  /* eslint-disable no-fallthrough */
  switch (status) {
    case JOB_STATUSES.SUCCESSFUL: {
      let message = 'Import: upload success.';

      if (importDetails?.length === 1) {
        const [entry] = importDetails;
        if (entry.message) message = `Import: ${entry.message}`;
      }
      else {
        message = getSuccessMessage(type, fileName) || message;
      }

      if (targets?.length) {
        const [{ type, id, locale }] = targets;
        const resource = `${type.toLowerCase().replace('_', '-')}s`;
        const params = { resource, id, locale };
        const to = generatePath(ROUTE_PATTERNS.show, params);

        const element = createElement(
          Link,
          { component: RrLink, to },
          message,
        );

        message = element;
      }

      return [message];
    }
    case JOB_STATUSES.FAILED:
    case JOB_STATUSES.PARTIAL:
      return [withLogMessage('error', 'Import: '), 'error'];
    default:
      console.error(`Missing on complete message for ${status}-${type} job`); // eslint-disable-line
      break;
  }
  /* eslint-enable */
};

export const getStartedJobProcessMessage = ({ locale, payload, fileName }) => {
  const { type } = payload;
  switch (type) {
    case fileImportType.LO_THUMBNAIL:
      return `Import: The uploaded "${fileName}" thumbnail is a ${!locale
        ? 'non-'
        : ''}localized thumbnail`;
    default:
      return `Import: "${fileName}" successfully started`;
  }
};
