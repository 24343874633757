/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ReferenceArrayInput, CreateButton, useNotify, useMutation } from 'react-admin';
import PropTypes from 'prop-types';
import { setLocales } from 'ra-redux/locales';
import { withCustomWidthTableStyles } from 'utils/styleUtils';
import { BASE_SEARCH_SOURCE } from 'constants';
import {
  List,
  Datagrid,
  LocaleReferenceField,
  LocaleListField,
  ReferenceField,
  TextField,
  Filter,
  TextInput,
  AutocompleteArrayInput,
  TaxonomyButton,
} from '_ui';
import {
  TAXONOMY_DOWNLOAD_EVENT_TYPE,
  TAXONOMY_DOWNLOAD_MESSAGE,
  generateTaxonomyReport as generateTaxonomyReportUtil,
  getTaxonomyReportParams,
} from 'utils/exports';

const SkillsFilters = props => (
  <Filter {...props} seleniumId="skillsets-filters">
    <TextInput
      alwaysOn
      allowEmpty
      label="Search"
      source={BASE_SEARCH_SOURCE}
      seleniumId="skillsets-filter-search"
      isFilter
    />
    <ReferenceArrayInput
      allowEmpty
      alwaysOn
      label="Language"
      perPage={50}
      reference="languages"
      sort={{ field: 'name', order: 'ASC' }}
      source="availableLocales"
    >
      <AutocompleteArrayInput
        maxValuesArrayLength={1}
        seleniumId="skillsets-filter-languages"
        optionText="code"
        allowDuplicates={false}
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <TextInput
      source="code"
      isFilter
      seleniumId="skillsets-filter-code"
    />
    <ReferenceArrayInput
      label="Discipline"
      source="disciplineId"
      reference="disciplines"
      allowEmpty
      sort={{ field: 'code', order: 'ASC' }}
      data-seleniumid="skillsets-filter-discipline"
    >
      <AutocompleteArrayInput
        minSearchableLength={1}
        seleniumId="skillsets-discipline-input"
        optionText="code"
        allowEmpty={false}
      />
    </ReferenceArrayInput>
  </Filter>
);

export const columnWidth = {
  code: 25,
  title: 25,
  synonym: 25,
  availableLocales: 25,
};

const useStyle = makeStyles(withCustomWidthTableStyles(columnWidth));

const SkillsList = props => {
  const { hasCreate } = props;
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = useNotify();

  const [generate] = useMutation(
    getTaxonomyReportParams('skillsets'),
  );

  const generateTaxonomyReport = useCallback(() => {
    generate();
    const downloadWindow = generateTaxonomyReportUtil('skillsets');
    downloadWindow.addEventListener(TAXONOMY_DOWNLOAD_EVENT_TYPE, () => {
      notify(`Skillsets ${TAXONOMY_DOWNLOAD_MESSAGE}`);
    });
  }, [generate, notify]);

  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={(
        <>
          {hasCreate ? <CreateButton basePath="../skillsets" data-seleniumid="create-skillset-btn" /> : null}
          <TaxonomyButton
            onClick={generateTaxonomyReport}
            data-seleniumid="generate-skillsets-taxonomy-report"
          />
        </>
      )}
      filters={<SkillsFilters />}
      seleniumId="skillsets"
      sort={{ field: 'code', order: 'ASC' }}
    >
      <Datagrid localeRowClick classes={classes}>
        <TextField source="code" data-seleniumid="skill-code" />
        <LocaleReferenceField
          source="id"
          label="Title"
          reference="skillsets-locales"
          link={false}
          sortable={undefined}
          allowEmpty
        >
          <TextField source="title" data-seleniumid="skill-title" />
        </LocaleReferenceField>
        <ReferenceField
          key="disciplineId"
          source="disciplineId"
          label="Discipline"
          reference="disciplines"
          link={false}
        >
          <LocaleReferenceField
            source="id" label="Title" reference="disciplines-locales" link={false}
          >
            <TextField source="title" data-seleniumid="skill-discipline" />
          </LocaleReferenceField>
        </ReferenceField>
        <LocaleListField
          sortable={undefined}
          source="availableLocales"
          label="Available in"
          hideLabel
          targetResource="skillsets"
          onClick={(locale, recordId) => {
            const { basePath } = props;

            dispatch(setLocales(locale));
            history.push(`${basePath}/${recordId}/show/${locale}`);
          }}
        />
      </Datagrid>
    </List>
  );
};

SkillsList.propTypes = {
  basePath: PropTypes.string,
  hasCreate: PropTypes.bool,
};

export default SkillsList;
