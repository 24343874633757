import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { List as RaList } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { useScrollIntoView } from 'utils/list';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from 'constants/pagination';
import { getResourceLoading } from 'ra-redux/loading';
import ListActions from './ListActions';
import Pagination from './Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > [class*="MuiToolbar"]': {
      display: ({ displayToolbar }) => (displayToolbar ? 'flex' : 'none'),
    },
    '& > .MuiToolbar-root': {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      marginBottom: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(2),
    },
  },
}));

const List = ({
  seleniumId,
  displayToolbar,
  actions,
  className,
  isLoading: isLoadingOverride,
  enableScrollIntoView,
  ...props
}) => {
  const isLoading = useSelector(getResourceLoading(props?.resource)) || isLoadingOverride;
  const { isExact } = useRouteMatch();
  const history = useHistory();
  const classes = useStyles({ displayToolbar });

  useScrollIntoView({ isLoading, enableScrollIntoView });
  useEffect(
    () => {
      if (!isExact) history.replace('/404');
    },
    [isExact, history],
  );

  return (
    <RaList
      actions={<ListActions>{actions}</ListActions>}
      classes={classes}
      className={className}
      data-seleniumid={seleniumId}
      pagination={<Pagination seleniumId={seleniumId} rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS} loading={isLoading} />}
      perPage={ROWS_PER_PAGE}
      {...props}
    />
  );
};

List.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  displayToolbar: PropTypes.bool,
  enableScrollIntoView: PropTypes.bool,
  exporter: PropTypes.func,
  isLoading: PropTypes.bool,
  seleniumId: PropTypes.string.isRequired,
};

List.defaultProps = {
  className: null,
  displayToolbar: true,
  exporter: null,
  isLoading: false,
  enableScrollIntoView: true,
};

export default List;
