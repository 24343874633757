import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useParams, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { Link as RrLink } from 'react-router-dom';
import _ from 'lodash';
import { Typography, Box } from '@material-ui/core';

import { ROUTE_PATTERNS } from 'constants/routes';
import { formatToBasename } from 'utils/format';
import { getResourceFromPathname } from 'utils/router';

export const Segment = (props) => (
  <Box display="flex" flexDirection="row" alignItems="center" {...props} />
);

export const Divider = () => (
  <Box marginX={1}>
    <Typography variant="h6">/</Typography>
  </Box>
);

export const Text = ({ seleniumId, ...props }) => (
  <Typography
    color="inherit"
    variant="subtitle1"
    {...props}
    data-seleniumid={seleniumId}
  />
);

Text.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  seleniumId: PropTypes.string.isRequired,
};

export const Link = (props) => (
  <Text
    component={RrLink}
    {...props}
  />
);

Link.propTypes = {
  ...Text.propTypes,
  to: PropTypes.string.isRequired,
};

export const localeBcSeleniumId = 'resource-locale-breadcrumb';

export const Locale = ({ link }) => {
  const { pathname } = useLocation();
  const {
    resource: paramsResource = getResourceFromPathname(pathname),
    id,
    locale: paramsLocale = '',
  } = useParams();
  const resource = paramsResource.replace('-locales', '');
  const locale = paramsLocale.replace('_', '');

  if (locale) {
    const to = generatePath(ROUTE_PATTERNS.show, { resource, id, locale });
    return (
      <Segment>
        <Divider />
        {
          link
            ? <Link seleniumId={localeBcSeleniumId} to={to}>{locale}</Link>
            : <Text seleniumId={localeBcSeleniumId}>{locale}</Text>
        }
      </Segment>
    );
  }

  return null;
};

Locale.propTypes = {
  link: PropTypes.bool,
};

Locale.defaultProps = {
  link: false,
};

export const idBcSeleniumId = 'resource-id-breadcrumb';

export const Id = ({
  field,
  link,
  labelMap,
  seleniumId,
}) => {
  const { pathname } = useLocation();
  const {
    resource: paramsResource = getResourceFromPathname(pathname),
    id,
  } = useParams();
  const resource = paramsResource.replace('-locales', '');
  const resourceData = useSelector((state) => _.get(state, `admin.resources.${resource}.data.${id}`, undefined));
  const fieldValue = formatToBasename(_.get(resourceData || {}, field, undefined));
  const label = labelMap ? labelMap[fieldValue] : fieldValue;
  const to = generatePath(ROUTE_PATTERNS.show, { resource, id });

  return fieldValue
    ? (
      <Segment>
        <Divider />
        {
          link
            ? <Link seleniumId={seleniumId || idBcSeleniumId} to={to}>{label}</Link>
            : <Text seleniumId={seleniumId || idBcSeleniumId}>{label}</Text>
        }
      </Segment>
    )
    : null;
};

Id.propTypes = {
  field: PropTypes.string,
  labelMap: PropTypes.object,
  link: PropTypes.bool,
  seleniumId: PropTypes.string,
};

Id.defaultProps = {
  field: 'code',
  link: false,
  seleniumId: idBcSeleniumId,
};
