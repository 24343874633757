import React from 'react';
import PropTypes from 'prop-types';

import {
  ReferenceInput,
  SimpleForm,
  required,
  regex,
} from 'react-admin';
import { getEntityModifyRedirectHref } from 'utils';
import {
  TextInput,
  ToolBar,
  AutocompleteInput,
  Edit,
  Create,
} from '_ui';

const sanitizeProps = ({ isCreate, ...props }) => props;

const SkillModify = props => {
  const { record: { id }, isCreate } = props;
  const excludeList = id ? [id] : [];
  const codeValidation = [required(), regex(/^\S+$/, '')];
  const disciplineValidation = [required()];

  return (
    <SimpleForm
      {...sanitizeProps(props)}
      redirect={getEntityModifyRedirectHref}
      toolbar={<ToolBar />}
    >
      <TextInput
        disabled={!isCreate}
        source="code"
        validate={codeValidation}
        seleniumId="skillset-modify-code"
      />
      <ReferenceInput
        key="disciplineId"
        source="disciplineId"
        reference="disciplines"
        perPage={50}
        exclude={excludeList}
        validate={disciplineValidation}
        sort={{ field: 'code', order: 'ASC' }}
      >
        <AutocompleteInput
          defaultLabel="Discipline"
          searchLabel="Search Discipline"
          isRefineSearch
          optionValue="id"
          optionText="code"
          seleniumId="skillset-modify"
        />
      </ReferenceInput>
    </SimpleForm>
  );
};

SkillModify.propTypes = {
  isCreate: PropTypes.bool,
  record: PropTypes.object,
};

export const SkillEdit = props => (
  <Edit undoable={false} {...props}>
    <SkillModify />
  </Edit>
);

export const SkillCreate = props => (
  <Create {...props}>
    <SkillModify isCreate={true} />
  </Create>
);
