import { useReducer } from 'react';
import { useNotify } from 'react-admin';

import { map } from 'utils/transform';
import {
  MAX_DOWNLOAD_RETRIES,
  // TODO CKCS-1165
  // DOWNLOAD_ABORT_EXCEPTION,
  // MAX_DOWNLOAD_FETCH_TIMEOUT,
} from './constants';

const MIME = {
  csv: 'text/csv',
  txt: 'text/plain',
  zip: 'application/zip',
};
class DownloadRequest extends Request {
  constructor(source, init = {}) {
    const controller = new AbortController();
    const { retries = 0, ...superInit } = init;
    super(source.url, { signal: controller.signal, ...superInit });

    this.controller = controller;
    this.source = source;
    this.timer = null;
    this.response = null;
    this.blob = null;
    this._retries = retries;
  }

  static requestTimeout = 15000;

  static chunkProcessTimeout = 10000;

  get retries() {
    return this._retries;
  }

  retry() {
    this._retries += 1;
    if (this.retries < MAX_DOWNLOAD_RETRIES) {
      const newInstance = new DownloadRequest(
        this.source,
        { retries: this.retries },
      );

      return newInstance;
    }
  }

  onContent(chunks) {
    this.blob = new Blob(chunks, { type: MIME.zip });
  }

  abort(error) {
    if (error) {
      this.source.error = error;
      console.warn(error.message); // eslint-disable-line
    }
    this.controller.abort();
  }

  async fetch() {
    if (this.timer) return;
    this.timer = setTimeout(() => {
      this.abort();
    }, DownloadRequest.requestTimeout);

    try {
      const response = await fetch(this.source.url);
      this.response = response;
    }
    finally {
      if (this.timer) {
        clearTimeout(this.timer);
      }
    }
  }
}

export const download = (content, type = 'txt', filename) => {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  document.body.appendChild(tempLink);

  const isChunked = Array.isArray(content);
  const isBlob = content instanceof Blob;

  const blob = isBlob
    ? content
    : new Blob(isChunked ? content : [content], { type: MIME[type] });

  const href = URL.createObjectURL(blob);
  tempLink.setAttribute('href', href);
  tempLink.setAttribute('download', `${filename}.${type}`);
  tempLink.setAttribute('data-seleniumid', `export-download-link-${href.split('/').pop()}`);
  tempLink.click();
};

async function* streamAsyncIterator(request) {
  const { response: { body } } = request;
  let error;
  let reader;

  let timer;
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
    if (!request.signal.aborted) {
      return setTimeout(() => {
        request.abort();
      }, DownloadRequest.chunkProcessTimeout);
    }
  };

  try {
    reader = body.getReader();
    timer = resetTimer();
    while (true) {
      if (request.signal.aborted) {
        throw new Error(`Chunck process timeout for ${name}`);
      }

      const { done, value } = await reader.read(); // eslint-disable-line no-await-in-loop
      timer = resetTimer();

      if (done) {
        return;
      }

      yield value;
    }
  }
  catch (chunkProcessError) {
    error = chunkProcessError;
    console.error(error); // eslint-disable-line
  }
  finally {
    if (timer) clearTimeout(timer);
    if (reader) reader.releaseLock();
    yield error;
  }
}

export const getResponseContentLength = (source) => (
  Array.isArray(source) ? source : [source]
).reduce((sum, { headers }) => {
  const contentEncoding = headers.get('content-encoding');
  const contentLength = +headers.get(contentEncoding ? 'x-file-size' : 'content-length');
  return sum + contentLength;
}, 0);

// TODO CKCS-1165
// const abortableAsyncDecorator = (controller, cb) => {
//   const { signal } = controller;
//   if (signal.aborted) {
//     console.log('ABORTED, REJECTING 1');
//     throw DOWNLOAD_ABORT_EXCEPTION;
//   }

//   return (async () => {
//     console.log('ADD ABORT LISTENER');
//     signal.addEventListener('abort', () => {
//       console.log('ABORTED, REJECTING 2');
//       throw DOWNLOAD_ABORT_EXCEPTION;
//     });
//     return cb;
//   })();
// };

const INITIAL_STATE = { loading: false, progress: 0 };
const ACTION_TYPES = {
  TOGGLE_LOADING: 'TOGGLE_LOADING',
  SET_PROGRESS: 'SET_PROGRESS',
  RESET_STATE: 'RESET_STATE',
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.TOGGLE_LOADING: return { ...state, loading: !state.loading };
    case ACTION_TYPES.SET_PROGRESS: return { ...state, progress: payload > 100 ? 0 : payload };
    case ACTION_TYPES.RESET_STATE: return { ...INITIAL_STATE };
    default:
      return new Error(`Nonexistent action type ${type}`);
  }
};

export const useDownload = ({ onSuccessful, onFailed }) => {
  const notify = useNotify();
  const [{
    loading,
    progress,
  }, dispatch] = useReducer(reducer, { ...INITIAL_STATE });

  const createProgressHandler = (total) => (received) => {
    const payload = map(received, 0, total, 0, 100);
    dispatch({ type: ACTION_TYPES.SET_PROGRESS, payload });
  };

  // const controller = new AbortController(); TODO CKCS-1165
  const init = async (exportSources) => {
    const sourceSet = new Set(exportSources);
    // const { signal } = controller; TODO CKCS-1165

    dispatch({ type: ACTION_TYPES.TOGGLE_LOADING });
    for (let retry = 1; retry <= MAX_DOWNLOAD_RETRIES; retry++) {
      for (const source of sourceSet) {
        const { name } = source;
        const request = new DownloadRequest(source);

        try {
          // TODO CKCS-1165
          // if (signal.aborted) {
          //   request.controller.abort();
          //   throw DOWNLOAD_ABORT_EXCEPTION;
          // }
          // signal.onabort = () => {
          //   request.controller.abort();
          //   throw DOWNLOAD_ABORT_EXCEPTION;
          // };
          // signal.onabort(abortHandler);
          await request.fetch(); // eslint-disable-line no-await-in-loop
          if (request.response.ok && !request.signal.aborted) {
            const contentLength = getResponseContentLength(request.response);
            const onProgress = createProgressHandler(contentLength);
            const chunks = [];
            let receivedLength = 0;
            for await (const value of streamAsyncIterator(request)) { // eslint-disable-line no-await-in-loop
              if (value?.length) {
                receivedLength += value.length;
                chunks.push(value);

                onProgress(receivedLength);
              }
            }
            onProgress(0);
            download(chunks, 'zip', name);
            sourceSet.delete(source);
          }
        }
        catch (error) {
          if (error.name === 'AbortError') {
            notify('Download aborted');
            if (!request.signal.aborted) request.controller.abort();
          }
        }
      }
    }

    const successfulSources = sourceSet.size
      ? exportSources.filter(source => !sourceSet.has(source))
      : exportSources;
    const failedSources = sourceSet.size
      ? [...sourceSet]
      : [];

    if (successfulSources.length) onSuccessful(successfulSources);
    if (failedSources.length) onFailed(failedSources);

    dispatch({ type: ACTION_TYPES.RESET_STATE });
  };

  // TODO CKCS-1165
  // function cancel() {
  //   return controller.abort();
  // }

  return {
    loading,
    progress,
    init,
    // cancel, TODO CKCS-1165
  };
};
