import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DownloadIcon from '@material-ui/icons/GetApp';

import ProgressButton from '_ui/Button/ProgressButton';

const Actions = ({ onToggle = () => {}, selectExport, isLoading, exportTypes, seleniumId }) => {
  const [anchor, setAnchor] = useState(null);

  const toggleOpen = (event) => {
    event.stopPropagation();
    onToggle(event?.currentTarget);
    setAnchor(event?.currentTarget);
  };

  const toggleClose = () => {
    onToggle(null);
    setAnchor(null);
  };

  const triggerExport = useCallback((exportType) => (e) => {
    e.stopPropagation();
    setAnchor(null);
    selectExport(exportType);
  }, []);

  return (
    <>
      <ProgressButton
        label="Export"
        color="primary"
        loading={isLoading}
        seleniumId={`${seleniumId}-export-button`}
        onClick={toggleOpen}
        startIcon={<DownloadIcon />}
      />
      <Menu
        id="export-menu"
        data-seleniumid={`${seleniumId}-export-menu`}
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={Boolean(anchor)}
        onClose={toggleClose}
      >
        {
          exportTypes.map((exportType, i) => {
            const key = `${seleniumId}-export-${exportType}`;
            return (
              <MenuItem
                key={key}
                divider={!i}
                data-seleniumid={key}
                onClick={triggerExport(exportType)}
              >
                <Box flex={1} textAlign="center">
                  {exportType.toUpperCase()}
                </Box>
              </MenuItem>
            );
          })
        }
      </Menu>
    </>
  );
};

Actions.propTypes = {
  exportTypes: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool.isRequired,
  onToggle: PropTypes.func,
  selectExport: PropTypes.func.isRequired,
  seleniumId: PropTypes.string.isRequired,
};

export default Actions;
