/* eslint-disable react/prop-types */

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ReferenceArrayInput,
  TextField,
  CreateButton,
  useMutation,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { BASE_SEARCH_SOURCE } from 'constants';
import { withCustomWidthTableStyles } from 'utils/styleUtils';
import {
  TAXONOMY_DOWNLOAD_EVENT_TYPE,
  TAXONOMY_DOWNLOAD_MESSAGE,
  generateTaxonomyReport as generateTaxonomyReportUtil,
  getTaxonomyReportParams,
} from 'utils/exports';
import {
  List,
  Filter,
  Datagrid,
  TextInput,
  AutocompleteArrayInput,
  LocaleReferenceField,
  LocaleListField,
  TaxonomyButton,
} from '_ui';

import SynonymList from './SynonymList';

const TagsFilter = (props) => (
  <Filter {...props} seleniumId="tags-filter">
    <TextInput
      label="Search"
      source={BASE_SEARCH_SOURCE}
      alwaysOn
      allowEmpty
      seleniumId="tags-filter-search"
      isFilter
    />
    <TextInput
      source="code"
      seleniumId="tags-filter-code"
      isFilter
    />
    <ReferenceArrayInput
      allowEmpty
      alwaysOn
      label="Language"
      perPage={50}
      reference="languages"
      sort={{ field: 'name', order: 'ASC' }}
      source="availableLocales"
    >
      <AutocompleteArrayInput
        maxValuesArrayLength={1}
        optionText="code"
        allowEmpty={false}
        seleniumId="tags-filter-languages"
      />
    </ReferenceArrayInput>
  </Filter>
);

const columnWidth = {
  code: 25,
  title: 25,
  synonym: 25,
  availableLocales: 25,
};

const useStyle = makeStyles(withCustomWidthTableStyles(columnWidth));

const TagList = props => {
  const { hasCreate } = props;
  const history = useHistory();
  const notify = useNotify();
  const classes = useStyle();

  const [generate] = useMutation(
    getTaxonomyReportParams('tag'),
  );

  const generateTaxonomyReport = useCallback(() => {
    generate();
    const downloadWindow = generateTaxonomyReportUtil('tag');
    downloadWindow.addEventListener(TAXONOMY_DOWNLOAD_EVENT_TYPE, () => {
      notify(`Tags ${TAXONOMY_DOWNLOAD_MESSAGE}`);
    });
  }, [generate, notify]);
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<TagsFilter />}
      actions={(
        <>
          {
            hasCreate
              ? <CreateButton basePath="../tags" data-seleniumid="create-tag-btn" />
              : null
          }
          <TaxonomyButton
            onClick={generateTaxonomyReport}
            data-seleniumid="generate-tag-taxonomy-report"
          />
        </>
      )}
      seleniumId="tags"
      sort={{ field: 'code', order: 'ASC' }}
    >
      <Datagrid localeRowClick classes={classes}>
        <TextField source="code" />
        <LocaleReferenceField
          source="id"
          label="Title"
          reference="tags-locales"
          link={false}
          sortable={undefined}
          allowEmpty
        >
          <TextField source="title" data-seleniumid="tag-locale-title" />
        </LocaleReferenceField>
        <LocaleReferenceField
          source="id"
          label="Synonym"
          reference="tags-locales"
          link={false}
          sortable={undefined}
          allowEmpty
        >
          <SynonymList source="synonym" seleniumId="tag-synonym" />
        </LocaleReferenceField>
        <LocaleListField
          sortable={undefined}
          source="availableLocales"
          label="Available in"
          hideLabel
          targetResource="tags"
          onClick={(locale, recordId) => {
            const { basePath } = props;
            history.push(`${basePath}/${recordId}/show/${locale}`);
          }}
        />
      </Datagrid>
    </List>
  );
};

export default TagList;
