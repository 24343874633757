import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
  },
  player: {
    height: '100%',
    width: '100%',
  },
});

const VideoPlayerLegacy = ({ config }) => {
  const classes = useStyles();
  const videoRef = useRef();
  const options = {
    autoplay: true,
    playbackRates: [0.5, 1, 1.25, 1.5, 2],
    poster: config.videoThumbnail,
    controls: true,
    controlBar: {
      pictureInPictureToggle: false,
    },
    sources: [
      {
        src: config.video || '',
      },
    ],
    subtitles: config.videoSubtitles || [],
  };

  useEffect(() => {
    videojs(videoRef.current, options);
  });

  return (
    <div className={classes.root}>
      {/*eslint-disable*/}
      <video ref={videoRef} className={`video-js ${classes.player}`}>
        {options.subtitles.map((sub, i) => (
          <track key={i} kind='captions' {...sub} default={!i} />
        ))}
      </video>
    </div>
  );
};

VideoPlayerLegacy.propTypes = {
  config: PropTypes.shape({
    video: PropTypes.string,
    videoSubtitles: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        srcLang: PropTypes.string,
        label: PropTypes.string
      })
    ),
    videoThumbnail: PropTypes.string
  })
};

export default VideoPlayerLegacy;
