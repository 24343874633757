/* eslint-disable react/prop-types */

import React, {
  memo,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { makeStyles, Chip, InputLabel, LinearProgress, Box } from '@material-ui/core';
import {
  MoreHoriz as MoreHorizIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftIcon,
} from '@material-ui/icons';

import { getLocales, getIsDefaultLocale } from 'ra-redux/locales';
import { sortLocales } from 'utils/locales';
import { useDelayed } from 'utils/hooks';
import { ROUTE_PATTERNS } from 'constants/routes';

import { VERTICAL, HORIZONTAL, MIN_VISIBLE_LOCALES } from '../constants';

const IS_TEST_ENV = process.env.NODE_ENV === 'test';

const useStyles = makeStyles((theme) => ({
  root: ({ variant, tableView }) => (
    variant === HORIZONTAL
      ? {
        display: 'flex',
        flexDirection: 'row',
        margin: theme.spacing(1, 0),
      }
      : {
        width: tableView ? '100%' : '8rem',
      }
  ),
  inner: {
    overflow: 'hidden',
  },
  chip: ({ variant }) => ({
    width: variant === VERTICAL ? '100%' : 'auto',
    height: 25,
    marginRight: Number(variant === HORIZONTAL),
    marginBottom: variant === HORIZONTAL ? theme.spacing(1) : 1,
  }),
  highlightIcon: {
    color: '#ffc266',
    width: 20,
  },
  chipIcon: {
    display: 'block !important',
    margin: 'auto !important',
  },
  chipRow: {
    '&::after': {
      content: '',
      display: 'table',
      clear: 'both',
    },
  },
  chipColumn: {
    float: 'left',
    width: '33%',
  },
  moreLocalesChip: {
    display: 'flex',
    width: '33%',
    minWidth: 70,
  },
  tabbedChips: {
    display: 'inline',
  },
  chipStyle: {
    padding: 2,
  },
  toggleChipLabel: { display: 'none' },
}));

const LocaleListField = memo(({
  onClick,
  popupSelected,
  variant,
  minVisible = MIN_VISIBLE_LOCALES[variant],
  record,
  source,
  chipColoring,
  showHidden,
  label,
  hideLabel,
  highlightedLocales,
  seleniumId,
  isLoading,
}) => {
  const isPreLoading = useDelayed();

  const { id } = record;
  const availableLocales = record[source] || [];

  const { pathname, search } = useLocation();
  const isListRoute = !!matchPath(pathname, {
    path: ROUTE_PATTERNS.list,
    exact: true,
  });

  let selectedLocales = [];
  const [selectedLocale] = useSelector(getLocales);
  if (!isListRoute || search.includes('availableLocales')) {
    selectedLocales = [selectedLocale];
  }

  const isDefaultLocale = useSelector(getIsDefaultLocale);
  const availableSelectedLocales = useMemo(
    () => _.intersection(availableLocales, selectedLocales),
    [availableLocales, selectedLocales],
  );

  const locales = popupSelected
    ? (
      _.uniq([
        ...sortLocales(availableSelectedLocales),
        ...sortLocales(availableLocales),
      ])
    )
    : sortLocales(availableLocales);

  const selectedLocaleIndex = locales.indexOf(selectedLocales[0]);
  const collapsed = !(showHidden && selectedLocaleIndex >= minVisible);

  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  useEffect(() => {
    if (isCollapsed && !collapsed) setIsCollapsed(collapsed);
  }, [collapsed]);

  const tableView = variant !== HORIZONTAL;

  const classes = useStyles({ variant, tableView });

  const onClickHandler = useCallback(
    (locale) => (e) => {
      if (!onClick) {
        return;
      }
      e.stopPropagation();
      onClick(locale, id);
    },
    [id, onClick],
  );

  const getChipColumnClasses = (isMoreLocalesChip = false) =>
    (tableView ? `${isMoreLocalesChip ? classes.moreLocalesChip : classes.chipColumn} ${classes.chipStyle}` : classes.tabbedChips);

  return (
    !IS_TEST_ENV && isPreLoading
      ? null
      : (
        <>
          {(label && !hideLabel) ? <InputLabel shrink>{label}</InputLabel> : null}
          { isLoading ? (
            <Box marginX="0" marginY="1.2rem"><LinearProgress /></Box>
          ) : (
            <div className={classes.root} data-seleniumid="locales-list">
              <div className={classes.inner} data-seleniumid={seleniumId}>
                <div className={tableView ? classes.chipRow : ''}>
                  {
                    (
                      isCollapsed
                        ? locales.slice(0, minVisible)
                        : locales
                    ).map((locale) => {
                      const isSelected = availableSelectedLocales.includes(locale) && chipColoring && !isDefaultLocale;
                      const { tooltip, icon: Icon } = highlightedLocales[locale] || {};

                      return (
                        (
                          <div
                            key={`${id}-${locale}`.toLowerCase()}
                            className={getChipColumnClasses()}
                          >
                            <Chip
                              className={cn({ selected: isSelected })}
                              title={tooltip || ''}
                              label={locale}
                              color={isSelected ? 'secondary' : 'primary'}
                              onClick={onClickHandler && onClickHandler(locale)}
                              classes={{ root: classes.chip }}
                              data-seleniumid={`locale-link-${locale}`}
                              icon={Icon ? <Icon data-seleniumid={`locales-list-${locale}-warning`} className={classes.highlightIcon} /> : null}
                            />
                          </div>
                        )
                      );
                    },
                    )
                  }
                  {
                    locales.length > minVisible && (
                      <div className={getChipColumnClasses(true)}>
                        <Chip
                          icon={(
                            // eslint-disable-next-line no-nested-ternary
                            isCollapsed
                              ? <MoreHorizIcon data-seleniumid="expand-button-closed" classes={{ root: classes.chipIcon }} />
                              : variant === VERTICAL
                                ? <KeyboardArrowUpIcon data-seleniumid="expand-button-opened" classes={{ root: classes.chipIcon }} />
                                : <KeyboardArrowLeftIcon data-seleniumid="expand-button-opened" classes={{ root: classes.chipIcon }} />
                          )}
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsCollapsed(!isCollapsed);
                          }}
                          classes={{
                            root: classes.chip,
                            label: classes.toggleChipLabel,
                          }}
                        />
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          )}
        </>
      )
  );
});

LocaleListField.propTypes = {
  chipColoring: PropTypes.bool,
  hideLabel: PropTypes.bool,
  highlightedLocales: PropTypes.object,
  isLoading: PropTypes.bool,
  minVisible: PropTypes.number,
  onClick: PropTypes.func,
  popupSelected: PropTypes.bool,
  seleniumId: PropTypes.string,
  showHidden: PropTypes.bool,
  variant: PropTypes.oneOf([
    HORIZONTAL,
    VERTICAL,
  ]),
};

LocaleListField.defaultProps = {
  minVisible: undefined,
  chipColoring: true,
  onClick: null,
  popupSelected: true,
  isLoading: false,
  showHidden: false,
  variant: VERTICAL,
  hideLabel: false,
  highlightedLocales: {},
  seleniumId: 'locales',
};

export default LocaleListField;
