import _ from 'lodash';
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ExportButton } from '_ui';
import { EXPORT_DATE_RANGE_FILTER } from '_domains/Jobs/constants';

import { parseValue } from 'utils';
import { EXPORT_TYPES, useExport } from 'utils/export';
import { useDataProvider } from 'react-admin';
import { EXPORT_FIELDS_MAP } from '../constants';
import { prepareLogsForExport } from '../utils';

const PublicationsListActions = ({ onExportMenuToggle }) => {
  const [exportType, setExportType] = useState(null);
  const [publicationCreators, setPublicationCreators] = useState([]);
  const dataProvider = useDataProvider();
  useEffect(() => {
    const fetchData = async () => {
      const { data: publicationCreators = [] } = await dataProvider.getList(
        'publication-creators',
      ) || {};

      setPublicationCreators(publicationCreators);
    };
    fetchData();
  }, []);

  const finishExport = useCallback(() => {
    setExportType(null);
    onExportMenuToggle(null);
  }, []);

  const getExportEntities = useCallback(({
    mainEntities,
    detailsEntities,
    ...options
  }) => prepareLogsForExport(
    mainEntities,
    detailsEntities,
    publicationCreators.length ? _.keyBy(publicationCreators, 'id') : {},
    options,
  ), [publicationCreators]);

  const getMainEntityFilter = useCallback((filterValues) => {
    let filter = filterValues;
    if (!Object.keys(filter).some((key) => ['published', 'created'].includes(key))) {
      const { gte, lte } = EXPORT_DATE_RANGE_FILTER;
      filter = {
        ...filter,
        created: {
          gte: parseValue(gte, 'created.gte'),
          lte: parseValue(lte, 'created.lte'),
        },
      };
    }
    return filter;
  }, []);

  const { inProgress } = useExport({
    resource: 'publications',
    detailResource: 'publication-logs',

    exportType,
    exportFields: EXPORT_FIELDS_MAP,
    onFulfill: finishExport,
    getMainEntityFilter,
    getExportEntities,
  });

  const triggerExport = useCallback((exportType) => {
    setExportType(exportType);
  }, []);

  return (
    <>
      <ExportButton
        seleniumId="publications"
        onToggle={onExportMenuToggle}
        selectExport={triggerExport}
        isLoading={!publicationCreators.length || inProgress}
        exportTypes={EXPORT_TYPES}
      />
    </>
  );
};

PublicationsListActions.propTypes = {
  onExportMenuToggle: PropTypes.func,
};

export default PublicationsListActions;
