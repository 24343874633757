import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { useSelector } from 'react-redux';
import { useGetList } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { selectLocaleLR } from 'ra-redux/selectors';
import WarningBox from '_ui/Layout/WarningBox';
import {
  isStaleSubtitles,
  isWithoutVideos,
  isWithoutSubtitles,
  isWithoutPDF,
} from '_domains/LearningResource/LearningResourceView/Actions/utils';
import {
  STALE_SUBTITLE_MESSAGE,
  THERE_ARE_NO_SUBTITLES_MESSAGE,
  THERE_ARE_NO_VIDEOS_MESSAGE,
  THERE_ARE_NO_PDF_MESSAGE,
} from '../Actions/constants';

const useStyles = makeStyles({
  warning: {
    borderRadius: 0,
  },
});

const WarningPanel = ({ record, locale }) => {
  const {
    id,
    mainVideoExists: lrMainVideoExists,
    availableLocales = [],
    directlyUploadedVideo,
    directlyUploadedPdf,
    contentName,
  } = record || {};
  const classes = useStyles();
  const localeEntity = useSelector(selectLocaleLR(`${id}_${locale}`)) || {};
  const isLocaleEntity = !isEmpty(localeEntity);

  const { data: contentNames } = useGetList('content-names');

  const {
    state,
    staleSubtitles,
    subtitlesExists,
    embeddedSubtitles,
    mainVideoExists,
    localizedVideoExists,
    localizedPdfExists,
  } = localeEntity;

  const withoutSubtitles = isWithoutSubtitles({
    directlyUploadedVideo,
    subtitlesExists,
    embeddedSubtitles,

    lrMainVideoExists,
    isLocalesAvailable: !!availableLocales.length,
  });

  const withoutVideos = isLocaleEntity && isWithoutVideos({
    directlyUploadedVideo,
    mainVideoExists,
    localizedVideoExists,
  });

  const withoutPDF = isLocaleEntity && isWithoutPDF({ directlyUploadedPdf, localizedPdfExists });

  const isStale = isLocaleEntity && isStaleSubtitles({ staleSubtitles, state });

  if (!isStale && !withoutSubtitles && !withoutVideos && !withoutPDF) return null;

  const warnings = [
    {
      condition: isStale,
      seleniumId: 'stale-subtitle-warning',
      message: STALE_SUBTITLE_MESSAGE,
    },
    {
      condition: withoutVideos,
      seleniumId: 'video-doesnt-have-subtitles-warning',
      message: THERE_ARE_NO_VIDEOS_MESSAGE,
    },
    {
      condition: withoutSubtitles,
      seleniumId: 'subtitle-doesnt-have-videocast-warning',
      message: THERE_ARE_NO_SUBTITLES_MESSAGE,
    },
    {
      condition: withoutPDF,
      seleniumId: 'pdf-doesnt-have-warning',
      message: `${THERE_ARE_NO_PDF_MESSAGE} ${get(contentNames, `${contentName}.name`, '')}.`,
    },
  ];
  return (
    <>
      {warnings.map(({ condition, seleniumId, message }) =>
        (condition
          ? (
            <WarningBox
              seleniumId={seleniumId}
              className={classes.warning}
            >
              {message}
            </WarningBox>
          )
          : ''),
      )}
    </>
  );
};

WarningPanel.propTypes = {
  locale: PropTypes.string,
  record: PropTypes.shape({
    availableLocales: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    // flag on LR level
    mainVideoExists: PropTypes.bool,
  }),
};

export default WarningPanel;
