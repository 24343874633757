import { cloneElement } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const UserInput = ({ children, ...props }) => {
  const { users, systems } = useSelector((state) => _.get(state, 'admin.resources'));
  const choices = Object.values({ ...users.data, ...systems.data }).map(({ id, name, email }) => ({ id, name: email || name }));
  return cloneElement(children, { optionText: 'name', choices, ...props });
};

export default UserInput;
