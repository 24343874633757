import { cloneElement } from 'react';
import _ from 'lodash';
import {
  LR_LOCALE_CURRENTLY_EDITING_STATE,
  LR_LOCALE_PUBLISHED_STATE,
  LR_LOCALE_ARCHIVED_STATE,
} from 'constants/localeStates';
import { getCapLabel } from 'utils';
import {
  LR_REQUIRED_FOR_PUBLISH,
  LR_LOCALE_REQUIRED_FOR_PUBLISH,
  LR_REQUIRED_FIELD_LIST,
  THERE_ARE_NO_VIDEOS_MESSAGE,
  THERE_ARE_NO_SUBTITLES_MESSAGE,
  THERE_ARE_NO_PDF_MESSAGE,
} from './constants';

export const isWithoutSubtitles = ({
  directlyUploadedVideo,
  subtitlesExists,
  embeddedSubtitles,

  lrMainVideoExists,
  isLocalesAvailable,
}) => ([directlyUploadedVideo, subtitlesExists, embeddedSubtitles].every(el => typeof el === 'boolean') && directlyUploadedVideo && !subtitlesExists && !embeddedSubtitles)
  || (lrMainVideoExists && !isLocalesAvailable);

export const isWithoutVideos = ({
  directlyUploadedVideo,
  mainVideoExists,
  localizedVideoExists,
}) => directlyUploadedVideo && !mainVideoExists && !localizedVideoExists;

export const isWithoutPDF = ({
  directlyUploadedPdf,
  localizedPdfExists,
}) => directlyUploadedPdf && !localizedPdfExists;

export const isStaleSubtitles = ({ staleSubtitles, state }) => staleSubtitles && state === LR_LOCALE_CURRENTLY_EDITING_STATE;

export const isShowPublish = state => state === LR_LOCALE_CURRENTLY_EDITING_STATE;
export const isShowPublishAll = locales => (locales || []).filter(el => el.state === LR_LOCALE_CURRENTLY_EDITING_STATE).length > 0;
export const isShowArchive = state => [LR_LOCALE_CURRENTLY_EDITING_STATE, LR_LOCALE_PUBLISHED_STATE].includes(state);
export const isShowRetrieveFromArchive = state =>
  state === LR_LOCALE_ARCHIVED_STATE;
export const isShowDelete = ({ state, firstPublished } = {}) => [
  LR_LOCALE_CURRENTLY_EDITING_STATE,
  LR_LOCALE_ARCHIVED_STATE,
].includes(state) && !firstPublished;
export const isShowEdit = state => [LR_LOCALE_CURRENTLY_EDITING_STATE, LR_LOCALE_PUBLISHED_STATE].includes(state);

export const validateLR = (record) => {
  const emptyRequiredFields = [];
  LR_REQUIRED_FOR_PUBLISH.forEach(({ field, name }) => {
    if (_.isNil(record[field])) {
      emptyRequiredFields.push({ name, field });
    }
  });
  return emptyRequiredFields;
};

const validateLRLocale = (localeRecord) => {
  const emptyRequiredFields = [];
  LR_LOCALE_REQUIRED_FOR_PUBLISH.forEach(({ field, name }) => {
    if (_.isNil(localeRecord[field])) {
      emptyRequiredFields.push({ name, field });
    }
  });
  return emptyRequiredFields;
};

export const validateRequiredForPublish = (record, localeRecord, contentName) => {
  const { directlyUploadedVideo, directlyUploadedPdf } = record || {};

  const emptyRequiredFields = [...validateLR(record), ...validateLRLocale(localeRecord)];
  const errorText = 'Required Fields are missing: ';
  const withoutSubtitles = isWithoutSubtitles({ directlyUploadedVideo, ...localeRecord });
  const withoutVideos = isWithoutVideos({ directlyUploadedVideo, ...localeRecord });
  const withoutPdf = isWithoutPDF({ directlyUploadedPdf, ...localeRecord });

  const errors = [];
  if (emptyRequiredFields.length) errors.push(errorText + emptyRequiredFields.map(({ name }) => name).join(', '));
  if (withoutVideos) errors.push(THERE_ARE_NO_VIDEOS_MESSAGE);
  if (withoutSubtitles) errors.push(THERE_ARE_NO_SUBTITLES_MESSAGE);
  if (withoutPdf) errors.push(`${THERE_ARE_NO_PDF_MESSAGE} ${contentName}.`);

  return errors.length ? errors.join('; ') : '';
};

export const isRequiredForPublish = (source) => source && LR_REQUIRED_FIELD_LIST.includes(source.toLowerCase());
export const addAsteriskToLabelProps = (props) => {
  let labeledProps = Object.entries(props || {}).reduce((acc, [key, value]) => {
    const isLabelProp = typeof value === 'string' && key.toLowerCase().includes('label');

    return isLabelProp
      ? { ...acc, [key]: isLabelProp ? `${value} *` : value }
      : acc;
  }, {});

  if (_.isEmpty(labeledProps) && props.source) {
    labeledProps = { label: `${getCapLabel(props.source)} *` };
  }

  return { ...props, ...labeledProps };
};
export const markComponentRequiredForPublish = (Component) => {
  const { props: componentProps } = Component;
  const source = Component.type?.displayName === 'LocaleReferenceField'
    ? componentProps.children.props.source
    : componentProps.source;

  if (isRequiredForPublish(source)) {
    if (componentProps.children) {
      return cloneElement(Component, {
        ...addAsteriskToLabelProps(componentProps),
        children: cloneElement(
          componentProps.children,
          addAsteriskToLabelProps(componentProps.children.props),
        ),
      });
    }

    return cloneElement(Component, addAsteriskToLabelProps(componentProps));
  }

  return Component;
};
