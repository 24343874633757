import { useState, useEffect } from 'react';
import { useListContext, useDataProvider, useNotify } from 'react-admin';
import chunk from 'lodash/chunk';

import format from 'date-fns/format';

import { getCapLabel } from 'utils';
import exporter from './exporter';
import {
  REQUEST_LIMIT,
  NO_DATA,
  MAIN_ERROR,
  EXPORT_FIELDS_MAP,
  FILE_NAME_DATE_FORMAT,
  FILE_NAME_DATE_TIME_FORMAT,
} from './constants';
import { getExportDateRange } from './format';

export const formatFileNameDateTime = (date, time = true) => format(
  new Date(date),
  time ? FILE_NAME_DATE_TIME_FORMAT : FILE_NAME_DATE_FORMAT,
);

export const getDefaultFileName = async (
  {
    exportEntries,
    filterValues,
  },
  resource,
  dataProvider,
) => {
  let fileNameParts = [getCapLabel(resource), 'report'];

  const {
    status = [],
    availableLocales = [],

    publicationType = [],
    type = publicationType,

    publishedBy = [],
    creator = publishedBy,
  } = filterValues;

  const {
    lte,
    gte,
    dateRange = [lte, gte],
  } = getExportDateRange(exportEntries, (date) => formatFileNameDateTime(date, false));
  const date = lte === gte ? gte : dateRange.join('_');

  const { data: usersData = [] } = await dataProvider.getMany(
    'users',
    { ids: creator },
  );

  const users = usersData.map(({ name }) => name);
  let initiatedBy = users;

  if (creator.length > users.length) {
    const systemIds = creator.filter(id => !users.includes(id));
    const { data: systemsData = [] } = await dataProvider.getMany(
      'systems',
      { ids: systemIds },
    );
    const systems = systemsData.map(({ email }) => email);
    initiatedBy = [...initiatedBy, ...systems];
  }

  fileNameParts = [
    ...fileNameParts,
    ...status,
    ...type,
    ...availableLocales,
    ...initiatedBy,
    date,
  ];

  return fileNameParts.filter(Boolean).join('.');
};

/**
 * @param {string} resource The resource name, e.g. 'imports' for search main entities
 * @param {string} detailResource The detailResource name, e.g. 'import-logs' for getById detail entities
 * @param {array} entitiesIds The resource identifiers. This prop skips first step with searching and get details for ids
 *
 * @param exportType The export type (one of EXPORT_TYPES)
 * @param {array} exportFields Field name to column heading map (EXPORT_FIELDS_MAP)
 * @param {Function} onFulfill Export completion function (success and failed flows)
 *
 * @param {Function} getMainEntityFilter Generate resource filter values for main entities search
 * @param {Function} getExportEntities Prepare entities for export based on search info and details info
 * @param {Function} getFileName Generate filename asynchronously
 *
 * @param {number} detailsLimit Set chunk size for details request
 */
const useExport = ({
  resource,
  detailResource,
  entitiesIds = [],

  exportType,
  exportFields = EXPORT_FIELDS_MAP,
  onFulfill,

  getMainEntityFilter,
  getExportEntities,
  getFileName = getDefaultFileName,

  detailsLimit = REQUEST_LIMIT,
}) => {
  const [inProgress, setInProgress] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const listContext = useListContext();
  const { currentSort, filterValues = {}, total } = listContext;
  const listParams = { sort: currentSort, pagination: { page: 1, perPage: total } };

  useEffect(() => {
    if (!exportType || inProgress) return;
    let didCancel = false;

    async function load(filter) {
      async function getEntities(filter) {
        try {
          const payload = { ...listParams, filter };
          const { data = [] } = await dataProvider.getList(resource, payload);
          return data;
        }
        catch (error) { return []; }
      }

      async function getDetails(detailIds) {
        const idChunks = chunk(detailIds, detailsLimit);
        const detailsSet = new Set();
        try {
          for (const ids of idChunks) {
            const payload = { ids };
            const { data = [] } = await dataProvider.getMany(detailResource, payload); // eslint-disable-line no-await-in-loop
            for (const detailEntry of data) {
              detailsSet.add(detailEntry);
            }
          }

          return [...detailsSet];
        }
        catch (error) { return []; }
      }

      setInProgress(true);
      try {
        if (didCancel) return;
        const mainEntities = await (
          entitiesIds.length
            ? Promise.resolve(entitiesIds.map(id => ({ id })))
            : getEntities(filter)
        );

        if (!mainEntities.length) throw new Error(NO_DATA);

        if (didCancel) return;

        const detailsEntities = await getDetails(mainEntities.map(({ id }) => id));
        let { data: creators = [] } = await dataProvider.getList(`${resource.slice(0, -1)}-creators`); // eslint-disable-line no-await-in-loop
        creators = creators.reduce((
          acc,
          { id: creatorId, name, email },
        ) => ({ ...acc, [creatorId]: name || email }), {});

        const exportEntries = getExportEntities({
          exportFields,
          mainEntities,
          detailsEntities,
          creators,
        });
        if (!exportEntries.length) throw new Error(NO_DATA);

        if (didCancel) return;
        const fileName = await getFileName(
          {
            exportType,
            mainEntities,
            exportEntries,
            filterValues,
          },
          resource,
          dataProvider,
        );

        const rename = Object.values(exportFields);
        exporter({
          exportType,
          exportEntries,
          fileName,
          rename,
        });
      }
      catch (error) {
        const message = [NO_DATA].includes(error?.message) ? error?.message : MAIN_ERROR;
        // eslint-disable-next-line no-console
        console.error('export: ', error);
        notify(message, 'warning');
      }
      finally {
        setInProgress(false);
        onFulfill();
      }
    }

    const filters = getMainEntityFilter(filterValues);

    load(filters);
    return () => {
      didCancel = true;
    };
  }, [exportType]);

  return { inProgress };
};

export default useExport;
