import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Datagrid,
  TextField,
  ReferenceManyField,
  FunctionField,
} from 'react-admin';
import { withCustomWidthTableStyles } from 'utils/styleUtils';
import { DataWithEmptyMessage, DateField } from '_ui';

const columnWidth = {
  date: 20,
  level: 10,
  message: 70,
};

const useStyle = makeStyles(withCustomWidthTableStyles(columnWidth));

export const addLinks = (message, target) => {
  const safeStringMessage = message.replace(/(<([^>]+)>)/ig, '');
  let result = safeStringMessage;
  const { id, locale, code, type } = target || {};

  if (id && code && type) {
    result = result
      .replace(code, `<a href="/#/${type.toLowerCase()}s/${id}/show${locale ? `/${locale}` : ''}">${code}</a>`);
  }

  return result;
};

const sanitizeProps = ({ basePath, resource }) => ({ basePath, resource });

const PublicationLogsField = props => {
  const classes = useStyle();
  return (
    <ReferenceManyField
      {...props}
      reference="publication-logs"
      target="publicationId"
      sort={{ field: 'date', order: 'ASC' }}
    >
      <DataWithEmptyMessage emptyLogsMessage="No logs available">{(props) => (
        <Datagrid {...sanitizeProps(props)} classes={classes}>
          <DateField source="date" data-seleniumid="log-field-date" />
          <TextField source="level" data-seleniumid="log-field-level" />
          <FunctionField
            {...sanitizeProps(props)}
            render={({ message, targets }) => (
              <span
                data-seleniumid="log-field-message"
                dangerouslySetInnerHTML={{ __html:
                    addLinks(message, targets && targets[0] ? targets[0] : null) }}
              />
            )}
          />
        </Datagrid>
      )}
      </DataWithEmptyMessage>
    </ReferenceManyField>
  );
};

PublicationLogsField.defaultProps = {
  addLabel: true,
  label: 'Publication Report',
};

export default PublicationLogsField;
