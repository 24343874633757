import React from 'react';

import {
  ReferenceField,
} from 'react-admin';

import {
  TextField,
  LocaleReferenceField,
  SimpleShowLayout,
  ChipLink,
  TabbedLanguagesView,
} from '_ui';

const sanitizeProps = ({ languageSpecificFields, languageAgnosticFields, hasCreate, hasShow, hasEdit, hasList, ...props }) => props;

const ThemesView = (props) => (
  <TabbedLanguagesView
    {...props}
    resource="themes"
    languageAgnosticFields={(props) => (
      <SimpleShowLayout {...props}>
        <TextField source="code" seleniumId="themes-code" />
        <ReferenceField source="parentId" label="Parent Theme" reference="themes" link={false}>
          <ChipLink seleniumId="themes-parent" />
        </ReferenceField>
      </SimpleShowLayout>
    )}
    languageSpecificFields={(props) => (
      <SimpleShowLayout {...props}>
        {[
          ['Title', 'title', 'themes-locale-title'],
        ].map(([label, source, seleniumId]) => (
          <LocaleReferenceField
            {...sanitizeProps(props)}
            key={`themes-field-${source}`}
            addLabel
            link={false}
            source="id"
            label={label}
            reference="themes-locales"
            usePriorityLocale={false}
          >
            <TextField showProgress source={source} seleniumId={seleniumId} />
          </LocaleReferenceField>
        ))}
      </SimpleShowLayout>
    )}
  />
);

export default ThemesView;
