import { noWhiteSpaceTag } from 'utils';
import { ERROR_ID, ERROR_TEXT, LOADING_ID, LOADING_TEXT } from '../constants';

const REPORT_CLASS = 'preview-report';
const REDUNDANT_SLASH_REGEX = /(\w)(\/{2,})/g;

const normalizeSrc = (src) => src.replace(REDUNDANT_SLASH_REGEX, '$1/');

const hideLoadingInfo = (wd) => {
  const report = wd.document.querySelector('.preview-report');
  if (report) report.style.display = 'none';
};

export const addLoadingInfo = (wd) => {
  const report = wd.document.createElement('div');
  report.setAttribute('data-seleniumid', LOADING_ID);
  report.setAttribute('class', REPORT_CLASS);
  report.setAttribute('style', 'font-size:1.2rem;');
  report.innerHTML = LOADING_TEXT;
  wd.document.body.append(report);
};

export const initWindow = (code, locale) => {
  const wd = window.open('about:blank');
  wd.document.title = `${code} / ${locale}`;
  addLoadingInfo(wd);
  return wd;
};

export const addErrorInfo = (wd, rootCause) => {
  if (!wd) return;

  const report = wd.document.querySelector(`.${REPORT_CLASS}`);
  if (!report) return;

  report.setAttribute('data-seleniumid', ERROR_ID);
  report.innerHTML = noWhiteSpaceTag`
    <p>${ERROR_TEXT}</p>
    ${(rootCause && process.env.NODE_ENV === 'development')
    ? `<p style="color: red;">${rootCause}</p>`
    : ''}
  `;
};

export const loadInFrame = (wd, links) => {
  const timers = [];
  const FRAME_ID = 'rootFrame';
  const frameElement = wd.document.createElement('iframe');
  frameElement.setAttribute('id', FRAME_ID);

  hideLoadingInfo(wd);
  const styleElement = wd.document.createElement('style');
  styleElement.append('body { margin: 0; padding: 0; } iframe { border: 0; width: 100vw; height: 100vh; }');
  wd.document.head.append(styleElement);
  wd.document.body.append(frameElement);

  const renderedFrameElement = wd.document.querySelector(`#${FRAME_ID}`);
  if (renderedFrameElement) {
    // eslint-disable-next-line func-names
    renderedFrameElement.addEventListener('load', function() {
      const frameBody = this.contentWindow.document.body;

      const timer = setInterval(() => {
        const images = frameBody.getElementsByTagName('img');
        Array.from(images).forEach((element) => {
          const imgSrc = element.getAttribute('src');
          if (REDUNDANT_SLASH_REGEX.test(imgSrc)) {
            const normalizedSrc = normalizeSrc(imgSrc);
            element.src = `${normalizedSrc}?v=${new Date().getTime()}`;
          }
        });
      }, 1000);
      timers.push(timer);
    });

    renderedFrameElement.setAttribute('src', links);
  }
  return timers;
};
