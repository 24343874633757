const CONTEXT = window.location.pathname;
export const APP_URL = `${window.location.origin}${CONTEXT}`;
export const APP_CONF_URL = `${APP_URL}UIAppConfig.json`;
export const RESOURCE_PREFIX = `${CONTEXT}api/v1`;
export const URL_AUTHENTICATE = `${RESOURCE_PREFIX}/authenticate`;
export const SRC_PREFIX = `${RESOURCE_PREFIX}/contents`;
export const PUT = 'put';
export const POST = 'post';
export const PATCH = 'patch';
export const API_URL = () => `${window.ENDPOINT_BASE_URL}${RESOURCE_PREFIX}`;
