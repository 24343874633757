import { SHOW_NOTIFICATION } from 'react-admin';
import { LOCATION_CHANGE } from 'connected-react-router';

import { IS_TEST_ENV } from 'constants';

import { DEFAULT_HIDE_DURATION } from '_ui/Layout/Notification/constants';
import { IMPORT_HIDE_DURATION } from '_domains/Jobs/Imports/constants';

const NOTIFICATION_TYPES = ['warning', 'error'];
const NOTIFICATION_DELAY = 1000;
const VISIBLE_NOTIFICATIONS = 3;

const timers = NOTIFICATION_TYPES.reduce((acc, type) => ({ ...acc, [type]: null }), {});
const notifications = NOTIFICATION_TYPES.reduce((acc, type) => ({ ...acc, [type]: [] }), {});

const debounceNotification = ({ type, message, ...rest }, next) => {
  notifications[type].push(message);
  if (timers[type]) {
    clearTimeout(timers[type]);
  }

  timers[type] = setTimeout(() => {
    const messages = notifications[type].slice(-VISIBLE_NOTIFICATIONS).join(' | ');
    notifications[type] = [];
    next({
      type: SHOW_NOTIFICATION,
      payload: { type, message: messages, ...rest },
    });
  }, NOTIFICATION_DELAY);
};

const clearTimers = () => NOTIFICATION_TYPES.forEach(type => timers[type] && clearTimeout(timers[type]));
const clearNotifications = () => NOTIFICATION_TYPES.forEach(type => { notifications[type] = []; });
const reset = () => {
  clearTimers();
  clearNotifications();
};

export default () => (next) => (action) => {
  if (action.type === LOCATION_CHANGE) reset();
  if (action.type !== SHOW_NOTIFICATION) return next(action);

  const { payload = {} } = action;
  const {
    type,
    message,
    autoHideDuration = /^Import:/.test(message)
      ? IMPORT_HIDE_DURATION
      : DEFAULT_HIDE_DURATION,
  } = payload;
  payload.autoHideDuration = autoHideDuration;

  if (!IS_TEST_ENV && NOTIFICATION_TYPES.includes(type) && message) {
    debounceNotification(payload, next);
  }
  else {
    next(action);
  }

  return null;
};
