import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import {
  SimpleForm,
  required,
  RadioButtonGroupInput,
} from 'react-admin';
import {
  TextField,
  TextInput,
  ToolBar,
  Create,
  Edit,
} from '_ui';
import { getEntityModifyRedirectHref } from 'utils';
import { REGEX_GMO_CODE } from 'constants/regex';
import { validateXregexp } from 'utils/regex';
import { REPUTATION_OPTIONS } from './constants';

const validateCode = [required(), validateXregexp({ regExp: new RegExp(`^${REGEX_GMO_CODE.source}$`), message: 'Incorrect code' })];

const AuthorPropsEditForm = props => {
  const { record } = props;

  return (
    <SimpleForm
      {...props}
      toolbar={<ToolBar />}
      redirect={getEntityModifyRedirectHref}
    >
      {
        _.isEmpty(record)
          ? (<TextInput source="code" seleniumId="author-modify-code" validate={validateCode} />)
          : (<TextField source="code" seleniumId="author-modify-code" />)
      }
      <RadioButtonGroupInput
        source="reputation"
        choices={REPUTATION_OPTIONS}
        validate={required()}
        data-seleniumid="author-modify-reputation"
        helperText={false}
      />
    </SimpleForm>
  );
};

AuthorPropsEditForm.propTypes = {
  record: PropTypes.object,
};

export const AuthorsEditProps = props => (
  <Edit undoable={false} {...props}>
    <AuthorPropsEditForm />
  </Edit>
);

export const AuthorsCreateProps = props => (
  <Create {...props}>
    <AuthorPropsEditForm />
  </Create>
);
