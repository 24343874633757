import React from 'react';
import {
  SimpleForm,
} from 'react-admin';
import { getLocaleModifyRedirectHref } from 'utils';
import {
  TextInput,
  TextField,
  ToolBar,
  ReferenceField,
  Edit,
} from '_ui';

const ProgramsEditLocale = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm
      redirect={getLocaleModifyRedirectHref}
      toolbar={<ToolBar />}
      autoComplete="off"
    >
      <ReferenceField
        link={false}
        source="parentId"
        reference="programs"
        label="Program Code"
      >
        <TextField source="code" seleniumId="program-locale-modify-code" />
      </ReferenceField>
      <TextField source="locale" label="Language" seleniumId="program-locale-modify-locale" />
      <TextInput source="title" seleniumId="program-locale-modify-title" />
      <TextInput
        style={{ width: '100%' }}
        multiline
        label="Abstract"
        source="summary"
        seleniumId="program-locale-modify-abstract"
      />
    </SimpleForm>
  </Edit>
);

export default ProgramsEditLocale;
