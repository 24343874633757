import React, {
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  showNotification,
  useMutation,
  useNotify,
  useDataProvider,
  useRefresh,
} from 'react-admin';
import {
  LR_LOCALE_CURRENTLY_EDITING_STATE,
} from 'constants/localeStates';
import { useDispatch, useSelector } from 'react-redux';
import { addPublications } from 'ra-redux/publications';
import { getContentName } from 'ra-redux/selectors';
import { CommentModal, ProgressButton } from '_ui';
import { PUBLICATION_TYPE } from '_domains/Jobs/Publications/constants';
import { LR_LOCALES } from 'constants/resources';
import { validateRequiredForPublish } from './utils';
import { NOT_PUBLISH } from './constants';

const PublishAction = ({ disabled, record, localeRecord }) => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const { id: lrId, contentName } = record;
  const { locale, id: localeId } = localeRecord;
  const viewContentName = useSelector(getContentName(contentName));

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [publish, { loading }] = useMutation();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const onClick = () => {
    dataProvider.getOne(LR_LOCALES, { id: localeId }, {
      onSuccess: ({ data }) => {
        const { state } = data || {};
        if (state !== LR_LOCALE_CURRENTLY_EDITING_STATE) {
          notify(NOT_PUBLISH);
          refresh();
        }

        const error = validateRequiredForPublish(record, data, viewContentName);
        if (error) {
          dispatch(showNotification(error, 'error'));
        }
        else {
          toggleModal();
        }
      },
      onError: (err) => {
        notify('Error: Can\'t fetch actual locale data.', err ?? 'error');
      },
    });
  };

  const onSubmit = (comment) => {
    toggleModal();
    publish({
      type: 'update',
      resource: LR_LOCALES,
      payload: {
        action: 'publish',
        lrId,
        locale,
        comment,
      },
    },
    {
      onSuccess: ({ data }) => {
        dataProvider.getOne(LR_LOCALES, { id: localeId });
        dispatch(addPublications(data));
        notify('Publication has been started');
      },
      onError: (err) => notify('Error: LO is not published.', err ?? 'error'),
    });
  };

  return (
    <>
      <ProgressButton
        size="small"
        color="primary"
        disabled={disabled}
        loading={loading}
        seleniumId={`publish-${lrId}-${locale}`}
        onClick={onClick}
        label="Publish"
      />
      <CommentModal
        open={showModal}
        record={{
          ...record,
          publicationType: PUBLICATION_TYPE,
          language: locale,
        }}
        onSubmit={onSubmit}
        onCancel={toggleModal}
      />
    </>
  );
};

PublishAction.propTypes = {
  disabled: PropTypes.bool.isRequired,
  localeRecord: PropTypes.shape({
    id: PropTypes.string,
    locale: PropTypes.string,
  }),
  record: PropTypes.shape({
    contentName: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default PublishAction;
