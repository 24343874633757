import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  ShowController,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import {
  DateField,
  TruncatedTextField,
  ViewPaper,
  ViewCard,
  Grid,
  GridTile,
  UserField,
} from '_ui';

import { PublicationLRLocaleLinkField } from '_ui/Field';
import PublicationLogsField from './PublicationsLogField';
import { ActionsField, StatusField } from './Fields';

const useGeneralStyles = makeStyles({
  comment: {
    wordBreak: 'break-word',
  },
});

const GeneralCard = (props) => {
  const classes = useGeneralStyles();

  return (
    <ViewCard
      {...props}
      headerTitle="General"
      data-seleniumid="publications-general"
    >
      <TextField label="LR Code" source="learningObjectCode" data-seleniumid="publication-code" />
      <PublicationLRLocaleLinkField
        seleniumId="publication-locale"
      />
      <TruncatedTextField
        source="title"
        label="Title"
        data-seleniumid="publication-title"
        sortable={undefined}
      />
      <TextField
        label="Comment"
        source="comment"
        data-seleniumid="publication-comment"
        className={classes.comment}
      />
      <StatusField
        label="Status"
        mode="show"
        seleniumId="publication-status"
      />
    </ViewCard>
  );
};

const CreatorCard = (props) => (
  <ViewCard
    {...props}
    headerTitle="Published"
    data-seleniumid="publications-creator"
  >
    <UserField
      label="Initiated by"
      seleniumId="publication-creator"
      source="publishedBy"
    />
    <DateField
      source="initiatedDate"
      label="Initiated Date/Time"

      data-seleniumid="publication-initiated"
    />
    <DateField
      source="publicationDate"
      label="Completed Date/Time"

      data-seleniumid="publication-created"
    />
    <TextField
      label="Version"
      source="version"
      data-seleniumid="publication-version"
    />
  </ViewCard>
);

const LogsCard = (props) => (
  <ViewCard
    {...props}
    headerTitle="Publication Report"
    data-seleniumid="publication-report"
  >
    <PublicationLogsField addLabel={false} />
  </ViewCard>
);

const useStyles = makeStyles({
  actionsPanel: {
    padding: '1rem 0 0',
  },
  actions: {
    textAlign: 'end',
  },
});

const PublicationsView = props => {
  const classes = useStyles();

  return (
    <>
      <ViewPaper {...props}>

        <ShowController {...props}>
          {({ record, basePath }) => (
            <div className={classes.actionsPanel}>
              <ActionsField record={record} className={classes.actions} basePath={basePath} isShow />
            </div>
          )}
        </ShowController>

        <Grid spacing={1} direction="column">
          <ShowController {...props}>
            {(viewProps) => (
              <Grid spacing={1}>
                <GridTile xs={12} md={6}>
                  <GeneralCard {...props} {...viewProps} />
                </GridTile>

                <GridTile xs={12} md={6}>
                  <CreatorCard {...props} {...viewProps} />
                </GridTile>

                <GridTile xs={12} md={12}>
                  <LogsCard {...props} {...viewProps} />
                </GridTile>
              </Grid>
            )}
          </ShowController>
        </Grid>

      </ViewPaper>
    </>
  );
};

PublicationsView.propTypes = {
  resource: PropTypes.string,
};

export default PublicationsView;
