/* eslint-disable react/prop-types */
import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { useAvailableLocales } from 'utils/locales';
import { SimpleShowLayout, TabbedLanguagesView } from '_ui';
import { MEDIA_TYPE_VIDEO } from 'constants/mediaTypes';
import { selectLR } from 'ra-redux/selectors';
import MetadataView from './MetadataView';
import LocalesView from './LocalesView';
import Actions from './Actions';
import PreviewAction from './Actions/PreviewAction';
import { useContentList, useHighlightedLocales } from './utils';

const LearningResourceView = (props) => {
  const { id, resource } = props;
  const lr = useSelector(selectLR(id));
  useAvailableLocales({ resource });
  const isContentLoading = useContentList({ id });
  const highlightedLocales = useHighlightedLocales({ id, lr });

  return (
    <TabbedLanguagesView
      isLoading={isEmpty(lr) || isContentLoading}
      {...props}
      resource="learning-objects"
      languageAgnosticFields={(props) => (
        <SimpleShowLayout {...props}>
          <MetadataView />
        </SimpleShowLayout>
      )}
      languageSpecificFields={(props) => (
        <SimpleShowLayout {...props}>
          <LocalesView />
        </SimpleShowLayout>
      )}
      noLanguagesActions={({ record: { mediaType } }) => (mediaType === MEDIA_TYPE_VIDEO ? (
        <PreviewAction
          learningResourceId={id}
          localizedVideoExists
          mainVideoExists
        />
      ) : null)}
      languageSpecificActions={Actions}
      isCreateLocaleAvailable={false}
      tabsLabel="LR"
      highlightedLocales={highlightedLocales}
    />
  );
};

export default LearningResourceView;
