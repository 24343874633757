import React from 'react';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core';
import ReactDOMServer from 'react-dom/server';

const useStyle = makeStyles(theme => ({
  wrap: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
  },
  title: {
    fontSize: theme.typography.fontSize * 1.2,
    fontWeight: theme.typography.fontWeightBold,
    fontStyle: 'italic',
  },
}));

const REFINE_ID = 'refine-search';

const RefineSearch = () => {
  const { wrap, title } = useStyle();
  return (
    <div className={cn(wrap, REFINE_ID)}>
      <div>...</div>
      <div className={title} data-seleniumid="refine-search-title">
        Too many results. Please refine your search.
      </div>
    </div>
  );
};

export const clearRefineSearch = (node) => {
  const nodes = node.getElementsByClassName(REFINE_ID);
  while (nodes.length > 0) {
    nodes[0].parentNode.removeChild(nodes[0]);
  }
};

export const addRefineSearch = (node) => {
  const refineWrap = document.createElement('div');
  refineWrap.innerHTML = ReactDOMServer.renderToStaticMarkup(<RefineSearch />);
  node.children[0].children[0].appendChild(refineWrap);
};
