/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Box,
  Chip,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  SaveButton as RASaveButton,
  Toolbar as RAToolBar,
  Button,
  useNotify,
  useQueryWithStore,
  showNotification,
} from 'react-admin';
import usePristineForm from 'hoc/usePristineForm';
import { ToolBar } from '_ui/Layout';
import SaveIcon from '@material-ui/icons/Save';
import { useDispatch } from 'react-redux';

import { getManyLocalesPayloadRequest } from 'utils/locales';
import { LR_LOCALE_PUBLISHED_STATE } from 'constants/localeStates';
import { REPUBLISH_TITLE, NOTIFY } from '../constants';

const useToolBarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const SaveButton = ({ resource, disabled, onClick }) => (
  <>
    <Button
      disabled={disabled}
      label="Save"
      size="medium"
      variant="contained"
      onClick={onClick}
      data-seleniumid={`${resource}-save-button`}
    >
      <SaveIcon />
    </Button>
  </>
);

SaveButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  resource: PropTypes.string,
};

const LearningResourceEditToolBar = (props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();
  const classes = useToolBarStyles();
  const { resource, saving, invalid, record, record: { id, availableLocales } } = props;
  const pristine = usePristineForm(record);
  const { data, loading } = useQueryWithStore(getManyLocalesPayloadRequest(id, availableLocales));
  const locales = (data || []).filter(el => el.state === LR_LOCALE_PUBLISHED_STATE).map(({ locale }) => locale);
  const isDisabled = loading || saving || pristine;
  const isDisplayDefaultSaveButton = loading || !locales.length;

  useEffect(
    () => () => {
      if (saving && locales.length) notify(`${NOTIFY} ${locales.join(', ')}`);
    },
    [saving],
  );

  return (
    <RAToolBar {...props} classes={classes}>
      {isDisplayDefaultSaveButton ? (
        <RASaveButton
          data-seleniumid={`${resource}-save-button`}
          disabled={isDisabled}
        />
      ) : (
        <SaveButton
          resource={resource}
          onClick={() => {
            if (!invalid) {
              setOpen(true);
            }
            else {
              const errorText = `One or more localisations of the Learning Resource ${record.code} are in 'Published' state, so it cannot be saved with mandatory fields missing`;
              dispatch(showNotification(errorText, 'error'));
            }
          }}
          disabled={isDisabled}
        />
      )}
      <>
        <Dialog
          open={open}
          onClose={() => !saving && setOpen(false)}
          aria-labelledby="re-publish-title"
        >
          <DialogTitle data-seleniumid="re-publish-title" id="re-publish-title">{REPUBLISH_TITLE}</DialogTitle>
          <Divider />
          <Box component={DialogContent} marginTop={2}>
            {locales.map(locale => (
              <Box
                data-seleniumid={`locale-for-republish-${locale}`}
                key={`locale-for-republish-${locale}`}
                margin={1}
                component={Chip}
                label={locale}
                variant="outlined"
              />
            ))}
          </Box>
          <RAToolBar {...props} classes={classes}>
            <>
              <Button
                color="default"
                label="Cancel"
                data-seleniumid="re-publish-cancel"
                onClick={() => setOpen(false)}
                size="medium"
                variant="contained"
              />
              <Box display="flex" flexGrow="1" />
            </>
            <RASaveButton
              label="Confirm"
              data-seleniumid="re-publish-confirm"
            />
          </RAToolBar>
        </Dialog>
      </>
    </RAToolBar>
  );
};

LearningResourceEditToolBar.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  saving: PropTypes.bool,
};

export default (props) => {
  const { record } = props;
  const { availableLocales } = record || {};
  if (!availableLocales.length) return <ToolBar {...props} />;

  return <LearningResourceEditToolBar {...props} />;
};
