import React from 'react';
import {
  SimpleForm,
  TextField,
} from 'react-admin';
import PropTypes from 'prop-types';
import {
  Create,
  Edit,
  ToolBar,
  TextInput,
} from '_ui';
import _ from 'lodash';
import { getEntityModifyRedirectHref } from 'utils';

const TagModifyForm = ({ isCreate, ...props }) => {
  const { record } = props;

  return (
    <SimpleForm
      {...props}
      toolbar={<ToolBar />}
      redirect={getEntityModifyRedirectHref}
    >
      {
        _.isEmpty(record)
          ? (<TextInput source="code" seleniumId="tag-create-code" />)
          : (<TextField source="code" data-seleniumid="tag-edit-code" />)
      }
    </SimpleForm>
  );
};

TagModifyForm.propTypes = {
  isCreate: PropTypes.bool,
  record: PropTypes.object,
};

export const TagEdit = props => (
  <Edit undoable={false} {...props} hasShow={false}>
    <TagModifyForm />
  </Edit>
);

export const TagCreate = props => (
  <Create {...props}>
    <TagModifyForm isCreate={true} />
  </Create>
);
