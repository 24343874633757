import { useState, useCallback } from 'react';

import { useDataProvider } from 'react-admin';
import { NOT_FOUND } from './constants';
import { getAvailableLocalesFromContentFile } from './utils';

const useVideoPreview = ({ resource, id, storeBucket, locale, mediaType, allAvailableLocales }) => {
  const [previewMeta, setPreviewMeta] = useState({
    isLoading: false,
    bucket: storeBucket,
  });

  const dataProvider = useDataProvider();

  const MAIN_VIDEO_LABEL = 'Main';

  const getPreview = useCallback((onSuccess, onFailed, { entities }) => {
    setPreviewMeta(meta => ({ ...meta, isLoading: true }));
    dataProvider.getOne(resource, { id, locale })
      .then(async ({ data }) => {
        const { bucket } = data || {};
        if (!bucket) throw new Error(NOT_FOUND);

        const contentAvailableLanguages = getAvailableLocalesFromContentFile(entities, allAvailableLocales, bucket, MAIN_VIDEO_LABEL);

        const source = {
          selectedLanguage: locale || MAIN_VIDEO_LABEL,
          availableLanguages: contentAvailableLanguages,
        };

        setPreviewMeta(meta => ({
          ...meta,
          isLoading: false,
          bucket,
          source,
        }));
        onSuccess(source);
      })
      .catch((error) => {
        setPreviewMeta(meta => ({ ...meta, isLoading: false }));
        onFailed(error?.message);
      });
  }, [resource, id, locale, mediaType]);

  return [getPreview, previewMeta];
};

export default useVideoPreview;
