import React, {
  Fragment,
  useState,
  useCallback,
  memo,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  FunctionField,
  Labeled,
  useGetMany,
} from 'react-admin';
import LinearProgress from '@material-ui/core/LinearProgress';

const USER_FIELD = 'email';
const SYSTEM_FIELD = 'name';
const Field = ({ seleniumId, record }) => (
  <FunctionField
    data-seleniumid={seleniumId}
    record={record}
    render={({
      [USER_FIELD]: userFieldValue,
      [SYSTEM_FIELD]: systemFieldValue,
      id,
    }) => userFieldValue || systemFieldValue || id}
  />
);

Field.propTypes = {
  record: PropTypes.object.isRequired,
  seleniumId: PropTypes.string.isRequired,
};

const Empty = ({ seleniumId }) => (
  <div data-seleniumid={`${seleniumId}-empty`} />
);

Empty.propTypes = {
  seleniumId: PropTypes.string.isRequired,
};

export const USER_RESOURCE = 'users';
export const SYSTEM_RESOURCE = 'systems';

const getUserById = (id) => (state) => (
  id
    ? [USER_RESOURCE, SYSTEM_RESOURCE]
      .reduce((acc, resource) => {
        const user = _.get(
          state,
          `admin.resources.${resource}.data.${id}`,
        );

        if (user) return [user];
        return acc;
      }, [])
    : []
);

const UserField = ({
  seleniumId,
  source,
  record,
  label,
  showLabeled = true,
}) => {
  const id = record[source];
  const [resource, setResource] = useState(USER_RESOURCE);

  const onFailure = useCallback(() => {
    if (resource !== SYSTEM_RESOURCE) {
      setResource(SYSTEM_RESOURCE);
    }
  }, [resource]);

  const onSuccess = useCallback(({ data }) => {
    const [user] = data;
    if (!user && resource !== SYSTEM_RESOURCE) {
      setResource(SYSTEM_RESOURCE);
    }
  }, [resource]);

  const { data, loaded } = useGetMany(
    resource,
    id ? [id] : [],
    { onSuccess, onFailure },
  );
  const [stateUser] = useSelector(getUserById(id));
  const [user] = [...(data || []).filter(entry => !!entry), stateUser];

  const WrapperComponent = showLabeled ? Labeled : Fragment;
  const wrapperProps = showLabeled ? { label } : {};

  const FieldComponent = user ? Field : Empty;
  const fieldProps = { seleniumId, ...(user ? { record: user } : {}) };

  return (
    <WrapperComponent {...wrapperProps}>
      {
        (user || loaded)
          ? <FieldComponent {...fieldProps} />
          : (
            <LinearProgress
              label={label}
              data-seleniumid={seleniumId}
              data-selenium-loader="true"
            />
          )
      }
    </WrapperComponent>
  );
};

UserField.propTypes = {
  label: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  seleniumId: PropTypes.string.isRequired,
  showLabeled: PropTypes.bool,
  source: PropTypes.string.isRequired,
};

export default memo(UserField);
