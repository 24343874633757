import React, { useCallback, useState } from 'react';
import {
  ReferenceArrayInput,
  ReferenceField,
  TextField,
  FunctionField,
} from 'react-admin';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

import {
  BooleanInput,
  DateField,
  Filter,
  List,
  TextInput,
  AutocompleteArrayInput,
  Datagrid,
  UserField,
  WarningBox,
} from '_ui';

import DateRangeInput, { useDateQuickFilter } from '_ui/Input/DateRangeInput';

import { withCustomWidthTableStyles } from 'utils/styleUtils';
import { WARNING_MESSAGE } from 'utils/export/constants';
import { BASE_SEARCH_SOURCE } from 'constants';
import { IMPORT_LIST_SORT_PARAMS } from 'constants/sort';

import CreatorInput from '../common/UserInput';
import { EXCLUDE_TYPES } from './constants';
import ImportUploads from './ImportUploads';
import ImportListActions from './ImportListActions';
import {
  SourceField,
  TargetField,
  ImportLogs,
  ExportField,
  ActionField,
} from './Fields';
import { isCsvImport } from './utils';

const ImportsFilter = (props) => {
  const { lastTwoMonths }  = useDateQuickFilter();
  return (
    <Filter {...props} seleniumId="imports-filter">
      <TextInput
        label="Search (50 LRs at max)"
        source={BASE_SEARCH_SOURCE}
        alwaysOn
        seleniumId="imports-search-input"
        isFilter
        inputProps={{ maxLength: 450 }}
      />
      <ReferenceArrayInput
        allowEmpty
        alwaysOn
        label="Language"
        perPage={50}
        reference="languages"
        sort={{ field: 'name', order: 'ASC' }}
        source="availableLocales"
      >
        <AutocompleteArrayInput
          seleniumId="imports-language-input"
          optionText="code"
          allowEmpty={false}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Import Status"
        source="status"
        reference="import-statuses"
        allowEmpty
        alwaysOn
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteArrayInput
          optionText="name"
          allowEmpty={false}
          seleniumId="import-status-input"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Import Type"
        source="type"
        reference="import-types"
        allowEmpty
      >
        <AutocompleteArrayInput
          sort={{ field: 'name' }}
          optionText="name"
          seleniumId="import-type-input"
          allowEmpty={false}
          exclude={EXCLUDE_TYPES}
        />
      </ReferenceArrayInput>
      <DateRangeInput
        label="Created"
        source="created"
        seleniumId="import-created"
        inputProps={lastTwoMonths}
        initialValue={lastTwoMonths.max}
      />
      <CreatorInput
        source="creator"
        reference="import-creators"
        seleniumId="import-creator"
      />
      <BooleanInput
        label="Not applied video"
        source="hasImportantInfo"
        defaultValue={true}
        seleniumId="import-filter-video"
      />
    </Filter>
  );
};

export const columnWidth = {
  expand: 4,
  action: 4,
  type: 12,
  source: 12,
  targets: 12,
  creator: 12,
  created: 16,
  status: 14,
  export: 14,
};

const useStyle = makeStyles(withCustomWidthTableStyles(columnWidth));

const ImportList = (props) => {
  const { hasCreate, resource } = props;
  const classes = useStyle();
  const [showWarning, setShowWarning] = useState(false);
  const onExportMenuToggle = useCallback((anchor) => {
    setShowWarning(!!anchor);
  }, []);

  return (
    <>
      <ImportUploads resource={resource} />
      <List
        {...props}
        actions={
          hasCreate
            ? <ImportListActions onExportMenuToggle={onExportMenuToggle} />
            : null
        }
        filters={<ImportsFilter />}
        sort={IMPORT_LIST_SORT_PARAMS}
        bulkActionButtons={false}
        seleniumId="imports"
      >
        <Datagrid
          rowClick="show"
          expand={<ImportLogs />}
          warning={
            showWarning
              ? <WarningBox seleniumId="import-list-export-warning">{WARNING_MESSAGE}</WarningBox>
              : null
          }
          classes={classes}
        >
          <ActionField />
          <ReferenceField source="type" reference="import-types" link={false}>
            <TextField source="name" data-seleniumid="import-type-name" />
          </ReferenceField>
          <FunctionField label="Source" render={SourceField} />
          <TargetField source="targets" />
          <UserField
            label="Creator"
            showLabeled={false}
            seleniumId="import-creator"
            source="creator"
          />
          <DateField source="created" data-seleniumid="import-date" />
          <ReferenceField source="status" reference="import-statuses" link={false}>
            <TextField source="name" data-seleniumid="import-status" />
          </ReferenceField>
          <FunctionField label="Export" render={(record = {}) => isCsvImport(record) && <ExportField record={record} />} />
        </Datagrid>
      </List>
    </>
  );
};

ImportList.propTypes = {
  hasCreate: PropTypes.bool.isRequired,
  resource: PropTypes.string.isRequired,
};

export default ImportList;
