import { TODAY_LOCAL } from 'constants/dateTime';
import subMonths from 'date-fns/subMonths';

export const JOB_STATUSES = {
  SUCCESSFUL: 'SUCCESSFUL',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  PARTIAL: 'PARTIAL',
};

export const EXPORT_DATE_RANGE_FILTER = { gte: subMonths(TODAY_LOCAL, 2), lte: TODAY_LOCAL };
