/* eslint-disable react/no-unused-prop-types */
import React, { useState, useCallback } from 'react';

import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import {
  makeStyles,
  Avatar as MuiAvatar,
  Fade,
} from '@material-ui/core';

import { getImgSRC, getImgDimensions } from 'utils/images';
import { ImageField } from '_ui';

const AVATAR_SIZE_CAP = 160;

const useStyles = makeStyles((theme) => ({
  root: ({ width, height }) => ({
    width,
    height,
    backgroundColor: theme.palette.grey[400],
    '& svg': {
      width: width / 2,
      height: width / 2,
    },
  }),
}));

const AvatarField = props => {
  const {
    source,
    seleniumId = `image-field-${source}`,
  } = props;

  const [srcError, setSrcError] = useState(false);
  const imgSource = getImgSRC(props);
  const onError = useCallback(() => {
    if (!srcError) setSrcError(true);
  }, [imgSource, srcError]);

  const { maxWidth, ...dimensions } = getImgDimensions(props, AVATAR_SIZE_CAP);
  const classes = useStyles({ ...props, ...dimensions });

  const avatarProps = {
    src: imgSource,
    'data-seleniumid': seleniumId,
    imgProps: { onError, 'data-seleniumid': `${seleniumId}-image` },
    classes,
    ...dimensions,
  };

  return (
    <MuiAvatar {...avatarProps}>
      <Fade in>
        <PersonRoundedIcon data-seleniumid={`${seleniumId}-default`} />
      </Fade>
    </MuiAvatar>
  );
};

AvatarField.propTypes = ImageField.propTypes;
AvatarField.defaultProps = {
  ...ImageField.defaultProps,
  size: 40,
};

export default AvatarField;
