import React from 'react';
import PropTypes from 'prop-types';

import { ReferenceArrayInput } from 'react-admin';

import { getCapLabel } from 'utils';
import { AutocompleteArrayInput } from '_ui';

const UserInput = (props) => {
  const { seleniumId, source } = props;
  const inputSeleniumId = `${seleniumId}-input`;
  return (
    <>
      <ReferenceArrayInput label={getCapLabel(source)} allowEmpty data-seleniumid={seleniumId} {...props}>
        <AutocompleteArrayInput
          seleniumId={inputSeleniumId}
          optionText="name"
          allowDuplicates={false}
          allowEmpty={false}
          matchSuggestion={(
            filterValue,
            { name, email } = {},
          ) => !filterValue || [...name?.split(' '), email].some((prop) => prop?.match(filterValue))}
          setFilter={null}
          translateChoice={false}
        />
      </ReferenceArrayInput>

    </>
  );
};

const sanitizePropTypes = ({ children, ...propTypes }) => propTypes;
UserInput.propTypes = {
  ...sanitizePropTypes(ReferenceArrayInput.propTypes),
  seleniumId: PropTypes.string.isRequired,
};

UserInput.defaultProps = ReferenceArrayInput.defaultProps;

export default UserInput;
