/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { FunctionField } from 'react-admin';
import { Link } from '@material-ui/core';
import { PUBLICATION_STATUSES } from '_domains/Jobs/Publications/constants';
import { showLocaleResourceHref } from '../../utils';

const PublicationLRLocaleLinkField = ({ seleniumId, record }) => (
  <FunctionField
    record={record}
    onClick={event => event.stopPropagation()}
    source="language"
    data-seleniumid={seleniumId}
    render={
      ({ learningObjectId, language, status }) => (
        learningObjectId && status === PUBLICATION_STATUSES.SUCCESSFUL ? (
          <Link href={`/#/${showLocaleResourceHref('learning-objects', learningObjectId, language)}`}>
            {language}
          </Link>
        ) : language
      )
    }
  />
);

PublicationLRLocaleLinkField.propTypes = {
  addLabel: PropTypes.bool,
  label: PropTypes.string,
  record: PropTypes.object,
  seleniumId: PropTypes.string,
};

PublicationLRLocaleLinkField.defaultProps = {
  addLabel: true,
  label: 'Language',
};

export default PublicationLRLocaleLinkField;
