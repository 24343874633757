/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { showResourceHref, showLocaleResourceHref } from 'utils';
import { getLocales } from 'ra-redux/locales';
import { getRelevantLocale } from 'utils/locales';
import TruncatedTextField from './TruncatedTextField';

const styles = (theme) => ({
  truncTextField: {
    marginRight: 5,
  },
  drillDownLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
});

const DrillDownLinkField = withStyles(styles)(({ classes, record = {}, targetResource, ignoreLocale, seleniumId, ...rest }) => {
  const { id, availableLocales } = record;
  if (!id) {
    return <></>;
  }

  const selectedLocales = useSelector(getLocales);
  const locale = availableLocales?.length && getRelevantLocale(selectedLocales, availableLocales);

  return (
    <Link
      className={classes.drillDownLink}
      to={(ignoreLocale || !locale) ? `/${showResourceHref(targetResource, id)}` : `/${showLocaleResourceHref(targetResource, id, locale)}`}
      onClick={e => e.stopPropagation()}
      data-seleniumid={seleniumId ? `${seleniumId}-${id}` : `show-${targetResource}-${id}-button`}
    >
      <TruncatedTextField className={classes.truncTextField} record={record} {...rest} />
    </Link>
  );
});

DrillDownLinkField.defaultProps = {
  ignoreLocale: false,
  record: {},
};

DrillDownLinkField.propTypes = {
  ignoreLocale: PropTypes.bool,
  record: PropTypes.object,
  seleniumId: PropTypes.string,
  targetResource: PropTypes.string.isRequired,
};

export default memo(DrillDownLinkField);
