import { createStore, compose, applyMiddleware } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import {
  createAppReducer,
  adminSaga,
  CLEAR_STATE,
} from 'react-admin';
import {
  errorHandlerMiddleware,
  notificationMiddleware,
  expandStateHandlerMiddleware,
  localesMiddleware,
  scormExportMiddleware,
  loadingMiddleware,
} from './ra-middleware';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['menu'],
};

export default ({
  dataProvider,
  customReducers = {},
  authProvider = null,
  customSagas = [],
  initialState,
  history,
}) => {
  const appReducer = createAppReducer(customReducers, history);

  const resettableAppReducer = (state, action) =>
    appReducer(
      action.type !== CLEAR_STATE
        ? state
        : {
          ...state,
          admin: {
            ...state.admin,
            loading: 0,
            resources: {},
            customQueries: {},
            references: { oneToMany: {}, possibleValues: {} },
          },
        },
      action,
    );
  const saga = function* rootSaga() {
    yield all(
      [adminSaga(dataProvider, authProvider), ...customSagas].map(fork),
    );
  };
  const sagaMiddleware = createSagaMiddleware();
  const typedWindow = window;

  const composeEnhancers = (process.env.NODE_ENV === 'development'
    && typeof typedWindow !== 'undefined'
    && typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    && typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) || compose;

  const persistedReducer = persistReducer(persistConfig, resettableAppReducer);

  const store = createStore(
    persistedReducer,
    typeof initialState === 'function' ? initialState() : initialState,
    composeEnhancers(
      applyMiddleware(
        errorHandlerMiddleware,
        notificationMiddleware,
        expandStateHandlerMiddleware,
        localesMiddleware,
        scormExportMiddleware,
        loadingMiddleware,
        sagaMiddleware,
        routerMiddleware(history),
      ),
    ),
  );
  sagaMiddleware.run(saga);
  return store;
};
