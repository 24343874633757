import {
  GET_LIST,
  UPDATE,
} from 'react-admin';
import _ from 'lodash';

import defaultExports from './defaultExportsList';

export const getItemsForExports = (sourceItems, params) => {
  const itemsWithDefault = Array.isArray(sourceItems) ? sourceItems : [];
  const alreadyAddedItemTypes = itemsWithDefault.map(({ type }) => type);
  defaultExports.forEach(item => {
    if (!alreadyAddedItemTypes.includes(item.type) && _.isEmpty(params?.filter)) {
      itemsWithDefault.push(item);
    }
  });

  return itemsWithDefault.reduce((acc, report) => {
    acc.push({ ...report, id: report.type, backendId: report.id });
    return acc;
  }, []);
};

export const processExportsHTTPResponse = (type, source, params) => {
  switch (type) {
    case GET_LIST:
      return ({ ...source, items: getItemsForExports(source.items, params) });
    case UPDATE:
      return ({ ...params, ...source, status: 'IN_PROGRESS' });
    default:
      return source;
  }
};
