import React from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import { PUBLICATION_STATUSES } from '../constants';

const TITLE = 'Download SCORM';
const NOTIFICATION = 'Download will start soon';
const defaultProps = {
  color: 'primary',
  title: TITLE,
};

export const getSeleniumId = id => `download-publication-${id}`;
const getLink = id => `${window.location.origin}/api/v1/publications/${id}/download`;

const DownloadField = ({ record }) => {
  const notify = useNotify();
  const { id, status } = record || {};
  if (!id || status !== PUBLICATION_STATUSES.SUCCESSFUL) return null;

  return (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        notify(NOTIFICATION, 'info', {}, false, 3000);
      }}
      href={getLink(id)}
      data-seleniumid={getSeleniumId(id)}
      {...defaultProps}
    >
      <DownloadIcon />
    </IconButton>
  );
};

DownloadField.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
    language: PropTypes.string,
  }),
};

export default DownloadField;
