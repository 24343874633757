import React from 'react';
import {
  BooleanInput,
  ReferenceInput,
  SimpleForm,
} from 'react-admin';

import {
  TextInput,
  ToolBar,
  AutocompleteInput,
  SelectInput,
  Edit,
} from '_ui';
import { getLocaleModifyRedirectHref } from 'utils';
import { formatNumberInput } from 'utils/format';
import { useSelector } from 'react-redux';
import { LEVEL_OPTIONS } from '../../constants';

import RequiredAnnotation from '../../LearningResourceView/Actions/RequiredAnnotation';
import { markComponentRequiredForPublish } from '../../LearningResourceView/Actions/utils';
import LearningResourceLocaleEditToolBar from './ToolBar';

const DURATION_DIGITS_LIMIT = 5;

const LearningResourceEditLocale = (props) => {
  const { state = '' } = useSelector(state =>
    state.admin.resources['learning-objects-locales']?.data[props?.id]) || {};

  const inputComponents = [
    <TextInput
      key="title"
      fullWidth={true}
      source="title"
      label="Title"
      seleniumId="lo-edit-locale-title"
      inputProps={{ autoComplete: 'off' }}
    />,
    <TextInput
      key="brief"
      fullWidth={true}
      source="brief"
      label="In brief"
      seleniumId="lo-edit-locale-brief"
      multiline
    />,
    <TextInput
      key="briefSummary"
      fullWidth={true}
      source="briefSummary"
      label="What you will learn"
      seleniumId="lo-edit-locale-briefSummary"
      multiline
    />,
    <TextInput
      key="includedSummary"
      fullWidth={true}
      source="includedSummary"
      label="Included in this learning resource"
      seleniumId="lo-edit-locale-includedSummary"
    />,
    <TextInput
      key="audience"
      fullWidth={true}
      source="audience"
      label="Target audience"
      seleniumId="lo-edit-locale-audience"
    />,
    <ReferenceInput
      key="editorialStatus"
      label="Editorial Status"
      reference="editorial-statuses"
      source="editorialStatus"
      allowEmpty
      link={false}
    >
      <AutocompleteInput
        optionText="name"
        seleniumId="lo-edit-locale-editorial"
        emptyText="None"
        sort={{ field: 'name' }}
      />
    </ReferenceInput>,
    <BooleanInput
      key="hasVideo"
      source="hasVideo"
      data-seleniumid="lo-edit-locale-has-video"
      helperText={false}
    />,
    <BooleanInput
      key="hasAudio"
      source="hasAudio"
      data-seleniumid="lo-edit-locale-has-audio"
      helperText={false}
    />,
    <TextInput
      key="duration"
      source="duration"
      seleniumId="lo-edit-locale-has-audio"
      options={{ variant: 'standard' }}
      format={(v) => formatNumberInput(v, DURATION_DIGITS_LIMIT)}
      parse={(v) => formatNumberInput(v, DURATION_DIGITS_LIMIT)}
    />,
    <SelectInput
      allowEmpty
      key="interactivity"
      seleniumId="lo-edit-locale-interactivity"
      translateChoice={false}
      label="Interactivity"
      source="interactivity"
      choices={LEVEL_OPTIONS}
      options={{ inputProps: { name: 'lo-edit-locale-interactivity' } }}
    />,
    <SelectInput
      allowEmpty
      key="level"
      seleniumId="lo-edit-locale-level"
      translateChoice={false}
      label="Level"
      source="level"
      choices={LEVEL_OPTIONS}
      options={{ inputProps: { name: 'lo-edit-locale-level' } }}
    />,
  ];

  return (
    <Edit undoable={false} {...props}>
      <SimpleForm
        toolbar={state === 'PUBLISHED' ? <LearningResourceLocaleEditToolBar /> : <ToolBar {...props} />}
        redirect={getLocaleModifyRedirectHref}
      >
        <RequiredAnnotation />
        { inputComponents.map(markComponentRequiredForPublish) }
      </SimpleForm>
    </Edit>
  );
};
export default LearningResourceEditLocale;
