import React from 'react';
import { Link } from 'react-admin';
import PropTypes from 'prop-types';
import { Box, Button, Toolbar, makeStyles } from '@material-ui/core';
import {
  isShowPublish,
  isShowPublishAll,
  isShowArchive,
  isShowRetrieveFromArchive,
  isShowEdit,
  isShowDelete,
} from './utils';
import PublishAction from './PublishAction';
import PublishAllAction from './PublishAllAction';
import ArchiveAction from './ArchiveAction';
import PreviewAction from './PreviewAction';
import DeleteAction from './DeleteAction';

const PREVIEW_TIP = fileName => `This Video is a localised video ${fileName}`;

const useStyles = makeStyles({
  disabled: {
    pointerEvents: 'none',
  },
});

const Actions = ({
  editHref,
  record,
  localeRecord,
  localesRecords,
  isLoading,
  hiddenPublishButton,
  hiddenArchiveButton,
}) => {
  const classes = useStyles();
  const {
    locale,
    state,

    mainVideoExists,
    localizedVideoExists,
    localizedVideoFileName,

    localizedPdfExists,
  } = localeRecord || {};
  const { id: lrId } = record || {};
  const disabled = isLoading;
  const previewTooltip = (localizedVideoExists && localizedVideoFileName) ? PREVIEW_TIP(localizedVideoFileName) : '';

  return (
    <Toolbar disableGutters variant="dense">
      <Box display="flex" flex={1} />
      <PreviewAction
        tooltip={previewTooltip}
        learningResourceId={lrId}
        locale={locale}
        mainVideoExists={mainVideoExists}
        localizedVideoExists={localizedVideoExists}
        localizedPdfExists={localizedPdfExists}
      />
      {isShowPublish(state) && !hiddenPublishButton
        && (
          <PublishAction
            disabled={disabled}
            record={record}
            localeRecord={localeRecord}
          />
        )}
      {isShowPublishAll(localesRecords) && !hiddenPublishButton
        ? (
          <PublishAllAction
            disabled={disabled}
            record={record}
            locales={localesRecords}
          />
        ) : null}
      {((isShowArchive(state) || isShowRetrieveFromArchive(state)) && !hiddenArchiveButton)
        && (
          <ArchiveAction
            isArchive={isShowArchive(state)}
            disabled={disabled}
            record={record}
            localeRecord={localeRecord}
          />
        )}

      {isShowEdit(state) && (
        <Link
          to={editHref}
          className={disabled ? classes.disabled : ''}
          color="primary"
          variant="outlined"
          size="small"
          data-seleniumid="edit-button"
          component={({ navigate, ...buttonProps }) => <Button {...buttonProps} disabled={disabled}>Edit</Button>}
        />
      )}

      {
        (isShowDelete(localeRecord)) && (
          <DeleteAction disabled={disabled} />
        )
      }
    </Toolbar>
  );
};

Actions.propTypes = {
  currentlyEditingLocales: PropTypes.arrayOf(PropTypes.string),
  editHref: PropTypes.string,
  hiddenArchiveButton: PropTypes.bool,
  hiddenPublishButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  localeRecord: PropTypes.object,
  localesRecords: PropTypes.arrayOf(PropTypes.object),
  record: PropTypes.object,
};

Actions.defaultProps = {
  isLoading: false,
};

export default Actions;
