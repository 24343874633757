/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField, ReferenceManyField } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { withCustomWidthTableStyles } from 'utils/styleUtils';
import {
  Datagrid,
  DateField,
  DataWithEmptyMessage,
} from '_ui';

import TargetField from './TargetField';

const useStyle = (columnWidth) => makeStyles(withCustomWidthTableStyles(columnWidth));
export const getColumnWidth = (isCsvImport) => (
  isCsvImport
    ? {
      date: 20,
      level: 10,
      targets: 12,
      message: 58,
    }
    : {
      date: 20,
      level: 10,
      message: 70,
    }
);

const SORT = {
  field: 'date',
  order: 'DESC',
};

const ImportLogs = (props) => {
  const { record } = props;
  const isCsvImport = /.csv$/.test(record?.source?.fileName);
  const isFailed = record?.status === 'FAILED';

  const columnWidth = getColumnWidth(isCsvImport);

  const classes = useStyle(columnWidth)();
  return (
    <ReferenceManyField
      {...props}
      reference="import-logs"
      target="id"
      sort={SORT}
    >
      <DataWithEmptyMessage emptyLogsMessage="No logs available">{(props) => (
        <Datagrid
          {...{ ...props, addLabel: undefined }}
          classes={classes}
          data-seleniumid={`import-table-${record?.id}`}
        >
          <DateField source="date" data-seleniumid="import-log-date" />
          <TextField source="level" data-seleniumid="import-log-level" />
          {
            'targets' in columnWidth && <TargetField source="targets" addLink={!isFailed} />
          }
          <TextField source="message" data-seleniumid="import-log-message" />
        </Datagrid>
      )}
      </DataWithEmptyMessage>
    </ReferenceManyField>
  );
};

ImportLogs.propTypes = {
  addLabel: PropTypes.bool,
  label: PropTypes.string,
  record: PropTypes.shape({
    reports: PropTypes.arrayOf(PropTypes.shape({
      date: PropTypes.string,
      level: PropTypes.string,
      message: PropTypes.string,
    })),
    status: PropTypes.string,
  }),
};

ImportLogs.defaultProps = {
  addLabel: true,
  label: 'Import Report',
};

export default ImportLogs;
