import React from 'react';
import usePristineForm from 'hoc/usePristineForm';
import { withStyles } from '@material-ui/core';
import {
  DeleteButton,
  SaveButton,
  Toolbar as RAToolBar,
} from 'react-admin';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

const ToolBar = withStyles(toolbarStyles)(({ showSave, showDelete, ...props }) => {
  const { resource, record } = props;
  const pristine = usePristineForm(record);

  return (
    <RAToolBar {...props}>
      { showSave && <SaveButton data-seleniumid={`${resource}-save-button`} disabled={pristine} /> }
      { showDelete && <DeleteButton data-seleniumid={`${resource}-delete-button`} /> }
    </RAToolBar>
  );
});

ToolBar.defaultProps = {
  showDelete: false,
  showSave: true,
};

export default ToolBar;
