import React from 'react';
import PropTypes from 'prop-types';
import { FunctionField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import WarningIcon from '@material-ui/icons/Warning';
import Box from '@material-ui/core/Box';

import { usePathMatch } from 'utils/router';

const useStyles = makeStyles((theme) => ({
  root: ({ addLabel }) => ({
    ...(
      addLabel
        ? { marginRight: theme.spacing() }
        : {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }
    ),
  }),
}));

export const getDefaultSeleniumId = (...args) => `${args.filter(Boolean).join('-')}`.toLowerCase();

const WarningField = ({
  iconProps: { component: IconComponent, ...iconProps },
  ...props
}) => {
  const { title, label } = props;
  const { params } = usePathMatch();
  const {
    source,
    seleniumId = getDefaultSeleniumId(...Object.values(params), source),
  } = props;

  const classes = useStyles(props);
  return (
    !source
      ? (
        <Box
          title={title}
          display="flex"
          alignItems="center"
          data-seleniumid={`${seleniumId}-warning`}
        >
          <IconComponent
            classes={classes}
            {...iconProps}
          />
          <Box alignSelf="center">{label}</Box>
        </Box>
      )
      : (
        <FunctionField
          {...props}
          classes={classes}
          render={({ [source]: hasWarning }) => (
            hasWarning && (
              <IconComponent
                data-seleniumid={seleniumId}
                classes={classes}
              />
            )
          )}
        />
      )
  );
};

WarningField.propTypes = {
  ...FunctionField.propTypes,
  iconProps: PropTypes.shape(Icon.propTypes),
  seleniumId: PropTypes.string,
  title: PropTypes.string,
};

WarningField.defaultProps = {
  ...FunctionField.defaultProps,
  iconProps: {
    ...Icon.defaultProps,
    component: WarningIcon,
    color: 'error',
  },
};

export default WarningField;
