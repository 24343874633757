import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { Fade, makeStyles, Typography } from '@material-ui/core';
import { ErrorOutline as WarningIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey[700],
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.state.warning,
  },
}));

const WarningBox = ({ icon: Icon, children, seleniumId, className = '' }) => {
  const classes = useStyles();

  return (
    <Fade in>
      <div className={cn(classes.root, className)} data-seleniumid={seleniumId}>
        <Icon classes={{ root: classes.icon }} />
        <Typography variant="subtitle1" data-seleniumid="currently-editing-warning">
          {children}
        </Typography>
      </div>
    </Fade>
  );
};

WarningBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.string,
  icon: PropTypes.elementType,
  seleniumId: PropTypes.string.isRequired,
};

WarningBox.defaultProps = {
  icon: WarningIcon,
};

export default WarningBox;
