import React from 'react';
import PropTypes from 'prop-types';
import {
  ReferenceInput,
  SimpleForm,
} from 'react-admin';
import {
  Create,
  Edit,
  ToolBar,
  TextInput,
  AutocompleteInput,
} from '_ui';
import { getEntityModifyRedirectHref } from 'utils';

const sanitizeProps = ({ isCreate, ...props }) => props;

const EditForm = props => {
  const { isCreate } = props;

  return (
    <SimpleForm
      {...sanitizeProps(props)}
      redirect={getEntityModifyRedirectHref}
      toolbar={<ToolBar />}
    >
      <TextInput
        disabled={!isCreate}
        source="code"
        seleniumId="themes-modify-code"
      />
      <ReferenceInput
        allowEmpty
        reference="themes"
        source="parentId"
        label="Parent Theme"
        link={false}
        sort={{ field: 'code', order: 'ASC' }}
      >
        <AutocompleteInput
          minSearchableLength={1}
          emptyText="None"
          optionText="code"
          defaultLabel="Parent Theme"
          seleniumId="themes-parent-code"
        />
      </ReferenceInput>
    </SimpleForm>
  );
};

EditForm.propTypes = {
  isCreate: PropTypes.bool,
};

export const ThemesEdit = props => (
  <Edit undoable={false} {...props} hasShow={false}>
    <EditForm />
  </Edit>
);

export const ThemesCreate = props => (
  <Create {...props}>
    <EditForm isCreate />
  </Create>
);
