import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLogout, usePermissions, LoadingIndicator } from 'react-admin';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {
  AppBar as MuiAppBar,
  Toolbar,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ExitToApp as ExitToAppIcon,
  AccountCircle as AccountCircleIcon,
  HelpOutline as HelpOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons';

import ls from 'api/ls';
import { SERVICE_NOW, USER_MANUAL } from 'constants/externalLinks';
import PublicationsProgress from '_domains/Jobs/Publications/PublicationsProgress';

import Breadcrumbs from './Breadcrumbs';
import History from './History';
import { SidebarToggle, DRAWER_WIDTH } from '../Sidebar';

const useNavStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.spacing(),
    paddingLeft: theme.spacing(9),
    transition: theme.transitions.create(['padding-left']),
  },
  shifted: {
    paddingLeft: DRAWER_WIDTH,
  },
}));

const Navigation = () => {
  const open = useSelector(({ admin: { ui } }) => ui.sidebarOpen);
  const classes = useNavStyles();

  return (
    <div className={cn(classes.root, open && classes.shifted)}>
      <History />
      <Box marginX={0.5} />
      <Breadcrumbs />
    </div>
  );
};

const AppBar = ({ title, ...props }) => {
  const logout = useLogout();
  // eslint-disable-next-line no-unused-vars
  const { permissions = [] } = usePermissions();
  const user = ls.getJSON('user');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <MuiAppBar {...props}>
      <Box component={Toolbar} disableGutters paddingX={1.5}>
        <Navigation />
        <SidebarToggle />
        <Box display="flex" flexGrow={1} />
        <Box width={48}><LoadingIndicator /></Box>
        <IconButton aria-controls="account-menu" aria-haspopup="true" color="inherit" onClick={handleOpen}>
          <AccountCircleIcon />
        </IconButton>
        {
          user && (
            <Menu
              disableAutoFocusItem
              id="account-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              getContentAnchorEl={null}
            >
              {/* TODO 1.0+ CPP2-1089 */}
              {/* <MenuItem button={false}>*/}
              {/*  <ListItemText*/}
              {/*    primary={user.email}*/}
              {/*    secondary={(*/}
              {/*      <>*/}
              {/*        <Typography*/}
              {/*          component="span"*/}
              {/*          variant="body2"*/}
              {/*          color="textPrimary"*/}
              {/*        >*/}
              {/*          User roles:*/}
              {/*        </Typography>*/}
              {/*        {permissions.map((role) => (*/}
              {/*          <Box component="span" key={`user-role-${role.toLowerCase()}`} display="flex" flexGrow={1}>*/}
              {/*            - {role}*/}
              {/*          </Box>*/}
              {/*        ))}*/}
              {/*      </>*/}
              {/*    )}*/}
              {/*  />*/}
              {/* </MenuItem>*/}
              <MenuItem component="a" href={USER_MANUAL} target="_blank" data-seleniumid="user-manual-button">
                <Box marginRight={1} component={HelpOutlineIcon} />
                User Manual
              </MenuItem>
              <MenuItem component="a" href={SERVICE_NOW} target="_blank" data-seleniumid="service-now-button">
                <Box marginRight={1} component={ErrorOutlineIcon} />
                Log a Support ticket
              </MenuItem>
              <MenuItem onClick={logout} data-seleniumid="logout-button">
                <Box marginRight={1} component={ExitToAppIcon} />
                Logout
              </MenuItem>
            </Menu>
          )
        }
        <PublicationsProgress />
      </Box>
    </MuiAppBar>
  );
};

AppBar.propTypes = {
  logout: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default AppBar;
