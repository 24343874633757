/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  ShowController,
  TabbedShowLayout,
  Tab,
  useQueryWithStore,
} from 'react-admin';

import { ShowView } from '_ui/Layout';
import { LocaleListField } from '_ui/Field';
import { Box, Toolbar, Typography } from '@material-ui/core';

import { getLocales } from 'ra-redux/locales';
import { findLocaleParam, getManyLocalesPayloadRequest } from 'utils/locales';
import { LR_LOCALE_BEING_PUBLISHED_STATE } from 'constants/localeStates';
import { ProgressButton } from '../Button';

import TabbedLanguagesLocaleActions from './TabbedLanguagesLocaleActions';
import { HORIZONTAL } from '../constants';
import { sanitizeProps, getTabLabel, localeWarning } from './utils';

const NO_LANGUAGES = 'No languages available';
const NO_LANGUAGE = locale => `Language ${locale} is not available`;
export const EDIT_LOCK_MESSAGE =  'Editing is locked as one or more localisations of this LR are in "Being Published" state.';

const Loader = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(
    () => {
      const timer = setTimeout(() => { setIsLoaded(true); }, 300);
      return () => clearTimeout(timer);
    },
    [],
  );

  return isLoaded
    ? React.cloneElement(children)
    : <Box paddingBottom={4} />;
};

Loader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

const Info = props => (<Typography data-seleniumid="languages-tab-info" variant="h6" {...props} />);

const MetaToolbar = ({ record, resource, id }) => {
  const { data, loading } = useQueryWithStore(getManyLocalesPayloadRequest(id, record?.availableLocales));
  const anyLocaleInBeingPublishedState = (data || []).some(el => el.state === LR_LOCALE_BEING_PUBLISHED_STATE);
  const disabled = !id || loading || anyLocaleInBeingPublishedState;

  return (
    <>
      <Toolbar disableGutters variant="dense">
        <Box display="flex" flex={1} />
        <Tooltip
          placement="bottom"
          title={
            anyLocaleInBeingPublishedState
              ? EDIT_LOCK_MESSAGE
              : ''
          }
        >
          <div>
            <Link
              to={`/${resource}/${id}`}
              component={({ navigate, ...buttonProps }) => (
                <ProgressButton seleniumId="edit-button" {...buttonProps} />
              )}
              label="Edit"
              color="primary"
              size="small"
              variant="outlined"
              loading={loading}
              disabled={disabled}
            />
          </div>
        </Tooltip>
      </Toolbar>
    </>
  );
};

MetaToolbar.propTypes = {
  id: PropTypes.string,
  record: PropTypes.shape({ availableLocales: PropTypes.arrayOf(PropTypes.string) }),
  resource: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  tabbedShowLayout: {
    paddingBottom: `${theme.spacing(2)}px !important`,
  },
  tabWrap: {
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  noLanguagesBlockWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const useTabsStyles = makeStyles({
  content: {
    padding: 0,
  },
});

const TabbedLanguagesView = (props) => {
  const { basePath, hasEdit, highlightedLocales, isLoading } = props;
  const history = useHistory();
  const storeLocales = useSelector(getLocales);
  const [locale] = findLocaleParam(history.location) || storeLocales;
  const classes = useStyles();
  const tabsClasses = useTabsStyles();

  return (
    <ShowController {...props}>
      {
        (viewProps) => {
          const { record = {}, resource } = viewProps;
          const isRecord = !isEmpty(record);
          const { id, availableLocales = null, canBeLocalizedIn = null, directlyUploadedVideo, directlyUploadedPdf } = record;

          const { isCreateLocaleAvailable, resource: parentResource, tabsLabel, languageSpecificActions } = props;
          const hasLocales = availableLocales?.length;
          const hasNewLocales = canBeLocalizedIn?.length && isCreateLocaleAvailable;
          const editHref = `/${resource}-locales/${id}_${locale}`;
          const tabsLabelMainPart = tabsLabel || parentResource;
          const LocaleWarning = localeWarning[resource];

          const fieldsProps = { ...props, record, resource, basePath };
          const isLocaleExist = !!(hasLocales && availableLocales.includes(locale));

          return (
            <ShowView
              {...sanitizeProps(props)}
              {...sanitizeProps(viewProps)}
              actions={null}
            >
              <TabbedShowLayout
                className={classes.tabbedShowLayout}
                classes={tabsClasses}
              >
                <Tab label={getTabLabel(tabsLabelMainPart, 'metadata')} data-seleniumid="metadata-tab">
                  <div className={classes.tabWrap}>
                    {
                      hasEdit ? (
                        <MetaToolbar record={record} resource={resource} id={id} />
                      ) : null
                    }
                    {
                      process.env.NODE_ENV === 'test'
                        ? props.languageAgnosticFields(fieldsProps)
                        : (
                          <Loader>
                            {props.languageAgnosticFields(fieldsProps)}
                          </Loader>
                        )
                    }
                  </div>
                </Tab>
                <Tab
                  label={getTabLabel(tabsLabelMainPart, 'languages')}
                  path={locale}
                  data-seleniumid="languages-tab"
                >
                  {(isRecord && LocaleWarning) ? <><LocaleWarning record={record} locale={locale} /></> : null}
                  <div className={classes.tabWrap}>
                    {!hasLocales
                      ? (
                        <div className={classes.noLanguagesBlockWrapper}>
                          <Info>{NO_LANGUAGES}</Info>
                          { props.noLanguagesActions(fieldsProps) }
                        </div>
                      )
                      : null}
                    {
                      (hasLocales && hasEdit && isLocaleExist) ? (
                        <TabbedLanguagesLocaleActions
                          actions={languageSpecificActions}
                          basePath={basePath}
                          resource={resource}
                          editHref={editHref}
                          learningResourceId={id}
                          learningResourceLocale={locale}
                          directlyUploadedVideo={directlyUploadedVideo}
                          directlyUploadedPdf={directlyUploadedPdf}
                        />
                      ) : null
                    }
                    {
                      hasLocales ? (
                        <LocaleListField
                          record={record}
                          isLoading={isLoading}
                          seleniumId="available-locales"
                          popupSelected={false}
                          showHidden
                          sortable={undefined}
                          source="availableLocales"
                          label="Available in:"
                          targetResource={parentResource}
                          variant={HORIZONTAL}
                          onClick={(locale) => {
                            history.push(locale);
                          }}
                          highlightedLocales={highlightedLocales}
                        />
                      ) : null
                    }
                    {(hasLocales && !isLocaleExist) ? <Info>{NO_LANGUAGE(locale)}</Info> : null}
                    {
                      (hasLocales && isLocaleExist) ? (process.env.NODE_ENV === 'test'
                        ? props.languageSpecificFields(sanitizeProps(fieldsProps))
                        : (<Loader>{props.languageSpecificFields(sanitizeProps(fieldsProps))}</Loader>)) : null
                    }
                    {
                      (hasNewLocales && hasEdit) ? (
                        <Box marginTop={2}>
                          <LocaleListField
                            chipColoring={false}
                            record={record}
                            link={false}
                            seleniumId="new-locales"
                            source="canBeLocalizedIn"
                            label="Add language for:"
                            variant={HORIZONTAL}
                            onClick={(locale, recordId) => {
                              history.push(`/${resource}-locales/create/${recordId}/${locale}`);
                            }}
                          />
                        </Box>
                      ) : null
                    }
                  </div>
                </Tab>
              </TabbedShowLayout>
            </ShowView>
          );
        }
      }
    </ShowController>
  );
};

TabbedLanguagesView.propTypes = {
  basePath: PropTypes.string,
  hasEdit: PropTypes.bool.isRequired,
  highlightedLocales: PropTypes.object,
  isCreateLocaleAvailable: PropTypes.bool,
  isLoading: PropTypes.bool,
  languageAgnosticFields: PropTypes.func,
  languageSpecificActions: PropTypes.func,
  languageSpecificFields: PropTypes.func,
  noLanguagesActions: PropTypes.func,
  resource: PropTypes.string,
  tabsLabel: PropTypes.string,
};

TabbedLanguagesView.defaultProps = {
  isCreateLocaleAvailable: true,
  noLanguagesActions: () => <></>,
  highlightedLocales: {},
};

export default TabbedLanguagesView;
