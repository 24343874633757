import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import MenuItemLink from './MenuItemLink';
import MenuTransition from './MenuTransition';

const styles = theme => ({
  sidebarIsOpen: {
    paddingLeft: theme.spacing(3),
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
});

const SubMenuHolder = ({
  isOpen,
  classes,
  children,
  name,
  sidebarIsOpen,
  seleniumId,
  handleToggle,
  ...menuItemLinkProps
}) => (
  <div>
    <MenuItemLink
      primaryText={name}
      name={name}
      sidebarIsOpen={sidebarIsOpen}
      data-seleniumid={`${seleniumId}-button`}
      {...menuItemLinkProps}
    />
    {
      <MenuTransition name={name}>
        <List
          component="div"
          disablePadding
          data-seleniumid={`${seleniumId}-list`} className={
            sidebarIsOpen
              ? classes.sidebarIsOpen
              : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
      </MenuTransition>
    }
    <Divider />
  </div>
);

SubMenuHolder.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  classes: PropTypes.object,
  handleToggle: PropTypes.func,
  isOpen: PropTypes.bool,
  name: PropTypes.string,
  seleniumId: PropTypes.string,
  sidebarIsOpen: PropTypes.bool,
};

export default withStyles(styles)(SubMenuHolder);
