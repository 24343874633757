import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

const TextAreaShowField = ({ allowHTML = false, source, record, seleniumId }) => {
  const el = useRef(null);
  const [wrapHeight, setHeight] = useState(0);

  // Need for correct showing when window width updates
  window.addEventListener('resize', () => setHeight(el?.current?.offsetHeight || 0));

  useLayoutEffect(() => {
    setHeight(el.current.offsetHeight);
  }, [el.current?.offsetHeight]);

  const divProps = {
    ref: el,
    'data-seleniumid': seleniumId || source,
    style: { position: 'absolute', width: '100%' },
  };

  return (
    <div style={{ width: '100%', height: wrapHeight }}>
      {allowHTML ? (
        <div
          {...divProps}
          dangerouslySetInnerHTML={{ __html: record[source] }}
        />
      ) : (
        <div {...divProps}>
          {record[source]}
        </div>
      )}
    </div>
  );
};

TextAreaShowField.propTypes = {
  allowHTML: PropTypes.bool,
  record: PropTypes.object,
  seleniumId: PropTypes.string,
  source: PropTypes.string,
};

export default TextAreaShowField;
