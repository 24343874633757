import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableHead, TableBody, TableCell, TableRow, LinearProgress } from '@material-ui/core';
import { getUploadsList } from 'ra-redux/importsUpload';
import { useRefreshList } from 'utils/hooks';

const renderProgress = (progress, id) => (
  <LinearProgress
    key={`progress-${id}`}
    variant={progress < 100 ? 'determinate' : 'indeterminate'}
    value={progress || 0}
  />
);

const tableDescription = [
  {
    key: 'title',
    header: '',
    width: '80%',
  },
  {
    key: 'progress',
    header: 'Import progress',
    render: renderProgress,
    width: '20%',
  },
];

const useStyles = makeStyles({
  paper: { padding: '1rem', marginBottom: '1rem', overflow: 'auto', maxHeight: '15rem' },
  table: { marginTop: '1rem' },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    display: 'inline',
  },
  subTitle: {
    fontSize: 14,
    display: 'inline',
  },
});

const ImportUploads = ({ resource }) => {
  const classes = useStyles();
  const items = useSelector(getUploadsList);

  const isItems = !!items?.length;
  useRefreshList({ resource, condition: !isItems });

  if (!isItems) return null;

  return (
    <Paper className={classes.paper}>
      <Table key="mainTable" className={classes.table}>
        <colgroup>
          {tableDescription.map(({ width, key }) => <col key={`${key}-colgroup`} style={{ width }} />)}
        </colgroup>
        <TableHead>
          <TableRow>
            {tableDescription.map(({ header, key }) => <TableCell key={`${key}-header`} align="right">{header}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(row => (
            <TableRow key={`${row.id}-table-row`}>
              {tableDescription.map(({ render, key }) => (
                <TableCell
                  key={`${row.id}-${key}-tableDescription`}
                  align="right"
                >
                  {render ? render(row[key], row.id) : row[key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

ImportUploads.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default ImportUploads;
