import { GET_LIST } from 'react-admin';
import { CN_FILTER_FIELDS } from 'constants/contentNames';

export default (requestHandler) => async (type, resource, params = {}) => {
  if (resource === 'learning-objects' && type === GET_LIST) {
    const hasFilter = Object.values(CN_FILTER_FIELDS).some((name) => (
      params.filter && params.filter[name]
    ));

    if (hasFilter) {
      const result = await requestHandler(type, resource, params);
      const { data, total } = result;

      if (total > data.length) {
        params.paginaton.perPage = total;
        const result = await requestHandler(type, resource, params);
        return result;
      }

      return result;
    }
  }

  return requestHandler(type, resource, params);
};
