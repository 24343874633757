import { GET_LIST } from 'react-admin';

export default (wrappedDataProvider) => async (type, resource, params = {}) => {
  const isImportsListRequest = type === GET_LIST && resource === 'imports';

  if (isImportsListRequest) {
    const { order } = params.sort || {};
    const isDateFieldSort = params.sort.field === 'created';
    const isMonoLingualFieldSort = ['type', 'creator', 'status'].includes(params.sort.field);

    if (
      (order === 'DESC' && isDateFieldSort)
      || (order === 'ASC' && isMonoLingualFieldSort)
    ) {
      params.sort.nullsFirst = true;
    }
    else if (order === 'ASC' && isDateFieldSort) {
      params.sort.nullsFirst = false;
    }
  }

  const result = await wrappedDataProvider(type, resource, params);

  return result;
};
