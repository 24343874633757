import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-admin';

const BackButton = ({ label }) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <>
      <Button
        onClick={() => history.go(-1)}
        style={{ boxShadow: 'none', width: '10rem', margin: theme.spacing(2, 0) }}
        label={label}
        size="medium"
        variant="contained"
        data-seleniumid={`${label.toLowerCase()}-button`}
      >
        <ArrowBackIcon />
      </Button>
    </>
  );
};

BackButton.propTypes = {
  label: PropTypes.oneOf(['Cancel', 'Back']),
};

BackButton.defaultProps = {
  label: 'Back',
};

export default BackButton;
