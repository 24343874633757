import React from 'react';
import { TextField } from 'react-admin';
import { useIsLanguageAgnosticView } from 'utils/hooks';

import {
  LocaleReferenceField,
  TabbedLanguagesView,
  SimpleShowLayout,
} from '_ui';
import SynonymList from './SynonymList';

const TagView = props => {
  const isLanguageAgnosticView = useIsLanguageAgnosticView();

  return (
    <TabbedLanguagesView
      {...props}
      hasEdit={!isLanguageAgnosticView}
      resource="tags"
      languageAgnosticFields={(props) => (
        <SimpleShowLayout {...props}>
          <TextField source="code" data-seleniumid="tag-code" />
        </SimpleShowLayout>
      )}
      languageSpecificFields={(props) => (
        <SimpleShowLayout {...props}>
          <LocaleReferenceField
            source="id"
            label="Title"
            reference="tags-locales"
            link={false}
            usePriorityLocale={false}
          >
            <TextField source="title" data-seleniumid="tag-locale-title" />
          </LocaleReferenceField>
          <LocaleReferenceField
            source="id"
            label="Synonym"
            reference="tags-locales"
            link={false}
            usePriorityLocale={false}
          >
            <SynonymList seleniumId="tag-locale-Synonym" />
          </LocaleReferenceField>
        </SimpleShowLayout>
      )}
    />
  );
};

export default TagView;
