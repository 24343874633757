import React from 'react';
import { TextField } from 'react-admin';

import {
  LocaleReferenceField,
  TabbedLanguagesView,
  SimpleShowLayout,
  ReferenceField,
} from '_ui';

const SkillView = props => (
  <TabbedLanguagesView
    {...props}
    resource="skillsets"
    languageAgnosticFields={(props) => (
      <SimpleShowLayout {...props}>
        <TextField source="code" data-seleniumid="skillset-code" />
        <ReferenceField
          key="disciplineId"
          source="disciplineId"
          label="Discipline"
          reference="disciplines"
          link={false}
        >
          <LocaleReferenceField
            source="id" label="Title" reference="disciplines-locales" link={false}
          >
            <TextField source="title" data-seleniumid="skillset-discipline" />
          </LocaleReferenceField>
        </ReferenceField>
      </SimpleShowLayout>
    )}
    languageSpecificFields={(props) => (
      <SimpleShowLayout {...props}>
        <LocaleReferenceField
          source="id"
          label="Title"
          reference="skillsets-locales"
          link={false}
          usePriorityLocale={false}
        >
          <TextField source="title" data-seleniumid="skillset-locale-title" />
        </LocaleReferenceField>
      </SimpleShowLayout>
    )}
  />
);

export default SkillView;
