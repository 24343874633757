import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

import { RepublicationButton } from '../Button';
import { STATUS_OPTIONS, PUBLICATION_STATUSES } from '../constants';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: ({ mode }) => (mode === 'action' ? 'flex-end' : 'flex-start'),
    alignItems: 'center',
    '& button': {
      transform: ({ mode }) => (mode === 'list' ? `translate(-${theme.spacing(2)}px, 0)` : 'none'),
      marginRight: ({ mode }) => (mode === 'list' ? -theme.spacing(2) : 0),
    },
  },
}));

const StatusField = (props) => {
  const { record, seleniumId, mode } = props;

  const {
    hasWarnings,
    nextSuccessfulPubId,
    status,
    id,
  } = record;

  const showRepubLink = nextSuccessfulPubId
    && (PUBLICATION_STATUSES.FAILED === status || hasWarnings)
    && ['list', 'action'].includes(mode);

  const classes = useStyles({ mode, showRepubLink });

  if (!record || !status) return null;

  return (
    <div className={classes.root} data-seleniumid={seleniumId || `${id}-status`}>
      { showRepubLink && <RepublicationButton {...props} /> }
      {['list', 'show'].includes(mode) ? (
        <span data-seleniumid={`${id}-status-label`}>
          {STATUS_OPTIONS.find(option => option.id === status)?.name || 'Incorrect Status'}
        </span>
      ) : null }
    </div>
  );
};

StatusField.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  mode: PropTypes.oneOf(['list', 'show', 'action']),
  record: PropTypes.shape({
    hasWarnings: PropTypes.bool,
    id: PropTypes.string,
    nextSuccessfulPubId: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
    status: PropTypes.string,
  }),
  seleniumId: PropTypes.string,
};

StatusField.defaultProps = {
  addLabel: true,
  record: {},
};

export default StatusField;
