import { get, difference, compact } from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  EXPANDABLE_RESOURCES,
  MANUAL_EXPANDABLE_RESOURCES,
  LOCALE_EXPANDABLE_RESOURCES,

  processManualExpand,
  resetManualExpand,
} from 'ra-redux/manualExpandList';
import { parseLocations, isSearchUpdated } from './utils';

export const RA_EXPAND = 'RA/TOGGLE_LIST_ITEM_EXPAND';
export const RA_GET_LIST_SUCCESS = 'RA/CRUD_GET_LIST_SUCCESS';
export const CRUD_CHANGE_LIST_PARAMS = 'RA/CRUD_CHANGE_LIST_PARAMS';

const expandableResourcesPaths = EXPANDABLE_RESOURCES.map(el => `/${el}`);
const manualExpandableResourcesPaths = MANUAL_EXPANDABLE_RESOURCES.map(el => `/${el}`);

export const actionToggleExpand = (id, resource) => ({
  type: RA_EXPAND,
  payload: id,
  meta: { resource },
  notManual: true,
});

const isExpandablePath = path => expandableResourcesPaths.includes(path);
const isManualExpandablePath = path => manualExpandableResourcesPaths.includes(path);
const isLeaveList = (oldPath, path) => isExpandablePath(oldPath) && path !== oldPath;

const getListContext = (store, resource) => {
  const state = store.getState();
  return get(state, `admin.resources.${resource}.list`, { expanded: [], params: {} });
};

const collapseResource = (store, resource = []) => {
  const { dispatch } = store;
  const { expanded } = getListContext(store, resource);
  expanded.forEach(id => dispatch(actionToggleExpand(id, resource)));
};

const clearManualExpand = (store) => {
  const { dispatch } = store;
  dispatch(resetManualExpand());
};

const handleLocaleExpandableResource = (store, action) => {
  const { payload, requestPayload: { filter }, meta: { resource } = {} } = action;
  const { dispatch } = store;
  const data = get(payload, 'data', []);
  const locales = get(filter, 'availableLocales', []);
  if (!data.length) return;
  const { expanded } = getListContext(store, resource);

  if (locales.length) {
    const ids = compact(data.map(({ id }) => id));
    if (!ids.length) return;
    // When there is locales filter, we expand not expanded rows (e.g. expanded some rows before manually and they contain selected locale filter)
    difference(ids, expanded).forEach(id => dispatch(actionToggleExpand(id, resource)));
  }
  else {
    // When we remove locale filter from List it requires to collapse all rows
    if (expanded.length) collapseResource(store, resource);

    // When we open List view we expand manually selected rows before
    const state = store.getState();
    const manualExpandList = get(state, 'manualExpandList', []);
    if (manualExpandList.length) {
      manualExpandList.forEach(id => dispatch(actionToggleExpand(id, resource)));
    }
  }
};

const handleLocationChange = (store, next, payload) => {
  const state = store.getState();
  const { oldPathname, pathname, toggleFromMenu, search, oldSearch } = parseLocations(state, payload);
  if (toggleFromMenu && isManualExpandablePath(pathname)) {
    clearManualExpand(store);
    return;
  }
  if (isLeaveList(oldPathname, pathname)) {
    collapseResource(store, oldPathname.substring(1));
  }
  const isWorkOnManualPage = isManualExpandablePath(oldPathname) && isManualExpandablePath(pathname) && pathname === oldPathname;
  if (!isWorkOnManualPage) return;

  if (isSearchUpdated({ search, oldSearch })) {
    clearManualExpand(store);
  }
};

const handleManualExpand = (store, next, action) => {
  const { notManual } = action;
  if (notManual) return;

  const { payload, meta: { resource } = {} } = action;
  const { params } = getListContext(store, resource);
  const locales = get(params, 'filter.availableLocales', []);

  if (MANUAL_EXPANDABLE_RESOURCES.includes(resource) && !locales.length) {
    const { dispatch } = store;
    dispatch(processManualExpand(payload));
  }
};

export default (store) => (next) => (action) => {
  const { type, meta: { resource } = {}, payload } = action;

  if (type === LOCATION_CHANGE && !payload?.isFirstRendering) handleLocationChange(store, next, payload);
  if (type === RA_GET_LIST_SUCCESS && LOCALE_EXPANDABLE_RESOURCES.includes(resource)) {
    handleLocaleExpandableResource(store, action);
  }
  if (type === RA_EXPAND) handleManualExpand(store, next, action);

  return next(action);
};
