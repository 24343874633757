import React from 'react';
import PropTypes from 'prop-types';
import { Create as RaCreate } from 'react-admin';

import { BackButton } from '_ui/Button';

const Create = (props) => (
  <>
    <BackButton label="Cancel" />
    <RaCreate {...props} />
  </>
);

Create.propTypes = {
  resource: PropTypes.string.isRequired,
};

export default Create;
