import React, { useCallback, useEffect, memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { IndeterminateCheckBox } from '@material-ui/icons';
import { update, getPublishedLocalesForLR, getSelectedByLRId } from 'ra-redux/exportList';
import { Checkbox } from '_ui/Field';
import EmptyField from './EmptyField';

const useStyles = makeStyles((theme) => ({
  indeterminate: {
    color: theme.palette.primary.main,
  },
}));

const INACTIVE_STATE = 'Select all SCORMs';
const ACTIVE_STATE = `Uns${INACTIVE_STATE.substring(1)}`;
const INDETERMINITE_STATE = 'Partially selected';

const getTitle = (isSelected, indeterminate) => {
  if (indeterminate) return INDETERMINITE_STATE;
  return isSelected ? ACTIVE_STATE : INACTIVE_STATE;
};

const DownloadField = memo(({ id, publishedLocales }) => {
  const [isSelected, setIsSelected] = useState(false);
  const dispatch = useDispatch();
  const selected = useSelector(getSelectedByLRId(id));
  const classes = useStyles();

  useEffect(() => {
    if (selected.length !== publishedLocales.length && isSelected) {
      setIsSelected(false);
    }
    if (selected.length && !isSelected && selected.length === publishedLocales.length) {
      setIsSelected(true);
    }
  }, [selected.length, publishedLocales.length]);

  const indeterminate = selected?.length && selected?.length !== publishedLocales?.length;
  const title = getTitle(isSelected, indeterminate);

  const onClick = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(update({
      ids: publishedLocales.map(locale => `${id}_${locale}`),
      flag: indeterminate ? false : !isSelected,
    }));
  }, [dispatch, isSelected, publishedLocales, indeterminate]);

  return (
    <div title={title}>
      <Checkbox
        defaultChecked={false}
        indeterminate={!!indeterminate}
        indeterminateIcon={<IndeterminateCheckBox className={classes.indeterminate} />}
        checked={!!isSelected}
        onClick={onClick}
        data-seleniumid={`export-scorm-${id}`}
        color="primary"
      />
    </div>
  );
});

DownloadField.propTypes = {
  id: PropTypes.string,
  publishedLocales: PropTypes.arrayOf(PropTypes.string),
};

const DownloadFieldWrapper = ({ record }) => {
  const { id } = record;
  const publishedLocales = useSelector(getPublishedLocalesForLR(id));

  if (!id || !publishedLocales?.length) return <EmptyField />;

  return (
    <DownloadField
      id={id}
      publishedLocales={publishedLocales}
    />
  );
};

DownloadFieldWrapper.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string,
  }),
};

DownloadFieldWrapper.defaultProps = {
  record: {},
};

export default DownloadFieldWrapper;
