/* eslint-disable react/prop-types */
import React, { useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSidebarVisibility,
  getResources,
  Responsive,
} from 'react-admin';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { useLocation } from 'react-router';
import { parse as searchParse } from 'query-string';
import { useTheme, useMediaQuery, Box } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { isMatch } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import {
  ArrowRight as ArrowRightIcon,
} from '@material-ui/icons';
import MenuItemLink from './MenuItemLink';
import SubMenuSection from './SubMenuSection';
import subMenusConfig from './subMenusConfig';

const styles = theme => ({
  selectedLink: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  selectedSubLink: {
    backgroundColor: theme.palette.grey['200'],
  },
  menuItemWrapper: {
    overflowY: 'hidden',
    height: '100%',
  },
});

const Menu = ({ classes }) => {
  const refContainer = useRef(null);
  const resources = useSelector(getResources);
  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const dispatch = useDispatch();
  const toggleSidebar = () => {
    dispatch(setSidebarVisibility(!open));
  };

  const checkMenuItemSelected = (path, filterObj = null) => {
    const { search, pathname } = location;
    const isPathMatch = pathname.includes(path);
    let selected = isPathMatch && !filterObj;

    const searchObject = searchParse(search);
    if (isPathMatch && filterObj && searchObject.filter) {
      selected = isMatch(JSON.parse(searchObject.filter), filterObj);
    }

    return selected;
  };

  const menuResources = useMemo(
    () => (resources || []).filter((resource) => resource.hasList),
    [resources],
  );

  return (
    <div ref={refContainer} className={classes.menuItemWrapper}>
      <SimpleBar style={{ maxHeight: refContainer?.current?.offsetHeight }}>
        {
          menuResources.map(({ name, options, icon }) => ((
            (subMenusConfig[name] && (
              <SubMenuSection
                key={name}
                classes={{ ...classes, menuItem: classes.menuItem || '' }}
                name={name}
                icon={icon}
                isDesktop={isDesktop}
                toggleSidebar={toggleSidebar}
                open={open}
                checkMenuItemSelected={checkMenuItemSelected}
                config={{ label: name, ...subMenusConfig[name], ...options }}
              />
            )) || (
              <div key={name}>
                <MenuItemLink
                  to={`/${name}`}
                  primaryText={(options?.label) || name}
                  classes={{ root: checkMenuItemSelected(`/${name}`) && classes.selectedLink }}
                  leftIcon={
                    <Box component={icon || ArrowRightIcon} m={1} marginRight={3.5} />
                  }
                  data-seleniumid={`menu-${name}-button`}
                  sidebarIsOpen={open}
                  onClick={!isDesktop ? toggleSidebar : null}
                />
                <Divider />
              </div>
            ))
          ))
        }
        <Responsive
          small={null}
          medium={null}
        />
      </SimpleBar>
    </div>
  );
};

export default withStyles(styles)(Menu);
