/* eslint-disable react/prop-types */

import React from 'react';
import { useSelector } from 'react-redux';
import { CreateButton } from 'react-admin';
import { BASE_SEARCH_SOURCE } from 'constants';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
} from '_ui';

const Filters = (props) => (
  <Filter {...props} seleniumId="languages-filter">
    <TextInput label="Search" source={BASE_SEARCH_SOURCE} alwaysOn seleniumId="text-input-q" />
  </Filter>
);

const LanguagesList = (props) => {
  const { hasCreate } = props;
  const { total } = useSelector(({ admin: { resources } }) => resources.languages.list);

  return (
    <List
      seleniumId="LanguagesList"
      {...props}
      bulkActionButtons={false}
      actions={
        hasCreate
          ? <CreateButton basePath="../languages" data-seleniumid="create-languages-btn" />
          : null
      }
      filters={<Filters />}
      sort={{ field: 'code', order: 'ASC' }}
      perPage={total}
      pagination={null}
      enableScrollIntoView={false}
    >
      <Datagrid rowClick="show">
        <TextField source="code" />
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};

export default LanguagesList;
