/* eslint-disable no-console */
import cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';

export const getToken = () => cookie.get('WPP_AUTH_TOKEN');
export const removeToken = () => cookie.remove('WPP_AUTH_TOKEN', { path: '' });
export const getPtpId = (token) => {
  try {
    const { ptpid } = jwtDecode(token) || {};
    return ptpid || null;
  }
  catch (e) {
    return null;
  }
};

export const isAuthorized = (token = getToken()) => {
  if (!token) {
    return false;
  }

  try {
    jwtDecode(token);

    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
};
