import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as RaPagination } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
  },
  loading: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    bottom: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
});

const sanitizeProps = ({ seleniumId, ...props }) => props;

const Pagination = props => {
  const { seleniumId, rowsPerPageOptions, loading } = props;
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      { loading && <div className={classes.loading} />}
      <RaPagination
        {...sanitizeProps(props)}
        data-seleniumid={`${seleniumId}-pagination`}
        rowsPerPageOptions={rowsPerPageOptions}
        variant="outlined"
      />
    </div>
  );
};

Pagination.propTypes = {
  loading: PropTypes.bool,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  seleniumId: PropTypes.string,
};

Pagination.defaultProps = {
  loading: false,
  seleniumId: 'common',
};

export default Pagination;
