import { CREATE } from 'react-admin';

const ENTITIES_CREATED_WITH_LOCALES = ['themes', 'programs', 'tags', 'authors'];
export default (wrappedDataProvider) => async (type, resource, params = {}) => {
  if (type === CREATE && ENTITIES_CREATED_WITH_LOCALES.includes(resource)) {
    const { data } = params;
    if (!data.availableLocales) {
      return wrappedDataProvider(type, resource, {
        ...params,
        data: { ...data, availableLocales: [] },
      });
    }
  }

  return wrappedDataProvider(type, resource, params);
};
