import { useEffect, useMemo } from 'react';
import { useDataProvider, CRUD_GET_MANY } from 'react-admin';
import { useSelector, shallowEqual } from 'react-redux';

import _ from 'lodash';

import { GET_MANY_REFERENCE_TYPE } from 'api/backend-data-server/dataProviderAdapter';
import { REGEX_LOCALE } from 'constants/regex';

export const DEFAULT_LOCALE = 'en-US';
export const LOCALE_ORDER = _.uniq([DEFAULT_LOCALE, 'en-GB', 'fr-FR', 'es-ES', 'de-DE', 'nl-NL', 'pt-BR', 'it-IT']);

export const parseLocaleId = (localeId) => {
  const [locale, ...restReversedParts] = localeId.split('_').reverse();
  return { locale, id: restReversedParts.reverse().join('_') };
};

export const sortLocales = (locales) => _.uniq([
  ...(_.intersection(LOCALE_ORDER, locales)),
  ...locales.sort(),
]);

export const getAvailablePriorityLocale = (availableLocales = []) => {
  const [defaultLocale] = _.intersection(LOCALE_ORDER, availableLocales);
  return defaultLocale || (availableLocales && availableLocales.sort()[0]);
};

export const getRelevantLocale = (selectedLocales = [], availableLocales = []) => {
  const [locale] = _.intersection(selectedLocales, availableLocales);
  if (locale) return locale;

  return getAvailablePriorityLocale(availableLocales);
};

export const getLocaleFromPath = pathname => {
  const [lastPathNameSeg] = pathname.split('/').reverse();
  const hasSelectedLangInHeader = /\w{2}-\w{2,3}/.test(lastPathNameSeg);
  return hasSelectedLangInHeader ? lastPathNameSeg : null;
};

export const findLocaleParam = ({ pathname }) => pathname.match(/(?!\/)[a-z]{2}-[A-Z]{2,3}/g);

const payload = {
  pagination: { page: 1, perPage: 100 },
  sort: { field: '', order: '' },
};

export const getManyLocalesPayloadRequest = (id, availableLocales) => ({
  type: GET_MANY_REFERENCE_TYPE,
  resource: 'learning-objects-locales',
  payload: {
    id,
    target: 'parentId',
    ...payload,
    filter: { availableLocales },
  },
});

export const parseResourceLocale = (source = []) => {
  for (const entry of (Array.isArray(source) ? source : [source])) {
    const match = (entry || '').match(REGEX_LOCALE)?.[0];
    if (match) return match;
  }
};

export const useAvailableLocales = ({ resource }) => {
  const dataProvider = useDataProvider();
  const data = useSelector(state => state.admin.resources[resource]?.data, shallowEqual);
  const ids = useMemo(() => Object.values(data).reduce((acc, { id, availableLocales }) => {
    if (availableLocales?.length) {
      const availableLocaleIds = availableLocales.map(locale => `${id}_${locale}`);
      return [
        ...acc,
        ...availableLocaleIds,
      ];
    }
    return acc;
  }, []), [data]);
  ids.sort();

  useEffect(() => {
    if (ids.length > 1) {
      dataProvider.getMany(`${resource}-locales`, { ids }, { action: CRUD_GET_MANY });
    }
  }, [JSON.stringify(ids)]);
};
