export const ACTION_TIPS_LABEL = 'Action Tips';
export const ACTION_TIPS_PREFIX = ['U', 'W', 'X', 'Y', 'Z'];

export const ACTION_TIPS_TEAM_LABEL = 'Action Tips the team';
export const ACTION_TIPS_TEAM_PREFIX = ['ZT', 'YT', 'XT', 'WT', 'UT'];

export const ESSENTIAL_LABEL = 'Essential';
export const ESSENTIAL_PREFIX = ['E'];

export const ESSENTIAL_TEAM_LABEL = 'Essential the team';
export const ESSENTIAL_TEAM_PREFIX = ['ET'];

export const ACTION_TIPS_AND_ESSENTIAL_PREFIX = [...ACTION_TIPS_TEAM_PREFIX, ...ESSENTIAL_TEAM_PREFIX, ...ACTION_TIPS_PREFIX, ...ESSENTIAL_PREFIX];
