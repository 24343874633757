import React, {
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/styles';

import ProgressButton from '_ui/Button/ProgressButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      outline: 'none',
    },
  },
  paper: {
    width: '40vw',
    minWidth: 400,
    '& > form > div': {
      display: 'flex',
      flexGrow: 1,
      padding: theme.spacing(),
    },
  },
  textareaContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2, 1)} !important`,
  },
  textarea: {
    width: '100%',
    marginTop: theme.spacing(),
    padding: theme.spacing(),
    resize: 'none',
  },
  button: {
    margin: 0,
  },
}));

const ConfirmationModal = ({
  children,
  label,
  message,
  seleniumId = label?.replace(' ', '-')?.toLowerCase(),
  onSubmit,
  onCancel,
  submitBtnProps,
  ...modalProps
}) => {
  const formRef = useRef();

  const cancel = useCallback((event) => {
    event.stopPropagation();
    onCancel();
  }, [formRef, onCancel]);

  const classes = useStyles();

  return (
    <Modal
      {...modalProps}
      className={classes.root}
      data-seleniumid={`${seleniumId}-confirmation-modal`}
    >
      <>
        <ClickAwayListener onClickAway={cancel} mouseEvent="onMouseDown">
          <Paper classes={{ root: classes.paper }}>
            <form
              ref={formRef}
              onSubmit={onSubmit}
              data-seleniumid={`${seleniumId}-confirmation-form`}
            >
              <div>
                <Typography
                  variant="h5"
                  data-seleniumid={`${seleniumId}-confirmation-label`}
                >
                  {label}
                </Typography>
              </div>
              <Divider />
              {
                message && (
                  <div>
                    <Typography
                      variant="h6"
                      data-seleniumid={`${seleniumId}-confirmation-message`}
                    >
                      {message}
                    </Typography>
                  </div>
                )
              }
              {children}
              <Divider />
              <div>
                <Button
                  color="default"
                  onClick={cancel}
                  classes={{ root: classes.button }}
                  data-seleniumid={`${seleniumId}-confirmation-cancel-button`}
                >
                  Cancel
                </Button>
                <Box display="flex" flexGrow={1} />
                <ProgressButton
                  color="primary"
                  type="submit"
                  onClick={onSubmit}
                  classes={{ root: classes.button }}
                  seleniumId={`${seleniumId}-confirmation-submit-button`}
                  label="Confirm"
                  {...submitBtnProps}
                />
              </div>
            </form>
          </Paper>
        </ClickAwayListener>
      </>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  ...Modal.propTypes,
  children: PropTypes.element,
  label: PropTypes.string,
  message: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  seleniumId: PropTypes.string,
  submitBtnProps: PropTypes.object,
};

ConfirmationModal.defaultProps = {
  children: null,
  label: 'Confirm',
  message: 'Requested action requires confirmation!',
  submitBtnProps: {},
};

export default ConfirmationModal;
