export default [
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa1',
    type: 'WHATS_NEW',
    title: "What's new?",
    status: '',
    startDate: '',
    finishDate: '',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa4',
    type: 'METADATA',
    title: 'All Languages Metadata',
    status: '',
    startDate: '',
    finishDate: '',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa3',
    type: 'PROGRAM',
    title: 'Catalogue by Program',
    status: '',
    startDate: '',
    finishDate: '',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa2',
    type: 'THEME',
    title: 'Catalogue by Theme',
    status: '',
    startDate: '',
    finishDate: '',
  },
  {
    id: 'c4d386a0-3a0a-496f-ba05-5024d444f746',
    type: 'SKILLSET',
    title: 'Catalogue by Skillsets',
    status: '',
    startDate: '',
    finishDate: '',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa5',
    type: 'BLACKLIST',
    title: 'Blacklist',
    status: '',
    startDate: '',
    finishDate: '',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    type: 'AUTHOR_TAXONOMY',
    title: 'Taxonomy Authors',
    status: '',
    startDate: '',
    finishDate: '',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa9',
    type: 'PROGRAM_TAXONOMY',
    title: 'Taxonomy Programs',
    status: '',
    startDate: '',
    finishDate: '',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
    type: 'THEME_TAXONOMY',
    title: 'Taxonomy Themes',
    status: '',
    startDate: '',
    finishDate: '',
  },
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa8',
    type: 'TAG_TAXONOMY',
    title: 'Taxonomy Tags',
    status: '',
    startDate: '',
    finishDate: '',
  },
  {
    id: '41625b84-3098-4a49-99a3-60f39a1f860e',
    type: 'DISCIPLINE_TAXONOMY',
    title: 'Taxonomy Disciplines',
    status: '',
    startDate: '',
    finishDate: '',
  },
  {
    id: '1ac7acc7-0e40-4e68-8903-9269c9763aa5',
    type: 'SKILLSET_TAXONOMY',
    title: 'Taxonomy Skill Sets',
    status: '',
    startDate: '',
    finishDate: '',
  },
];
