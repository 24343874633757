import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'react-admin';
import moment from 'moment';
import _ from 'lodash';
import { sanitizeProps } from '_ui/Field/utils';

export const MOMENT_DATE_FORMAT = 'M/DD/YYYY, h:mm:ss A';

export const getUserTZ = _.memoize(() => (new Date())
  .toLocaleString('en', { timeZoneName: 'short' })
  .split(' ')
  .pop(),
);

export const formatDateWithTZ = (date) => `${moment(date).format(MOMENT_DATE_FORMAT)} ${getUserTZ()}`;

const getDateWithTZ = (record, source) => (record[source]
  ? { ...record, [source]: `${formatDateWithTZ(record[source])}` }
  : record);

const DateField = ({ seleniumId, record: rawRecord, ...props }) => {
  const { source } = props;

  return (
    <TextField
      data-seleniumid={seleniumId || `date-field-${source}`}
      {...sanitizeProps(props)}
      record={getDateWithTZ(rawRecord, source)}
    />
  );
};

DateField.propTypes = {
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  seleniumId: PropTypes.string,
  source: PropTypes.string,
};
DateField.defaultProps = {
  addLabel: true,
};

export default DateField;
