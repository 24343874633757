import { flatten, groupBy, pick } from 'lodash';

import { getCapLabel } from 'utils';
import { formatExportDate, getEmptyFieldsMap } from 'utils/export';
import { EXPORT_FIELDS_MAP, MISSING_GMO } from './constants';

const getGMOCode = (message) => {
  let result = '';
  try {
    result = message
      .replace(/.+"([^"]+)"$/, '$1');
  }
  catch (e) {
    // eslint-disable-next-line no-console
    console.log(`can't extract gmoCode from the message ${message}`, e);
  }
  return result;
};

const getGMOType = (message) => {
  let result = '';
  try {
    result = message
      .replace(/.+ ([A-Za-z]+):.+$/, '$1');
  }
  catch (e) {
    // eslint-disable-next-line no-console
    console.log(`can't extract gmoType from the message ${message}`, e);
  }
  return getCapLabel(result);
};

export const getInitiatedBy = ({ publicationCreators, publishedBy }) => {
  let initiatedBy = publishedBy;
  if (publishedBy && publicationCreators && publicationCreators[publishedBy]) {
    const { firstName, lastName, email } = publicationCreators[publishedBy];
    if (firstName && lastName) {
      initiatedBy = `${firstName} ${lastName}`;
    }
    else if (email) {
      initiatedBy = email;
    }
  }
  return initiatedBy;
};

export const prepareLogsForExport = (jobs, logs, publicationCreators, { exportFields }) => {
  const logsByJobId = groupBy(logs, 'id');
  const fieldSet = Object.keys(exportFields);

  return flatten(
    jobs.map(({
      id,
      learningObjectCode: code,
      language,
      initiatedDate,
      publicationDate,
      status,
      publishedBy,
    }) => {
      const jobExportEntry = {
        ...getEmptyFieldsMap(EXPORT_FIELDS_MAP),
        code,
        language,
        date: publicationDate || initiatedDate,
        status,
      };

      const jobLogs = logsByJobId[id] || [];
      const exportEntries = jobLogs.length ? jobLogs : [jobExportEntry];
      return exportEntries.map(({ id, date, ...log }) => {
        const exportEntry = { ...jobExportEntry, ...log };
        exportEntry.date = formatExportDate(date);
        if (exportEntry.message?.includes(MISSING_GMO)) {
          exportEntry.gmoCode = getGMOCode(exportEntry.message);
          exportEntry.gmoType = getGMOType(exportEntry.message);
        }
        exportEntry.initiatedBy = getInitiatedBy({ publicationCreators, publishedBy });
        return pick(exportEntry, fieldSet);
      });
    }),
  );
};
