import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  useLogin,
  showNotification,
  Notification,
} from 'react-admin';

import {
  makeStyles,
  useTheme,
  Fade,
  Box,
  Icon,
  Card,
  Button,
  Link,
  FormGroup,
  TextField,
  CardContent,
  FormControl,
  CircularProgress,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

export const SD_LOG_MESSAGE = 'Log a Service desk ticket to get access';
export const SD_LOG_LINK = 'https://wiley.service-now.com/service_gateway?id=sc_cat_item&sys_id=8e4bbd3edb551b002522d7795e9619a3&sysparm_app=2446e929dbb5e700defc777a8c961943';

const getErrorMessage = (status) => {
  if (status === 401) return 'Unauthorized';
  if (status === 403) return 'Forbidden';
  return 'Failed to login';
};

const useStyles = makeStyles({
  card: {
    width: 320,
  },
  cardContent: {
    paddingBottom: '16px !important',
  },
  field: {
    paddingBottom: 16,
  },
});

const UserLogin = () => {
  const [authLoading, setAuthLoading] = useState(false);
  const [isSdLogErrorVisible, setIsSdLogErrorVisible] = useState(false);

  const formRef = useRef(null);
  const login = useLogin();
  const dispatch = useDispatch();

  const submit = (e) => {
    e.preventDefault();

    if (formRef?.current) {
      setIsSdLogErrorVisible(false);
      setAuthLoading(true);
      const formData = new FormData(formRef.current);

      login({
        email: formData.get('email'),
        password: formData.get('password'),
      }).catch((error) => {
        const { status, message } = error;
        if (status === 403) {
          setIsSdLogErrorVisible(true);
        }
        setAuthLoading(false);
        dispatch(showNotification(message || getErrorMessage(status), 'error'));
      });
    }
  };

  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box alignItems="center" justifyContent="center">
      <Card
        classes={{ root: classes.card }}
        component="form"
        ref={formRef}
        onSubmit={submit}
        data-seleniumid="login-form"
      >
        <CardContent
          classes={{ root: classes.cardContent }}
        >
          <FormGroup>
            <FormControl classes={{ root: classes.field }}>
              <TextField
                id="username"
                autoFocus
                variant="outlined"
                label="Email"
                type="email"
                name="email"
                inputProps={{ 'data-seleniumid': 'login-email' }}
                autoComplete="username"
              />
            </FormControl>
            <FormControl classes={{ root: classes.field }}>
              <TextField
                id="password"
                variant="outlined"
                label="Password"
                type="password"
                name="password"
                inputProps={{ 'data-seleniumid': 'login-password' }}
                autoComplete="current-password"
              />
            </FormControl>
            <FormControl>
              <Button
                id="login"
                fullWidth
                type="submit"
                size="large"
                variant="contained"
                color="primary"
                data-seleniumid="login-submit"
              >
                {
                  authLoading
                    ? (
                      <CircularProgress
                        color="inherit"
                        size={theme.spacing(3)}
                      />
                    )
                    : 'Login'
                }
              </Button>
            </FormControl>
          </FormGroup>
          {
            isSdLogErrorVisible && (
              <Fade in>
                <Box display="flex" alignItems="center" marginTop={3}>
                  <Box marginRight={1} clone>
                    <Icon component={InfoIcon} color="disabled" />
                  </Box>
                  <Link
                    data-seleniumid="service-desk-log"
                    href={SD_LOG_LINK}
                    target="_blank"
                    variant="body2"
                  >
                    {SD_LOG_MESSAGE}
                  </Link>
                </Box>
              </Fade>
            )
          }
        </CardContent>
      </Card>
      <Notification />
    </Box>
  );
};

export default UserLogin;
