import React from 'react';
import PropTypes from 'prop-types';

const SynonymList = (props) => {
  const { seleniumId, record } = props;
  const { synonym } = record || {};

  return (
    <div data-seleniumid={seleniumId || 'synonym-list'}>
      {synonym?.length ? synonym.join(', ') : ''}
    </div>
  );
};

SynonymList.propTypes = {
  record: PropTypes.object,
  seleniumId: PropTypes.string,
};

export default SynonymList;
