import React, {
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useState,
  useEffect,
} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  useTranslate,
  useNotify,
  FileInput,
  SimpleForm,
} from 'react-admin';
import {
  CircularProgress,
  Box,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';

import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { fetchImportUploads, isImportProcess } from 'ra-redux/importsUpload';
import ImportInputPanel from './ImportInputPanel';
import { fileImportType, CONTENT_TO_FILE_TYPE_MAP, IMPORT_HIDE_DURATION } from '../../constants';

const useStyles = makeStyles(
  theme => ({
    form: {
      minWidth: 320,
      minHeight: 185,
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > div': {
        padding: '0 !important',
      },
    },
    root: {
      position: 'relative',
      '&:hover .import-input-panel': {
        opacity: 1,
      },
      '.import-input-preview svg': {
        opacity: 1,
      },
      '&:hover .import-input-preview svg': {
        opacity: 0,
        transition: theme.transitions.create('opacity'),
      },
    },
    dropZone: {
      position: 'absolute',
      zIndex: 90,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      padding: theme.spacing(1),
      background: 'transparent',
      cursor: 'pointer',
      textAlign: 'center',
      color: theme.palette.getContrastText(
        theme.palette.background.default,
      ),
    },
    preview: {
      position: 'relative',
    },
  }),
  { name: 'RaFileInput' },
);

const useRawFileTransform = ({ source, title }) => useCallback((rawFile) => {
  if (rawFile instanceof File) {
    const file = {
      rawFile,
      [source]: URL.createObjectURL(rawFile),
    };

    if (title && file.name) file[title] = file.name;
    return file;
  }

  return rawFile;
}, []);

export const useFileUpload = ({ entityId, entityResource, ...fieldElementProps }) => {
  const dispatch = useDispatch();
  const transformProps = pick(fieldElementProps, ['importType', 'source', 'title']);

  const transform = useRawFileTransform(transformProps);
  const upload = useCallback((rawFile) => {
    const { importType: type } = transformProps;
    const source = { file: transform(rawFile) };
    const payload = { type, source, entityId, entityResource };
    dispatch(fetchImportUploads(payload));
  }, [JSON.stringify(transformProps)]);

  return { upload, transform };
};

const ImportInput = props => {
  const {
    id,
    entityId,
    entityResource,
    accept,
    children,
    className,
    classes: classesOverride,
    format,
    helperText,
    importType,
    label,
    labelMultiple = 'ra.input.file.upload_several',
    labelSingle = 'ra.input.file.upload_single',
    maxSize,
    minSize,
    multiple = false,
    options: {
      inputProps: inputPropsOptions,
      onDrop: onDropOverride,
      loading: isRemoving,
      ...options
    } = {},
    parse,
    placeholder,
    seleniumId,
    source,
    validator,
    ...rest
  } = props;
  const translate = useTranslate();
  const notify = useNotify();

  const fieldElement = Children.only(children);
  const { upload, transform } = useFileUpload({
    ...(fieldElement?.props || {}),
    importType,
    entityId,
    entityResource,
  });

  const isImportProgress = useSelector(isImportProcess(entityId));

  const onDrop = useCallback((acceptedFiles = []) => {
    const [rawFile] = acceptedFiles;
    const error = !rawFile
      ? `Import: file format not supported, only accepting "${accept}`
      : validator(rawFile)?.message;

    if (error) {
      notify(`Import: ${error}`, 'error', {}, false, IMPORT_HIDE_DURATION);
    }
    else {
      const file = transform(rawFile);
      const uploadCb = upload.bind(null, file);
      if (onDropOverride) {
        onDropOverride(uploadCb, rawFile, transform);
      }
      else {
        uploadCb();
      }
    }
  }, [
    accept,
    onDropOverride,
    upload,
    validator,
    transform,
  ]);

  const {
    getRootProps,
    getInputProps,
    open,
  } = useDropzone({
    ...options,
    accept,
    maxSize,
    minSize,
    multiple,
    onDrop,
  });

  const classes = useStyles();
  const dropRootProps = getRootProps({
    title: !placeholder
      ? translate(multiple ? labelMultiple : labelSingle)
      : null,
    className: classes.dropZone,
    'data-seleniumid': `${seleniumId}-dropzone`,
  });
  const dropInputProps = getInputProps({
    id,
    'data-seleniumid': `${seleniumId}-input`,
    ...inputPropsOptions,
  });

  return (
    <>
      <div data-seleniumid={`${seleniumId}-input-root`} className={classes.root}>
        {(isImportProgress || isRemoving) ? (
          <CircularProgress />
        )
          : (
            <>
              <ImportInputPanel
                {...options}
                onDrop={open}
                seleniumId={seleniumId}
              />
              <div {...dropRootProps}>
                <input {...dropInputProps} />
                { isValidElement(placeholder) && placeholder }
              </div>
              <Box className={cn(classes.preview, 'import-input-preview')}>
                {cloneElement(fieldElement, {
                  ...rest,
                  source,
                  seleniumId,
                })}
              </Box>
            </>
          )}
      </div>
    </>
  );
};

ImportInput.propTypes = {
  seleniumId: PropTypes.string,
  ...omit(FileInput.propTypes, 'classes'),
};

ImportInput.defaultProps = {
  ...omit(FileInput.defaultProps, 'classes', 'label'),
};

const ImportForm = (props) => {
  const { record, importType, resource, source } = props;
  const { id, [source]: recordSource } = record || {};
  const classes = useStyles();

  const [sourceUrl, setSourceUrl] = useState(recordSource);
  useEffect(() => {
    if (sourceUrl !== recordSource) {
      setSourceUrl(recordSource);
    }
  }, [recordSource]);

  return (
    <SimpleForm
      toolbar={null}
      className={classes.form}
      record={record}
    >
      <ImportInput
        accept={CONTENT_TO_FILE_TYPE_MAP[importType]}
        entityId={id}
        entityResource={resource}
        {...props}
      />
    </SimpleForm>
  );
};

ImportForm.propTypes = {
  importType: PropTypes.oneOf(Object.values(fileImportType)).isRequired,
  ...ImportInput.propTypes,
};

ImportForm.defaultProps = {
  ...ImportInput.defaultProps,
  options: {},
};

export default ImportForm;
