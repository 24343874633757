import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { makeStyles, Typography, Box } from '@material-ui/core';

import {
  FunctionField,
  TextField,
} from 'react-admin';

import {
  LocaleReferenceField,
  SimpleShowLayout,
  ViewCard,
  TabbedLanguagesView,
  TextAreaShowField,
} from '_ui';

import { fileImportType } from '_domains/Jobs/Imports/constants';
import { ImportInput } from '_domains/Jobs/Imports/Input';
import AuthorBG from 'assets/images/author_bg.svg';
import { AvatarField } from './Fields';
import { REPUTATION_OPTIONS } from './constants';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const authorCardStyles = makeStyles(theme => ({
  wrap: {
    height: '100%',
    width: '100%',
    background: `url(${AuthorBG})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: theme.spacing(7),
  },
  label: {
    display: 'inline',
    marginRight: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
  },
  baseCardContentRow: {
    padding: '0 !important',
    '& > div': {
      height: '20rem !important',
    },
  },
}));

const AuthorCard = props => {
  const { record: { code } } = props;

  const validate = useCallback(file => {
    const fileName = file?.name;

    if (
      code
      && fileName
      && !new RegExp(`^p?${code}`).test(fileName)
    ) {
      return `The file name "${fileName}" does not match the code of the selected author "${code}"`;
    }
  }, [code]);

  const { wrap, label, ...cardClasses } = authorCardStyles();
  return (
    <ViewCard {...props} data-seleniumid="author-card" classes={cardClasses}>
      <>
        <div className={wrap}>
          <ImportInput
            {...props}
            importType={fileImportType.LO_THUMBNAIL}
            seleniumId="author-avatar"
            source="thumbnailUrl"
            validator={validate}
          >
            <AvatarField size={120} />
          </ImportInput>
          <Box marginTop={1} marginBottom={1}>
            <LocaleReferenceField
              {...props}
              reference="authors-locales"
              source="id"
              link={false}
            >
              <FunctionField
                data-seleniumid="author-name"
                render={({ firstName, lastName }) => <Typography variant="h6">{firstName} {lastName}</Typography>}
              />
            </LocaleReferenceField>
          </Box>
          <Box marginTop={1}>
            <Typography variant="subtitle2" className={label}>
              Code
            </Typography>
            <TextField source="code" data-seleniumid="author-code" {...props} />
          </Box>
          <Box marginTop={1}>
            <Typography variant="subtitle2" className={label}>
              Reputation
            </Typography>
            <FunctionField
              {...props}
              source="reputation"
              data-seleniumid="author-reputation"
              render={({ reputation }) => REPUTATION_OPTIONS.find((entry) => entry.id === reputation)?.name || ''}
            />
          </Box>
        </div>
      </>
    </ViewCard>
  );
};

AuthorCard.propTypes = {
  record: PropTypes.shape({ code: PropTypes.string.isRequired }),
};

AuthorCard.defaultProps = {
  record: {},
};

const LanguageAgnosticFields = (props) => <AuthorCard {...props} />;

const sanitizeProps = ({ languageSpecificFields, languageAgnosticFields, hasCreate, hasShow, hasEdit, hasList, ...props }) => props;

const AuthorView = (props) => {
  const classes = useStyles();
  return (
    <TabbedLanguagesView
      {...props}
      resourse="authors"
      languageAgnosticFields={(props) => (
        <SimpleShowLayout {...props}>
          <LanguageAgnosticFields />
        </SimpleShowLayout>
      )}
      languageSpecificFields={(props) => (
        <SimpleShowLayout {...props}>
          {[
            ['Given Name', 'firstName', { 'data-seleniumid': 'firstName' }],
            ['Family Name', 'lastName', { 'data-seleniumid': 'lastName' }],
            ['Biography', 'biography', { 'data-seleniumid': 'biography', allowHTML: true }, TextAreaShowField],
          ].map(([label, source, options, Component = TextField]) => (
            <LocaleReferenceField
              {...sanitizeProps(props)}
              key={`author-field-${source}`}
              addLabel
              link={false}
              source="id"
              label={label}
              reference="authors-locales"
              usePriorityLocale={false}
              className={classes.wrap}
            >
              <Component source={source} {...options} />
            </LocaleReferenceField>
          ))}
        </SimpleShowLayout>
      )}
    />
  );
};

export default AuthorView;
