import { GET_LIST } from 'react-admin';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

import defaultExports from '../../backend-data-server/processHTTPResponse/processExports/defaultExportsList';

export const EXPORT_TYPE_ORDER = defaultExports.map(({ type }) => type);
export const sortListByDefault = (data) => data.sort((a, b) => (
  EXPORT_TYPE_ORDER.indexOf(a.type) - EXPORT_TYPE_ORDER.indexOf(b.type)
));

export const sortListByField = (data, sort) => {
  const sorted = sortBy(data, [sort.field]);
  return sort.order === 'DESC' ? sorted.reverse() : sorted;
};

export default (wrappedDataProvider) => async (type, resource, params) => {
  const result = await wrappedDataProvider(type, resource, params);

  const isExportsListRequest = type === GET_LIST && resource === 'exports';
  if (isExportsListRequest && result?.data) {
    const data = uniqBy([
      ...result.data,
      ...defaultExports,
    ], 'type');
    const sortParams = params?.sort || { field: 'type', order: 'ASC' };

    result.data = sortParams.field === 'id'
      ? sortListByDefault(data)
      : sortListByField(data, sortParams);
    result.total = result.data.length;
  }

  return result;
};
