import React, { useState, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import {
  Dialog,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import 'video.js/dist/video-js.css';

import { ProgressButton } from '_ui';
import { selectLRPreviewContent } from 'ra-redux/selectors';
import { ERROR_TEXT } from './constants';
import VideoPlayer from '../VideoPlayer';
import VideoPlayerLegacy from '../VideoPlayerLegacy';
import useVideoPreview from './useVideoPreview';
import usePreview from './usePreview';

const useStyles = makeStyles({
  dialogPaper: {
    height: '80%',
    width: '100%',
    maxWidth: 960,
    overflowY: 'visible',
    backgroundColor: 'transparent',
    ['@media (min-height:512px)']: { // eslint-disable-line no-useless-computed-key
      maxHeight: 512,
    },
  },
  closeButton: {
    color: '#fff',
    cursor: 'pointer',
    textAlign: 'right',
    marginRight: '-2em',
    marginTop: '-2em',
    '&:hover': {
      color: '#000',
    },
  },
});
/**
 * Video preview description for Learning Resources.
 * At the moment there are two types of Video preview:
 * 1) Legacy (usePreview), when BE puts video with subtitle to sub folder (folder where name is equal CODE) and locale has short format. E.g.
 * "...preview/VDAW302_ja-JP_8-0/VDAW302/VDAW302.mp4"
 * ".../preview/VDAW302_ja-JP_8-0/VDAW302/VDAW302_JP.vtt"
 * 2) New one (useVideoPreview), when BE puts content directly to main preview folder (it also uses content-list endpoint) and subtitles use full locale. E.g.
 * "...preview/LEAD400_1-0_en-US_1-0/LEAD400.mp4"
 * "...preview/LEAD400_1-0_en-US_1-0/LEAD400_en-US.vtt"
 * The described cases depend on the prop: isLegacyVideo
 *
 * Video preview description for Publications.
 * For legacy flow we use the same implementation as for LRs.
 * For new flow, we don't use content-list endpoint, we use legacy implementation but with another files paths (as for new flor for LRs), depend on isLegacyPath prop.
 */
const VideoPreview = (props) => {
  const { label, tooltip, classes, isLegacyVideo, ...previewProps } = props;
  const { id, locale, code } = previewProps;
  const hook = isLegacyVideo ? usePreview : useVideoPreview;
  const [getPreview, { bucket, isLoading, source }] = hook(previewProps);
  const [isVideoCastOpened, setVideoCastOpened] = useState(false);
  const videoClasses = useStyles();
  const notify = useNotify();

  const entities = useSelector(selectLRPreviewContent(id));

  const onClick = useCallback(async () => {
    if (bucket?.includes(`/${code}_${locale}`) && source) {
      setVideoCastOpened(true);
      return;
    }

    getPreview(() => {
      setVideoCastOpened(true);
    }, (msg) => {
      notify(ERROR_TEXT, 'error', {}, false, 10e3);
      // eslint-disable-next-line no-console
      console.error(msg);
    },
    { entities },
    );
  }, [bucket, source, code, locale, entities]);

  const onClose = useCallback(() => {
    setVideoCastOpened(false);
  }, [setVideoCastOpened]);

  const VideoComponent = isLegacyVideo ? VideoPlayerLegacy : VideoPlayer;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ProgressButton
        tooltip={tooltip}
        size="small"
        color="primary"
        loading={isLoading}
        seleniumId={`preview-button-${id}`}
        onClick={onClick}
        label={label}
        className={classes.previewButton}
      />
      <Dialog
        data-seleniumid={`preview-dialog-${id}`}
        maxWidth="xl"
        PaperProps={{ className: videoClasses.dialogPaper }}
        open={isVideoCastOpened}
        onBackdropClick={onClose}
      >
        <div className={videoClasses.closeButton}>
          <CloseIcon onClick={onClose} />
        </div>
        <VideoComponent config={source || {}} />
      </Dialog>
    </div>
  );
};

VideoPreview.propTypes = {
  classes: PropTypes.object,
  code: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isLegacyPath: PropTypes.bool,
  isLegacyVideo: PropTypes.bool,
  label: PropTypes.string,
  locale: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  storeBucket: PropTypes.string,
  tooltip: PropTypes.string,
};

export default memo(VideoPreview);
