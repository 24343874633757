import { useState, useCallback } from 'react';
import { useDataProvider } from 'react-admin';
import { getStaticPaths } from './utils';
import { NOT_FOUND } from './constants';

const usePreview = ({ resource, id, storeBucket, code, locale, mediaType, isLegacyPath }) => {
  const [previewMeta, setPreviewMeta] = useState({
    isLoading: false,
    bucket: storeBucket,
    source: getStaticPaths({ bucket: storeBucket, code, locale, mediaType, isLegacyPath }),
  });
  const dataProvider = useDataProvider();

  const getPreview = useCallback((onSuccess, onFailed) => {
    setPreviewMeta(meta => ({ ...meta, isLoading: true }));

    dataProvider.getOne(resource, { id, locale })
      .then(({ data }) => {
        const { bucket } = data || {};
        if (!bucket) throw new Error(NOT_FOUND);

        const source = getStaticPaths({ bucket, code, locale, mediaType, isLegacyPath });
        setPreviewMeta(meta => ({
          ...meta,
          isLoading: false,
          bucket,
          source,
        }));
        onSuccess(source);
      })
      .catch((error) => {
        setPreviewMeta(meta => ({ ...meta, isLoading: false }));
        onFailed(error?.message);
      });
  }, [resource, code, id, locale, mediaType, isLegacyPath]);

  return [getPreview, previewMeta];
};

export default usePreview;
