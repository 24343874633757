import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Checkbox, Typography } from '@material-ui/core';
import {
  update,
  getSelectedPage,
  clearPage,
  getSelectableLocaleIds,
} from 'ra-redux/exportList';
import { selectDisplayedLRs } from 'ra-redux/selectors';
import { getWithNBSpace } from 'utils/regex';

const UNSELECT_HINT = 'Unselect all SCORMs on page';
const SELECT_HINT = 'Select all SCORMs on page';

const SELECT_LABEL = getWithNBSpace('Select all');

const useStyles = makeStyles(() => ({
  wrap: {
    marginLeft: '-3rem',
    paddingTop: '0.2rem',
    display: 'flex',
    alignItems: 'center',
  },
}));

const ExportHeader = () => {
  const [isSelected, setIsSelected] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const selected = useSelector(getSelectedPage);
  const selectableLocaleIds = useSelector(getSelectableLocaleIds);
  const displayedLRs = useSelector(selectDisplayedLRs);

  useEffect(() => {
    if (selected.length !== selectableLocaleIds.length && isSelected) {
      setIsSelected(false);
    }
    if (selected.length && !isSelected && selected.length === selectableLocaleIds.length) {
      setIsSelected(true);
    }
  }, [selected.length, selectableLocaleIds.length]);

  const onClick = useCallback((e) => {
    e.stopPropagation();

    const newState = !isSelected;
    if (newState) {
      dispatch(update({ ids: selectableLocaleIds, flag: true }));
    }
    else {
      dispatch(clearPage({ lrs: displayedLRs }));
    }
    setIsSelected(newState);
  }, [isSelected, selectableLocaleIds, displayedLRs]);

  if (!selectableLocaleIds.length) return null;
  return (
    <div className={classes.wrap}>
      <Checkbox
        defaultChecked={false}
        checked={!!isSelected}
        onClick={onClick}
        title={isSelected ? UNSELECT_HINT : SELECT_HINT}
        color="primary"
        data-seleniumid="export-all-scorm"
      />
      <Typography variant="subtitle2" component="span">
        {SELECT_LABEL}
      </Typography>
    </div>
  );
};

export default ExportHeader;
