import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { useLocation } from 'react-router';

import { ROUTE_PATTERNS } from 'constants/routes';

export const usePathMatch = locationParam => {
  const routeLocation = useLocation();
  const { pathname } = locationParam || routeLocation;
  return useMemo(() => Object.values(ROUTE_PATTERNS).reduce((acc, path) => {
    const match = matchPath(pathname, { path, exact: true, strict: true });
    if (match?.params) {
      const params = Object.entries(match.params).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: value?.replace(/[^a-z|A-Z|0-9|-]/, ''),
      }), {});

      match.params = params;
    }
    return match || acc;
  }, {}), [pathname]);
};

export const getResourceFromPathname = pathname => pathname.split('/').filter(el => !!el)[0];
