import React from 'react';
import { SimpleForm } from 'react-admin';
import {
  TextInput,
  ToolBar,
  CreateLocale,
  ReferenceField,
  TextField,
} from '_ui';
import { getLocaleModifyRedirectHref } from 'utils';

const ThemesCreateLocale = props => (
  <CreateLocale {...props}>
    <SimpleForm toolbar={<ToolBar />} redirect={getLocaleModifyRedirectHref}>
      <ReferenceField
        label="Parent Code"
        source="parentId"
        link={false}
        reference="themes"
        seleniumId="themes-locale-modify-parent-code"
      >
        <TextField source="code" />
      </ReferenceField>
      <TextInput source="locale" disabled seleniumId="themes-locale-modify-title" />
      <TextInput
        source="title"
        seleniumId="themes-locale-modify-title"
        inputProps={{ autoComplete: 'off' }}
      />
    </SimpleForm>
  </CreateLocale>
);

export default ThemesCreateLocale;
