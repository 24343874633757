import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import _ from 'lodash';

import { generateSearchParams, getCapLabel } from 'utils';
import { getResourceFromPathname } from 'utils/router';
import { SCROLL_LOCALE_PARAM } from 'utils/list';
import { getLocaleFromPath } from 'utils/locales';
import { LR } from 'constants/resources';
import { Text, Link } from './Breadcrumb';

export const seleniumId = 'resource-list-breadcrumb';

const useLinkParamsWithScroll = ({ resource, params, pathname, isLink }) => {
  const [to, setTo] = useState(null);

  useEffect(() => {
    if (!isLink) return;
    const path = generateSearchParams(`/${resource}`, params);
    const location = getLocaleFromPath(pathname);
    if (resource !== LR || !location) {
      setTo(path);
    }
    else {
      const [pathname = null, search = null] = (path || '').split('?');
      setTo({
        pathname,
        search,
        state: { [SCROLL_LOCALE_PARAM]: location },
      });
    }
  }, [isLink]);

  return to;
};

const List = () => {
  const location = useLocation();
  const params = useParams();
  const { resource: paramsResource = getResourceFromPathname(location.pathname) } = params;
  const resource = paramsResource.replace('-locales', '');
  const { list = {}, props: listProps } = useSelector((state) => _.get(state, `admin.resources.${resource}`)) || {};

  const pathname = `/${resource}`;
  const label = getCapLabel(listProps?.options?.label || resource);

  const isLink = location.pathname !== pathname;
  const to = useLinkParamsWithScroll({ resource, params: list?.params, pathname: location.pathname, isLink });

  return (isLink && to)
    ? <Link seleniumId={seleniumId} to={to}>{label}</Link>
    : <Text seleniumId={seleniumId}>{label}</Text>;
};

export default List;
