import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,

  makeStyles,
  Typography,
  IconButton,
  Divider,
  Fade as MuiFade,
} from '@material-ui/core';
import LayersIcon from '@material-ui/icons/Layers';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ProgressRow from './ProgressRow';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',

    '& .MuiTableContainer-root td, th': {
      padding: theme.spacing(1),
    },
  },
  root: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    width: '42rem',

    borderRadius: theme.spacing(1),
    'box-shadow': 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',

    borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    cursor: 'pointer',
  },
  titleButton: {
    width: '2rem',
    height: '2rem',
    color: theme.palette.common.white,
  },
  table: {
    padding: theme.spacing(1),
    overflowY: 'auto',
    maxHeight: '25rem',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const progressHeaderStyle = { paddingLeft: '10px', width: '30%' };

// eslint-disable-next-line react/prop-types
const Fade = ({ children }) => {
  const [show, setShow] = useState(false);
  useEffect(() => { setShow(true); }, []);

  return <MuiFade in={show}>{children}</MuiFade>;
};

const PublicationsProgressView = ({ data }) => {
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  const seleniumId = 'publication-progress';

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className={classes.wrap}>
      { open ? (
        <Fade>
          <div className={classes.root} data-seleniumid={`${seleniumId}-panel`}>
            <div
              onClick={handleClick}
              className={classes.title}
            >
              <Typography variant="h5">Publication(s) progress</Typography>
              <IconButton
                title="Hide"
                data-seleniumid={`${seleniumId}-hide-icon`}
                className={classes.titleButton}
              >
                <ExpandMoreIcon />
              </IconButton>
            </div>
            <Divider />
            <TableContainer className={classes.table}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width="10%">Code</TableCell>
                    <TableCell style={progressHeaderStyle}>In Progress</TableCell>
                    <TableCell style={progressHeaderStyle}>Failed</TableCell>
                    <TableCell style={progressHeaderStyle}>Successful</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map(({ code, locales }) => (
                    <ProgressRow
                      seleniumId={seleniumId}
                      key={code}
                      code={code}
                      locales={locales}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Fade>
      ) : null}
      <IconButton
        title="Publications progress"
        aria-haspopup="true"
        color="primary"
        data-seleniumid={`${seleniumId}-icon`}
        onClick={handleClick}
        className={classes.button}
      >
        <LayersIcon />
      </IconButton>
    </div>
  );
};

PublicationsProgressView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    locales: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      id: PropTypes.string,
      locale: PropTypes.string,
      status: PropTypes.string,
    })),
  })),
};

export default PublicationsProgressView;
