import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { changeListParams } from 'react-admin';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import LayersIcon from '@material-ui/icons/Layers';

import { useGetResourceSelector } from 'ra-redux/selectors';
import { customIconColors } from '_ui/Layout/Menu/subMenusConfig';
import { IMPORT_STATUSES, fileImportType } from '../constants';

export const checkIsLinkAllowed = record => (
  !!record
  && [IMPORT_STATUSES.SUCCESSFUL, IMPORT_STATUSES.PARTIAL].includes(record.status)
  && record.type === fileImportType.LO_PUBLISH
  && !!record.subtaskIds?.length
);

const TARGET_RESOURCE = 'publications';

const STATUSES = {
  SUCCESSFUL: { color: customIconColors.success, tooltip: 'Successful publication' },
  PARTIAL: { color: customIconColors.partial, tooltip: 'Partial publication' },
  FAILED: { color: customIconColors.failed, tooltip: 'Failed publication' },
};

const SubtasksField = ({ record }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { subtasksStatus } = record;

  const isLinkAllowed = checkIsLinkAllowed(record);
  const targetListParams = useGetResourceSelector(TARGET_RESOURCE, 'list.params');

  const onClick = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(changeListParams(
      TARGET_RESOURCE,
      { ...targetListParams, filter: { ids: record.subtaskIds } },
    ));
    history.push(`/${TARGET_RESOURCE}`);
  }, [record.subtaskIds, targetListParams]);

  if (!isLinkAllowed) return null;
  const seleniumId = `subtasks-${record.id}-button`;

  return (
    <IconButton
      title={STATUSES[subtasksStatus]?.tooltip || 'Check publication status'}
      size="medium"
      onClick={onClick}
      color="primary"
      style={{ color: STATUSES[subtasksStatus]?.color }}
      data-seleniumid={seleniumId}
    >
      <LayersIcon />
    </IconButton>
  );
};

SubtasksField.propTypes = {
  /* eslint-enable */
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(IMPORT_STATUSES)).isRequired,
    subtaskIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    subtasksStatus: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

export default SubtasksField;
