import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Button, Toolbar } from '@material-ui/core';

const DefaultLocaleActions = ({ editHref }) => (
  <Toolbar disableGutters variant="dense">
    <Box display="flex" flex={1} />
    <Link
      to={editHref}
      component={({ navigate, ...buttonProps }) => <Button {...buttonProps}>Edit</Button>}
      color="primary"
      variant="outlined"
      size="small"
      data-seleniumid="edit-button"
    />
  </Toolbar>
);

DefaultLocaleActions.propTypes = {
  editHref: PropTypes.string.isRequired,
};

const TabbedLanguagesLocaleActions = ({ actions: Actions = DefaultLocaleActions, ...rest }) => {
  if (!Actions) return null;
  return (<Actions {...rest} />);
};

TabbedLanguagesLocaleActions.propTypes = {
  actions: PropTypes.func,
};

export default TabbedLanguagesLocaleActions;
