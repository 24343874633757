/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import {
  SimpleShowLayout,
} from 'react-admin';

import { Toolbar, Button, Box } from '@material-ui/core';

import {
  Show,
  TextField,
} from '_ui';

const sanitizeProps = ({ staticContext, ...props }) => props;

const Actions = ({ link }) => (
  <Toolbar disableGutters variant="dense">
    <Box display="flex" flex={1} />
    <Button
      data-seleniumid="edit-button"
      color="primary"
      size="small"
      variant="outlined"
      href={link}
    >
      Edit
    </Button>
  </Toolbar>
);

Actions.propTypes = {
  link: PropTypes.string,
};

const LanguagesView = (props) => {
  const { hasEdit, resource, match: { params: { id } } } = props;
  return (
    <Show {...sanitizeProps(props)} actions={null}>
      <SimpleShowLayout>
        { hasEdit && <Actions link={`#/${resource}/${id}`} /> }
        <TextField source="code" seleniumId="text-field-code" />
        <TextField source="name" seleniumId="text-field-name" />
      </SimpleShowLayout>
    </Show>
  );
};

LanguagesView.propTypes = {
  match: PropTypes.object,
  resource: PropTypes.string,
};

export default LanguagesView;
