import { createSelector } from 'reselect';
import { PUBLICATION_STATUSES } from '_domains/Jobs/Publications/constants';
import { keyBy, groupBy, sortBy, map, flatten } from 'lodash';

/**
 * State model:
 * interface Entity {
 *   id: string,
 *   status: PUBLICATION_STATUSES.IN_PROGRESS | PUBLICATION_STATUSES.FAILED | PUBLICATION_STATUSES.SUCCESSFUL
 *   locale?: string,
 *   processed?: boolean, - true when the publication was processed and a notification about it was shown
 * }
 *
 * interface initialState {
 *   [code: string]: Entity[]
 * }
 */
export const initialState = {};

export const PUBLISH_PROGRESS_ADD = 'PUBLISH_PROGRESS_ADD';
export const PUBLISH_PROGRESS_UPDATE = 'PUBLISH_PROGRESS_UPDATE';
export const PUBLISH_PROGRESS_FINISH = 'PUBLISH_PROGRESS_FINISH';

export const select = state => state.publicationsProgress || {};

export const getProgressPublicationsIDs = createSelector(
  select,
  data => Object.values(data)
    .filter(({ status }) => status === PUBLICATION_STATUSES.IN_PROGRESS)
    .map(({ id }) => id),
);

/**
 * Return publications grouped by LRs code, which processed (have SUCCESS/FAILED status)
 * and about which there was no notification (processed != true)
 */
export const getProcessedPublications = createSelector(select,
  data => {
    const res = [];
    Object.entries(groupBy(data, 'code')).forEach(([code, locales]) => {
      const notFinishedLocales = locales.filter(({ processed }) => !processed);
      if (notFinishedLocales.length && notFinishedLocales.every(({ status }) => [PUBLICATION_STATUSES.SUCCESSFUL, PUBLICATION_STATUSES.FAILED].includes(status))) {
        res.push({ code, locales: notFinishedLocales });
      }
    });
    return res;
  },
);

/**
 * Return publications grouped by LRs code.
 * LRs codes where locales are still being processed is displayed above.
 */
export const getPublicationsState = createSelector(select,
  data => {
    const progress = [];
    const finished = [];
    Object.entries(groupBy(data, 'code')).forEach(([code, locales]) => {
      if (locales.every(({ processed }) => !!processed)) {
        finished.push({ code, locales });
      }
      else {
        progress.push({ code, locales });
      }
    });

    return [
      ...sortBy(progress, 'code'),
      ...sortBy(finished, 'code'),
    ];
  });

const filterData = data => data.filter(({ code, locale, id }) => !!code && !!locale && !!id);
export const setID = items => items.map(el => {
  const { code, locale } = el;
  return {
    ...el,
    storeId: `${code}_${locale}`,
  };
});

export const addPublications = data => ({ type: PUBLISH_PROGRESS_ADD, payload: filterData(data) });
export const updatePublications = data => ({ type: PUBLISH_PROGRESS_UPDATE, payload: filterData(data) });
export const finishPublications = data => ({ type: PUBLISH_PROGRESS_FINISH, payload: data });

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PUBLISH_PROGRESS_ADD: {
      const items = (payload || []).map(el => ({ ...el, status: PUBLICATION_STATUSES.IN_PROGRESS }));
      return {
        ...state,
        ...keyBy(setID(items), 'storeId'),
      };
    }
    case PUBLISH_PROGRESS_UPDATE: {
      return {
        ...state,
        ...keyBy(setID(payload), 'storeId'),
      };
    }
    case PUBLISH_PROGRESS_FINISH: {
      const items = map(flatten(payload.map(({ locales }) => locales)), 'storeId');
      return {
        ...state,
        ...items.reduce((acc, id) => {
          acc[id] = { ...state[id], processed: true };
          return acc;
        }, {}),
      };
    }
    default:
      return state;
  }
};
