import React, { useState, memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ProgressButton } from '_ui';
import {
  MEDIA_TYPE_INTERACTIVE,
} from 'constants/mediaTypes';
import { initWindow, addErrorInfo, loadInFrame } from './utils/dom';
import usePreview from './usePreview';

const InteractivePreview = (props) => {
  const { label, classes, ...previewProps } = props;
  const { id, locale, code, mediaType } = previewProps;
  const [getPreview, { bucket, isLoading, source }] = usePreview(previewProps);

  // for MEDIA_TYPE_INTERACTIVE
  const [timers, setTimers] = useState([]);
  useEffect(() => () => {
    timers.forEach((timer) => clearInterval(timer));
  }, [timers]);

  const display = async (wd, source) => {
    try {
      const headResponse = await fetch(source, { method: 'HEAD' });
      if (!headResponse.ok) throw new Error(headResponse.statusText);

      if (mediaType === MEDIA_TYPE_INTERACTIVE) {
        const frameTimers = loadInFrame(wd, source);
        if (frameTimers.length) setTimers(frameTimers);
      }
      else {
        wd.location = source;
      }
    }
    catch (error) {
      addErrorInfo(wd, error?.message);
    }
  };

  const onClick = useCallback(async () => {
    const wd = initWindow(code, locale);
    if (bucket?.includes(`/${code}_${locale}`) && source) {
      display(wd, source);
      return;
    }

    getPreview((source) => {
      if (source) display(wd, source);
    }, (msg) => {
      addErrorInfo(wd, msg);
    });
  }, [bucket, source, code, locale]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <ProgressButton
        size="small"
        color="primary"
        loading={isLoading}
        seleniumId={`preview-button-${id}`}
        onClick={onClick}
        label={label}
        className={classes.previewButton}
      />
    </div>
  );
};

InteractivePreview.propTypes = {
  classes: PropTypes.object,
  code: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  locale: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  storeBucket: PropTypes.string,
};

export default memo(InteractivePreview);
