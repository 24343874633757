import React from 'react';
import {
  SimpleForm,
  TextField,
} from 'react-admin';
import { getLocaleModifyRedirectHref } from 'utils';
import {
  TextInput,
  ToolBar,
  CreateLocale,
  ReferenceField,
} from '_ui';

const AuthorCreateLocale = props => (
  <CreateLocale {...props}>
    <SimpleForm toolbar={<ToolBar />} redirect={getLocaleModifyRedirectHref}>
      <ReferenceField
        label="Author Code"
        source="parentId"
        link={false}
        reference="authors"
      >
        <TextField data-seleniumid="author-locale-modify-code" source="code" />
      </ReferenceField>
      <ReferenceField
        label="Language Code"
        link={false}
        perPage={50}
        reference="languages"
        source="locale"
      >
        <TextField data-seleniumid="author-locale-modify-locale" source="code" />
      </ReferenceField>
      <TextInput label="Given Name" source="firstName" seleniumId="author-locale-modify-firstName" />
      <TextInput label="Family Name" source="lastName" seleniumId="author-locale-modify-lastName" />
      <TextInput
        multiline
        source="biography" style={{ width: '70%' }}
        seleniumId="author-locale-modify-biography"
        inputProps={{ autoComplete: 'off' }}
      />
    </SimpleForm>
  </CreateLocale>
);

export default AuthorCreateLocale;
