import { parseSortParamsToListParams } from 'utils';

export const DEFAULT_SORT_FIELD = 'id';
export const DEFAULT_SORT_ORDER = 'ASC';

export const LO_SORT_PARAMS = { sort: 'code', order: 'ASC' };
export const IMPORT_SORT_PARAMS = { sort: 'created', order: 'DESC' };
export const PUBLICATION_SORT_PARAMS = { sort: 'publicationDate', order: 'DESC' };
export const REPORT_SORT_PARAMS = { sort: 'title', order: 'ASC' };

export const LO_LIST_SORT_PARAMS = parseSortParamsToListParams(LO_SORT_PARAMS);
export const IMPORT_LIST_SORT_PARAMS = parseSortParamsToListParams(IMPORT_SORT_PARAMS);
export const PUBLICATION_LIST_SORT_PARAMS = parseSortParamsToListParams(PUBLICATION_SORT_PARAMS);
export const REPORT_LIST_SORT_PARAMS = parseSortParamsToListParams(REPORT_SORT_PARAMS);
