import { startCase } from 'utils';

const addFieldNames = ({ field, name }) => ({ field, name: name || startCase(field) });

export const LR_REQUIRED_FOR_PUBLISH = [
  { field: 'code' },
  { field: 'contentName' },
  { field: 'publisher' },
  { field: 'programId', name: 'Program' },
  { field: 'disciplineId', name: 'Discipline' },
].map(addFieldNames);

export const LR_LOCALE_REQUIRED_FOR_PUBLISH = [
  { field: 'title' },
  { field: 'brief', name: 'In Brief' },
  { field: 'audience', name: 'Target audience' },
  { field: 'thumbnailUrl', name: 'Thumbnail' },
  { field: 'editorialStatus' },
  { field: 'interactivity' },
  { field: 'duration' },
  { field: 'level' },
].map(addFieldNames);

export const LR_REQUIRED_FIELD_LIST = [
  ...LR_REQUIRED_FOR_PUBLISH,
  ...LR_LOCALE_REQUIRED_FOR_PUBLISH,
].map(({ field }) => field.toLowerCase());

export const REQUIRED_FOR_PUBLISH_MESSAGE = 'Required for publishing';
export const REQUIRED_FOR_PUBLISH_ANNOTATION = `* - ${REQUIRED_FOR_PUBLISH_MESSAGE}`;

export const STALE_SUBTITLE_MESSAGE = 'The Videocast has been re-imported. Please check the subtitles file as you may want to re-import it too.';
export const THERE_ARE_NO_SUBTITLES_MESSAGE = 'Subtitles are yet to be uploaded for this Videocast.';
export const THERE_ARE_NO_VIDEOS_MESSAGE = 'Video is yet to be uploaded for this Videocast.';
export const THERE_ARE_NO_PDF_MESSAGE = 'A PDF file is yet to be uploaded for this';

export const NOT_PUBLISH = 'There are no locales to publish. The page has been refreshed.';
