import React from 'react';

import MuiGrid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  gridItem: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'stretch',
    alignContent: 'stretch',
    flexDirection: 'column',
    '&>div': {
      flexGrow: 1,
    },
  },
});

const GridTile = props => {
  const classes = useStyles();

  return (
    <MuiGrid
      className={classes.gridItem}
      {...props}
      item
    />
  );
};

export default GridTile;
