import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { generateSearchParams } from 'utils';

import {
  ReferenceField,
  ReferenceManyField,
  Button,
} from 'react-admin';

import { VideoLibrary as LearningResourceIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

import {
  TextField,
  LocaleReferenceField,
  SimpleShowLayout,
  ChipLink,
  TabbedLanguagesView,
  TextAreaShowField,
} from '_ui';

const sanitizeProps = ({ languageSpecificFields, languageAgnosticFields, hasCreate, hasShow, hasList, staticContext, ...props }) => props;

const useStyle = makeStyles(theme => ({
  wrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  lrLinkBtn: {
    marginBottom: theme.spacing(2),
  },
}));

const RenderChildPrograms = props => {
  const { data, basePath } = props;
  const childChips = [];
  _.forOwn(data, (value, key) =>
    childChips.push(<ChipLink seleniumId="program-child-code" key={key} basePath={basePath} record={{ id: key, code: value.code }} />));
  return childChips;
};

const getLRLink = id => generateSearchParams('#/learning-objects', { filter: { programId: [id] } });

const ProgramsView = props => {
  const classes = useStyle();
  return (
    <TabbedLanguagesView
      {...sanitizeProps(props)}
      resource="programs"
      languageAgnosticFields={(props) => (
        <SimpleShowLayout {...props}>
          <TextField source="code" seleniumId="program-code" />
          <ReferenceField
            source="parentId"
            reference="programs"
            label="Parent Program"
            link={false}
          >
            <ChipLink seleniumId="program-parent-code" />
          </ReferenceField>

          <ReferenceManyField
            label="Children"
            reference="programs"
            target="parentId"
          >
            <RenderChildPrograms />
          </ReferenceManyField>
          <>
            <Button
              href={getLRLink(props.id)}
              className={classes.lrLinkBtn}
              variant="outlined"
              data-seleniumid="related-lrs-button"
              label="Open Related Learning Resources"
            >
              <LearningResourceIcon />
            </Button>
          </>
        </SimpleShowLayout>
      )}
      languageSpecificFields={(props) => (
        <SimpleShowLayout {...props}>
          {[
            ['Title', 'title', { 'data-seleniumid': 'programs-locale-title' }],
            ['Abstract', 'summary', { 'data-seleniumid': 'programs-locale-abstract', allowHTML: true }, TextAreaShowField],
          ].map(([label, source, options, Component = TextField]) => (
            <LocaleReferenceField
              {...sanitizeProps(props)}
              key={`programs-field-${source}`}
              addLabel
              link={false}
              source="id"
              label={label}
              reference="programs-locales"
              usePriorityLocale={false}
              className={classes.wrap}
            >
              <Component showProgress source={source} {...options} />
            </LocaleReferenceField>
          ))}
        </SimpleShowLayout>
      )}
    />
  );
};

ProgramsView.propTypes = {
  id: PropTypes.string,
};

export default ProgramsView;
