import { GET_LIST } from 'react-admin';

export default (wrappedDataProvider) => async (type, resource, params) => {
  const result = await wrappedDataProvider(type, resource, params);

  if (type === GET_LIST && resource === 'languages' && result.data?.length) {
    result.data = result.data.sort((a, b) => a.id.localeCompare(b.id));
  }

  return result;
};
