import React from 'react';
import { SimpleForm } from 'react-admin';
import { getLocaleModifyRedirectHref } from 'utils';
import {
  TextField,
  TextInput,
  ToolBar,
  Edit,
  ReferenceField,
} from '_ui';

const AuthorEditLocale = props => (
  <Edit undoable={false} {...props}>
    <SimpleForm toolbar={<ToolBar />} redirect={getLocaleModifyRedirectHref}>
      <ReferenceField
        label="Author Code"
        source="parentId"
        link={false}
        reference="authors"
      >
        <TextField source="code" date-seleniumid="author-locale-modify-code" />
      </ReferenceField>
      <TextField source="locale" label="Language" seleniumId="author-locale-modify-locale" />
      <TextInput label="Given Name" source="firstName" seleniumId="author-locale-modify-firstName" />
      <TextInput label="Family Name" source="lastName" seleniumId="author-locale-modify-lastName" />
      <TextInput
        multiline
        source="biography"
        style={{ width: '70%' }}
        seleniumId="author-locale-modify-biography"
        inputProps={{ autoComplete: 'off' }}
      />
    </SimpleForm>
  </Edit>
);

export default AuthorEditLocale;
