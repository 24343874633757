/* eslint-disable react/no-unused-prop-types */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { generatePath, useHistory } from 'react-router';
import { getResourceFromPathname } from 'utils/router';
import IconButton from '@material-ui/core/IconButton';
import LayersIcon from '@material-ui/icons/Layers';

import { ROUTE_PATTERNS } from 'constants/routes';
import { formatDateWithTZ } from '_ui/Field/DateField';

const RepublicationButton = props => {
  const history = useHistory();
  const { location: { pathname } } = history;

  const {
    record: {
      id,
      nextSuccessfulPubId,
      nextSuccessfulPubDate,
    },
    resource = getResourceFromPathname(pathname),
  } = props;

  const onClick = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();

    history.push(generatePath(ROUTE_PATTERNS.show, {
      resource,
      id: nextSuccessfulPubId,
    }));
  }, [resource, nextSuccessfulPubId]);

  const buttonTitle = `Successful republication${nextSuccessfulPubDate
    ? ` ${formatDateWithTZ(nextSuccessfulPubDate)}`
    : ''}`;

  return (
    <IconButton
      title={buttonTitle}
      size="medium"
      onClick={onClick}
      color="primary"
      data-seleniumid={`${id}-status-republication`}
    >
      <LayersIcon />
    </IconButton>
  );
};

RepublicationButton.propTypes = {
  record: PropTypes.shape({
    id: PropTypes.string.isRequired,
    nextSuccessfulPubDate: PropTypes.string,
    nextSuccessfulPubId: PropTypes.string.isRequired,
  }),
  resource: PropTypes.string.isRequired,
};

RepublicationButton.defaultProps = {
  record: {},
};

export default RepublicationButton;
