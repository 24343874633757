import { LR, PUBLICATIONS, IMPORTS } from 'constants/resources';

export const PROCESS_MANUAL_EXPAND = 'PROCESS_MANUAL_EXPAND';
export const RESET_MANUAL_EXPAND = 'RESET_MANUAL_EXPAND';
export const EXPANDABLE_RESOURCES = [LR, PUBLICATIONS, IMPORTS];
export const MANUAL_EXPANDABLE_RESOURCES = [LR];
export const LOCALE_EXPANDABLE_RESOURCES = [LR];
export const processManualExpand = (id) => ({ type: PROCESS_MANUAL_EXPAND, id });
export const resetManualExpand = () => ({ type: RESET_MANUAL_EXPAND });

export default (state = [], { type, id }) => {
  switch (type) {
    case PROCESS_MANUAL_EXPAND: {
      return state.includes(id) ? state.filter(el => el !== id) : state.concat(id);
    }
    case RESET_MANUAL_EXPAND:
      return [];
    default:
      return state;
  }
};
