export const capString = str => str.charAt(0).toUpperCase() + str.slice(1);

export const getCapLabel = (label = '') => {
  if (!label || typeof label !== 'string') return '';
  return label.split(' ').map(el => capString((el || '').toLowerCase())).join(' ');
};

export const slugify = (source = '') => (source || '')
  .replace(/[^a-zA-Z|\s]+/g, ' ')
  .trim()
  .replace(/\s+/g, '-')
  .toLowerCase();

export function noWhiteSpaceTag(strings, ...placeholders) {
  const concatStrings = strings.reduce((result, string, i) => `${result}${placeholders[i - 1]}${string}`);
  return concatStrings.replace(/$\n^\s*/gm, ' ');
}
