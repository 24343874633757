import React from 'react';
import {
  ReferenceField,
  ChipField,
  SingleFieldList,
  ReferenceArrayField,
  FunctionField,
} from 'react-admin';
import Box from '@material-ui/core/Box';

import {
  Grid,
  GridTile,
  ViewCard,
  LocaleReferenceField,
  TextField,
  UserField,
  DateField,
} from '_ui';

import { CN_VIDEOCASTS_FILTER } from 'constants/contentNames';
// todo CK1.0+ CPP0-1882
// eslint-disable-next-line no-unused-vars
import { LR_LINKS_CONFIG } from '../constants';
import LearningResourceACField, { useAssociatedContent } from '../Fields/ACField';
import LearningResourceLinkedField from '../Fields/LinkedField';

import { markComponentRequiredForPublish } from './Actions/utils';
import RequiredAnnotation from './Actions/RequiredAnnotation';

const GeneralCard = props => {
  const fieldComponents = [
    <LocaleReferenceField
      key="title"
      source="id"
      label="Title"
      reference="learning-objects-locales"
      link={false}
    >
      <TextField source="title" data-seleniumid="lo-title" />
    </LocaleReferenceField>,
    <TextField key="code" source="code" label="Code" data-seleniumid="lo-code" />,
    <ReferenceField
      key="contentName"
      source="contentName"
      label="Content Type"
      reference="content-names"
      link={false}
    >
      <TextField source="name" data-seleniumid="lo-content-name" />
    </ReferenceField>,
    <ReferenceField
      key="publisher"
      label="Publisher"
      reference="publishers"
      source="publisher"
      sort={{ field: 'name', order: 'DESC' }}
      allowEmpty
      link={false}
    >
      <TextField source="name" data-seleniumid="lo-publisher" />
    </ReferenceField>,
  ];

  return (
    <ViewCard {...props} headerTitle="General" data-seleniumid="lo-general-card">
      {fieldComponents.map(markComponentRequiredForPublish)}
    </ViewCard>
  );
};

const ModificationCard = props => (
  <ViewCard {...props} headerTitle="Modification Data" data-seleniumid="lo-modifications-card">
    <UserField
      label="Creator"
      seleniumId="lo-creator"
      source="creator"
    />
    <DateField source="created" data-seleniumid="lo-created" />
    <UserField
      label="Modifier"
      seleniumId="lo-modifier"
      source="modifier"
    />
    <DateField source="modified" data-seleniumid="lo-modified" />
  </ViewCard>
);

const ReferencesCard = props => {
  const contentName = props?.record?.contentName;
  let fieldComponents = [];

  if (
    !LR_LINKS_CONFIG[contentName]
    || LR_LINKS_CONFIG[contentName].ESSENTIAL
    || LR_LINKS_CONFIG[contentName].ESSENTIAL_THE_TEAM
  ) {
    fieldComponents.push(
      <ReferenceField
        key="essentialId"
        label="Essential"
        reference="learning-objects"
        source="essentialId"
        sort={{ field: 'id', order: 'DESC' }}
        link="show"
        allowEmpty
      >
        <TextField source="code" data-seleniumid="lo-essential" />
      </ReferenceField>,
    );
  }

  if (
    !LR_LINKS_CONFIG[contentName]
    || LR_LINKS_CONFIG[contentName].ACTION_TIPS
    || LR_LINKS_CONFIG[contentName].ACTION_TIPS_THE_TEAM
  ) {
    fieldComponents.push(
      <LearningResourceLinkedField
        key="actionTips"
        source="actionTips"
        label="Action Tips"
        seleniumId="lo-action-tips"
      />,
    );
  }

  if (
    !LR_LINKS_CONFIG[contentName]
    || !CN_VIDEOCASTS_FILTER.includes(contentName)
  ) {
    if (!contentName?.toLowerCase()?.includes('session')) {
      fieldComponents.push(
        <LearningResourceACField
          key="sessions"
          associationType="sessions"
          label="Sessions"
          sortable={undefined}
          seleniumId="lo-session"
        />,
      );
    }

    fieldComponents.push(
      <LearningResourceACField
        key="videocasts"
        associationType="videocasts"
        label="Videocast Resources"
        sortable={undefined}
        seleniumId="lo-videocasts"
      />,
    );
  }

  fieldComponents = [
    ...fieldComponents,
    <ReferenceField key="authorId" source="authorId" reference="authors" link={false}>
      <LocaleReferenceField
        reference="authors-locales"
        source="id"
        link={false}
      >
        <FunctionField
          data-seleniumid="lo-author-name"
          render={({ firstName, lastName }) => `${firstName} ${lastName}`}
        />
      </LocaleReferenceField>
    </ReferenceField>,
    <ReferenceArrayField key="themeIds" label="Themes" reference="themes" source="themeIds">
      <SingleFieldList linkType={false}>
        <LocaleReferenceField
          source="id" label="Title" reference="themes-locales" link={false}
        >
          <ChipField source="title" data-seleniumid="lo-theme" />
        </LocaleReferenceField>
      </SingleFieldList>
    </ReferenceArrayField>,
    <ReferenceArrayField key="tagIds" label="Tags" reference="tags" source="tagIds">
      <SingleFieldList linkType={false}>
        <LocaleReferenceField
          source="id" label="Title" reference="tags-locales" link={false}
        >
          <ChipField source="title" data-seleniumid="lo-tag" />
        </LocaleReferenceField>
      </SingleFieldList>
    </ReferenceArrayField>,
    <ReferenceField
      key="mainSkillsetId"
      source="mainSkillsetId"
      label="Major Skill Set"
      reference="skillsets"
      link={false}
    >
      <LocaleReferenceField
        source="id" label="Title" reference="skillsets-locales" link={false}
      >
        <ChipField source="title" data-seleniumid="lo-major-skillset" />
      </LocaleReferenceField>
    </ReferenceField>,
    <ReferenceArrayField key="skillsetIds" label="Skill Sets" reference="skillsets" source="skillsetIds">
      <SingleFieldList linkType={false}>
        <LocaleReferenceField
          source="id" label="Title" reference="skillsets-locales" link={false}
        >
          <ChipField source="title" data-seleniumid="lo-skillset" />
        </LocaleReferenceField>
      </SingleFieldList>
    </ReferenceArrayField>,
    <ReferenceField
      key="programId"
      source="programId"
      label="Program"
      reference="programs"
      link={false}
    >
      <LocaleReferenceField
        source="id" label="Title" reference="programs-locales" link={false}
      >
        <TextField source="title" data-seleniumid="lo-program" />
      </LocaleReferenceField>
    </ReferenceField>,
    <ReferenceField
      key="disciplineId"
      source="disciplineId"
      label="Discipline"
      reference="disciplines"
      link={false}
    >
      <LocaleReferenceField
        source="id" label="Title" reference="disciplines-locales" link={false}
      >
        <TextField source="title" data-seleniumid="lo-discipline" />
      </LocaleReferenceField>
    </ReferenceField>,
  ];

  return (
    <ViewCard
      {...props}
      headerTitle="References"
      data-seleniumid="lo-references-card"
    >
      {fieldComponents.map(markComponentRequiredForPublish)}
    </ViewCard>
  );
};

const MetadataView = (props) => {
  useAssociatedContent(props);

  return (
    <>
      <Grid spacing={1} direction="column">
        <Grid xs={12}>
          <Box marginLeft={2}>
            <RequiredAnnotation />
          </Box>
        </Grid>
        <Grid spacing={1}>
          <GridTile xs={12} md={4}>
            <GeneralCard {...props} />
          </GridTile>
          <GridTile xs={12} md={4}>
            <ReferencesCard {...props} />
          </GridTile>
          <GridTile xs={12} md={4}>
            <ModificationCard {...props} />
          </GridTile>
        </Grid>
      </Grid>
    </>
  );
};

export default MetadataView;
