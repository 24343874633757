import React from 'react';
import {
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  TextField,
} from 'react-admin';
import {
  CreateLocale,
  ToolBar,
  TextInput,
  ReferenceField,
} from '_ui';
import { getLocaleModifyRedirectHref } from 'utils';
import { formatSynonyms } from './utils';

const TagCreateLocale = props => (
  <CreateLocale {...props}>
    <SimpleForm redirect={getLocaleModifyRedirectHref} toolbar={<ToolBar />}>
      <ReferenceField
        label="Tag Code"
        source="parentId"
        link={false}
        reference="tags"
        data-seleniumid="tag-locale-modify-code"
      >
        <TextField source="code" />
      </ReferenceField>
      <ReferenceField
        data-seleniumid="tag-locale-modify-locale"
        label="Language Code"
        link={false}
        perPage={50}
        reference="languages"
        source="locale"
      >
        <TextField source="code" />
      </ReferenceField>
      <TextInput autoComplete="off" source="title" seleniumId="tag-locale-modify-title" />
      <ArrayInput source="synonym" label="Synonyms">
        <SimpleFormIterator>
          <TextInput label={false} format={formatSynonyms} seleniumId="tag-locale-modify-synonym" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </CreateLocale>
);

export default TagCreateLocale;
