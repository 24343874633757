import React from 'react';
import PropTypes from 'prop-types';

import Create from './Create';

const CreateLocale = ({ children, ...props }) => {
  const { resource, history: { location: { pathname } } } = props;
  const [, rawParams] = pathname.split(`/${resource}/create/`);
  const [parentId, locale] = rawParams.split('/');

  return (
    <Create {...props} record={{ id: parentId, parentId, locale }}>
      {children}
    </Create>
  );
};

CreateLocale.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
};

export default CreateLocale;
