import _ from 'lodash';

export const formatToUpperCase = string => (
  `${string[0].toUpperCase()}${string.slice(1)}`
);

export const formatNumberInput = (value, rawLength) => {
  if (_.isNil(value)) return null;
  const strValue = value.toString();
  if (!strValue) return null;

  const digits = strValue.match(/\d+/g);
  if (!digits) return null;
  if (digits.length === 1 && digits[0] === '0') return null;
  const strDigit = digits.join('');
  const length = (!rawLength || rawLength < 1) ? strDigit.length : rawLength;

  return parseInt(strDigit.substr(0, length), 10);
};

export const formatToBasename = (fileName) => (fileName || '').split('/').pop();

export const startCase = (string) => _.startCase(string)
  .toLowerCase()
  .replace(/^./, (str) => str.toUpperCase());
