import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getReferenceResource } from 'react-admin';
import get from 'lodash/get';
import { LR_VIDEO_CONTENT_LIST, LR, LR_LOCALES, CONTENT_NAMES } from 'constants/resources';
import { createSelector } from 'reselect';

export const useGetResourceSelector = (reference, path = 'data') => {
  const resource = useSelector(state => getReferenceResource(
    state,
    { reference },
  ));
  return get(resource, path) || {};
};

export const useGetResourceLocaleSelector = ({ resource, locale }) => {
  const resourceLocales = useGetResourceSelector(`${resource}-locale`);
  return useMemo(() => Object.keys(resourceLocales).find(id => (
    new RegExp(`${locale}$`).test(id)
  )), [resourceLocales, locale]);
};

export const selectLRs = state => get(state, `admin.resources.${LR}.data`, {});
export const selectLocalesLRs = state => get(state, `admin.resources.${LR_LOCALES}.data`, {});
export const selectDisplayedLRs = state => get(state, `admin.resources.${LR}.list.ids`, []);
export const selectFilterLRs = state => get(state, `admin.resources.${LR}.list.params.filter`, {});
export const selectContentNames = state => get(state, `admin.resources.${CONTENT_NAMES}.data`, {});
const selectLRsPreviewContent = state => get(state, `admin.resources.${LR_VIDEO_CONTENT_LIST}.data`, {});

export const selectLRPreviewContent = lrId => createSelector(
  selectLRsPreviewContent,
  data => {
    const { entities } = data[lrId] || {};
    return entities || [];
  },
);

export const selectLocaleLR = localeId => createSelector(
  selectLocalesLRs,
  locales => locales[localeId],
);

export const selectLocalesByLR = lrId => createSelector(
  selectLocalesLRs,
  locales => Object.keys(locales)
    .filter(id => id.startsWith(lrId))
    .map(id => locales[id]),
);

export const selectLR = lrId => createSelector(
  selectLRs,
  lrs => lrs[lrId] || {},
);
export const selectAllLocalesByLRId = lrId => createSelector(
  selectLocalesLRs,
  locales => Object.keys(locales)
    .filter(key => locales[key].parentId === lrId)
    .map(key => ({ key, value: locales[key] })),
);

export const getContentName = contentType => createSelector(
  selectContentNames,
  names => (names[contentType] || {}).name,
);

export const selectIsLoading = state => state.admin.loading > 0;

export const selectResource = (resource, id) => state => get(state, `admin.resources.${resource}.data.${id}`, null);
