import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  MEDIA_TYPE_INTERACTIVE,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_READING,
} from 'constants/mediaTypes';
import PropTypes from 'prop-types';
import {
  makeStyles,
} from '@material-ui/core';
import InteractivePreview from './InteractivePreview';
import VideoPreview from './VideoPreview';

const useStyles = makeStyles({
  previewButton: isShow => (isShow ? { marginBottom: '1rem' } : {}),
});

const Preview = ({ isShow, ...props }) => {
  const { mediaType, resource, id } = props;
  const { bucket: storeBucket } = useSelector((state) => _.get(state, `admin.resources.${resource}.data.${id}`)) || {};
  const classes = useStyles(isShow);

  let Component;

  switch (mediaType) {
    case MEDIA_TYPE_READING:
    case MEDIA_TYPE_INTERACTIVE:
      Component = InteractivePreview;
      break;
    case MEDIA_TYPE_VIDEO:
      Component = VideoPreview;
      break;
    default:
      return null;
  }

  return (
    <Component
      {...props}
      storeBucket={storeBucket}
      classes={classes}
    />
  );
};

Preview.propTypes = {
  allAvailableLocales: PropTypes.arrayOf(PropTypes.string),
  code: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isLegacyPath: PropTypes.bool,
  isLegacyVideo: PropTypes.bool,
  isShow: PropTypes.bool,
  label: PropTypes.string,
  locale: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
};

Preview.defaultProps = {
  label: 'Open Preview',
  isShow: false,
  isLegacyPath: true,
};

export default Preview;
