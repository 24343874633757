import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  ReferenceArrayInput,
  TextField,
  CreateButton,
  RichTextField,
  useMutation,
  useNotify,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import {
  Datagrid,
  Filter,
  List,
  LocaleListField,
  LocaleReferenceField,
  AutocompleteArrayInput,
  TextInput,
  CollapsedTextField,
  SelectInput,
  TaxonomyButton,
} from '_ui';

import { withCustomWidthTableStyles } from 'utils/styleUtils';
import {
  TAXONOMY_DOWNLOAD_EVENT_TYPE,
  TAXONOMY_DOWNLOAD_MESSAGE,
  generateTaxonomyReport as generateTaxonomyReportUtil,
  getTaxonomyReportParams,
} from 'utils/exports';
import { BASE_SEARCH_SOURCE } from 'constants';

import { REPUTATION_OPTIONS } from './constants';
import { AvatarField } from './Fields';

const AuthorFilter = (props) => (
  <Filter {...props} seleniumId="author-filter">
    <TextInput
      alwaysOn
      allowEmpty
      label="Search"
      source={BASE_SEARCH_SOURCE}
      seleniumId="authors-filter-search"
      isFilter
    />

    <ReferenceArrayInput
      allowEmpty
      alwaysOn
      label="Language"
      perPage={50}
      reference="languages"
      sort={{ field: 'name', order: 'ASC' }}
      source="availableLocales"
    >
      <AutocompleteArrayInput
        maxValuesArrayLength={1}
        seleniumId="authors-filter-languages"
        optionText="code"
        allowDuplicates={false}
        allowEmpty={false}
      />
    </ReferenceArrayInput>

    <TextInput
      source="code"
      isFilter
      seleniumId="authors-filter-code"
    />

    <SelectInput
      isFilter
      label="Reputation"
      source="reputation"
      choices={REPUTATION_OPTIONS}
      seleniumId="authors-filter-reputation"
    />
  </Filter>
);

const columnWidth = {
  thumbnailUrl: 10,
  code: 10,
  givenName: 10,
  lastName: 10,
  biography: 35,
  availableLocales: 25,
};

const useStyle = makeStyles(withCustomWidthTableStyles(columnWidth));

const AuthorList = (props) => {
  const { hasCreate } = props;
  const history = useHistory();
  const notify = useNotify();
  const classes = useStyle();

  const [generate] = useMutation(
    getTaxonomyReportParams('author'),
  );

  const generateTaxonomyReport = useCallback(() => {
    generate();
    const downloadWindow = generateTaxonomyReportUtil('author');
    downloadWindow.addEventListener(TAXONOMY_DOWNLOAD_EVENT_TYPE, () => {
      notify(`Authors ${TAXONOMY_DOWNLOAD_MESSAGE}`);
    });
  }, [generate, notify]);

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<AuthorFilter />}
      actions={(
        <>
          {hasCreate
            ? <CreateButton basePath="../authors" data-seleniumid="create-author-btn" />
            : null}
          <TaxonomyButton
            onClick={generateTaxonomyReport}
            data-seleniumid="generate-author-taxonomy-report"
          />
        </>
      )}
      seleniumId="authors"
      sort={{ field: 'code', order: 'ASC' }}
    >
      <Datagrid localeRowClick classes={classes}>
        <AvatarField
          seleniumId="author-avatar"
          size={80}
          label="Author Thumbnail"
          source="thumbnailUrl"
          sortable={undefined}
        />
        <TextField source="code" data-seleniumid="author-code" />
        <LocaleReferenceField
          sortable={undefined}
          link={false}
          source="id"
          label="Given Name"
          reference="authors-locales"
        >
          <TextField source="firstName" data-seleniumid="author-locale-firstName" />
        </LocaleReferenceField>
        <LocaleReferenceField
          sortable={undefined}
          link={false}
          source="id"
          label="Family Name"
          reference="authors-locales"
        >
          <TextField source="lastName" data-seleniumid="author-locale-lastName" />
        </LocaleReferenceField>
        <LocaleReferenceField
          sortable={undefined}
          link={false}
          source="id"
          label="Biography"
          reference="authors-locales"
        >
          <CollapsedTextField
            numLines={4}
            source="biography"
            seleniumId="author-locale-biography"
            Component={RichTextField}
          />
        </LocaleReferenceField>
        <LocaleListField
          sortable={undefined}
          source="availableLocales"
          label="Available in"
          hideLabel
          targetResource="authors"
          onClick={(locale, recordId) => {
            const { basePath } = props;
            history.push(`${basePath}/${recordId}/show/${locale}`);
          }}
        />
      </Datagrid>
    </List>
  );
};

AuthorList.propTypes = {
  basePath: PropTypes.string,
  hasCreate: PropTypes.bool,
};

export default AuthorList;
