import XRegExp from 'xregexp';
import { get, flatten, groupBy, pick } from 'lodash';
import { isAfter, subMonths } from 'date-fns';

import { formatExportDate, getEmptyFieldsMap } from 'utils/export';
import { parseResourceCode, parseFileExtension } from 'utils/regex';
import { parseResourceLocale } from 'utils/locales';
import {
  REGEX_GMO_CODE,
  REGEX_LR_CODE,
  REGEX_LR_CODE_WITH_LOCALE,
  REGEX_LOCALE,
} from 'constants/regex';
import {
  ACTION_TIPS_AND_ESSENTIAL_PREFIX,
} from 'constants/actionTips';
import {
  fileImportType,
  CONTENT_TO_FILE_TYPE_MAP,
  REGEX_FILENAME_IMPORT,
  EXPORT_FIELDS_MAP,
} from './constants';

const typesWithSubTypes = ['GlobalMetadata', 'LOAssociations'];
const singleFileTypes = ['GlobalMetadata', 'LOAssociations'];

export const isDisplaySubTypes = ({ type }) => typesWithSubTypes.includes(type);
export const isMultipleFiles = ({ type }) => !singleFileTypes.includes(type);

export const validateCodeWithLocale = name => new RegExp(`^${REGEX_LR_CODE_WITH_LOCALE.source}$`).test(name);
export const mapContentToFileType = (type) => CONTENT_TO_FILE_TYPE_MAP[type];
export const validateLOContent = name => REGEX_FILENAME_IMPORT.test(name);
export const validateLOThumbnail = name => [REGEX_LR_CODE, REGEX_LR_CODE_WITH_LOCALE].some(regexp => new RegExp(`^p${regexp.source}$`).test(name));
export const validateVideocast = name => [REGEX_LR_CODE, REGEX_LR_CODE_WITH_LOCALE].some(regexp => new RegExp(`^${regexp.source}$`).test(name));
export const validateAuthorThumbnail = name => XRegExp(new RegExp(`^${REGEX_GMO_CODE.source}$`)).test(name);

export const getThumbType = (name) => {
  if (validateLOThumbnail(name)) {
    return 'LO';
  }
  if (validateAuthorThumbnail(name)) {
    return 'AUTHOR';
  }

  return undefined;
};

export const getAuthorImportInfo = name => {
  const [code] = name.match(REGEX_GMO_CODE) || [];
  const [locale] = name.match(REGEX_LOCALE) || [];
  return { code, locale };
};

export const getMetaFromTargets = (targets = []) => flatten(
  (targets || []).map(({ code, locale, message }) => [code, locale, message]),
);

const parseExtension = el => (el ? el.replace(/(\.|\w+\/)/, '') : el);
export const getFileExtensionByType = (type) => {
  const typeExtensions = CONTENT_TO_FILE_TYPE_MAP[type];
  const extensions = (Array.isArray(typeExtensions) ? typeExtensions : (typeExtensions || '').split(',')).map(parseExtension);
  return extensions?.length === 1 ? (extensions.pop() || undefined) : extensions;
};

export const validateFilenames = (importType, source) => {
  const invalidFiles = [];

  for (const { name, rawFile } of Array.isArray(source) ? source : [source]) {
    const fileName = name ?? rawFile?.name;
    if (!fileName) {
      throw new Error('Filename not defined!');
    }
    const parsedName = fileName.replace(/\.[A-Za-z0-9]+$/, '');

    switch (importType) {
      case fileImportType.LO_INGESTION:
        if (!validateLOContent(parsedName)) invalidFiles.push(parsedName);
        break;
      case fileImportType.LO_THUMBNAIL:
        if (!getThumbType(parsedName)) invalidFiles.push(parsedName);
        break;
      case fileImportType.LCMS:
      case fileImportType.MOHIVE:
      case fileImportType.LO_PUBLISH:
      case fileImportType.LO_METADATA:
      case fileImportType.LO_ASSOCIATIONS:
      case fileImportType.LO_EXPORT:
      case fileImportType.GLOBAL_METADATA: {
        const allowedExtensions = getFileExtensionByType(importType);
        const fileExtensions = parseFileExtension(fileName, allowedExtensions);
        if (allowedExtensions?.length && !fileExtensions) invalidFiles.push(parsedName);
      }
        break;
      case fileImportType.VideoUpload:
        if (!validateVideocast(parsedName)) invalidFiles.push(parsedName);
        break;
      case fileImportType.PdfUpload:
        if (!validateCodeWithLocale(parsedName) || !ACTION_TIPS_AND_ESSENTIAL_PREFIX.some(el => fileName.startsWith(el))) invalidFiles.push(parsedName);
        break;
      case fileImportType.SubtitleUpload:
        if (!validateCodeWithLocale(parsedName)) invalidFiles.push(parsedName);
        break;
      default:
        throw new Error('Wrong import type');
    }
  }

  return invalidFiles;
};

export const isCsvImport = (record = {}) => {
  const { created } = record;
  if (!(get(record, 'source.fileName', '') || '').endsWith('.csv') || !created) return false;
  return isAfter(new Date(created), subMonths(new Date(), 2));
};

export const prepareLogsForExport = (jobs, logs, { exportFields, creators }) => {
  const logsByJobId = groupBy(logs, 'id');
  const fieldSet = Object.keys(exportFields);
  return flatten(
    jobs.map(({
      id,
      status,
      type,
      source: { fileName },
      created,
      creator,
      targets,
    }) => {
      const isThumbnailImport = type === fileImportType.LO_THUMBNAIL;
      const [target = {}] = targets || [];
      let { code, locale: language } = target;
      const { message } = target;
      if (!language) language = parseResourceLocale([message, fileName]);
      if (!code) code = parseResourceCode([message, fileName], { type, locale: language });

      const allowedExtension = getFileExtensionByType(type);
      const extension = parseFileExtension([fileName, message], [allowedExtension]);

      const jobExportEntry = {
        ...getEmptyFieldsMap(EXPORT_FIELDS_MAP),
        code: (isThumbnailImport && extension)
          ? `${code}.${extension}`
          : code,
        language,
        date: created,
        status,
        creator: creators[creator],
      };

      const jobLogs = logsByJobId[id] || [];
      const exportEntries = jobLogs.length ? jobLogs : [jobExportEntry];

      return exportEntries.map(({ id, date, targets, ...log }) => {
        const exportEntry = { ...jobExportEntry, ...log };

        const [target = {}] = targets || [];
        const { type } = target;
        let { code, locale: language } = target;

        if (!language) language = parseResourceLocale([exportEntry.message, message]);
        if (!code) code = parseResourceCode([exportEntry.message, message], { type, locale: language });
        const extension = parseFileExtension([exportEntry.message, message], [allowedExtension]);

        if (code) {
          exportEntry.code = (isThumbnailImport && extension)
            ? `${code}.${extension}`
            : code;
        }
        if (language) exportEntry.language = language;

        exportEntry.date = formatExportDate(date);

        return pick(exportEntry, fieldSet);
      });
    }));
};
