import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Preview from '_ui/Layout/Preview';
import { LR_PREVIEW, LR_PREVIEW_VIDEO } from 'constants/resources';
import { MEDIA_TYPE_VIDEO, MEDIA_TYPE_READING } from 'constants/mediaTypes';
import { useSelector } from 'react-redux';
import { selectLR } from 'ra-redux/selectors';
import { isPreviewAvailable } from 'utils';
import { isWithoutVideos, isWithoutPDF } from '_domains/LearningResource/LearningResourceView/Actions/utils';

/**
 * LR View
 * For MEDIA_TYPE_VIDEO the isLegacyPath always true, because for MEDIA_TYPE_VIDEO isLegacyPath is used only in case when isLegacyVideo - true
 * For MEDIA_TYPE_READING the isLegacyPath is true when !directlyUploadedPdf
 */
const isLegacyPath = (mediaType, directlyUploadedPdf) => {
  if (mediaType === MEDIA_TYPE_READING && directlyUploadedPdf) return false;
  return true;
};

const PreviewAction = ({
  learningResourceId,
  locale,
  tooltip,

  mainVideoExists,
  localizedVideoExists,

  localizedPdfExists,
}) => {
  const learningResource = useSelector(selectLR(learningResourceId));
  if (!learningResource) return null;

  const { code, mediaType, availableLocales: allAvailableLocales, directlyUploadedVideo, directlyUploadedPdf } = learningResource;
  if (!isPreviewAvailable(mediaType)) return null;

  const withoutVideos = isWithoutVideos({ directlyUploadedVideo, mainVideoExists, localizedVideoExists });
  const withoutPdf = isWithoutPDF({ directlyUploadedPdf, localizedPdfExists });
  if ((mediaType === MEDIA_TYPE_VIDEO && withoutVideos) || (mediaType === MEDIA_TYPE_READING && withoutPdf)) return null;

  return (
    <Preview
      tooltip={tooltip}
      id={learningResourceId}
      code={code}
      locale={locale}
      mediaType={mediaType}
      label="Open Preview"
      resource={mediaType === MEDIA_TYPE_VIDEO && directlyUploadedVideo ? LR_PREVIEW_VIDEO : LR_PREVIEW}
      allAvailableLocales={allAvailableLocales}
      isLegacyVideo={!directlyUploadedVideo}
      isLegacyPath={isLegacyPath(mediaType, directlyUploadedPdf)}
    />
  );
};

PreviewAction.propTypes = {
  learningResourceId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  localizedPdfExists: PropTypes.bool,
  localizedVideoExists: PropTypes.bool,
  mainVideoExists: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default memo(PreviewAction);
