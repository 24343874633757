import React, { memo } from 'react';
import {
  ReferenceManyField,
  Datagrid,
  DatagridBody,
} from 'react-admin';
import { getCorrectSeleniumId } from 'utils/selenium';
import {
  DateField,
  TextField,
  EmptyProgressReferenceField,
} from '_ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { withCustomWidthTableStyles } from 'utils/styleUtils';
import { DatagridRow } from '_ui/List/Datagrid';
import { ExpandDownloadField, EmptyField, StateField } from '../Fields';
import HasValueField from './HasValueField';
import HasLocaleValueField from './HasLocaleValueField';

const onRowClickHref = (id, resource) => (recordId, basePath, record) => `/${resource}/${id}/show/${record.locale}`;
const getLR = (list, id) => list.find(el => el.id === id) || { code: '-' };
const getLinkedFields = (list = [], key, learningResources) => list.map(el => {
  const learningResource = getLR(learningResources, el);
  return (
    <HasLocaleValueField
      sortable={undefined}
      source={`${getCorrectSeleniumId(key)}-${learningResource.code}`} key={el} id={el} label={`${key}: ${learningResource.code}`}
      learningResource={learningResource}
    />
  );
});

export const columnWidth = {
  download: 4,
  locale: 8,
  title: 19,
  brief: 5,
  state: 10,
  editorialStatus: 7,
  firstPublished: 7,
  lastPublished: 7,
};

const useDatagridStyle = makeStyles(withCustomWidthTableStyles(columnWidth));

const LocalesExpandRow = (props) => {
  const { id, record = {}, learningResources, resource, ...rest } = props;
  const { availableLocales, essentialId, actionTips, sessions, videocasts } = record;
  const isEssential = learningResources && essentialId;
  const LOListFilter = useSelector((state) => state.admin.resources['learning-objects'].list?.params?.filter) || {};

  const filter = {
    state: LOListFilter.state,
    availableLocales: LOListFilter.availableLocales || availableLocales,
    editorialStatus: LOListFilter.editorialStatus,
    publishedGte: LOListFilter.published?.gte,
    publishedLte: LOListFilter.published?.lte,
  };

  const dataGridClasses = useDatagridStyle();

  return (
    <ReferenceManyField
      record={record}
      resource={resource}
      {...rest}
      label="Language"
      reference="learning-objects-locales"
      target="parentId"
      filter={filter}
    >
      <Datagrid classes={dataGridClasses} rowClick={onRowClickHref(id, resource)} body={<DatagridBody row={<DatagridRow />} />}>
        <ExpandDownloadField label="" />
        <TextField source="locale" label="Language" seleniumId="locale-name" sortable={undefined} />
        <TextField source="title" seleniumId="locale-title" sortable={undefined} />
        <HasValueField source="brief" sortable={undefined} />
        <EmptyProgressReferenceField source="state" reference="learning-object-states" link={false} sortable={undefined}>
          <StateField source="name" seleniumId="locale-state" />
        </EmptyProgressReferenceField>
        <EmptyProgressReferenceField
          source="editorialStatus"
          reference="editorial-statuses"
          link={false}
          sortable={undefined}
        >
          <TextField source="name" seleniumId="locale-editorial-state" />
        </EmptyProgressReferenceField>
        <DateField source="firstPublished" data-seleniumid="locale-firstPublished" sortable={undefined} />
        <DateField source="lastPublished" data-seleniumid="locale-lastPublished" sortable={undefined} />
        {isEssential ? [essentialId].map(el => {
          const learningResource = getLR(learningResources, el);
          const { code } = learningResource;
          return (
            <HasLocaleValueField
              sortable={undefined}
              key={id}
              source={`Essential-${code}`}
              id={el}
              label={`Essential: ${code}`}
              learningResource={learningResource}
            />
          );
        }) : EmptyField()}
        {learningResources ? getLinkedFields(actionTips, 'Action Tips', learningResources) : EmptyField()}
        {learningResources ? getLinkedFields(sessions, 'Session', learningResources) : EmptyField() }
        {learningResources ? getLinkedFields(videocasts, 'Videocast', learningResources) : EmptyField() }
      </Datagrid>
    </ReferenceManyField>
  );
};

LocalesExpandRow.propTypes = {
  id: PropTypes.string.isRequired,
  learningResources: PropTypes.arrayOf(PropTypes.object),
  record: PropTypes.object,
  resource: PropTypes.string.isRequired,
};

LocalesExpandRow.defaultProps = {
  record: {},
};
export default memo(LocalesExpandRow);
