import React, { useCallback, useState } from 'react';
import { CreateButton } from 'react-admin';
import PropTypes from 'prop-types';

import { ExportButton } from '_ui';
import { EXPORT_DATE_RANGE_FILTER } from '_domains/Jobs/constants';

import { parseValue } from 'utils';
import { EXPORT_TYPES, EXPORT_FIELDS_MAP, useExport } from 'utils/export';
import { prepareLogsForExport } from '../utils';

const ImportListActions = ({ onExportMenuToggle }) => {
  const [exportType, setExportType] = useState(null);

  const finishExport = useCallback(() => {
    setExportType(null);
    onExportMenuToggle(null);
  }, []);

  const getExportEntities = useCallback(({
    mainEntities,
    detailsEntities,
    ...options
  }) => prepareLogsForExport(
    mainEntities,
    detailsEntities,
    options,
  ), []);

  const getMainEntityFilter = useCallback((filterValues) => {
    let filter = filterValues;
    if (!Object.keys(filter).some((key) => /^created/.test(key))) {
      const { gte, lte } = EXPORT_DATE_RANGE_FILTER;
      filter = {
        ...filter,
        created: {
          gte: parseValue(gte, 'created.gte'),
          lte: parseValue(lte, 'created.lte'),
        },
      };
    }
    return filter;
  }, []);

  const { inProgress } = useExport({
    resource: 'imports',
    detailResource: 'import-logs',

    exportType,
    exportFields: { ...EXPORT_FIELDS_MAP, creator: 'Creator' },
    onFulfill: finishExport,

    getExportEntities,
    getMainEntityFilter,
  });

  const triggerExport = useCallback((exportType) => {
    setExportType(exportType);
  }, []);

  return (
    <>
      <CreateButton label="Upload" basePath="/imports/bulk" data-seleniumid="import-upload-button" />
      <ExportButton
        seleniumId="import"
        onToggle={onExportMenuToggle}
        selectExport={triggerExport}
        isLoading={inProgress}
        exportTypes={EXPORT_TYPES}
      />
    </>
  );
};

ImportListActions.propTypes = {
  onExportMenuToggle: PropTypes.func,
};

export default ImportListActions;
